// eslint-disable-next-line import/no-anonymous-default-export
export default {
  language_code: "en",
  language_title: "English",
  welcome_to_connect: "Welcome to Connect.",
  Login: "Login",
  school_login_title: "School Login",
  login_btn: "Login",
  sign_up: "Sign Up",
  enter_your_email: "Enter Your Email",
  enter_your_password: "Enter Your Password",
  school_signup_title: "School Sign Up",
  signup_btn: "Sign up",
  logout: "Logout",
  change_password: "Change Password",
  current_password: "Enter current password",
  new_password: "Enter new password",
  confirm_password: "Confirm password",
  incorrect_password: "Incorrect Password",
  forgot_password: "Forgot Password?",
  valid_password:
    "Password must have 8 characters with uppercase, lowercase and a number",
  login_for_school_only_msg: "Sorry, This is login form for school only",
  login_for_student_only_msg: "Sorry, This is login form for student only",
  home: "Home",
  School: "School",
  Dashboard: "Dashboard",
  blog: "Blog",
  Contact: "Contact",
  schools: "schools",
  courses: "courses",
  applications: "Applications",
  about_us: "About Us",
  contact_us: "contact us",
  profile: "My Profile",
  for_inquiries: "For any inquiries",
  inquiry_form: "Student Inquiry Form",
  for_business_inquiries: "For inquiries about the service, click here.",
  business_inquiry_form: "Business Inquiry Form",
  submit: "Submit",
  language: "language",
  view_all: "view all",
  our_services: "our services",
  course_search: "course search",
  school_search: "school search",
  our_other_services: "our other services",
  privacy_policy: "privacy policy",
  terms_of_service: "terms of service",
  page_not_found: "Sorry, Page not found",
  student_tag_line_heading: "Study & Work in Japan!",
  student_tag_line_subheading:
    "Choose a unique study abroad program that suits you",
  find_best_matched_course: "Find your best matched course",
  recommended_schools_msg: "Our Best Schools For you",
  popular_schools: "Popular schools",
  statistical_facts: "Some Statistical Facts",
  courses_listed: "Courses Listed",
  schools_listed: "Schools Listed",
  no_results_found: "Sorry, No results found",
  go_to_home: "Go to Home",
  office_address: "Office Address",
  read_more: "Read more",
  phone_number: "Phone Number",
  something_went_wrong: "Something went wrong",
  area: {
    title: "area",
    value: {
      hokkaido: "hokkaido",
      tohoku: "tohoku",
      kanto: "kanto",
      chubu: "chubu",
      kansai: "kansai",
      chugoku: "chugoku",
      shikoku: "shikoku",
      kyushu: "kyushu",
    },
  },
  course_type: {
    title: "course type",
    value: {
      general_japanese: "General Japanese",
      university_preparation: "University Preparation",
      business_employment: "Business Employment",
      others: "Others",
    },
  },
  course_duration: {
    title: "course duration",
    value: {
      short: "short",
      long: "long",
    },
  },
  enrollment_month: {
    title: "enrollment month",
    value: {
      april: "april",
      july: "july",
      october: "october",
      january: "january",
    },
  },
  japanese_level: {
    title: "japanese level",
    value: {
      beginner: "beginner",
      intermediate: "intermediate",
      advance: "advanced",
    },
  },
  english_level: {
    title: "english level",
    value: {
      beginner: "basic",
      intermediate: "conversational",
      advance: "native",
    },
  },
  course_info: {
    value: {
      course_name: "course name",
      course_outline: "course outline",
      course_description: "course description",
      applicable_student: "applicable student",
      visa_type: "visa type",
      beginning_period: "beginning period",
      learning_content: "learning content",
      application_period: "application period",
      curriculum: "curriculum",
      course_goal: "course goal",
      curriculum_description: "curriculum description",
      tuition: "tuition (JPY)",
      screening_fee: "screening fee",
      enrollment_fee: "enrollment fee",
      class_fee: "class fee",
      facility_fee: "facility fee",
      total: "total",
      tuition_fee_notes: "Notes for the tuition fee",
    },
  },
  school_info: {
    value: {
      school_name: "school name",
      school_logo: "school logo",
      school_type: "school type",
      available_course_type: "available course type",
      available_course_length: "available course length",
      school_introduction: "school introduction",
      basic_information: "basic information",
      location: "location",
      nearest_station: "nearest station",
      phone_number: "phone number",
      fax_number: "fax number",
      school_website: "school website",
      email: "email",
      founder: "founder",
      director: "director",
      authorized_date: "authorized date",
      establishmentDate: "establishment date",
      faculty_staff: "faculty staff",
      num_of_faculty_full_time: "number of faculty (full-time)",
      assisstant_for_housing: "assistant for housing",
      foreign_speaking_staff: "staff who can use English/Chinese/Korean etc",
      students: "students",
      quota_of_school: "quota of school",
      total_num_students: "total number of students",
      major_countries_students: "major countries of students",
      grads_this_year: "expected graduates (End of this year)",
      grads_last_year: "number of graduates (Last year)",
      after_graduation: "after graduation",
      jlpt_passer: "number of passer in JLPT exam",
      admission_selection: "admission",
      selection_method: "selection method",
      application_fee: "application fee",
      deadline: "deadline",
      mailing_address: "mailing address",
      pr_school: "PR from school",
      message_from_school: "message from school",
      images: "images",
      videos: "videos",
      school_year_calendar: "school year calendar (event schedule)",
      scholarship_if_available: "scholarship if available",
      facility: "facility",
    },
  },
  confirm_account_modal_header: "Confirm your account",
  confirm_account_modal_body:
    "Sorry, login failed! Please confirm your account first using email verification.",
  confirm: "Confirm",
  cancel: "Cancel",
  email: "Email Address",
  password: "Password",
  do_not_have_account: "Don’t have an account?",
  validation_empty_email: "Email Address cannot be empty",
  validation_empty_password: "Password cannot be empty",
  user_agreement_prefix: "I agree to the Connect Study Abroad ",
  user_agreement_conjunction: " and ",
  user_agreement_postfix: "",
  user_agreement_validation:
    "Please agree to Terms of Service and Privacy Policy",
  already_have_account: "Already have an account?",
  verification_code_sent:
    "We have sent a code by email. Enter it below to confirm your account",
  confirm_account: "Confirm Account",
  did_not_receive_code: "Didn't receive a code?",
  resend: "Resend it",
  verification_title: "Verification",
  passwords_do_not_match: "Passwords do not match",
  forgot_password_description:
    "Enter your Email below and we will send a message to reset your password",
  reset_password: "Reset Password",
  password_reset_code_sent:
    "We have sent a password reset code by email. Enter it below to reset your password.",
  code: "Code",
  onboarding: {
    first_name: "First name",
    last_name: "Last name",
    date_of_birth: "Date of birth",
    year: "Year",
    month: "Month",
    day: "Day",
    nationality: "Nationality",
    start_study_in_japan: "When do you want to start studying in Japan?",
    currently_reside_in_japan: "Do you currently reside in Japan?",
    yes: "Yes",
    no: "No",
  },
  student_profile: {
    user_picture: "User picture",
    first_name: "First name",
    last_name: "Last name",
    date_of_birth: "Date of birth",
    sex: "Sex",
    nationality: "Nationality",
    place_of_birth: "Place of birth",
    current_address: "Current address",
    current_occupation: "Current occupation",
    start_study_in_japan: "When do you want to start studying in Japan?",
    currently_reside_in_japan: "Do you currently reside in Japan?",
  },
  profile_tabs: {
    profile: "Profile",
    application_for_admission: "Application for Admission",
    documents: "Documents",
  },
  admission_application_forms: {
    personal_info: "Personal Info",
    passport_info_stay_history: "Passport information/ Stay history",
    family_friend_japan: "Family & friend in Japan",
    marital_status: "Marital status",
    education_background: "Education background",
    professional_background: "Professional background",
    japanese_study_experience: "Experience of studying Japanese",
    japanese_proficiency: "Japanese language proficiency",
    english_proficiency: "English language proficiency",
    plan_after_graduation: "Plan after graduation",
    english_level: "English level",
    about_sponsor: "About Financial Sponsor",
  },
  admission_application_page1: {
    passport_status: "Passport status",
    passport_number: "Passport number",
    passport_expiration_date: "Passport expiration date",
    passport_issue_date: "Passport issue date",
    previous_coe_application:
      "Previous application for certificate of eligibility",
    previous_stay_in_japan: "Previous stay in Japan",
    previous_stay_in_japan_detail: "Previous stay in Japan (Detail)",
    date_of_entry: "Date of Entry",
    date_of_departure: "Date of Departure",
    status_of_residence: "Status of Residence",
    purpose_of_entry: "Purpose of Entry",
    family_or_friend_question: "Do you have family or friend in Japan?",
    family_or_friend_title: "Family & friend in Japan",
    relationship: "Relationship",
    name: "Name",
    intention_to_reside: "Intend to reside with applicant or not",
    place_of_employment: "Place of Employment or School",
    residence_card_number: "Residence card number",
    marital_status: "Marital Status",
    partner_name: "Partner's name",
  },
  admission_application_page2: {
    educational_background: "Educational background",
    type_of_school: "Type of School",
    name_of_school: "Name of School (official name)",
    location_in_detail: "Location (Country)",
    period_of_attendance: "Period of Attendance",
    start_date: "Start Date",
    expected_end_date: "(Expected) End Date",
    end_date: "End Date",
    years: "years",
    professional_career_question: "Do you have any professional career?",
    professional_career_title: "Professional career",
    company_name: "Company Name",
    type_of_job: "Type of Job",
    period_of_employment: "Period of Employment",
    japanese_study_experience_question:
      "Do you have experience of studying Japanese?",
    japanese_study_experience_title: "Experience of studying Japanese",
    hours: "Total Study Hours",
    japanese_proficiency_certification_question:
      "Do you have any certificate or Japanese language proficiency?",
    japanese_proficiency_certification_title: "Japanese language ability",
    name_of_test: "Name of Test",
    experience_of_test: "Experience of Test",
    year_of_test: "Year of Test",
    level: "Level",
    score: "Score",
    plan_after_graduation: "Plan after graduation",
    currently_enrolled: "Currently Enrolled",
    do_you_have_work_experience: "Do you have a work experience?",
    expected_graduation_date: "(Expected) Graduation Date",
    enrollemnt_period: "Enrollment Period (Years)",
    currently_working_company: "I am currently working for this company",
    studied_japanese_institute: "Have you studied Japanese at any institute?",
    not_yet_completed: "Not yet Completed",
  },
  admission_application_page3: {
    sponsor_name: "Financial Sponsor's name",
    sponsor_address: "Financial Sponsor's address",
    sponsor_relationship: "Financial Sponsor's relationship with the applicant",
    sponsor_annual_income: "Financial Sponsor's annual income (USD)",
    sponsor_occupation: "Financial Sponsor's occupation",
    sponsor_employer: "Financial Sponsor’s Company Name",
  },
  student_document: {
    application_for_admission: "Application for admission",
    statement_of_reason_title: "Statement of reason for attending school",
    statement_of_reason_about:
      "(When 5 or more years have elapsed after graduating the final education school)",
    statement_of_advancement_title:
      "Statement of advancement after graduating the Japanese language educational organization",
    statement_of_advancement_about:
      "(When 5 or more years have elapsed after graduating the final education school)",
    identification_photograph:
      "Identification photograph * Size (H x W): 4cm x 3cm",
    passport_or_identification: "Passport copy",
    diploma_of_last_school: "Diploma of the school last attended",
    transcript_of_last_school: "Graduation certificate of the school last attended",
    japanese_language_proficiency:
      "Document certifying the Japanese Language Proficiency",
    english_language_proficiency:
      "Document certifying the English Language Proficiency",
    employment_certificate_title: "Employment certificate",
    employment_certificate_about: "(only when you have employment record)",
    tuberculosis_screening_title: "Japan pre-entry tuberculosis screening",
    tuberculosis_screening_about:
      "Submission of this is required for applicant holding passport from Philippines, Vietnam, China, Indonesia, Nepal and Myanmar.",
    financial_support:
      "Financial support document (Document specified by the School)",
    supporter_family_members:
      "List of family members of the financial supporter (Document specified by the School)",
    supporter_family_structure:
      "Document certifying the family structure of the financial supporter",
    supporter_relationship:
      "Document certifying the relationship between the financial supporter and the applicant",
    certificate_of_deposit: "Proof of bank balance",
    supporter_employment:
      "Document certifying the employment of the financial supporter",
    three_years_asset_formation:
      "Document certifying the asset formation process for past 3 years",
    supporter_three_years_income:
      "Document certifying the income of the financial supporter for past 3 years",
  },
  save_profile_btn: "Save Profile",
  date_of_birth: "Date of Birth",
  nationality: "Nationality",
  additional_fee_label: "Is there an additional fee after enrollment?",
  additional_fee_yes: "Yes",
  additional_fee_no: "No",
  early_application_deadline:
    "The application deadline may become earlier than planned depending on the number of applications received.",
  notes_for_the_tuition_fee: "Notes for the tuition fee",
  course_list: "Course List",
  course_detail: "Course Detail",
  school_list: "School List",
  school_detail: "School Detail",
  go_to_list: "Go to List",
  apply: "Apply",
  applied_date: "Applied Date",
  application_status: "Application Status",
  student_top_page: {
    why_japans: {
      why_japan: "Why JAPAN?",
      cost_of_living:
        "Cost of living and tuition is lower than the US, UK, and Australia!",
      various_scholarship: "Various scholarships are available!",
      part_time_during_study:
        "You can work part-time during study, and full-time after graduation!",
    },
    what_is_csas: {
      what_is_csa: "What is CSA?",
      platform_directly_match:
        "A platform directly matching schools and students",
      sign_up_free: "Sign Up Free",
    },
    blogs: {
      blog: "Blog",
    },
    why_japans_stat: {
      why_japan: "Why Japan?",
      tuition_and_cost_living_japan: "Tuition and cost of living in Japan",
      annual_tuition: "Annual tuition(USD)",
      annual_living: "Annual cost of living (USD)",
    },
    carrers: {
      career_during_study_available: "Career during study is available!",
      partime_job: "Part time job is available up to  ",
      hrs_per_week: "28 hours per week",
      while_studying: "while studying.",
      work_upto:
        "*During the schools’ vacation, you can work up to 40 hours per week!",
    },
    approval_rate_of_working_visas: {
      approval_rate_of_working_visa: "High approval rate of work visa! ",
      career_after_study: "Career after study is also available!",
      number_of_visa_changes:
        "The number of visa status change from study abroad to work",
      by_how_much: "In 2020, approximately ",
      eighty_Seven: "87%",
      approved: "is approved!",
    },
    grant_type_scholarships: {
      grant_type_scholarship: "Grant-type scholarships are widely",
      available: "available!",
      almost_foreign_students:
        "Almost 30% of foreign students get grant-type of scholarship",
      for_study_in_japan: "for study in Japan      ",
      there_142_scholarship:
        "There are 142 kinds of grant-type scholarships offered by",
      japanese_government: "Japanese Government and private organizations",
      got_scholarship: "1,840 students got the scholarship by the Japanese",
      government_2021: "government in 2021",
    },
    connect_study_deliver_support: {
      connect_study_deliver_support_students:
        "Connect Study ABROAD deliver support for students",
      sign_up_free: "Sign Up Free",
    },
    about_csas: {
      about_csa: "About CSA",
      get_support_fulfill_student_life:
        "Get support to fulfill your student life with jobs and more!",
      information_not_only_schools:
        "CSA delivers information of not only schools but also scholarships, accommodation, part-time jobs, and more!",
      get_support_for_education: "Get support for education and career",
      our_strong_track_supporting:
        "Our strong track record of supporting Japanese companies’ recruitment of global talent will broaden your possibilities after your study.",
    },
    our_factss: {
      our_facts: "Our Facts",
      support_for_7yrs:
        "Supported students’ dream of studying in Japan for the past",
      seven_yrs: " 7 years.",
      more_than: " More than",
      thirteen_thousand: " 13,000 students",
      used_our_service: " used our service.",
      career_event: "Career events in more than",
      twenty_countries: " 20 countries",
      pre_covid: " (Pre-COVID). Study abroad events in",
      ten_countries: " 10 countries",
      covid: " (Pre-COVID).",
      supported_around: "Supported around",
      four_thousand_Candidates: " 4,000 candidates",
      job_hunting: " with their job hunting in Japan last year.",
      registered_users: "Our registered users are from around",
      hundred_forty: " 140 countries.",
    },
    facebook_pages: {
      facebook_page: "Facebook Page",
    },
  },
  select_one: "Select one",
  pre_apply: "Pre-apply",
  tab_exit_heading: "Are you sure?",
  tab_exit_description: "Any unsaved changes will be lost.",
  yes: "Yes",
  no: "No",
  duplicate_application_msg: "You have already applied to this course.",
  csa_free_minimun_balance:
    "CSA service is FREE. However, to apply for visa to study in Japan, either you or a family member have to show a proof of annual income AND bank balance of minimum 2 million JPY.",
  required_student_profile_fields:
    "Required Student Profile fields are not filled up",
  redirect_student_profile_page:
    "You will be redirected to Student Profile Page.",
  redirect: "Redirect",
  proceed_with_course_application: "Proceed with Course Application",
  faq: {
    faq1: {
      id: "1",
      title: "What is CSA？",
      description:
        "CSA is a plarform directly matching you and the schools in Japan. There is NO fee for candidate. You don't need to pay to agents anymore to study in Japan! You can even get a scout from schools after you input your profile and upload your documents! Start from filling up your profile and pre-entry to schools you are interested in.",
    },
    faq2: {
      id: "2",
      title: "What kind of services that CSA provides?",
      description:
        "CSA is a plarform directly matching you and the schools in Japan. There is NO fee for candidate. Some of the things you can do in CSA is..... - Search for schools - Get scouts from schools - Find necessary supports for living in Japan (housing, getting cell phones, etc. etc.)  One of the best thing about CSA is that we can support you in getting part-time job while studying and also full-time job after studying! ",
    },
    faq3: {
      id: "3",
      title: "How to apply to schools?",
      description:
        "1. Register to Connect Study ABROAD 2. Find schools/courses you are interested in 3.  When you find your favorite course, push pinned button to compare all the lists. 4. Click the Pre-apply button *The more profile you fill, the more documents you upload, the more chances of scouts you get from schools. So make sure you do this as soon as you register to the Connect Study ABROAD!",
    },
    faq4: {
      id: "4",
      title: "What is CSA Internship?",
      description:
        "It is a package program offered by Fourth Valley Concierge Corporation (a company that runs the Connect Study Abroad) of studying in Japan and doing internship in order to support students to experience working in Japan and also to support them financially. No Japanese language is required (English required) ",
    },
    faq5: {
      id: "5",
      title: "What kind of school system in Japan?",
      description:
        "There are roughly three options. 1. Japanese language school: You can study in Japan from JLPT N5 level. You can study Japanese with people from all over the world. Courses varies by schools. 2. Universities (Bachelor, Master, Doctor): Basically, you need to pass the EJU. There are schools that has English courses which allow to take the exam in English, too, but not many. So, you need at least JLPT N2 level Japanese. This is why most of the international students first come to Japanese language school and go to higher degree. 3. Vocational/Training schools: You can study specific fields (e.g. Anime, accounting, IT, cooking, etc. etc.) in Japan. Basically you need JLPT N2 above and/or have experience studying in Japan for more than 6 months. Please see here for details ",
    },
    faq6: {
      id: "6",
      title: "When will the semistar start?",
      description:
        "Basically, schools in Japan starts from April, ending in March. However, there are schools in universities and vocational/training schools that has September intake. Japanese language schools has 4 timings per year to start; April, January, July, and October. You will need to prepare 1 year to 6 months in advance to apply so be careful and start early! For details, please see here",
    },
    faq7: {
      id: "7",
      title: "How to get scholarship",
      description:
        "In Japan, therer are 142 scholarships that is not necessary to pay back (info: Jasso) There are also CSA exclusive scholarships as well! Check it out on CSA and make sure you don't miss the chance! For details, please see here",
    },
    faq8: {
      id: "8",
      title: "How much does it cost to study in Japan?",
      description:
        "Compared to US, Europe, studying in Japan is very reasonable! Of course, it depends on individuals/types of schools, courses etc., but usually it is around 2 mil. JPY per year including the tuition and living expences. It is 1/2 ~ 1/3 compared to studyin in the US or Europe! YOu can also legally work part-time in Japan while you are studying as well. For details, please see here",
    },
    faq9: {
      id: "9",
      title: "Can I do a part-time job as an international student?",
      description:
        "In Japan, you are allowed to work up to 28 hours per week (during the holiday period of school, 40 hours per week) The minimum hourly wage (in Tokyo) is about 1,000JPY (=9USD), so you can earn abour 1.6mil per year! About 70% of international students in Japan is working part-time to support themselves for their studies. For details, please see here",
    },
    faq10: {
      id: "10",
      title: "How can i get a job in Japan",
      description:
        "The number of foreigners working in Japan has grown 3 times in 10 years (and the number of companies hiring foreigners are also rising) so it means the chances for you to find a job in Japan is increasing. Of course we need to give different advise depending on individuals and situations, but what is common is that you will need a certain level of Japanese in any field. Minimum is N4, but the more fluent you are in Japanese, the more chances you will get. We are supporting those who wish to get a job in Japan, too, so please take a look in these platforms as well for job opportunities.",
    },
    faq11: {
      id: "11",
      title: "I want to pursure Bachelor Degree/ Master Degree in Japan",
      description:
        "The information about bachelor degrees and master degrees yet to be added on CSA. If you would like to pursue bachelor degree/ master degree, please check https://www.jpss.jp/ja/",
    },
  },
};
