import moment from "moment";
import { useState } from "react";
import { useSelector } from "react-redux";

const getTimeFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const msgFromToCurrentDate = moment(date).fromNow();
  return msgFromToCurrentDate;
};

const ChannelPreview = ({ channel, onChannelSelect, id }) => {
  const [activeIndex, setActiveIndex] = useState(channel.url);

  const handleActiveIndex = (activeId) => {
    setActiveIndex(activeId);
  };
  const { username, email, isAuth, role } = useSelector(
    (state) => state.user)

  // useEffect(async () => {
  //   let isAllChannelListed = true;
  //   axios
  //     .get(
  //       `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/group_channels`,
  //       {
  //         headers: {
  //           "Api-Token": process.env.REACT_APP_API_TOKEN,
  //         },
  //       }
  //     )
  //     .then((response) => {
  //       if (isAllChannelListed) {
  //         setChannelData(response.data.channels);
  //       }
  //     });
  //   return () => {
  //     // cancel the subscription
  //     isAllChannelListed = false;
  //   };
  // }, [activeIndex]);

  // const arrayofActiveChannel =
  //   Array.isArray(channelData) &&
  //   channelData.map((eachChannel) => {
  //     if (eachChannel.channel_url == id) {
  //       return eachChannel.channel_url;
  //     }
  //     return;
  //   });
  // const activeChannel =
  //   Array.isArray(arrayofActiveChannel) &&
  //   arrayofActiveChannel.filter((element) => {
  //     return element !== undefined;
  //   });
  // const activeBox = activeChannel.toString();
  // console.log("activeChannel", activeChannel)
  // console.log("activeBox", activeBox);

  return (
    <>
      <div
        className={`channel-preview-wrapper ${activeIndex === id ? "active_chat" : "unactive_chat"
          }
          `}
        onClick={() => {
          onChannelSelect(channel);
          handleActiveIndex(channel.url);
        }}
      >
        <img
          className="channel-preview-cover"
          style={{ width: "50px" }}
          src={channel.coverUrl}
          alt=""
        />
        <div>
          <div className="channel-preview-name">

            {role == "student" && (channel.members.filter(c => c.metaData.userType === "School" || c.metaData.userType === "school")[0]?.nickname)}
            {role == "school" && (channel.name)}

          </div>
          <div className="d-flex">
            <div
              className="channel-preview-last-message"
              style={{ width: "155px" }}
            >
              {channel.lastMessage?.messageType === "file"
                ? channel.lastMessage.name
                : channel.lastMessage?.message}
            </div>

            <div className="channel_left_list_time">
              {" "}
              {getTimeFromTimestamp(channel.lastMessage.createdAt)}{" "}
            </div>
          </div>
        </div>
        <div className="channel-preview-last-message-date">
          {new Intl.DateTimeFormat("en-us", {
            month: "short",
            day: "2-digit",
          }).format(channel.lastMessage?.createdAt)}
        </div>
      </div>


    </>
  );
};

export default ChannelPreview;
