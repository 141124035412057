/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const postStudentProfile = /* GraphQL */ `
  mutation PostStudentProfile($student: StudentInput) {
    postStudentProfile(student: $student)
  }
`;
export const postAgentProfile = /* GraphQL */ `
  mutation PostAgentProfile($input: AgentInput) {
    postAgentProfile(input: $input) {
      name
      phoneNumber
      address
      country_id
      description
      logo
      isActive
      timeStamp
    }
  }
`;
export const uploadImage = /* GraphQL */ `
  mutation UploadImage($fileExtension: String!, $fileContent: String!) {
    uploadImage(fileExtension: $fileExtension, fileContent: $fileContent)
  }
`;
export const postStudentCourseApplication = /* GraphQL */ `
  mutation PostStudentCourseApplication($application: ATSApplicationInput) {
    postStudentCourseApplication(application: $application)
  }
`;
export const postProfilePage1 = /* GraphQL */ `
  mutation PostProfilePage1(
    $visaInfoInput: VisaInfoInput
    $previousStayJapanInput: [PreviousStayJapanInput]
    $friendsFamilyJapanInput: [FriendsFamilyJapanInput]
    $maritalStatusInput: [MaritalStatusInput]
  ) {
    postProfilePage1(
      visaInfoInput: $visaInfoInput
      previousStayJapanInput: $previousStayJapanInput
      friendsFamilyJapanInput: $friendsFamilyJapanInput
      maritalStatusInput: $maritalStatusInput
    )
  }
`;
export const postProfilePage2 = /* GraphQL */ `
  mutation PostProfilePage2($profilePage2Input: ProfilePage2Input) {
    postProfilePage2(profilePage2Input: $profilePage2Input)
  }
`;
export const postProfilePage3 = /* GraphQL */ `
  mutation PostProfilePage3($profilePage3Input: ProfilePage3Input) {
    postProfilePage3(profilePage3Input: $profilePage3Input)
  }
`;
export const postStudentProfileDocs = /* GraphQL */ `
  mutation PostStudentProfileDocs(
    $studentProfileDocs: StudentProfileDocsInput
  ) {
    postStudentProfileDocs(studentProfileDocs: $studentProfileDocs)
  }
`;
export const postSchoolProfile = /* GraphQL */ `
  mutation PostSchoolProfile(
    $schoolId: String
    $schoolProfileInput: SchoolProfileInput
  ) {
    postSchoolProfile(
      schoolId: $schoolId
      schoolProfileInput: $schoolProfileInput
    )
  }
`;
export const schoolPostCourseDetailsProfile = /* GraphQL */ `
  mutation SchoolPostCourseDetailsProfile(
    $schoolId: String!
    $courseDetailInput: CourseDetailInput
  ) {
    schoolPostCourseDetailsProfile(
      schoolId: $schoolId
      courseDetailInput: $courseDetailInput
    )
  }
`;
export const schoolPostPr = /* GraphQL */ `
  mutation SchoolPostPr($schoolId: String!, $input: SchoolPRInput) {
    schoolPostPR(schoolId: $schoolId, input: $input)
  }
`;
export const schoolPostPrImage = /* GraphQL */ `
  mutation SchoolPostPrImage($schoolId: String!, $input: SchoolPrImageInput) {
    schoolPostPrImage(schoolId: $schoolId, input: $input)
  }
`;
export const schoolPostApplicationStatus = /* GraphQL */ `
  mutation SchoolPostApplicationStatus(
    $applicationId: String!
    $status: String!
  ) {
    schoolPostApplicationStatus(applicationId: $applicationId, status: $status)
  }
`;
export const schoolDeleteCourse = /* GraphQL */ `
  mutation SchoolDeleteCourse($courseId: String!) {
    schoolDeleteCourse(courseId: $courseId)
  }
`;
export const studentPostPin = /* GraphQL */ `
  mutation StudentPostPin(
    $pinCourse: pinCourseInput
    $pinSchool: pinSchoolInput
  ) {
    studentPostPin(pinCourse: $pinCourse, pinSchool: $pinSchool) {
      pinCourse
      pinSchool
    }
  }
`;
export const studentPostSeminarRegistration = /* GraphQL */ `
  mutation StudentPostSeminarRegistration(
    $seminarRegistration: SeminarRegistration
  ) {
    studentPostSeminarRegistration(seminarRegistration: $seminarRegistration)
  }
`;
export const manageSchoolM = /* GraphQL */ `
  mutation ManageSchoolM(
    $verifySchoolStaffAccount: verifySchoolStaffAccountInput
    $updateProfile: schoolStaffProfileInput
    $deleteStaff: staffDeleteInput
    $scoutCreate: scoutInput
    $updateScoutStatus: getScoutStatusInput
    $schoolPrMediaDelete: getSchoolPrMediaDelInput
    $schoolPinStudent: getSchoolPinStudentInput
    $schoolUnPinStudent: getSchoolPinStudentInput
    $duplicateCourse: getDuplicateCourseInput
    $updateNotificationReadStatus: getNotfInput
  ) {
    manageSchoolM(
      verifySchoolStaffAccount: $verifySchoolStaffAccount
      updateProfile: $updateProfile
      deleteStaff: $deleteStaff
      scoutCreate: $scoutCreate
      updateScoutStatus: $updateScoutStatus
      schoolPrMediaDelete: $schoolPrMediaDelete
      schoolPinStudent: $schoolPinStudent
      schoolUnPinStudent: $schoolUnPinStudent
      duplicateCourse: $duplicateCourse
      updateNotificationReadStatus: $updateNotificationReadStatus
    ) {
      verifySchoolStaffAccount {
        newUserCount
        isSubIdNeeded
      }
      updateProfile {
        guid
        firstName
        lastName
        email
        verified
      }
      deleteStaff
      scoutCreate {
        schoolId
        schoolGuid
        studentGuid
        totalScout
      }
      updateScoutStatus
      schoolPrMediaDelete
      schoolPinStudent
      schoolUnPinStudent
      duplicateCourse
      updateNotificationReadStatus
    }
  }
`;
export const manageSchoolPublic = /* GraphQL */ `
  mutation ManageSchoolPublic($changeVerifyStatus: changeVerifyStatusInput) {
    manageSchoolPublic(changeVerifyStatus: $changeVerifyStatus) {
      verifyStatusChange
    }
  }
`;
export const verifyUserAccount = /* GraphQL */ `
  mutation VerifyUserAccount($subId: String!) {
    verifyUserAccount(subId: $subId)
  }
`;
