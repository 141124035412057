import {
  faCircle,
  faCommentDots,
  faUser,
} from "@fortawesome/free-regular-svg-icons";
import { faCheck, faDotCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API } from "aws-amplify";
import axios from "axios";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import SnackbarIn from "src/components/Snackbar";
import { getAuthMode } from "src/core/utilities/authMode";
import { getValueOfMatchedItemInMasterData } from "src/core/utilities/utils";
import { manageSchoolM } from "src/graphql/mutations";

const getIsResidenceJapanString = (value) => {
  if (value == "1") {
    return "Yes";
  } else if (value == "2") {
    return "No";
  } else {
    return "-";
  }
};

const ScoutPersonalInfo = ({ personalInfo, masterNationality, masterSNS }) => {
  const [studentError, setStudentError] = useState(false);
  const [schoolError, setSchoolError] = useState(false);
  const [isStudentData, setIsStudentData] = useState(false);
  const [isSchoolData, setIsSchoolData] = useState(false);
  const [openSuccessBar, setOpenSuccessBar] = useState(false);
  const [openErrorBar, setOpenErrorBar] = useState(false);
  const schoolChatGuid = localStorage.getItem("getSchoolProfileGUID");
  const schoolChatId = localStorage.getItem("getSchoolProfileID");
  const schoolChatName = localStorage.getItem("getSchoolProfileName");
  const [hasChannelUrl, setHasChannelUrl] = useState({});
  const [isChannelUrl, setIsChannelUrl] = useState(false);
  const [isScouted, setIsScouted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scoutData, setIsScoutData] = useState({});
  const [error, setError] = useState("");
  const [clickstudentGuid, setClickStudentGuid] = useState("");
  const [studentName, setStudentName] = useState("");
  const history = useHistory();
  const [showScoutModal, setShowScoutModal] = useState(false);
  // const [studentSendbirdData, setStudentSendbirdData] = useState(false);
  // const [studentActiveStatus, setStudentActiveStatus] = useState(false);
  const { id } = useParams();

  const createScout = async () => {
    setIsLoading(true);
    try {
      const createScoutData = await API.graphql({
        query: manageSchoolM,
        variables: {
          scoutCreate: {
            schoolGuid: schoolChatGuid,
            schoolId: schoolChatId,
            studentGuid: clickstudentGuid,
          },
        },
        authMode: await getAuthMode(),
      });
      const data = createScoutData.data.manageSchoolM.scoutCreate;
      setIsScoutData(data);
      setIsScouted(true);
      setOpenSuccessBar(true);
      setIsLoading(false);
    } catch (error) {
      setOpenErrorBar(true);
      setError(error.errors[0].message);
      setIsLoading(false);
      console.log("error while creating scout", error);
    }
    toggleShowScoutModal();
  };
  const schoolUser = scoutData.schoolGuid + "-" + scoutData.schoolId;

  // Toggle Modal
  const toggleShowScoutModal = () => {
    setShowScoutModal(!showScoutModal);
  };

  // fetch Student
  const FetchStudentUser = () => {
    const getToken = axios
      .get(
        `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/users/${scoutData.studentGuid ? scoutData.studentGuid : id
        }`,
        {
          headers: {
            "Api-Token": process.env.REACT_APP_API_TOKEN,
          },
        }
      )
      .then((repos) => {
        const allRepos = repos.data;
        // console.log("all repos from student fetch data", allRepos);
        // setStudentSendbirdData(allRepos.is_online);
        setIsStudentData(true);
      })
      .catch((error) => {
        console.log("error from student", error.response.data.error);
        setStudentError(error.response.data.error);
      });
  };
  // fetch School
  const FetchSchoolUser = () => {
    const getToken = axios
      .get(
        `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/users/${scoutData.schoolGuid}`,
        {
          headers: {
            "Api-Token": process.env.REACT_APP_API_TOKEN,
          },
        }
      )
      .then((repos) => {
        const allRepos = repos.data;
        setIsSchoolData(true);
      })
      .catch((error) => {
        console.log("error from school", error.response.data.error);
        setSchoolError(error.response.data.error);
      });
  };

  const CreateStudentUserInSendbird = () => {
    // POST request using fetch inside useEffect React hook

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Token": process.env.REACT_APP_API_TOKEN,
      },
      body: JSON.stringify({
        user_id: scoutData.studentGuid,
        nickname: studentName,
        profile_url: "",
        profile_file: "",
        issue_access_token: "true",
        discovery_keys: [
          scoutData.studentGuid,
          studentName,
          scoutData.schoolId,
        ],
        metadata: {
          schoolId: scoutData.schoolId,
          guid: scoutData.studentGuid,
          project: "VOY",
          userType: "Student",
        },
      }),
    };
    fetch(
      `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/users`,
      requestOptions
    )
      .then((response) => response.json())
      .then((repos) => {
        const allRepos = repos.data;
        // console.log("all repos from student create data", allRepos);
        // setStudentActiveStatus(allRepos.is_onlines);
        setIsStudentData(true);
      })
      .catch((error) => {
        console.log("error from create student user", error.response);
      });
  };
  const CreateSchoolUserInSendbird = () => {
    // POST request using fetch inside useEffect React hook

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Token": process.env.REACT_APP_API_TOKEN,
      },
      body: JSON.stringify({
        user_id: schoolUser,
        nickname: schoolChatName,
        profile_url: "",
        profile_file: "",
        issue_access_token: "true",
        discovery_keys: [schoolUser, schoolChatName, schoolChatId],
        metadata: {
          schoolId: schoolChatId,
          guid: schoolUser,
          project: "VOY",
          userType: "School",
        },
      }),
    };
    fetch(
      `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/users`,
      requestOptions
    )
      .then((response) => response.json())
      .then((repos) => {
        const allRepos = repos.data;
        setIsSchoolData(true);
      })
      .catch((error) => {
        console.log("error from create school user", error.response);
      });
  };

  useEffect(() => {
    FetchStudentUser();
    if (studentError) {
      CreateStudentUserInSendbird();
    }
    FetchSchoolUser();
    if (schoolError) {
      CreateSchoolUserInSendbird();
    }
  }, [isScouted]);

  // creating channel
  const CreateChannelInSendbird = () => {
    // POST request using fetch inside useEffect React hook
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Token": process.env.REACT_APP_API_TOKEN,
      },
      body: JSON.stringify({
        user_ids: [scoutData.studentGuid, schoolUser],
        name: studentName,
        channel_url: "VOY" + "-" + scoutData.studentGuid + "-" + schoolChatId,
        is_distinct: true,
      }),
    };
    fetch(
      `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/group_channels`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => (
        <>
          {setHasChannelUrl(data)}
          {setIsChannelUrl(true)}
        </>
      ))
      .catch((error) => {
        console.log("error from channel", error.response.data);
      });
  };

  useEffect(() => {
    if (isSchoolData && isStudentData) {
      CreateChannelInSendbird();
    }
  }, [isSchoolData, isStudentData]);

  //msg send in channel
  const MsgSendInChannel = () => {
    // POST request using fetch inside useEffect React hook

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Api-Token": process.env.REACT_APP_API_TOKEN,
      },
      body: JSON.stringify({
        message_type: "MESG",
        user_id: schoolUser,
        message:
          "You have been scouted from  " +
          schoolChatName +
          ". If you have any queries regarding the school and courses, please feel free to reach out. Thanks!",
        channel_type: "group_channels",
        channel_url: hasChannelUrl.channel_url,
      }),
    };
    fetch(
      `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/group_channels/${hasChannelUrl.channel_url}/messages`,
      requestOptions
    )
      .then((response) => response.json())
      .then((repos) => {
        const allRepos = repos;
        console.log("message send in channel", allRepos);
      })
      .catch((error) => {
        console.log("error from channel", error.response.data);
      });
  };

  useEffect(() => {
    MsgSendInChannel();
  }, [isChannelUrl]);

  // millisecond to day,hour,minutes

  function dhm(ms) {
    const days = Math.floor(ms / (24 * 60 * 60 * 1000));
    const daysms = ms % (24 * 60 * 60 * 1000);
    const hours = Math.floor(daysms / (60 * 60 * 1000));
    const hoursms = ms % (60 * 60 * 1000);
    const minutes = Math.floor(hoursms / (60 * 1000));
    const minutesms = ms % (60 * 1000);
    const sec = Math.floor(minutesms / 1000);

    if (days !== 0) {
      if (days == 1) {
        return days + " Day"

      } else {
        return days + " Days"

      }
    } else if (days == 0 && hours !== 0) {
      return hours + " hrs"
    } else if (days == 0 && hours == 0 && minutes !== 0) {
      return minutes + " mins"
    } else if (days == 0 && hours == 0 && minutes == 0 && sec !== 0) {
      return sec + " seconds"
    } else {
      return " long time "
    }
  }

  // GET LAST ACTIVE DATA
  function lastActive(time) {

    const now = Date.now()

    const gap = now - Date.parse(time)

    return dhm(gap)
  }

  return (
    <div>
      {/* --------------------image----------------------------- */}
      <div
        className="lCircle"
        style={{
          textAlign: "center",
          padding: "10px",
          border: "1px solid #C7C7C7",
        }}
      >
        {personalInfo.profileImage ? (
          <img src={personalInfo.profileImage} className="object-fit-cover rounded-circle" />
        ) : (
          <FontAwesomeIcon
            style={{ color: "#C7C7C7", fontSize: "70" }}
            icon={faUser}
          />
        )}
      </div>

      <div style={{ textAlign: "center", marginTop: "5px" }}>
        <div>
          {" "}
          {personalInfo.email}

        </div>
        <div>
          {personalInfo.scoutStatus == "" ? (
            <>
              {personalInfo.hasApplied ? (
                <>
                  <span
                    style={{
                      backgroundColor: "#10a9ac",
                      borderRadius: "50px",
                      color: "#fff",
                      width: "25px",
                      height: "25px",
                      margin: "auto 10px",
                      padding: "3px",
                    }}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </span>
                  <span>Pre-Applied</span>
                  <span
                    className="chat_icon_hover"
                    onClick={() =>
                      history.push(
                        "/chat/" +
                        "VOY" +
                        "-" +
                        personalInfo.guid +
                        "-" +
                        schoolChatId
                      )
                    }
                  >
                    {" "}
                    <FontAwesomeIcon
                      data-toggle="tooltip"
                      data-placement="right"
                      title="Let's chat!"
                      style={{
                        width: "30px",
                        height: "30px",
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      icon={faCommentDots}
                    />{" "}
                  </span>

                  <div className="my-1">
                    <span className="text-success">
                      <FontAwesomeIcon className="mr-1" icon={faDotCircle} />
                      Active
                    </span>
                    <span className="mx-2">{
                      lastActive(personalInfo.loginTime)
                    }</span>
                    <span>ago</span>
                  </div>
                </>
              ) : (
                <Button
                  onClick={() => {
                    toggleShowScoutModal();
                    setClickStudentGuid(personalInfo.guid);
                    setStudentName(personalInfo.firstName);
                  }}
                  style={{
                    borderRadius: "50px",
                    padding: "2px 12px",
                    marginLeft: "10px",
                  }}
                  disabled={isScouted || error}
                >
                  {" "}
                  {isLoading ? (
                    <Spinner />
                  ) : isScouted ? (
                    "Scouted"
                  ) : (
                    "Accepting Scouts"
                  )}
                </Button>
              )}
            </>
          ) : (
            <>
              <span
                style={{
                  backgroundColor: "#10a9ac",
                  borderRadius: "50px",
                  color: "#fff",
                  width: "25px",
                  height: "25px",
                  margin: "auto 10px",
                  padding: "3px",
                }}
              >
                <FontAwesomeIcon icon={faCheck} />
              </span>
              <span>Scouted</span>
              <span
                className="chat_icon_hover"
                onClick={() =>
                  history.push(`/chat/VOY-${personalInfo.guid}-${schoolChatId}`)
                }
              >
                {" "}
                <FontAwesomeIcon
                  data-toggle="tooltip"
                  data-placement="right"
                  title="Let's chat!"
                  style={{
                    width: "30px",
                    cursor: "pointer",
                    height: "30px",
                    marginLeft: "10px",
                  }}
                  icon={faCommentDots}
                />{" "}
              </span>
            </>
          )}{" "}
        </div>
      </div>
      {/* ----------------------Modal---------------------- */}
      <Modal
        isOpen={showScoutModal}
        toggle={toggleShowScoutModal}
        style={{ "margin-top": "180px" }}
      >
        <ModalHeader toggle={toggleShowScoutModal}>Scout Student</ModalHeader>
        <ModalBody>Do you want to scout this Student?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={createScout}>
            {/* OK */}
            Yes
          </Button>
          <Button
            style={{
              background: "none",
              border: "gray",
              color: "black",
            }}
            // color="primary"
            onClick={toggleShowScoutModal}
          >
            No
          </Button>
        </ModalFooter>
      </Modal>
      <div
        className=" row rowOutline--wrapper"
        id="personalInfoSection"
        style={{ marginTop: "50px" }}
      >
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span className="section-heading">
                {/* Personal Info */}
                個人情報
              </span>{" "}
              {/* <span>
                {" "}
                <FontAwesomeIcon icon={faChevronCircleUp} />{" "}
                <FontAwesomeIcon icon={faChevronCircleDown} />{" "}
              </span>{" "} */}
            </div>
          </div>
          <div
            className="row form-section app-admsn-form"
            style={{ padding: "15px" }}
          >
            <div class="col-12 col-sm-6" style={{ marginBottom: "10px" }}>
              <label for="inputFirstName" class="form-label title-label">
                {/* First Name */}姓
              </label>
              <br />
              <label class="form-label info-label">
                {personalInfo.firstName != "" ? personalInfo.firstName : "-"}
              </label>
            </div>
            <div class="col-12 col-sm-6" style={{ marginBottom: "10px" }}>
              <label for="inputLastName" class="title-label form-label ">
                {/* Last Name */}名
              </label>
              <br />
              <label class="form-label info-label">
                {personalInfo.lastName != "" ? personalInfo.lastName : "-"}
              </label>
            </div>
            {/* ----------------email---- */}
            <div class="col-12 col-sm-6" style={{ marginBottom: "10px" }}>
              <label for="inputEmail" class="title-label form-label ">
                Email
              </label>
              <br />
              <label class="form-label info-label">
                {personalInfo.email != "" ? personalInfo.email : "-"}
              </label>
            </div>
            {/* ---------phoneNumber----------- */}
            <div className="col-12 col-sm-6" style={{ marginBottom: "10px" }}>
              <label for="inputPhoneNumber" class="title-label form-label">
                電話番号
              </label>
              <br />
              <label class="form-label info-label">
                {personalInfo.phoneNumber != ""
                  ? personalInfo.phoneNumber
                  : "-"}
              </label>
            </div>

            <div class="col-12" style={{ marginBottom: "10px" }}>
              <div className="row">
                <div className="col-12 col-sm-6">
                  <label for="inputNationality" class="title-label form-label">
                    {/* Nationality */}
                    国籍
                  </label>
                  <br />
                  <label class="form-label info-label">
                    {getValueOfMatchedItemInMasterData(
                      personalInfo.nationality,
                      masterNationality
                    )}
                    {/* {personalInfo.nationality != ""
                      ? personalInfo.nationality
                      : "-"} */}
                  </label>
                </div>
                {/* ------------------------SNS--------------------------- */}
                <div className="col-12 col-sm-6">
                  <label for="inputSNS" class="title-label form-label">
                    SNS
                  </label>
                  <br />
                  <label class="form-label info-label">
                    {getValueOfMatchedItemInMasterData(
                      personalInfo.sns,
                      masterSNS
                    )}
                    {/* {personalInfo.nationality != ""
                      ? personalInfo.nationality
                      : "-"} */}
                  </label>
                </div>
              </div>
            </div>
            {/* ------------------------Reside in Japann--------------------------- */}
            <div class="col-12 col-sm-6">
              <label for="inputLastName" class="title-label form-label">
                {/* Do you currently reside in Japan? */}
                現在、日本に住んでいますか？
              </label>
              <br />
              <label class="form-label info-label">
                {personalInfo.isResidenceJapan != ""
                  ? getIsResidenceJapanString(personalInfo.isResidenceJapan)
                  : "-"}
              </label>
            </div>
            {/* ---------------------SNS Link--------------- */}
            <div class="col-12 col-sm-6">
              <label for="inputLink" class="title-label form-label ">
                SNS Link
              </label>
              <br />
              <label class="form-label info-label" style={{ lineBreak: "anywhere" }}>
                {personalInfo.snsDetail != "" ? personalInfo.snsDetail : "-"}
              </label>
            </div>
          </div>
        </div>
      </div>
      <SnackbarIn
        openSuccessBar={openSuccessBar}
        setOpenSuccessBar={setOpenSuccessBar}
        openErrorBar={openErrorBar}
        setOpenErrorBar={setOpenErrorBar}
        error={error}
        snackbarSuccessMsg="Student is scouted. Now, you can start the chat"
        snackbarErrorMsg={error}
      />
    </div>
  );
};

export default ScoutPersonalInfo;
