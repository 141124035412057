import * as React from "react";

const numInitialState = { num: 0, setNum: undefined };
const guidInitialState = { numGuid: 0, setNumGuid: undefined };
const textInitialState = { text: "", setText: undefined };

export const NumStateContext = React.createContext(numInitialState);
export const TextStateContext = React.createContext(textInitialState);
export const GuidNumStateContext = React.createContext(guidInitialState);

export const GlobalStateProvider = ({ children }) => {
  const [num, setNum] = React.useState(numInitialState.num);
  const [numGuid, setNumGuid] = React.useState(guidInitialState.numGuid);
  const [text, setText] = React.useState(textInitialState.text);
  const numContextValue = React.useMemo(() => ({ num, setNum }), [num]);
  const numGuidContextValue = React.useMemo(() => ({ numGuid, setNumGuid }), [
    numGuid,
  ]);
  const textContextValue = React.useMemo(() => ({ text, setText }), [text]);

  return (
    <NumStateContext.Provider value={numContextValue}>
      <TextStateContext.Provider value={textContextValue}>
        <GuidNumStateContext.Provider value={numGuidContextValue}>
          {children}
        </GuidNumStateContext.Provider>
      </TextStateContext.Provider>
    </NumStateContext.Provider>
  );
};
