import React from "react";
import { getValueOfMatchedItemInMasterData } from "src/core/utilities/utils";

const Index = ({
  planAfterGraduation,
  masterPlanAfterGraduation,
  masterStudyType,
}) => {

  return (
    <div>
      <div className="row rowOutline--wrapper" id="planAfterGraduationSection">
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span className="section-heading">
                {/* Plan After Graduation */}
                卒業後の予定
              </span>
              {/* <span>
              <FontAwesomeIcon icon={faChevronCircleUp} />{" "}
              <FontAwesomeIcon icon={faChevronCircleDown} />{" "}
            </span>{" "} */}
            </div>
          </div>
          <div className="row formInner">
            <div class="col-12 col-sm-6">
              <label class="form-label info-label">
                {getValueOfMatchedItemInMasterData(
                  planAfterGraduation.type,
                  masterPlanAfterGraduation
                )}
              </label>
              {planAfterGraduation.type === "323" && (
                <label style={{ paddingRight: "20px" }}>
                  {/* {getValueOfMatchedItemInStudyType(
                    planAfterGraduation.studyType
                  )} */}
                  {getValueOfMatchedItemInMasterData(
                    planAfterGraduation.studyType,
                    masterStudyType
                  )}
                </label>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
