import Amplify from "aws-amplify";
import ReactDOM from "react-dom";
import { Toaster } from "react-hot-toast";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "sendbird-uikit/dist/index.css";
import "src/localization/i18n";
import 'react-loading-skeleton/dist/skeleton.css'

import configureStore from "src/state/store";
import App from "src/views/Common/App";
import awsExports from "./aws-exports";
import { GetMasterDataContextProvider } from "./contexts/GetMasterDataContext";
import GetPage2DataContextProvider from "./contexts/GetPage2DataContext";
import { GetSponsorDataCOntextProvider } from "./contexts/GetSponsorDataContext";

Amplify.configure(awsExports);

if (process.env.REACT_APP_ENVIRONMENT === "production") {
  console.log = () => { };
  console.error = () => { };
  console.debug = () => { };
  console.warn = () => { };
}

const Container = () => {
  const preloadedState = {};
  const reduxStore = configureStore(preloadedState);

  return (
    <>
      <Toaster
        position="bottom-left"
        reverseOrder={false}
      />
      <Router>
        <Provider store={reduxStore}>
          <GetMasterDataContextProvider>
            <GetPage2DataContextProvider>
              <GetSponsorDataCOntextProvider>
                <App />
              </GetSponsorDataCOntextProvider>
            </GetPage2DataContextProvider>
          </GetMasterDataContextProvider>
        </Provider>
      </Router>
    </>
  );
};
ReactDOM.render(<Container />, document.getElementById("root"));
