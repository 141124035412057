import { API } from "aws-amplify";
import React, { createContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { postProfilePage3 } from "src/graphql/mutations";
import { getProfilePage } from "src/graphql/queries";

export const GetSponsorDataContext = createContext();

export const GetSponsorDataCOntextProvider = ({ children }) => {
  const [openSuccessBar, setOpenSuccessBar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuth, role } = useSelector((state) => state.user);
  const defaultSponsorInfo = {
    // SHOULD be null (not even empty string- "") otherwise, update statement will run
    id: null,
    sponsorName: "",
    sponsorAddress: "",
    // TODO: master data for relationWithApplicant
    relationWithApplicant: "",
    sponsorAnnualIncome: "",
    sponsorOccupation: "",
    sponsorEmployer: "",
    isUpdated: false,
  };
  const [sponsorInfo, setSponsorInfo] = useState({ ...defaultSponsorInfo });
  const [sponsorInfoError, setSponsorInfoError] = useState({
    sponsorName: "",
    sponsorAddress: "",
    relationWithApplicant: "",
    sponsorAnnualIncome: "",
    sponsorOccupation: "",
    sponsorEmployer: "",
  });

  const fetchProfilePage3 = async () => {
    try {
      const getProfilePage3Data = await API.graphql({
        query: getProfilePage,
        variables: {
          pageThree:true
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("getProfilePage3Data: ", getProfilePage3Data);
      const data = getProfilePage3Data.data.getProfilePage.pageThree;

      const sponsorData = data.sponsorInfo;
      if (sponsorData) {
        setSponsorInfo({
          id: sponsorData.id,
          sponsorName: sponsorData.sponsorName,
          sponsorAddress: sponsorData.sponsorAddress,
          relationWithApplicant: sponsorData.relationWithApplicant,
          sponsorAnnualIncome: sponsorData.sponsorAnnualIncome,
          sponsorOccupation: sponsorData.sponsorOccupation,
          sponsorEmployer: sponsorData.sponsorEmployer,
          isUpdated: false,
        });
      }

      setIsLoading(false);
    } catch (err) {
      // setError(t("something_went_wrong"));
      console.log(err);
      setIsLoading(false);
      // setisFormLoading(false);
    }
  };

  useEffect(async () => {
    await fetchProfilePage3();
  }, []);

  const isNullEmptyBlank = (str) => {
    return str === null || str.match(/^ *$/) !== null;
  };

  const validateSponserInfo = () => {
    let isValid = true;
    const sponsorInfoError = {
      sponsorName: "",
      sponsorAddress: "",
      relationWithApplicant: "",
      sponsorAnnualIncome: "",
      sponsorOccupation: "",
      sponsorEmployer: "",
    };

    if (isNullEmptyBlank(sponsorInfo.sponsorName)) {
      sponsorInfoError.sponsorName = "Sponsor name is mandatory";
      isValid = false;
    }
    if (isNullEmptyBlank(sponsorInfo.sponsorAddress)) {
      sponsorInfoError.sponsorAddress = "Sponsor adress is mandatory";
      isValid = false;
    }
    if (isNullEmptyBlank(sponsorInfo.relationWithApplicant)) {
      sponsorInfoError.relationWithApplicant =
        "Sponsor's relationship with applicant is mandatory";
      isValid = false;
    }
    if (isNullEmptyBlank(sponsorInfo.sponsorAnnualIncome)) {
      sponsorInfoError.sponsorAnnualIncome =
        "Sponsor's annual income is mandatory";
      isValid = false;
    }
    if (isNullEmptyBlank(sponsorInfo.sponsorOccupation)) {
      sponsorInfoError.sponsorOccupation = "Sponsor's occupation is mandatory";
      isValid = false;
    }
    if (isNullEmptyBlank(sponsorInfo.sponsorEmployer)) {
      sponsorInfoError.sponsorEmployer = "Sponsor's employer is mandatory";
      isValid = false;
    }
    setSponsorInfoError(sponsorInfoError);
    return isValid;
  };

  const validateSponsorForm = () => {
    if (!validateSponserInfo()) {
      return false;
    }
    return true;
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);
    if (isAuth) {
      let sponsorInfoToSubmit = {
        id: sponsorInfo.id,
        sponsorName: sponsorInfo.sponsorName,
        sponsorAddress: sponsorInfo.sponsorAddress,
        relationWithApplicant: sponsorInfo.relationWithApplicant,
        sponsorAnnualIncome: sponsorInfo.sponsorAnnualIncome,
        sponsorOccupation: sponsorInfo.sponsorOccupation,
        sponsorEmployer: sponsorInfo.sponsorEmployer,
      };
      console.log("sponsorInfo: ", sponsorInfo);
      console.log("sponsorInfoToSubmit: ", sponsorInfoToSubmit);
      try {
        const postProfilePage3Data = await API.graphql({
          query: postProfilePage3,
          variables: {
            profilePage3Input: {
              sponsorInfo: sponsorInfoToSubmit,
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        console.log("postProfilePage3Data: ", postProfilePage3Data);

        await fetchProfilePage3();
        setOpenSuccessBar(true);
        // setisFormLoading(false);
      } catch (err) {
        // setError(t("something_went_wrong"));
        setIsLoading(false);
        console.log(err);
        // setisFormLoading(false);
      }
    }
  };

  let sponsorData = {
    sponsorInfo,
    setSponsorInfo,
    sponsorInfoError,
    setSponsorInfoError,
    onSubmitHandler,
    validateSponserInfo,
    fetchProfilePage3,
    openSuccessBar,
    setOpenSuccessBar,
    isLoading,
    setIsLoading,
    validateSponsorForm,
  };
  return (
    <GetSponsorDataContext.Provider value={sponsorData}>
      {children}
    </GetSponsorDataContext.Provider>
  );
};
