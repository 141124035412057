import React from "react";
import capitalize from "@mui/utils/capitalize";

const PassportInformation = ({ passportInfo, previousStayJapanese }) => {
  return (
    <div>
      <div className=" row rowOutline--wrapper" id="passportInfoSection">
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span className="section-heading">
                {/* Passport Information/ Stay History */}
                パスポート取得状況
              </span>
              {/* <span>
                {" "}
                <FontAwesomeIcon icon={faChevronCircleUp} />{" "}
                <FontAwesomeIcon icon={faChevronCircleDown} />{" "}
              </span>{" "} */}
            </div>
          </div>

          <div className="row formInner">
            <div className="col-12">
              <label for="passportNumber" class="form-label title-label">
                {/* Passport Number */}
                旅券番号
              </label>
              <br />
              <label class="form-label info-label">
                {passportInfo.passportNumber != ""
                  ? passportInfo.passportNumber
                  : "-"}
              </label>
            </div>
            <div className="col-12">
              <label for="passportExpiryDate" class="form-label title-label">
                {/* Passport Expiration Date */}
                旅券の有効期限
              </label>
              <br />
              <label class="form-label info-label">
                {passportInfo.passportExpiryDate != ""
                  ? passportInfo.passportExpiryDate
                  : "-"}
              </label>
            </div>
            {/* passport issue */}
            <div className="col-12">
              <label for="inputDate" class="form-label  title-label">
                {/* Passport Issue Date */}
                旅券の発行年月日
              </label>
              <br />
              <label class="form-label info-label">
                {passportInfo.passportIssueDate != ""
                  ? passportInfo.passportIssueDate
                  : "-"}
              </label>
            </div>
            {/* previous stay in japan */}
            <div class="col-12 col-sm-6">
              <label class="form-label  title-label">
                {/* Previous Stay in Japan */}
                出入国履歴の有無
              </label>
              <br />
              <label class="form-label info-label">
                {passportInfo.previousStay != null &&
                passportInfo.previousStay != ""
                  ? capitalize(passportInfo.previousStay)
                  : "-"}
              </label>
            </div>
            {/* ------------------------------Model----------------- */}
            {passportInfo.previousStay == "yes" &&
              Array.isArray(previousStayJapanese) &&
              previousStayJapanese.map((item, index) => {
                let previousStayJapanpurposeOfStayId = `previousStayJapanpurposeOfStay-${index}`;
                let previousStayJapanstatusOfResidenceId = `previousStayJapanstatusOfResidence-${index}`;
                let previousStayJapanEntryYearId = `previousStayJapanEntryYear-${index}`;
                let previousStayJapanEntryMonthId = `previousStayJapanEntryMonth-${index}`;
                let previousStayJapanEntryDayId = `previousStayJapanEntryDay-${index}`;
                let previousStayJapanDepartureYearId = `previousStayJapanDepartureYear-${index}`;
                let previousStayJapanDepartureMonthId = `previousStayJapanDepartureMonth-${index}`;
                let previousStayJapanDepartureDayId = `previousStayJapanDepartureDay-${index}`;
                return (
                  <div
                    className="col-12"
                    style={{
                      backgroundColor: "#f5f5f5",
                      marginTop: "15px",
                      padding: "10px",
                      marginLeft: "15px",
                    }}
                  >
                    <div className="row">
                      {/* date of entry */}
                      <div className="col-12">
                        <label
                          for={previousStayJapanEntryYearId}
                          class="form-label title-label"
                        >
                          {/* Date of Entry */}
                          入国日
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.dateOfEntry != "" ? item.dateOfEntry : "-"}
                        </label>
                      </div>
                      {/* date of departure */}
                      <div className="col-12">
                        <label
                          for={previousStayJapanDepartureYearId}
                          class="form-label title-label"
                        >
                          {/* Date of Departure */}
                          出国日
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.dateOfDeparture != ""
                            ? item.dateOfDeparture
                            : "-"}
                        </label>
                      </div>
                      {/* student of residence */}
                      <div className="col-12">
                        <label
                          for={previousStayJapanstatusOfResidenceId}
                          class="form-label title-label"
                        >
                          {/* Status of Residence */}
                          在留資格
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.statusOfResidence != ""
                            ? item.statusOfResidence
                            : "-"}
                        </label>
                      </div>
                      {/* ppurpose of entry */}
                      <div className="col-12">
                        <label
                          for={previousStayJapanpurposeOfStayId}
                          class="form-label title-label"
                        >
                          {/* Purpose of Entry */}
                          入国目的
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.purposeOfStay != "" ? item.purposeOfStay : "-"}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PassportInformation;
