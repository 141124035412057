import { React, useState, useEffect } from "react";
import { API } from "aws-amplify";
import {
  getMasterData,
  manageSchoolQ,
  schoolGetApplicantProfileDocs,
  schoolGetApplicantList,
} from "src/graphql/queries";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getAuthMode } from "src/core/utilities/authMode";
import { Spinner } from "reactstrap";
import withHeader from "src/services/with_header_footer";
import Tab1 from "../../ApplicationDetail/ScoutTab1";
import Document from "src/components/Student/StudentProfile/Document";
import ScoutTab1 from "../../ApplicationDetail/ScoutTab1";

const ScoutStatus = () => {
  const { isAuth, role } = useSelector((state) => state.user);
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [masterNationality, setMasterNationality] = useState([]);
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    birthday: "",
    nationality: "",
    isResidenceJapan: "",
    profileImage: "",
  });
  const [applicationStatus, setApplicationStatus] = useState("");
  const languageCode = useSelector((store) => store.language);
  const [documents, setDocuments] = useState({
    statementOfReason: {
      title:
        // "Statement of reason for attending school"
        "就学理由書",
      about:
        // "(When 5 or more years have elapsed after graduating the final education school)"
        "(最終学歴となっている学校卒業後5年以上経過している場合)",
      // src from Backend
      srcFileLink: "",
      srcFileName: "",
      // src from Input
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    statementOfAdvancement: {
      // title:
      //   "Statement of advancement after graduating the Japanese language educational organization",
      // about:
      //   "(When 5 or more years have elapsed after graduating the final education school)",
      title: "日本語教育機関卒業後の進路説明書",
      about: "(最終学歴となっている学校卒業後5年以上経過している場合)",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    photograph: {
      // title: "Identification photograph",
      title: "証明写真",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    passport: {
      // title: "Copy of a passport or identification document",
      title: "パスポートまたは身分証明書のコピー",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    diplomaOfLastSchool: {
      // title: "Diploma of the school last attended",
      title: "最終学校の卒業証明書",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    transcriptFromLastSchool: {
      // title: "Transcript from the school last attended",
      title: "最終学校の成績証明書",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    japaneseLangCertificate: {
      // title: "Document certifying the Japanese language proficiency",
      title: "日本語能力立証資料",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    employmentCertificate: {
      // title: "Employment certificate",
      // about: "(only when you have employment record)",
      title: "在職証明書",
      about: "(職歴がある場合のみ)",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    tuberculosisScreeningCert: {
      // title: "Japan pre-entry tuberculosis screening",
      // about:
      //   "Submission of this is required for applicant holding passport from Philippines, Vietnam, China, Indonesia, Nepal and Myanmar.",
      title: "結核非発病証明書",
      about:
        "フィリピン、ベトナム、中国、 インドネシア、ネパール、 ミャンマーの国籍を有する申請者の方は必ず提出してください。",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    financialDoc: {
      // title: "Financial support document",
      title: "経費支弁書",
      about: "学校指定用紙",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    sponsorFamilyMemberList: {
      // title: "List of family members of the financial supporter",
      title: "経費支弁者家族一覧表",
      about: "学校指定用紙",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    sponsorFamilyStructure: {
      // title:
      //   "Document certifying the family structure of the financial supporter",
      title: "経費支弁者家族構成を立証する資料",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    sponsorRelationWithApplicantCert: {
      // title:
      //   "Document certifying the relationship between the financial supporter and the applicant",
      title: "経費支弁者と申請人との関係を立証する資料",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    depositBalanceCert: {
      // title: "Certificate of deposit balance (original)",
      title: "預金残高証明書（原本）",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    sponsorEmploymentCert: {
      // title: "Document certifying the employment of the financial supporter",
      title: "経費支弁者の職業を立証する資料",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    sponsorAssetFormationCert: {
      // title: "Document certifying the asset formation process for past 3 years",
      title: "過去3年間の資金形成過程立証資料",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
    sponsorIncomeCert: {
      // title:
      //   "Document certifying the income of the financial supporter for past 3 years",
      title: "過去3年間の経費支弁者の収入を立証する資料",
      about: "",
      srcFileLink: "",
      srcFileName: "",
      inputFileData: "",
      inputFileName: "",
      isLoading: false,
      error: "",
    },
  });

  /// TODO: Remove later. Temporary implemetation to get Course Info
  const [courseInfo, setCourseInfo] = useState({
    id: "",
    name: "",
    applicationStatus: "",
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  const handleOnClick = (index) => {
    setActiveIndex(index);
  };
  // const headerTabs = ["Application Detail", "Document Info"];
  const headerTabs = ["応募 詳細", "資料 情報"];
  // console.log("object", a)
  const selectHeader = headerTabs.map((headerTab, index) => {
    return (
      <>
        <button
          key={index}
          onClick={() => handleOnClick(index)} // pass the index
          style={{ width: "100%", height: "80px" }}
          className={`${
            activeIndex === index ? "active-tab" : "unactive-tab"
          } `}
        >
          {headerTab}
        </button>
        {/* {console.log("activeIndex", activeIndex === index)}
        {console.log("indez", index)} */}
      </>
    );
  });

  const fetchApplicantProfileDocs = async () => {
    const getApplicantProfileDocsData = await API.graphql({
      query: schoolGetApplicantProfileDocs,
      variables: { applicationId: id },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });
    console.log("getApplicantProfileDocsData: ", getApplicantProfileDocsData);
    const data = getApplicantProfileDocsData.data.schoolGetApplicantProfileDocs;
    let newDocuments = { ...documents };
    Object.keys(documents).map((key) => {
      if (data[key]) {
        newDocuments[key].srcFileLink = data[key];
      }
    });
    setDocuments(newDocuments);
  };

  const fetchMasterData = async () => {
    try {
      const masterDataFromAPI = await API.graphql({
        query: getMasterData,
        variables: {
          masterdata: {
            countryList: true,
            // language: languageCode,
            language: "JA",
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const masterData = masterDataFromAPI.data.getMasterData;
      if (masterData != null && Array.isArray(masterData)) {
        let countryList = masterData.filter(
          (item) => item.type == "countryList"
        )[0].items;
        setMasterNationality(countryList);
      }
    } catch (err) {
      // console.log("masterDataFromAPI error in root view: ", err);
      console.log(err);
    }
  };

  const fetchStudentProfile = async () => {
    try {
      const schoolGetApplicantProfileData = await API.graphql({
        query: manageSchoolQ,
        variables: {
          scoutStudentDetail: {
            scoutId: id,
            // language: languageCode
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log(
        "schoolGetApplicantProfileData: ",
        schoolGetApplicantProfileData
      );

      const data =
        schoolGetApplicantProfileData.data.manageSchoolQ.scoutStudentDetail;

      let personalInfoFromData = {
        firstName: "",
        lastName: "",
        email: "",
        nationality: "",
        scoutStatus: "",
        profileImage: "",
        studentGuid: "",
      };

      if (data.firstName) {
        personalInfoFromData.firstName = data.firstName;
      }

      if (data.email) {
        personalInfoFromData.email = data.email;
      }

      if (data.lastName) {
        personalInfoFromData.lastName = data.lastName;
      }

      if (data.scoutStatus) {
        personalInfoFromData.scoutStatus = data.scoutStatus;
      }

      if (data.nationality) {
        personalInfoFromData.nationality = data.nationality;
      }

      if (data.studentGuid) {
        personalInfoFromData.studentGuid = data.studentGuid;
      }

      if (data.profileImage) {
        personalInfoFromData.profileImage = data.profileImage;
      }

      setPersonalInfo(personalInfoFromData);
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  const fetchGetApplicationList = async () => {
    try {
      const schoolGetApplicationListData = await API.graphql({
        query: schoolGetApplicantList,
        variables: {
          // filters: { language: languageCode },
          pagination: { countPerPage: 5, page: 1 },
        },
        authMode: await getAuthMode(),
      });

      console.log(
        "schoolGetApplicationListData: ",
        schoolGetApplicationListData
      );

      let applications =
        schoolGetApplicationListData.data.schoolGetApplicantList.list;

      let matchedItem = applications.find(
        (element) => element.applicationId == id
      );
      if (matchedItem) {
        setCourseInfo({
          id: matchedItem.courseId,
          name: matchedItem.courseName,
          applicationStatus: matchedItem.applicationStatus,
        });

        setApplicationStatus(matchedItem.applicationStatus);
      }

      // setApplicants(studentCourseApplicationListData);
    } catch (err) {
      setError(t("something_went_wrong"));
      console.log(err);
    }
  };
  useEffect(async () => {
    try {
      /// Don't await it now, as it can be expensive request and it's temporary solution
      fetchGetApplicationList();
      await fetchMasterData();
      await Promise.all([fetchStudentProfile(), fetchApplicantProfileDocs()]);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(t("something_went_wrong"));
      console.log(err);
    }
  }, []);

  return (
    <div
      style={{
        paddingTop: "30px",
        paddingRight: "30px",
        paddingLeft: "30px",
        minHeight: "600px",
        backgroundColor: "#f5f5f5",
      }}
    >
      {isLoading && (
        <center>
          <Spinner />
        </center>
      )}
      {!isLoading && (
        <div
          className="student-form container m-auto"
          style={{ backgroundColor: "white" }}
        >
          {/* <ul
            style={{ height: "80px" }}
            class="row nav nav-tabs"
            id="myTab"
            role="tablist"
          > */}
          <ul
            style={{ height: "80px" }}
            class="row nav nav-tabs"
            id="myTab"
            role="tablist"
          >
            <li class="col-6 px-0 nav-item" role="presentation">
              {selectHeader[0]}
            </li>
            <li class="col-6 px-0 nav-item" role="presentation">
              {selectHeader[1]}
            </li>
          </ul>
          {activeIndex === 0 ? (
            <ScoutTab1
              applicationStatus={applicationStatus}
              courseInfo={courseInfo}
              masterNationality={masterNationality}
              personalInfo={personalInfo}
              documents={documents}
              passUrl="/applicants/detail/"
              studentId={personalInfo.studentGuid}
            />
          ) : activeIndex === 1 ? (
            <Document documents={documents} />
          ) : (
            ""
          )}
        </div>
      )}
    </div>
    // </>
  );
};

export default withHeader(ScoutStatus);
