import React from "react";
import {
  Col,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardFooter,
} from "reactstrap";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faEnvelope,
  faSquare,
  faCaretRight,
  faBriefcase,
  faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";

import logoM from "src/assets/images/common/logoM.png";
import SocialLink from "src/components/SocialLink";
import { useTranslation } from "react-i18next";
import capitalize from "../../../core/utilities/utils";

const FooOverview = ({ to }) => {
  const { t, i18n } = useTranslation();

  return (

    <>
      <Col lg={3} md={6}>
        <Card className="foo-overview bg-transparent border-0 text-capitalize">
          <CardHeader className="bg-transparent m-0 border-0">
            <Link to="/">
              <img src={logoM} alt="logo" />
            </Link>
          </CardHeader>
          <CardBody className="pt-0">
            {/* <div className="d-flex">
            <FontAwesomeIcon className="h4 fav-icon" icon={faMapMarkerAlt} />
            <CardText tag="p" className="m-0 text-muted ml-4 pb-5">
              Fourth Valley Concierge Corporation
            </CardText>
          </div> */}
            <div className="d-flex m">
              <FontAwesomeIcon className="h4 fav-icon" icon={faCaretRight} />
              <CardText tag="p" className="m-0 text-muted ml-4 pb-5">
                <Link to="/contactus"> {capitalize(t("about_us"))} </Link>
              </CardText>
            </div>
            <div className="d-flex m">
              <FontAwesomeIcon className="h4 fav-icon" icon={faEnvelope} />
              <CardText tag="p" className="m-0 text-muted ml-4 pb-5">
                <Link
                  style={{ textTransform: "initial" }}
                  to="/business-inquiry-form"
                >
                  {t("for_business_inquiries")}
                  {/* サービス利用に関するお問い合わせはこちら */}
                </Link>
              </CardText>
            </div>
            {/* <div className="d-flex">
            <FontAwesomeIcon
              className="mt-2 text-primary h4"
              icon={faPhoneAlt}
            />
            <CardText tag="p" className="m-0 text-muted ml-4 pb-5">
              +81-1234567890
              <br /> +81-1234567890
            </CardText>
          </div> */}
            {/* <div className="d-flex">
            <FontAwesomeIcon className="h4 fav-icon" icon={faEnvelope} />
            <CardText tag="li" className="m-0 text-muted ml-4 pb-5"> */}
            {/* <Link className="text-muted" to={to || "#"}> */}
            {/*   <Link to={to || "#"}>info@connect.com</Link>
            </CardText>
          </div> 
          </Col>*/}
          </CardBody>
          <CardFooter className="bg-transparent m-0 border-0 pb-0 pt-0">
            {/* <SocialLink childClass="btn btn-icon btn-sm btn-outline-primary rounded-circle" /> */}
          </CardFooter>
        </Card>
      </Col>
      <Col lg={3} md={6}>
        <Card className="foo-nav bg-transparent border-0 text-capitalize">
          <CardHeader
            tag="h5"
            className="bg-transparent border-0 text-white m-0 pb-2 pl-0"
          >
            Quick Links
          </CardHeader>
          <CardBody tag="ul" className="pl-0 pr-0 ">

            <li className="d-flex align-items-center mb-2">
              <FontAwesomeIcon
                className="fav-icon d-block"
                icon={faSquare}
              />
              <Link to="/business-inquiry-form" className="ml-4 text-muted d-block">
                Inquiry (For Schools)
              </Link>
            </li>
            <li className="d-flex align-items-center mb-2">
              <FontAwesomeIcon
                className="fav-icon d-block"
                icon={faSquare}
              />
              <a href="https://support.connectiu.com/hc/en-us/requests/new" target={"_blank"} className="ml-4 text-muted d-block">
                Inquiry (For Students)
              </a>
            </li>
            <li className="d-flex align-items-center mb-2">
              <FontAwesomeIcon
                className="fav-icon d-block"
                icon={faSquare}
              />
              <a href="https://www.4th-valley.com" target={"_blank"} className="ml-4 text-muted d-block">
                About Us
              </a>
            </li>
            <li className="d-flex align-items-center mb-2">
              <FontAwesomeIcon
                className="fav-icon d-block"
                icon={faSquare}
              />
              <Link to="/list/blog" className="ml-4 text-muted d-block">
                Blogs
              </Link>
            </li>
            <li className="d-flex align-items-center mb-2">
              <FontAwesomeIcon
                className="fav-icon d-block"
                icon={faSquare}
              />
              <a href="https://workers.connectiu.com" target={"_blank"} className="ml-4 text-muted d-block">
                CJW/CJ
              </a>
            </li>
            <li className="d-flex align-items-center mb-2">
              <FontAwesomeIcon
                className="fav-icon d-block"
                icon={faSquare}
              />
              <a href="https://nihongo.connectiu.com" target={"_blank"} className="ml-4 text-muted d-block">
                Japanese Language
              </a>
            </li>
            <li className="d-flex align-items-center mb-2">
              <FontAwesomeIcon
                className="fav-icon d-block"
                icon={faSquare}
              />
              <Link to="/#faq_section" className="ml-4 text-muted d-block">
                FAQs
              </Link>
            </li>

          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default FooOverview;
