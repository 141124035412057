// eslint-disable-next-line import/no-anonymous-default-export
export default {
  language_code: "id",
  language_title: "Indonesia",
  welcome_to_connect: "Welcome to Connect.",
  Login: "Gabung",
  school_login_title: "sekolah Gabung",
  login_btn: "Gabung",
  sign_up: "daftar",
  enter_your_email: "Silakan masukkan alamat email Anda",
  enter_your_password: "Silakan masukkan kata sandi Anda",
  school_signup_title: "sekolah daftar",
  signup_btn: "daftar",
  logout: "Keluar",
  change_password: "Ganti kata sandi",
  current_password: "Kata sandi saat ini",
  new_password: "Kata sandi baru",
  confirm_password: "Setujui password",
  incorrect_password: "Kata sandi saat ini salah",
  forgot_password: "Apakah Anda lupa kata sandi Anda?",
  valid_password:
    "Buat kata sandi dengan setidaknya satu huruf besar, setidaknya satu huruf kecil, setidaknya satu angka, dan setidaknya delapan karakter.",
  login_for_school_only_msg:
    "Maafkan saya. Formulir login ini hanya untuk sekolah",
  login_for_student_only_msg:
    "Maafkan saya. Formulir login ini hanya untuk siswa",
  home: "Atas",
  School: "sekolah",
  Dashboard: "Dashboard",
  blog: "Blog",
  Contact: "Kontak informasi",
  schools: "sekolah",
  courses: "kursus",
  applications: "Aplikasi",
  about_us: "Tentang kami",
  contact_us: "Kontak informasi",
  profile: "Profil",
  for_inquiries: "Hubungi kami",
  inquiry_form: "Student Inquiry Form",
  for_business_inquiries: "For inquiries about the service, click here.",
  business_inquiry_form: "Business Inquiry Form",
  submit: "Submit",
  language: "bahasa",
  view_all: "Lihat semua",
  our_services: "Konten layanan",
  course_search: "Pencarian kursus",
  school_search: "Pencarian sekolah",
  our_other_services: "Layanan lainnya",
  privacy_policy: "Rahasia pribadi",
  terms_of_service: "Persyaratan layanan",
  page_not_found: "Maaf, Halaman tidak ditemukan",
  student_tag_line_heading: "Belajar & Bekerja di Jepang!",
  student_tag_line_subheading:
    "Choose a unique study abroad program that suits you",
  find_best_matched_course:
    "Temukan kursus bahasa Jepang yang cocok untuk Anda",
  recommended_schools_msg: "Sekolah bahasa Jepang yang direkomendasikan",
  popular_schools: "Sekolah bahasa Jepang populer",
  statistical_facts: "Layanan kami",
  courses_listed: "Jumlah mata kuliah yang terdaftar",
  schools_listed: "Jumlah sekolah yang terdaftar",
  no_results_found:
    "Maafkan saya. Tidak ditemukan informasi yang memenuhi kriteria yang dipilih",
  go_to_home: "Kembali ke rumah",
  office_address: "Lokasi perusahaan",
  read_more: "Baca lebih lajut",
  phone_number: "Nomor telepon",
  something_went_wrong: "Ada yang salah",
  area: {
    title: "daerah",
    value: {
      hokkaido: "hokkaido",
      tohoku: "tohoku",
      kanto: "kanto",
      chubu: "chubu",
      kansai: "kansai",
      chugoku: "chugoku",
      shikoku: "shikoku",
      kyushu: "kyushu",
    },
  },
  course_type: {
    title: "Jenis kursus",
    value: {
      general_japanese: "Bahasa Jepang Umum",
      university_preparation: "Persiapan untuk melanjutkan ke universitas",
      business_employment: "pekerjaan bisnis",
      others: "yang lain",
    },
  },
  course_duration: {
    title: "Periode kursus",
    value: {
      short: "jangka pendek",
      long: "Jangka",
    },
  },
  enrollment_month: {
    title: "Waktu masuk",
    value: {
      april: "april",
      july: "Juli",
      october: "Oktober",
      january: "Januari",
    },
  },
  japanese_level: {
    title: "Tingkat Bahasa Jepang",
    value: {
      beginner: "Pemula",
      intermediate: "Intermediat",
      advance: "Canggih",
    },
  },
  course_info: {
    value: {
      course_name: "Nama kursus",
      course_outline: "Skema pembelajaran",
      course_description: "Deskripsi kursus",
      applicable_student: "Targetkan siswa",
      visa_type: "Jenis visa",
      beginning_period: "Jenis visa",
      learning_content: "isi studi",
      application_period: "Periode rekrutmen",
      curriculum: "Kurikulum",
      course_goal: "Tujuan kursus",
      curriculum_description: "Detail kurikulum",
      tuition: "Biaya pendidikan(JPY)",
      screening_fee: "Biaya seleksi",
      enrollment_fee: "biaya pendaftaran",
      class_fee: "biaya kuliah",
      facility_fee: "Biaya peralatan",
      total: "jumlah",
      tuition_fee_notes: "Notes for the tuition fee",
    },
  },
  school_info: {
    value: {
      school_name: "nama sekolah",
      school_logo: "logo sekolah",
      available_course_type: "Jenis kursus",
      available_course_length: "Periode kursus",
      school_introduction: "Pengenalan sekolah",
      basic_information: "Informasi dasar sekolah",
      location: "lokasi",
      nearest_station: "Stasiun terdekat",
      phone_number: "Nomor telepon",
      fax_number: "Nomor fax",
      school_website: "situs web",
      email: "Alamat E-mail",
      founder: "Pendiri",
      director: "kepala sekolah",
      authorized_date: "Tanggal izin",
      establishmentDate: "Tanggal pendirian",
      faculty_staff: "Guru・staf",
      num_of_faculty_full_time: "Jumlah guru",
      assisstant_for_housing: "Asisten berburu rumah",
      foreign_speaking_staff: "Staf yang bisa berbicara bahasa asing",
      students: "murid",
      quota_of_school: "Kapasitas",
      total_num_students: "Jumlah siswa",
      major_countries_students: "Kebangsaan utama",
      grads_this_year: "expected graduates (End of this year)",
      grads_last_year: "Jumlah lulusan tahun lalu",
      after_graduation: "Tujuan utama",
      jlpt_passer: "number of passer in JLPT exam",
      admission_selection: "Seleksi masuk",
      selection_method: "Metode pemilihan",
      application_fee: "Biaya seleksi",
      deadline: "Biaya seleksi",
      mailing_address: "Alamat surat",
      pr_school: "Dari sekolah",
      message_from_school: "Pesan dari sekolah",
      images: "Foto",
      videos: "film",
      school_year_calendar: "Konten aktivitas tahunan",
      scholarship_if_available: "Informasi beasiswa",
      facility: "Fasilitas",
    },
  },
  confirm_account_modal_header: "Harap verifikasi akun Anda",
  confirm_account_modal_body: "Gagal masuk. Harap autentikasi melalui email",
  confirm: "Confirm",
  cancel: "Cancel",
  email: "Alamat E-mail",
  password: "kata sandi",
  do_not_have_account: "Apakah kamu punya akun?",
  validation_empty_email: "Email Address cannot be empty",
  validation_empty_password: "Password cannot be empty",
  user_agreement_prefix: "I agree to the Connect Study Abroad ",
  user_agreement_conjunction: " and ",
  user_agreement_postfix: "",
  user_agreement_validation:
    "Harap setujui Persyaratan Layanan dan Kebijakan Privasi",
  already_have_account: "Already have an account?",
  verification_code_sent:
    "We have sent a code by email. Enter it below to confirm your account",
  confirm_account: "Confirm Account",
  did_not_receive_code: "Didn't receive a code?",
  resend: "Resend it",
  verification_title: "Verification",
  passwords_do_not_match: "Sandi tidak cocok",
  forgot_password_description:
    "Silakan masukkan alamat email Anda di bawah ini. Kami akan mengirimkan kode untuk mereset kata sandi Anda",
  reset_password: "Reset Password",
  password_reset_code_sent:
    "We have sent a password reset code by email. Enter it below to reset your password.",
  code: "Code",
  onboarding: {
    first_name: "Nama keluarga",
    last_name: "Nama",
    date_of_birth: "Tanggal lahir",
    year: "Year",
    month: "Month",
    day: "Day",
    nationality: "Warganegara",
    start_study_in_japan:
      "Kapan Anda berencana untuk belajar di luar negeri di Jepang?",
    currently_reside_in_japan: "Apakah Anda saat ini tinggal di Jepang?",
    yes: "Yes",
    no: "No",
  },
  student_profile: {
    user_picture: "Foto wajah",
    first_name: "Nama keluarga",
    last_name: "Nama",
    date_of_birth: "Tanggal lahir",
    sex: "Seks",
    nationality: "Warganegara",
    place_of_birth: "Tempat Lahir",
    current_address: "Alamat saat ini",
    current_occupation: "Profesi saat ini",
    start_study_in_japan:
      "Kapan Anda berencana untuk belajar di luar negeri di Jepang?",
    currently_reside_in_japan: "Apakah Anda saat ini tinggal di Jepang?",
  },
  profile_tabs: {
    profile: "Profil",
    application_for_admission: "Aplikasi penerimaan",
    documents: "Dokumen",
  },
  admission_application_forms: {
    personal_info: "Informasi pribadi",
    passport_info_stay_history: "Status perolehan paspor",
    family_friend_japan: "Kerabat dan teman di Jepang",
    marital_status: "Status pernikahan",
    education_background: "Latar belakang pendidikan",
    professional_background: "Riwayat pekerjaan",
    japanese_study_experience: "Sejarah belajar bahasa jepang",
    japanese_proficiency: "Kemampuan bahasa Jepang (sertifikat, dll)",
    plan_after_graduation: "Jadwal setelah lulus",
    about_sponsor: "Tentang pendukung biaya",
  },
  admission_application_page1: {
    passport_status: "Status perolehan paspor",
    passport_number: "Nomor paspor",
    passport_expiration_date: "Tanggal kedaluwarsa paspor",
    passport_issue_date: "Tanggal penerbitan paspor",
    previous_coe_application: "Status riwayat aplikasi tempat tinggal",
    previous_stay_in_japan: "Ada atau tidak adanya riwayat imigrasi",
    previous_stay_in_japan_detail: "Sejarah imigrasi",
    date_of_entry: "Tanggal masuk",
    date_of_departure: "Tanggal keberangkatan",
    status_of_residence: "Status tempat tinggal",
    purpose_of_entry: "Tujuan masuk",
    family_or_friend_question: "Ada atau tidaknya kerabat dan teman di Jepang",
    family_or_friend_title: "Kerabat dan teman di Jepang",
    relationship: "Hubungan",
    name: "Nama lengkap",
    intention_to_reside: "Dijadwalkan untuk hidup bersama",
    place_of_employment: "Sekolah atau kerja",
    residence_card_number: "Nomor kartu tempat tinggal",
    marital_status: "Status pernikahan",
    partner_name: "Nama pasangan",
  },
  admission_application_page2: {
    educational_background: "Latar belakang pendidikan",
    type_of_school: "Jenis sekolah",
    name_of_school: "Nama sekolah (nama resmi)",
    location_in_detail: "Alamat (detail)",
    period_of_attendance: "Periode pendaftaran",
    start_date: "Mulai tanggal",
    end_date: "Tanggal akhir",
    years: "Tahun pendaftaran",
    professional_career_question: "Ada atau tidaknya riwayat pekerjaan?",
    professional_career_title: "Riwayat pekerjaan",
    company_name: "Nama perusahaan",
    type_of_job: "Pekerjaan",
    period_of_employment: "Periode pendaftaran",
    japanese_study_experience_question:
      "Apakah Anda memiliki riwayat belajar bahasa Jepang atau tidak?",
    japanese_study_experience_title: "Sejarah belajar bahasa jepang",
    hours: "Jumlah jam belajar",
    japanese_proficiency_certification_question:
      "Kualifikasi untuk membuktikan kemampuan Jepang?",
    japanese_proficiency_certification_title: "Tentang kemampuan Jepang",
    name_of_test: "Nama ujian",
    experience_of_test: "Riwayat pemeriksaan",
    year_of_test: "Tahun ujian",
    level: "Tingkat",
    score: "Skor",
    plan_after_graduation: "Jadwal setelah lulus",
    currently_enrolled: "Saat ini terdaftar",
    do_you_have_work_experience:"Apakah Anda memiliki pengalaman kerja? – Ya / Tidak",
    expected_graduation_date:"Ekspektasi tanggal kelulusan ",
    enrollemnt_period:"Periode pendaftaran (tahun)",
    currently_working_company:"Saat ini saya bekerja di perusahaan ini",
    studied_japanese_institute:"Pernahkah Anda belajar bahasa Jepang di lembaga mana pun? – Ya / Tidak    ",
    not_yet_completed:"Belum selesai"
  },
  admission_application_page3: {
    sponsor_name: "Nama pendukung biaya",
    sponsor_address: "Alamat pendukung biaya",
    sponsor_relationship: "Hubungan dengan Pemohon Pendukung Biaya",
    sponsor_annual_income: "Pengeluaran pendukung pendapatan tahunan",
    sponsor_occupation: "Pekerjaan pendukung biaya",
    sponsor_employer: "Pendukung biaya Nama kerja",
  },
  student_document: {
    application_for_admission: "Aplikasi penerimaan",
    statement_of_reason_title: "Alasan masuk sekolah",
    statement_of_reason_about:
      "(Jika lebih dari 5 tahun telah berlalu sejak lulus dari sekolah, yang merupakan latar belakang akademik terakhir)",
    statement_of_advancement_title:
      "Petunjuk karir setelah lulus dari lembaga pendidikan bahasa Jepang",
    statement_of_advancement_about:
      "(jika lebih dari 5 tahun telah berlalu sejak lulus dari sekolah yang memiliki latar belakang akademik akhir)",
    identification_photograph: "Pas foto (5) 4 x 3 cm",
    passport_or_identification: "Salinan paspor atau ID Anda",
    diploma_of_last_school: "Sertifikat kelulusan sekolah terakhir",
    transcript_of_last_school: "Transkrip sekolah terakhir",
    japanese_language_proficiency: "Bahan bukti kemahiran bahasa Jepang",
    employment_certificate_title: "Sertifikat pekerjaan ",
    employment_certificate_about:
      "(hanya jika Anda memiliki riwayat pekerjaan)",
    tuberculosis_screening_title: "Sertifikat non-morbiditas tuberkulosis ",
    tuberculosis_screening_about:
      "Pelamar berkebangsaan Filipina, Vietnam, Cina, Indonesia, Nepal, dan Myanmar harus menyerahkan",
    financial_support:
      "Formulir pembayaran biaya (formulir yang ditentukan sekolah)",
    supporter_family_members:
      "Daftar keluarga pendukung pengeluaran (formulir yang ditunjuk sekolah)",
    supporter_family_structure:
      "Dokumen untuk membuktikan struktur keluarga pendukung pengeluaran",
    supporter_relationship:
      "Bahan untuk membuktikan hubungan antara pembayar biaya dan pemohon",
    certificate_of_deposit: "Sertifikat saldo deposito (asli)",
    supporter_employment:
      "Dokumen yang menyatakan profesi pendukung pengeluaran",
    three_years_asset_formation:
      "Bukti proses pendanaan selama 3 tahun terakhir",
    supporter_three_years_income:
      "Dokumen yang menunjukkan pendapatan pemberi dana selama tiga tahun terakhir",
  },
  save_profile_btn: "menyimpan",
  date_of_birth: "Tanggal lahir",
  nationality: "Warganegara",
  additional_fee_label: "Is there an additional fee after enrollment?",
  additional_fee_yes: "Yes",
  additional_fee_no: "No",
  early_application_deadline:
    "The application deadline may become earlier than planned depending on the number of applications received.",
  course_list: "Daftar kursus",
  course_detail: "Detail kursus",
  school_list: "Daftar sekolah",
  school_detail: "Detail sekolah",
  go_to_list: "Kembali ke Daftar",
  apply: "Apply",
  applied_date: "Tanggal Aplikasi",
  application_status: "Status",
  student_top_page: {
    why_japans: {
      why_japan: "Kelebihan Jepang?",
      cost_of_living:
        "Biaya hidup dan kuliah lebih rendah dari Amerika, Inggris, dan Australia!",
      various_scholarship: "Berbagai beasiswa tersedia!",
      part_time_during_study:
        "Anda dapat bekerja part-time selama studi, dan full-time setelah lulus!",
    },
    what_is_csas: {
      what_is_csa: "Apa itu CSA?",
      platform_directly_match: "Platform untuk mempertemukan kuliah dan murid.",
      sign_up_free: "Daftar Gratis",
    },
    blogs: {
      blog: "Blog",
    },
    why_japans_stat: {
      why_japan: "Kelebihan Jepang?",
      tuition_and_cost_living_japan: "Biaya kuliah dan biaya hidup di Jepang",
      annual_tuition: "Biaya kuliah/tahun (USD)",
      annual_living: "Biaya hidup/tahun (USD)",
    },
    carrers: {
      career_during_study_available: "Bisa kerja sambil kuliah! ",
      partime_job: "Anda bisa bekerja part-time maksimal",
      hrs_per_week: " 28jam/minggu",
      while_studying: "sebagai pelajar. ",
      work_upto:
        "*Dalam periode liburan sekolah, anda bisa bekerja selama 40jam/minggu!",
    },
    approval_rate_of_working_visas: {
      approval_rate_of_working_visa:
        "Tingkat penerimaan work visa yang tinggi! ",
      career_after_study: "Karir setelah kuliah juga tersedia!",
      number_of_visa_changes:
        "Jumlah visa yang berubah jadi pekerja dari pelajar. ",
      by_how_much: "In 2020, Kurang lebih ",
      eighty_Seven: "87%",
      approved: " di terima!",
    },
    grant_type_scholarships: {
      grant_type_scholarship: "Ada banyak beasiswa tipe ",
      available: "grant !",
      almost_foreign_students:
        "Hampir 30% pelajar mendapatkan beasiswa tipe g grant di ",
      for_study_in_japan: " Jepan ",
      there_142_scholarship:
        "Ada 142 jenis beasiswa tipe grant disediakan oleh pemerintah ",
      japanese_government: " Jepang dan organisasi lain.",
      got_scholarship: "1,840 murid telah mendapatkan beasiswa tersebut dari ",
      government_2021: " pemerintah  Jepang pada tahun 2021.",
    },
    connect_study_deliver_support: {
      connect_study_deliver_support_students:
        "Connect Study ABROAD memberi dukungan untuk pelajar",
      sign_up_free: "Daftar Gratis",
    },
    about_csas: {
      about_csa: "Tentang CSA ",
      get_support_fulfill_student_life:
        "Dapatkan dukungan kehidupan pelajar dan dukungan lain!",
      information_not_only_schools:
        "Tidak hanya informasi sekolah, CSA memberi informasi beasiswa, akomodasi, part time job, dan lain-lain!",
      get_support_for_education:
        "Dapatkan dukungan untuk pendidikan tinggi dan karir",
      our_strong_track_supporting:
        "Pengalaman lama kita dalam mendukung perekrutan bakat global untuk perusahaan Jepang akan membantu perluaskan kemungkinan Anda setelah studi.",
    },
    our_factss: {
      our_facts: "Prestasi kami",
      support_for_7yrs:
        "Telah mendukung impian siswa untuk belajar di Jepang selama",
      seven_yrs: " 7 tahun.",
      more_than: " Lebih dari",
      thirteen_thousand: " 13,000 siswa telah",
      used_our_service: " menggunakan layanan kami.",
      career_event: "Acara karir di lebih dari",
      twenty_countries: " 20 negara",
      pre_covid: " (Pre-COVID). Acara study abroad di",
      ten_countries: " 10 negara",
      covid: " (Pre-COVID).",
      supported_around: "Kami telah mendukung sekitar",
      four_thousand_Candidates: " 4,000 kandidat",
      job_hunting: "untuk mencari kerja di Jepang tahun lalu.",
      registered_users: "Kami mempunyai user dari sekitar",
      hundred_forty: " 140 negara.",
    },
    facebook_pages: {
      facebook_page: "Facebook Page",
    },
  },
  select_one: "Select one",
  pre_apply: "Pre-apply",
  tab_exit_heading: "Are you sure?",
  tab_exit_description: "Any unsaved changes will be lost.",
  yes: "Ya",
  no: "Tidak",
  duplicate_application_msg: "You have already applied to this course.",
  csa_free_minimun_balance:
    "Layanan CSA GRATIS. Namun, untuk mengajukan visa belajar di Jepang, Anda atau anggota keluarga harus menunjukkan bukti pendapatan tahunan DAN saldo bank minimal 2 juta JPY.",
  required_student_profile_fields:
    "Required Student Profile fields are not filled up",
  redirect_student_profile_page:
    "You will be redirected to Student Profile Page.",
  redirect: "Redirect",
  proceed_with_course_application: "Proceed with Course Application",
  faq: {
    faq1: {
      id: "1",
      title: "Apa itu CSA? ",
      description:
        "CSA adalah plarform yang secara langsung mempertumukan Anda dan sekolah-sekolah di Jepang. Tidak ada biaya untuk kandidat. Anda tidak perlu lagi membayar ke agen untuk belajar di Jepang! Anda bahkan bisa mendapatkan ajakan dari sekolah setelah Anda memasukkan profil Anda dan mengunggah dokumen Anda! Mulai dari mengisi profil dan pra-masuk ke sekolah yang Anda minati.",
    },
    faq2: {
      id: "2",
      title: "Layanan apa yang disediakan CSA?",
      description:
        "CSA adalah platform yang secara langsung mempertumukan Anda dan sekolah di Jepang. Tidak ada biaya untuk kandidat. Beberapa hal yang dapat Anda lakukan di CSA adalah.....- Cari sekolah - Dapatkan ajakan dari sekolah      - Dukungan yang diperlukan untuk tinggal di Jepang (perumahan, mendapatkan ponsel, dll. dll.)      Salah satu hal terbaik tentang CSA adalah kami dapat mendukung Anda dalam mendapatkan pekerjaan part time sambil belajar dan juga pekerjaan penuh waktu setelah lulus dari sekolah! ",
    },
    faq3: {
      id: "3",
      title: "Bagaimana cara mendaftar ke sekolah?",
      description:
        "1. Daftar ke Connect Study ABROAD 2. Temukan sekolah/kursus yang Anda minati 3.      4. Klik tombol ''Pra-lamar'' *Semakin banyak profil yang Anda isi, semakin banyak dokumen yang Anda unggah, semakin banyak peluang ajakan yang Anda dapatkan dari sekolah. Jadi pastikan Anda melakukan ini segera setelah Anda mendaftar ke Connect Study LUAR ABROAD!",
    },
    faq4: {
      id: "4",
      title: "Apa itu CSA Internship?",
      description:
        "Ini adalah program paket yang ditawarkan oleh Fourth Valley Concierge Corporation (perusahaan yang menjalankan Connect Study Abroad) untuk belajar di Jepang dan melakukan magang dalam rangka mendukung siswa untuk mendapatkan pengalaman bekerja di Jepang dan juga untuk mendukung mereka secara finansial. Tidak diperlukan bahasa Jepang (diperlukan bahasa Inggris)",
    },
    faq5: {
      id: "5",
      title: "Ada berapa jenis sekolah di Jepang?",
      description:
        "Ada sekitar tiga opsi.1. Sekolah bahasa Jepang Anda dapat belajar di Jepang dari level JLPT N5. Anda dapat belajar bahasa Jepang dengan orang-orang dari seluruh dunia. Kursus bervariasi menurut sekolah. 2. Perguruan Tinggi (Sarjana, Magister, Doktor) Pada dasarnya, Anda harus lulus EJU. Ada sekolah yang memiliki kursus bahasa Inggris yang memungkinkan untuk mengikuti ujian dalam bahasa Inggris juga, tetapi tidak banyak. Jadi, Anda membutuhkan setidaknya JLPT N2 level Jepang. Inilah sebabnya mengapa sebagian besar siswa internasional pertama kali datang ke sekolah bahasa Jepang dan melanjutkan ke tingkat yang lebih tinggi. 3. Sekolah Kejuruan/Pelatihan Anda dapat mempelajari bidang tertentu (cth. Anime, akuntansi, IT, memasak, dll) di Jepang. Pada dasarnya Anda membutuhkan JLPT N2 di atas dan/atau memiliki pengalaman belajar di Jepang selama lebih dari 6 bulan. Silakan lihat di sini untuk detailnya",
    },
    faq6: {
      id: "6",
      title: "Cara mendapatkan beasiswa?",
      description:
        "Di Jepang, ada 142 beasiswa yang tidak perlu dikembalikan (info: Jasso) Ada juga beasiswa eksklusif CSA! Lihat di CSA dan pastikan Anda tidak melewatkan kesempatan ini! Untuk detailnya, silakan lihat di sini",
    },
    faq7: {
      id: "7",
      title: "Berapa biaya belajar di Jepang?",
      description:
        "Dibandingkan dengan AS, Eropa, belajar di Jepang sangat wajar! Tentu saja, itu tergantung pada individu/jenis sekolah, kursus dll, tetapi biasanya sekitar 2 juta JPY per tahun termasuk biaya kuliah dan biaya hidup. Ini 1/2 ~ 1/3 dibandingkan dengan belajar di AS atau Eropa! Anda juga dapat bekerja paruh waktu secara legal di Jepang saat Anda belajar. Untuk detailnya, silakan lihat di sini",
    },
    faq8: {
      id: "8",
      title:
        "Bisakah saya melakukan pekerjaan parut time sebagai siswa internasional?",
      description:
        "Di Jepang, Anda diperbolehkan bekerja hingga 28 jam per minggu (selama masa liburan sekolah, 40 jam per minggu) Upah minimum per jam (di Tokyo) adalah sekitar 1,000JPY (=9USD), jadi Anda bisa mendapatkan 1,6 juta per tahun! Sekitar 70% siswa internasional di Jepang bekerja paruh waktu untuk mendukung diri mereka sendiri untuk studi mereka. Untuk detailnya, silakan lihat di sini",
    },
    faq9: {
      id: "9",
      title: "Bagaimana cara mendapatkan pekerjaan di Jepang?",
      description:
        "Jumlah orang asing yang bekerja di Jepang telah tumbuh 3 kali lipat dalam 10 tahun (dan jumlah perusahaan yang mempekerjakan orang asing juga meningkat) sehingga itu berarti peluang Anda untuk mencari pekerjaan di Jepang meningkat. Tentu saja kami perlu memberikan saran yang berbeda tergantung pada individu dan situasi, tetapi yang umum adalah bahwa Anda akan membutuhkan tingkat bahasa Jepang tertentu di bidang apa pun. Minimum adalah N4, tetapi semakin lancar Anda berbahasa Jepang, semakin banyak peluang yang akan Anda dapatkan. Kami juga mendukung pencarian pekerjaan di Jepang, jadi silakan lihat di platform ini juga untuk peluang kerja.",
    },
    faq10: {
      id: "10",
      title:
        "Saya ingin melanjutkan S1(gelar sarjana)/S2 (gelar magister) di Jepang",
      description:
        "Informasi tentang S1(gelar sarjana) dan S2(gelar magister) belum ditambahkan di CSA. Jika Anda ingin mengejar gelar sarjana/magister, silakan cek https://www.jpss.jp/ja/",
    },
  },
};
