import { useEffect, useState } from "react";
import { sendBirdSelectors, useSendbirdStateContext } from "sendbird-uikit";

const MessageCount = () => {
  const [messageCount, setMessageCount] = useState(0);
  // useState
  const context = useSendbirdStateContext();
  const sdkInstance = sendBirdSelectors.getSdk(context);

  useEffect(() => {
    async function getUnreadCount() {
      const unreadCount =
        sdkInstance &&
        sdkInstance.getTotalUnreadMessageCount &&
        (await sdkInstance.getTotalUnreadMessageCount());
      setMessageCount(unreadCount);
    }
    getUnreadCount();
    let id = setInterval(getUnreadCount, 2000);
    return () => clearInterval(id);
  }, [sdkInstance]);
  return (
    <>
      {messageCount > 0 &&
        <div className="chat_circle">

          <div className="chat_circle_notification">

            {messageCount > 9 ? "9+" : messageCount}
          </div>
        </div>
      }
    </>
  );
};

export default MessageCount;
