import { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Channel as SBConversation,
  ChannelList as SBChannelList,
  ChannelSettings as SBChannelSettings,
} from "sendbird-uikit";
import { MyCustomChannelPreview } from "../ChannelList";
import { CustomizedHeader } from "../CustomizedHeader";
import ChannelPreview from "../Mobile/ChannelPreview";

function CustomizedApp({ id }) {
  // useState
  const history = useHistory();
  const [currentChannelUrl, setCurrentChannelUrl] = useState("");
  const [showSettings, setShowSettings] = useState(false);
  const { isAuth, role } = useSelector((state) => state.user);
  const [channel, setChannel] = useState(null);

  const [query, setQuery] = useState({
    channelListQuery: {
      includeEmpty: true,
      memberStateFilter: "all",
      order: "latest_last_message",
      // channelUrlsFilter: ['seminar', 'lecture'],
      // channelNameContainsFilter: ""
    },
    applicationUserListQuery: {
      limit: 30,
      // userIdsFilter: ['SC0034', 'SC0059'],
    },
  });

  const onChannelSelect = (_channel) => {
    setChannel(_channel);
    history.push("/chat/" + _channel.url);
  };

  return (
    <div className="customized-app">
      <div className="chat_main_content sendbird-app__wrap">
        <div className="sendbird-app__channellist-wrap">
          {/* <CustomChannelListHeader
            setChannelUrl={(url) => {
              setCurrentChannelUrl(url);
            }}
            onSetQuery={setQuery}
          /> */}
          {isAuth && role == "student" && (
            <SBChannelList
              onChannelSelect={(channel) => {
                console.log("channel", channel);
                if (channel && channel.url) {
                  setCurrentChannelUrl(channel.url);
                } else {
                  setCurrentChannelUrl("");
                }
              }}
              renderChannelPreview={({ channel, onLeaveChannel }) => (
                <MyCustomChannelPreview
                  onLeaveChannel={onLeaveChannel}
                  channel={channel}
                />
              )}
              queries={query}
            />
          )}
          {isAuth && role == "school" && (
            <SBChannelList
              onChannelSelect={(channel) => {
                console.log("channel", channel);
                if (channel && id) {
                  setCurrentChannelUrl(id);
                } else if (channel && channel.url) {
                  setCurrentChannelUrl(channel.url);
                }
              }}
              renderChannelPreview={({ channel, onLeaveChannel }) => (
                <ChannelPreview
                  onLeaveChannel={onLeaveChannel}
                  channel={channel}
                  onChannelSelect={onChannelSelect}
                  id={id}
                />
              )}
              queries={query}
            />
          )}
        </div>

        <div className="sendbird-app__conversation-wrap">
          <SBConversation
            channelUrl={currentChannelUrl}
            onChatHeaderActionClick={() => {
              setShowSettings(true);
            }}
            renderChatHeader={({ channel, user }) => (
              <CustomizedHeader
                userId={user}
                channel={channel}
                onChatHeaderActionClick={() => {
                  setShowSettings(true);
                }}
              />
            )}
          />
        </div>
        {showSettings && (
          <div className="sendbird-app__settingspanel-wrap">
            <SBChannelSettings
              channelUrl={currentChannelUrl}
              onCloseClick={() => {
                setShowSettings(false);
              }}
            />
          </div>
        )}
      </div>
      {/* <MessageCount/> */}
    </div>
  );
}

export default CustomizedApp;
