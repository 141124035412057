import React from "react";

const Index = ({ professionalCareers }) => {
  return (
    <div>
      <div className="row rowOutline--wrapper" id="professionalCareerSection">
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span>
                {/* Work Experience */}
                職歴
              </span>
              {/* <span>
              {" "}
              <FontAwesomeIcon icon={faChevronCircleUp} />{" "}
              <FontAwesomeIcon icon={faChevronCircleDown} />{" "}
            </span>{" "} */}
            </div>
          </div>

          <div className="row formInner">
            {Array.isArray(professionalCareers) &&
              professionalCareers.map((item, index) => {
                let professionalNameId = `professionalName-${index}`;
                let professionalTypeId = `professionalType-${index}`;
                let professionalLocationId = `professionalLocation-${index}`;
                let professionalStartYearId = `professionalStartYear-${index}`;
                let professionalStartMonthId = `professionalStartMonth-${index}`;
                let professionalEndYearId = `professionalEndYear-${index}`;
                let professionalEndMonthId = `professionalEndMonth-${index}`;
                return (
                  <div
                    key={index}
                    className="col-12"
                    style={{
                      backgroundColor: "#f5f5f5",
                      marginTop: "15px",
                      padding: "10px",
                      marginLeft: "15px",
                    }}
                  >
                    <div className="row">
                      <div class="col-12">
                        <label
                          for={professionalNameId}
                          class="form-label title-label"
                        >
                          {/* Company Name */}
                          会社名
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.nameOfCompany != "" ? item.nameOfCompany : "-"}
                        </label>
                      </div>
                      <div class="col-12">
                        <label
                          for={professionalTypeId}
                          class="form-label title-label"
                        >
                          {/* Type of Job */}
                          職種
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.typeOfJob != "" ? item.typeOfJob : "-"}
                        </label>
                      </div>
                      <div class="col-12">
                        <label
                          for={professionalLocationId}
                          class="form-label title-label"
                        >
                          {/* Location (in detail) */}
                          住所（詳細に）
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.location != "" ? item.location : "-"}
                        </label>
                      </div>

                      <div className="col-12">
                        <label class="form-label title-label">
                          {/* Period of Employment */}
                          在籍期間
                        </label>
                        <br />
                        <label
                          for={professionalStartYearId}
                          class="form-label title-label"
                        >
                          {/* Start Date */}
                          開始日
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.startDate != "" ? item.startDate : "-"}
                        </label>
                      </div>

                      <div className="col-12">
                        <label
                          for={professionalEndYearId}
                          class="form-label title-label"
                        >
                          {/* End Date */}
                          終了日
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.endDate != "" ? item.endDate : "-"}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
