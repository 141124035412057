import { API, Auth } from "aws-amplify";
import { lazy, Suspense, useEffect } from "react";
import ReactGA from "react-ga4";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Switch, useHistory, useLocation } from "react-router-dom";
import { StickyContainer } from "react-sticky";
import { Spinner } from "reactstrap";
import "src/assets/scss/style.scss";
import Fallback from "src/components/Fallback";
import MetaDecorator from "src/components/MetaDecorator";
import { GetSchoolDataContextProvider } from "src/contexts/GetSchoolDataContext";
import { GlobalStateProvider } from "src/contexts/GlobalState";
import {
  shouldNavigateToSchoolOnboarding,
  shouldNavigateToStudentOnboarding,
} from "src/core/utilities/utils";
import { getSchoolAccountInfo, getUserRole } from "src/graphql/queries";
import GuardedRoute from "src/services/guarded_route";
import { setNewLanguage, toggleTheme } from "src/state/ducks/ui";
import {
  loginUser,
  logoutUser,
  setAuthReady,
  setRoleReady,
} from "src/state/ducks/user";
import PinnedStudentList from "src/views/School/PinnedStudentList";
import ScoutStatus from "src/views/School/Scout/ScoutStatus";
import StudentSearchDetail from "src/views/School/StudentSearch/StudentSearchDetail";
import UserVerification from "src/views/Verification/UserVerification";
// import SchoolPinList from "src/views/School/SchoolPinList";
import RouteGuards from "../../../core/constants/auth/route_guards";
import googleAnalytics from "../../../core/constants/google-analytics";
import ChatBoxDetail from "../ChatBox/ChatBoxDetail";
import UnderMaintainance from "../UnderMaintainance";

// lazy loads to optimize load time for dynamic elements
const NotFound = lazy(() => import("src/views/Common/NotFound"));
const StudentDetail = lazy(() => import("../../School/StudentDetail"));
const SchoolPREdit = lazy(() => import("../../School/SchoolPREdit"));
const SchoolPRPreview = lazy(() => import("../../School/SchoolPREdit/Preview"));
const Signup = lazy(() => import("../SignUp"));
const Signin = lazy(() => import("../Signin"));
const ForgotPassword = lazy(() => import("../ForgotPassword"));
const ChangePassword = lazy(() => import("../ChangePassword"));
const Landing = lazy(() => import("../../Student/Landing"));
const Courses = lazy(() => import("../../Courses"));
const Schools = lazy(() => import("../../School/Schools"));
//const Test = lazy(() => import("../Test"));
const SchoolDetail = lazy(() => import("../../School/SchoolDetail"));
const EventList = lazy(() => import("../Seminar/EventList"));
const BlogList = lazy(() => import("../Blog/BlogList"));
const Faq = lazy(() => import("../../Student/Landing/FAQ"));

const CourseDetail = lazy(() => import("../../CourseDetail"));
const ContactUs = lazy(() => import("../ContactUs"));
const BusinessInquiryForm = lazy(() => import("../../BusinessInquiryForm"));
//const Dashboard = lazy(() => import("../Dashboard"));
const Onboarding = lazy(() => import("../../Student/Onboarding"));
// const Students = lazy(() => import("../Students/Index"));
const StudentsApplication = lazy(() =>
  import("../../Student/StudentsApplication")
);
const StudentProfile = lazy(() => import("../../Student/StudentsProfile"));
const StudentDashboard = lazy(() => import("../../Student/Dashboard"));
// const AgentDashboard = lazy(() => import("../../Agent/AgentDashboard"));
const TermsConditions = lazy(() => import("../TermsConditions"));
//School Pages
const SchoolSignIn = lazy(() => import("../../School/Signin"));
const SchoolSignup = lazy(() => import("../../School/SignUp"));
const SchoolForgotPassword = lazy(() => import("../../School/ForgotPassword"));
const SchoolChangePassword = lazy(() => import("../../School/ChangePassword"));
const SchoolTopPage = lazy(() => import("../../School/SchoolTopPage"));
const ChatBox = lazy(() => import("../ChatBox/App"));
const EventDetail = lazy(() => import("../Seminar/EventDetail"));
const SchoolProfileEdit = lazy(() =>
  import("../../School/SchoolProfileForm/SchoolProfileEdit")
);
const SchoolCourseCreate = lazy(() =>
  import("../../School/Course/SchoolCourseCreate")
);
const SchoolCourseEdit = lazy(() =>
  import("../../School/Course/SchoolCourseEdit")
);
const StudentSearch = lazy(() => import("../../School/StudentSearch"));
const SchoolGetApplicantList = lazy(() =>
  import("../../School/Scout/ScoutApplicationList/ApplicationList")
);
const SchoolDashboard = lazy(() => import("src/views/School/SchoolDashboard"));
const BlogDetails = lazy(() =>
  import("src/views/Student/Landing/Blog/BlogDetails")
);
const SchoolGetApplicationDetail = lazy(() =>
  import("../../School/ApplicationDetail")
);

const SchoolGetApplicantProfile = lazy(() =>
  import("../../School/ApplicationDetail/StudentsApplication")
);
const SchoolGetOwnCourseList = lazy(() =>
  import("../../School/SchoolGetOwnCourseList")
);
const SchoolPinList = lazy(() => import("src/views/School/SchoolPinList"));
const ApplicationDetail = lazy(() => import("../../ApplicationDetail"));
const ApplicationList = lazy(() => import("../../ApplicationList"));
const MultiAccountDashboard = lazy(() =>
  import("src/views/School/MultiAccountDashboard")
);
const Verification = lazy(() => import("src/views/Verification"));

if (process.env.REACT_APP_ALLOW_GA_TRACKING == "true") {
  console.log("GA");
  ReactGA.initialize(googleAnalytics.tracking_id);
  ReactGA.send("pageview");
}

function App() {
  const dispatch = useDispatch();
  const { isAuthReady, isRoleReady } = useSelector((state) => state.user);
  const theme = useSelector((store) => store.theme);
  const history = useHistory();
  const languageCode = useSelector((store) => store.language);

  // set `SHOULD_ENABLE_UNDER_CONSTRUCTION ` to true to enable under construction screen
  // TODO: Disable all the context API calls, when `SHOULD_ENABLE_UNDER_CONSTRUCTION` is set to true
  const SHOULD_ENABLE_UNDER_CONSTRUCTION = false;
  const loginCheck = async () => {
    try {
      const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

      if (currentAuthenticatedUser) {
        // Making another async call to getUserRole after auto-login will take some time and due to which, calling API by pasting URL doesn’t work as authMode will have wrong value.
        const userRole = await API.graphql({
          query: getUserRole,
          variables: { userRole: "default" }, // Here should be allow "nullable" userRole? as we don't have forms to get userRole from Form
          // variables: { userRole: "agent" },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        const { data } = userRole;
        if (data.getUserRole.role == "school") {
          const schoolAccountInfoData = await API.graphql({
            query: getSchoolAccountInfo,
            variables: {},
            authMode: "AMAZON_COGNITO_USER_POOLS",
          });
          const isApproved =
            schoolAccountInfoData.data.getSchoolAccountInfo.isApproved;
          if (isApproved == "1") {
            dispatch(
              loginUser({
                email: currentAuthenticatedUser.attributes.email,
                role: data.getUserRole.role,
              })
            );

            dispatch(setNewLanguage("ja"));
            dispatch(toggleTheme("school"));

            if (await shouldNavigateToSchoolOnboarding()) {
              history.push("/school/profile");
            } else {
              // BE CAREFUL to not push to school_top as default like in sign in,
              // because, if the user has pasted other urls in bar
              // e.g. `../school/applications` but here the user is always navigated to "../school_top"
              // history.push("/school_top");
            }
          } else {
            await Auth.signOut();
            dispatch(logoutUser());
            dispatch(toggleTheme("default"));

            return;
          }
        } else {
          let shouldNavigateToOnboarding = await shouldNavigateToStudentOnboarding();

          dispatch(
            loginUser({
              // TODO: retrive username from AWS Cognito
              // username: ""
              // email: sessionInfo.idToken.payload.email,
              email: currentAuthenticatedUser.attributes.email,
              role: data.getUserRole.role,
            })
          );

          dispatch(toggleTheme("default"));

          /// TODO: Handle for other roles too, currently only student
          if (shouldNavigateToOnboarding) {
            history.replace("/onboarding");
          }
        }
      } else {
        disableLoadingSpinnerOnAppStart();
      }
    } catch (err) {
      /// `currentAuthenticatedUser` throws error
      /// so, always this `catch` block executes for unauth users
      await Auth.signOut();
      dispatch(logoutUser());
      // setIsLogin(true);
      dispatch(toggleTheme("default"));
      // console.log("Error from loginCheck: ", err);
      // localStorage.setItem("redirectRoute", location.pathname);
      // console.log("ooooiiiiiiiiiiiiiiiiiiiiiiiiii", location);
    }
  };

  // Complete app initialization and hide loading spinner
  const disableLoadingSpinnerOnAppStart = () => {
    dispatch(setAuthReady(true));
    dispatch(setRoleReady(true));
  };

  useEffect(() => {
    dispatch(toggleTheme(theme));
    if (!SHOULD_ENABLE_UNDER_CONSTRUCTION) {
      loginCheck();
    } else {
      disableLoadingSpinnerOnAppStart();
    }
  }, [dispatch]);

  const langRegex = () => "/:lang(en|ja|vi|id|np|cn|ko)?";

  return (
    <div className="App">
      <MetaDecorator
        title={
          languageCode == "ja"
            ? "Connect Study Abroad |【日本に留学したい人へ】自分にあった日本語学校・コースを無料で検索して直接応募ができるサービスです。留学後の生活支援や就職もサポートします"
            : "Connect Study ABROAD offers support to study and work in Japan for people from around the world. Schools, scholarships, life in Japan, all information and support are here!"
        }
        description={
          languageCode == "ja"
            ? "Connect Study Abroad |【日本に留学したい人へ】自分にあった日本語学校・コースを無料で検索して直接応募ができるサービスです。留学後の生活支援や就職もサポートします"
            : "Connect Study ABROAD offers support to study and work in Japan for people from around the world. Schools, scholarships, life in Japan, all information and support are here!"
        }
      />
      <Helmet>
        <script>
          {` !(function(f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function() {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "1004504066766759");
      fbq("track", "PageView");
      `}
        </script>
        <noscript>
          {`
        <img
        height="1"
        width="1"
        style="display:none"
        src="https://www.facebook.com/tr?id=1004504066766759&ev=PageView&noscript=1"
    />
    `}
        </noscript>
      </Helmet>
      <GetSchoolDataContextProvider>
        <GlobalStateProvider>
          <StickyContainer>
            {!(isAuthReady || isRoleReady) && (
              <center className="mt-8">
                <Spinner />
              </center>
            )}
            {isAuthReady && isRoleReady && (
              <>
                {/* <Header
              loggedIn={isAuth}
              username={username}
              email={email}
              role={role}
            /> */}
                {/* Fallback animation page for slow loads -suspense and fallback*/}
                <Suspense fallback={<Fallback />}>
                  {/* all switching elements handled here */}
                  <Switch>
                    {/* IMPORTANT: On adding new route, assign values for "guard" and "isUnderConstruction” */}
                    {/* ============================== PUBLIC ROUTES  ============================================= */}

                    <GuardedRoute
                      exact
                      // path="/"
                      path={langRegex() + "/under-construction"}
                      component={UnderMaintainance}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      // path="/"
                      path={
                        langRegex() + "/confirm_client_verification_code/:id"
                      }
                      // guard={RouteGuards.GUEST}
                      component={Verification}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* -------User VErification------------ */}
                    <GuardedRoute
                      path={langRegex() + "/verify_user/:id"}
                      component={UserVerification}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/"
                      path={langRegex() + "/staff_account"}
                      guard={RouteGuards.SCHOOL}
                      component={MultiAccountDashboard}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/"
                      path={langRegex() + "/chat"}
                      component={ChatBox}
                      // guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/"
                      path={langRegex() + "/chat/:id"}
                      component={ChatBoxDetail}
                      // guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/list/courses"
                      path={langRegex() + "/list/courses"}
                      component={Courses}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/list/courses/:id"
                      path={langRegex() + "/list/courses/:id"}
                      component={CourseDetail}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path={langRegex() + "/blog/:id"}
                      component={BlogDetails}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* <GuardedRoute
                  exact
                  path={langRegex() + "/blog/covid"}
                  component={CovidBlog}
                  isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                /> */}
                    <GuardedRoute
                      exact
                      // path="/list/schools"
                      path={langRegex() + "/list/schools"}
                      component={Schools}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/list/schools/:id"
                      path={langRegex() + "/list/schools/:id"}
                      component={SchoolDetail}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* NOTE: Following URL is hard-coded in Backend codebase */}
                    <GuardedRoute
                      exact
                      // path="/contactus"
                      path={langRegex() + "/contactus"}
                      component={ContactUs}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/termsconditions"
                      path={langRegex() + "/termsconditions"}
                      component={TermsConditions}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/"
                      path={langRegex() + "/"}
                      component={Landing}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    // setIsLogin={setIsLogin}
                    // islogin={islogin}
                    />
                    <GuardedRoute
                      path="/school_top"
                      component={SchoolTopPage}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/auth/school/signin"
                      component={SchoolSignIn}
                      guard={RouteGuards.GUEST}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/auth/school/forgot-password"
                      component={SchoolForgotPassword}
                      guard={RouteGuards.GUEST}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/auth/school/signup"
                      component={SchoolSignup}
                      guard={RouteGuards.GUEST}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* ============================== SCHOOL  ============================================= */}
                    <GuardedRoute
                      exact
                      path="/school/courses"
                      component={SchoolGetOwnCourseList}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/school/dashboard"
                      component={SchoolDashboard}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* <GuardedRoute
                            path="/school_top"
                            component={SchoolTopPage}
                          />{" "} */}
                    <GuardedRoute
                      exact
                      path="/school/student/detail"
                      component={StudentDetail}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/school/search/student"
                      component={StudentSearch}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/scout/applications/:id"
                      component={ScoutStatus}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/applicants/detail/:id"
                      component={StudentSearchDetail}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/school/courses/create"
                      component={SchoolCourseCreate}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* <GuardedRoute
                  exact
                  path="/school/courses/create/:id"
                  component={SchoolCourseCreateEdit}
                  guard={RouteGuards.SCHOOL}
                  isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                /> */}
                    <GuardedRoute
                      exact
                      path="/school/courses/edit/:id"
                      component={SchoolCourseEdit}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/school/profile"
                      component={SchoolProfileEdit}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/auth/school/changepassword"
                      component={SchoolChangePassword}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* NOTE: Following URL is hard-coded in Backend codebase */}

                    <GuardedRoute
                      exact
                      path="/school/applications"
                      component={SchoolGetApplicantList}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* NOTE: Following URL is hard-coded in Backend codebase */}
                    <GuardedRoute
                      exact
                      path="/school/applications/:id"
                      component={SchoolGetApplicationDetail}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/school/applicants/:applicationId"
                      component={SchoolGetApplicantProfile}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/school/pr/edit"
                      component={SchoolPREdit}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />

                    <GuardedRoute
                      exact
                      path="/school/pr/preview/:id"
                      component={SchoolPRPreview}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path="/school/pinned-students"
                      component={PinnedStudentList}
                      guard={RouteGuards.SCHOOL}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />

                    {/* NOTE: Following URL is hard-coded in Backend codebase */}
                    <GuardedRoute
                      exact
                      path="/business-inquiry-form"
                      component={BusinessInquiryForm}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* <Route
                  exact
                  path="/list/courses/:areaLP?/:courseTypeLP?/:courseDurationLP?"
                  component={Courses}
                  isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                /> */}
                    {/* ============================== GUEST  ============================================= */}
                    {/* TODO: Common Login Components for all roles, even if different views and routes */}
                    <GuardedRoute
                      exact
                      // path="/signin"
                      path={langRegex() + "/signin"}
                      component={Signin}
                      guard={RouteGuards.GUEST}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/forgot-password"
                      path={langRegex() + "/forgot-password"}
                      component={ForgotPassword}
                      guard={RouteGuards.GUEST}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/signup"
                      path={langRegex() + "/signup"}
                      component={Signup}
                      guard={RouteGuards.GUEST}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />

                    {/* ============================== STUDENT  ============================================= */}
                    <GuardedRoute
                      exact
                      path={langRegex() + "/list/event"}
                      component={EventList}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path={langRegex() + "/list/blog"}
                      component={BlogList}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path={langRegex() + "/faq"}
                      component={Faq}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/list/schools"
                      path={langRegex() + "/list/event/:id"}
                      component={EventDetail}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/changepassword"
                      path={langRegex() + "/changepassword"}
                      component={ChangePassword}
                      guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/course/apply/:courseId"
                      path={langRegex() + "/course/apply/:courseId"}
                      component={StudentsApplication}
                      guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/onboarding"
                      path={langRegex() + "/onboarding"}
                      component={Onboarding}
                      guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/student/profile"
                      path={
                        langRegex() +
                        "/student/profile/:profile_active_tab?/:active_tab?"
                      }
                      component={StudentProfile}
                      guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path={
                        langRegex() +
                        "/student/dashboard"
                      }
                      component={StudentDashboard}
                      guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/applicationdetail"
                      path={langRegex() + "/applicationdetail"}
                      component={ApplicationDetail}
                      guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      // path="/applications"
                      path={langRegex() + "/applications"}
                      component={ApplicationList}
                      guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    <GuardedRoute
                      exact
                      path={langRegex() + "/pinned/list"}
                      component={SchoolPinList}
                      guard={RouteGuards.STUDENT}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                    {/* default PUBLIC component when route not found */}
                    <GuardedRoute
                      component={NotFound}
                      isUnderConstruction={SHOULD_ENABLE_UNDER_CONSTRUCTION}
                    />
                  </Switch>
                </Suspense>
              </>
            )}
            {/* {process.env.NODE_ENV === "development" && <Setting />} */}
          </StickyContainer>
        </GlobalStateProvider>
      </GetSchoolDataContextProvider>{" "}
    </div>
  );
}
export default App;
