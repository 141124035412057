import React from "react";
import { getValueOfMatchedItemInMasterData } from "src/core/utilities/utils";

const Index = ({ educations, masterSchoolTypes }) => {
  return (
    <div>
      <div className="row rowOutline--wrapper" id="educationBackgroundSection">
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span className="section-heading">
                {/* Educational Background */}
                学歴（小・中・高・大・専門・短大）
              </span>
              {/* <span>
              {" "}
              <FontAwesomeIcon icon={faChevronCircleUp} />{" "}
              <FontAwesomeIcon icon={faChevronCircleDown} />{" "}
            </span>{" "} */}
            </div>
          </div>
          <div className="row formInner">
            {Array.isArray(educations) &&
              educations.map((item, index) => {
                let schoolTypeId = `schoolType-${index}`;
                let schoolNameId = `schoolName-${index}`;
                let schoolLocationId = `schoolLocation-${index}`;
                let schoolStartYearId = `schoolStartYear-${index}`;
                let schoolStartMonthId = `schoolStartMonth-${index}`;
                let schoolEndYearId = `schoolEndYear-${index}`;
                let schoolEndMonthId = `schoolEndMonth-${index}`;
                let schoolYearsId = `schoolYears-${index}`;
                return (
                  <div
                    key={index}
                    className="col-12"
                    style={{
                      backgroundColor: "#f5f5f5",
                      marginTop: "15px",
                      padding: "10px",
                      marginLeft: "15px",
                    }}
                  >
                    <div className="row">
                      <div class="col-12">
                        <label
                          for={schoolTypeId}
                          class="form-label title-label"
                        >
                          {/* Type of School */}
                          学校種別
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {getValueOfMatchedItemInMasterData(
                            item.type,
                            masterSchoolTypes
                          )}
                          {/* {item.type != "" ? item.type : "-"} */}
                        </label>
                      </div>
                      <div class="col-12">
                        <label
                          for={schoolNameId}
                          class="form-label title-label"
                        >
                          {/* Name of School (official name) */}
                          学校名（正式名称）
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.nameOfInstitution != ""
                            ? item.nameOfInstitution
                            : "-"}
                        </label>
                      </div>
                      <div class="col-12">
                        <label
                          for={schoolLocationId}
                          class="form-label title-label"
                        >
                          {/* Location (in detail) */}
                          住所（詳細に）
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.location != "" ? item.location : "-"}
                        </label>
                      </div>

                      <div className="col-12">
                        <label class="form-label title-label">
                          {/* Period of Attendance */}
                          在籍期間
                        </label>
                        <br />
                        <label
                          for={schoolStartYearId}
                          class="form-label title-label"
                        >
                          {/* Start Date */}
                          開始日
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.startDate != "" ? item.startDate : "-"}
                        </label>
                      </div>

                      <div className="col-12">
                        <label
                          for={schoolEndYearId}
                          class="form-label title-label"
                        >
                          {/* End Date */}
                          終了日
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.endDate != "" ? item.endDate : "-"}
                        </label>
                      </div>

                      <div class="col-12">
                        <label
                          for={schoolYearsId}
                          class="form-label title-label"
                        >
                          {/* Years */}
                          在籍年数
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.years != "" ? item.years : "-"}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Index;
