import React from "react";
import { getValueOfMatchedItemInMasterData } from "src/core/utilities/utils";

const RelativeInJapan = ({ friendsFamilyJapanese, masterNationality }) => {
  return (
    <div>
      <div className=" row rowOutline--wrapper" id="friendsFamilySection">
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span className="section-heading">
                {/* Family & Friend in Japan */}
                在日親族や友人
              </span>
              {/* <span>
                {" "}
                <FontAwesomeIcon icon={faChevronCircleUp} />{" "}
                <FontAwesomeIcon icon={faChevronCircleDown} />{" "}
              </span>{" "} */}
            </div>
          </div>
          <div className="col-12">
            <div className="row formInner">
              {/* family stay in japan */}
              {/* <div class="col-12 col-sm-6" style={{ marginBottom: "12px" }}>
                <label for="inputLastName" class="form-label">
                  Do you have Family & Friend in Japan?
                </label>
                <br />
                <div onClick={imgHandler} class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="option1"
                  />
                  <label
                    style={{ color: "black" }}
                    class="form-check-label"
                    for="inlineRadio1"
                  >
                    Yes
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <input
                    class="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="option2"
                  />
                  <label
                    style={{ color: "black" }}
                    class="form-check-label"
                    for="inlineRadio2"
                  >
                    No
                  </label>
                </div>
              </div> */}
              {/* ------------------------------Model----------------- */}
              {Array.isArray(friendsFamilyJapanese) &&
                friendsFamilyJapanese.map((item, index) => {
                  let nameId = `relative-name-${index}`;
                  let placeOfEmploymentOrSchoolId = `relative-placeOfEmploymentOrSchool-${index}`;
                  let relationshipId = `relative-relationship-${index}`;
                  let nationalityId = `relative-nationality-${index}`;
                  let birthYearId = `relative-birthYear-${index}`;
                  let birthMonthId = `relative-birthMonth-${index}`;
                  let birthDayId = `relative-birthDay-${index}`;
                  let intendToResideId = `relative-intendToReside-${index}`;
                  let idNumberId = `relative-idNumber-${index}`;

                  return (
                    <div
                      className="col-12"
                      style={{
                        backgroundColor: "#f5f5f5",
                        marginTop: "15px",
                        padding: "10px",
                      }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <label
                            for={relationshipId}
                            class="form-label title-label"
                          >
                            {/* Relationship */}
                            関係
                          </label>
                          <br />
                          <label class="form-label info-label">
                            {item.relationship != "" ? item.relationship : "-"}
                          </label>
                        </div>
                        {/* Name */}
                        <div className="col-12">
                          <label
                            for="inputStatus"
                            class="form-label title-label"
                          >
                            {/* Name */}
                            氏名
                          </label>
                          <br />
                          <label class="form-label info-label">
                            {item.name != "" ? item.name : "-"}
                          </label>
                        </div>
                        {/* date of birth */}
                        <div className="col-12">
                          <label
                            for={birthYearId}
                            class="form-label title-label"
                          >
                            {/* Date of Birth */}
                            生年月日
                          </label>
                          <br />
                          <label class="form-label info-label">
                            {item.dateOfBirth != "" ? item.dateOfBirth : "-"}
                          </label>
                        </div>
                        {/* Nationality */}
                        <div class="col-12">
                          <label
                            for={nationalityId}
                            class="form-label title-label"
                          >
                            {/* Nationality */}
                            国籍
                          </label>
                          <br />
                          <label class="form-label info-label">
                            {getValueOfMatchedItemInMasterData(
                              item.nationality,
                              masterNationality
                            )}
                            {/* {item.nationality != "" ? item.nationality : "-"} */}
                          </label>
                        </div>

                        {/* Region */}
                        <div className="col-12">
                          <label
                            for={intendToResideId}
                            class="form-label title-label"
                          >
                            {/* Intend to reside with applicant or not */}
                            同居予定
                          </label>
                          <br />
                          <label class="form-label info-label">
                            {item.intendToReside != ""
                              ? item.intendToReside
                              : "-"}
                          </label>
                        </div>

                        {/* Place of Employment/School */}
                        <div className="col-12">
                          <label
                            for={placeOfEmploymentOrSchoolId}
                            class="form-label title-label"
                          >
                            {/* Place of Employment or School */}
                            学校または勤務先
                          </label>
                          <br />
                          <label class="form-label info-label">
                            {item.placeOfEmploymentOrSchool != ""
                              ? item.placeOfEmploymentOrSchool
                              : "-"}
                          </label>
                        </div>
                        <div className="col-12">
                          <label
                            for={idNumberId}
                            class="form-label title-label"
                          >
                            {/* Residence card number */}
                            在留カード番号
                          </label>
                          <br />
                          <label class="form-label info-label">
                            {item.idNumber != "" ? item.idNumber : "-"}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RelativeInJapan;
