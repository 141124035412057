import { createSlice } from "@reduxjs/toolkit";

const defaultProfile = {};

const studentProfileSlice = createSlice({
  name: "studentProfile",
  initialState: defaultProfile,
  reducers: {
    ProfileUser: (state, { payload }) => {
      return payload;
    },
  },
});

export const { ProfileUser } = studentProfileSlice.actions;
export default {
  studentProfile: studentProfileSlice.reducer,
};
