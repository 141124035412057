import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { API } from "aws-amplify";
import { getAuthMode } from "src/core/utilities/authMode";
import { verifyUserAccount } from "src/graphql/mutations";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "reactstrap";
import SnackbarIn from "src/components/Snackbar";

const UserVerification = () => {
  const location = useLocation();
  const roleValue = new URLSearchParams(location.search).get("role");
  const [error, setError] = useState("");
  const [openSuccessBar, setOpenSuccessBar] = useState(false);
  const [openErrorBar, setOpenErrorBar] = useState(false);
  const { id } = useParams();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  console.log("id from url", id, roleValue);

  const shouldVerifyUserAccount = async () => {
    setIsLoading(true);
    try {
      const verifyUserAccountData = await API.graphql({
        query: verifyUserAccount,
        variables: {
          subId: id,
        },
        authMode: await getAuthMode(),
      });
      console.log(
        "verifyUserAccountData",
        verifyUserAccountData.data.verifyUserAccount
      );
      setOpenSuccessBar(true);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error.errors[0].message);
      setIsLoading(false);
      setOpenErrorBar(true);
      setError(error.errors[0].message);
    }
  };
  useEffect(async () => {
    await shouldVerifyUserAccount();
    setTimeout(() => {
      history.push("/signin");
    }, 1500);
  }, []);

  return (
    <div className="container">
      {isLoading && (
        <center>
          <Spinner />
        </center>
      )}
      {/* {error && (
        <div className="pb-3">
          <span className="text-danger text-left">{error}</span>{" "}
        </div>
      )} */}

      <SnackbarIn
        openSuccessBar={openSuccessBar}
        setOpenSuccessBar={setOpenSuccessBar}
        openErrorBar={openErrorBar}
        setOpenErrorBar={setOpenErrorBar}
        error={error}
        snackbarSuccessMsg=" Successfully Verified!"
        snackbarErrorMsg="Something went wrong"
      />
    </div>
  );
};

export default UserVerification;
