import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { SendBirdProvider as SBProvider } from "sendbird-uikit";
import "sendbird-uikit/dist/index.css";
import useWindowSize from "src/core/utilities/useWindowSize";
import withHeaderFooter from "src/services/with_header_footer";
import CustomizedApp from "../CustomizedApp";
import MobileCustomizedApp from "../Mobile/CustomizedApp";

function ChatBoxDetail() {
  const [appState, setAppState] = useState(null);
  const [schoolAppState, setSchoolAppState] = useState(null);
  const { isAuth, role } = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [schoolLoading, setSchoolLoading] = useState(false);
  const { t, i18n } = useTranslation();
  const [error, setError] = useState(null);
  const history = useHistory();
  const { width } = useWindowSize();
  const [showModal, setShow] = useState(false);
  const [showSchoolModal, setSchoolShow] = useState(false);
  const location = useLocation();
  const studentProfileDataValue = localStorage.getItem("getStudentProfile1");
  const schoolProfileDataGUID = localStorage.getItem("getSchoolProfileGUID");
  const schoolProfileDataID = localStorage.getItem("getSchoolProfileID");
  const { id } = useParams();

  //get student access token
  const fetchToken = async () => {
    try {
      const getToken = await axios
        .get(
          `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/users/${studentProfileDataValue}`,
          {
            headers: {
              "Api-Token": process.env.REACT_APP_API_TOKEN,
            },
          }
        )
        .then((repos) => {
          const allRepos = repos.data;
          setAppState(allRepos);
          setLoading(true);
        });
    } catch (err) {
      setShow(true);
      setError(t("something_went_wrong"));
      console.log("Student Chat Error", err);
    }
  };

  //get school access token
  const schoolFetchToken = async () => {
    setSchoolLoading(true);
    try {
      const getToken = await axios
        .get(
          `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/users/${schoolProfileDataGUID}-${schoolProfileDataID}`,
          {
            headers: {
              "Api-Token": process.env.REACT_APP_API_TOKEN,
            },
          }
        )
        .then((repos) => {
          const allRepos1 = repos.data;
          console.log("All repos", allRepos1);
          setSchoolAppState(allRepos1);
          setSchoolLoading(false);
        });
    } catch (err) {
      setError(t("something_went_wrong"));
      setSchoolShow(true);
      console.log(err);
    }
  };
  useEffect(async () => {
    if (role == "school") {
      schoolFetchToken();
    }
    if (role == "student") {
      fetchToken();
    }
  }, []);
  const navToCourses = () => {
    history.push({
      pathname: "/list/courses",
    });
  };
  const navToSchoolProfile = () => {
    history.push({
      pathname: "/school_top",
    });
  };

  const myColorSet = {
    "--sendbird-light-primary-500": "#00487c",
    // '--sendbird-light-primary-400': '#4bb3fd',
    // '--sendbird-light-primary-300': '#3e6680',
    // '--sendbird-light-primary-200': '#0496ff',
    // '--sendbird-light-primary-100': '#027bce',
  };

  return (
    <div className="container app-wrapper">
      {/* {location.state != null &&
        location.state === "refresh" &&
        window.location.reload()} */}

      {isAuth ? (
        role == "school" ? (
          schoolAppState ? (
            <SBProvider
              appId={process.env.REACT_APP_APP_ID}
              userId={schoolProfileDataGUID + "-" + schoolProfileDataID}
              accessToken={
                schoolAppState.access_token ? schoolAppState.access_token : ""
              }
              colorSet={myColorSet}
            >
              {width > 768 ? (
                <CustomizedApp id={id} />
              ) : (
                <MobileCustomizedApp />
              )}
            </SBProvider>
          ) : showSchoolModal ? (
            <Modal
              style={{ margin: "200px auto" }}
              show={showSchoolModal}
              onHide={navToSchoolProfile}
            >
              <Modal.Header closeButton>
                <Modal.Title>
                  Student hasn't registered any courses!!!
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <b> Student should apply to courses to start chat.</b>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={navToSchoolProfile}>
                  Continue
                </Button>
                {/* <Button variant="primary" onClick={handleClose}>
            No
          </Button> */}
              </Modal.Footer>
            </Modal>
          ) : (
            <center style={{ height: "100vh" }}>
              {" "}
              <Spinner />
            </center>
          )
        ) : role == "student" ? (
          loading ? (
            <SBProvider
              appId={process.env.REACT_APP_APP_ID}
              userId={studentProfileDataValue}
              accessToken={appState.access_token ? appState.access_token : ""}
              colorSet={myColorSet}
            >
              {width > 768 ? <CustomizedApp /> : <MobileCustomizedApp />}
            </SBProvider>
          ) : showModal ? (
            <Modal
              style={{ margin: "200px auto" }}
              show={showModal}
              onHide={navToCourses}
            >
              <Modal.Header closeButton>
                <Modal.Title>Course Not Registered!!!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                {" "}
                <b> Please apply to the courses to start chat with School.</b>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={navToCourses}>
                  Continue
                </Button>
              </Modal.Footer>
            </Modal>
          ) : (
            <center style={{ height: "100vh" }}>
              {" "}
              <Spinner />
            </center>
          )
        ) : (
          ""
        )
      ) : (
        <Redirect to="/" />
      )}
    </div>
  );
}
export default withHeaderFooter(ChatBoxDetail);
