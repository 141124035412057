import { API } from "aws-amplify";
import { createContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getAuthMode } from "src/core/utilities/authMode";
import { schoolGetOwnSchoolList } from "src/graphql/queries";

export const GetSchoolDataContext = createContext();

export const GetSchoolDataContextProvider = ({children}) => {
  const languageCode = useSelector((store) => store.language);
  const [schoolList, setSchoolList] = useState([]);
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(async () => {
    try {
      const schoolGetSchoolListData = await API.graphql({
        query: schoolGetOwnSchoolList,
        variables: {
          // filters: { language: languageCode },
          // pagination: { countPerPage: 5, page: 1 },
        },
        authMode: await getAuthMode(),
      });

      console.log("schoolGetSchoolListData: ", schoolGetSchoolListData);

      setSchoolList(schoolGetSchoolListData.data.schoolGetOwnSchoolList.list);
      setIsLoading(false);
    } catch (err) {
      setError(t("something_went_wrong"));
      console.log(err);
      setIsLoading(false);
    }
  }, [languageCode]);

  let schoolDataValue = { schoolList };

  return (
    <GetSchoolDataContext.Provider value={schoolDataValue}>
      {children}
    </GetSchoolDataContext.Provider>
  );
};
