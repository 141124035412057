// eslint-disable-next-line import/no-anonymous-default-export
export default [
  {
    id: "0",
    value: "pre_apply_received",
    format_en_value: "Pre-apply completed",
    jp_value: "Pre-apply完了",
    color:"warning"
  },
  {
    id: "1",
    value: "interview_date_fixed",
    format_en_value: "Set interview",
    jp_value: "面接日程調整完了",
    color:"warning"
  },
  {
    id: "2",
    value: "interview_pass",
    format_en_value: "Passed interview",
    jp_value: "面接合格 ",
    color:"success"
  },
  {
    id: "3",
    value: "interview_fail",
    format_en_value: "Failed interview",
    jp_value: "面接不合格",
    color:"danger"
  },
  {
    id: "4",
    value: "application_done",
    format_en_value: "Passed document screening  by school",
    jp_value: "学校による書類審査合格",
    color:"success"
  },
  {
    id: "5",
    value: "document_screening_complete",
    format_en_value: "Failed document screening by school",
    jp_value: "学校による書類審査不合格",
    color:"danger"
  },
  {
    id: "6",
    value: "coe_granted",
    format_en_value: "COE granted",
    jp_value: "COE交付",
    color:"success"
  },
  {
    id: "7",
    value: "coe_not_granted",
    format_en_value: "COE not granted",
    jp_value: "COE不交付",
    color:"danger"
  },
  {
    id: "8",
    value: "visa_granted",
    format_en_value: "Visa approved",
    jp_value: "Visa取得",
    color:"success"
  },
  {
    id: "9",
    value: "visa_not_granted",
    format_en_value: "Visa not approved",
    jp_value: "Visa不許可",
    color:"danger"
  },
  {
    id: "10",
    value: "joined_school",
    format_en_value: "Enter School",
    jp_value: " 入学",
    color:"success"
  },
];
