import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import profileImg from "../../../assets/images/students/Document.png";

const Document = () => {
  return (
    <div className="container">
      <div className="row std-align-content" style={{paddingTop: "50px"}}>
        <div className="col-12 headerclr">
          <div className="d-flex justify-content-between">
            <span>My Documents</span>{" "}
            <span>
              {" "}
              <FontAwesomeIcon icon={faUpload} /> Upload Document
            </span>{" "}
          </div>
        </div>
        {/* ----------------------------my documenta------------- */}
        <div
          className="col-12 student-document-align"
          style={{ border: "3px solid #f5f5f5", borderTopStyle: "none"}}
        >
            {/* 1st row */}
            <div className="row" style={{justifyContent:'space-around', paddingTop:'20px'}}>
                <div className="col-3" >
                    <div className="row" style={{border:'2px solid #f5f5f5', padding:'5px', borderRadius:'10px'}}>
                        <div className="col-12 col-md-4"> <img src={profileImg} style= {{ width:'100%'}} /> </div>
                        <div className="col-12 col-md-8">Application for Admission</div>
                    </div>
                </div>
                <div className="col-3">
                <div className="row" style={{border:'2px solid #f5f5f5', padding:'5px', borderRadius:'10px'}}>
                        <div className="col-12 col-md-4"> <img src={profileImg} style= {{ width:'100%'}} /> </div>
                        <div className="col-12 col-md-4">Application for Admission</div>
                    </div>
                </div>
                <div className="col-3">
                <div className="row" style={{border:'2px solid #f5f5f5', padding:'5px', borderRadius:'10px'}}>
                        <div className="col-12 col-md-4"> <img src={profileImg} style= {{ width:'100%'}} /> </div>
                        <div className="col-12 col-md-4">Application for Admission</div>
                    </div>
                </div>
            </div>
            {/* 2nd row */}
            <div className="row" style={{justifyContent:'space-around', paddingTop:'20px'}}>
                <div className="col-3">
                <div className="row" style={{border:'2px solid #f5f5f5', padding:'5px', borderRadius:'10px'}}>
                        <div className="col-12 col-md-4"> <img src={profileImg} style= {{ width:'100%'}} /> </div>
                        <div className="col-12 col-md-4">Application for Admission</div>
                    </div>
                </div>
                <div className="col-3">
                <div className="row" style={{border:'2px solid #f5f5f5', padding:'5px', borderRadius:'10px'}}>
                        <div className="col-12 col-md-4"> <img src={profileImg} style= {{ width:'100%'}} /> </div>
                        <div className="col-12 col-md-4">Application for Admission</div>
                    </div>
                </div>
                <div className="col-3">
                <div className="row" style={{border:'2px solid #f5f5f5', padding:'5px', borderRadius:'10px'}}>
                        <div className="col-12 col-md-4"> <img src={profileImg} style= {{ width:'100%'}} /> </div>
                        <div className="col-12 col-md-4">Application for Admission</div>
                    </div>
                </div>
            </div>
            <div className="col-12" style={{textAlign:'center', padding:'30px'}}> <button style={{width:'20%'}} className="btn-primary">Show all <br/><FontAwesomeIcon icon={faCaretDown}/> </button> </div>
        </div>
      </div>
    </div>
  );
};

export default Document;
