import { ChannelList, Channel } from "sendbird-uikit"
import "sendbird-uikit/dist/index.css"
import { useState } from "react"
import ChatHeader from "../ChatHeader"
import ChannelPreview from "../ChannelPreview"
import { useSelector } from "react-redux"

export default function App() {
  const [channel, setChannel] = useState(null)
  const { isAuth, role } = useSelector((state) => state.user);


  const onChannelSelect = (_channel) => {
    setChannel(_channel)
    window.history.pushState({}, _channel.name, "/" + _channel.url)
  }

  const onBack = () => {
    setChannel(null)
    window.history.pushState({}, document.title, "/chat")
  }

  return (
    <>
      {channel ? (
        <Channel
          channelUrl={channel.url}
          renderChatHeader={({ channel, user }) => (
            <ChatHeader role={role} channel={channel} user={user} onBack={onBack} />
          )}
        />
      ) : (
        <ChannelList
          renderChannelPreview={({ channel }) => (
            <ChannelPreview
              channel={channel}
              onChannelSelect={onChannelSelect}
            />
          )}
        />
      )}
    </>
  )
}
