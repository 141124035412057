// eslint-disable-next-line import/no-anonymous-default-export
export default {
  language_code: "ko",
  language_title: "한국어",
  welcome_to_connect: "Connect에 오신 것을 환영합니다.",
  Login: "로그인",
  school_login_title: "학교 로그인",
  login_btn: "로그인",
  sign_up: "등록",
  enter_your_email: "이메일 주소를 입력해 주세요.",
  enter_your_password: "비밀번호를 입력해 주세요.",
  school_signup_title: "School Sign Up",
  signup_btn: "등록",
  logout: "로그아웃",
  change_password: "비밀번호 재설정하기",
  current_password: "현재 비밀번호",
  new_password: "새로운 비밀번호",
  confirm_password: "비밀번호 확인하기",
  incorrect_password: "Incorrect Password",
  forgot_password: "비밀번호를 잊으셨습니까?",
  valid_password:
    "비밀번호는 대문자 1자이상, 소문자 1자이상, 숫자 1자이상, 8자이상이어야 됩니다.",
  login_for_school_only_msg:
    "이 로그인 폼은 학교전용입니다. 불편을 드려 죄송합니다.",
  login_for_student_only_msg:
    "이 로그인 폼은 학생전용입니다. 불편을 드려 죄송합니다.",
  home: "탭",
  School: "School",
  Dashboard: "대시보드",
  blog: "블로그",
  Contact: "문의",
  schools: "학교정보",
  courses: "코스",
  applications: "지원",
  about_us: "저희에 대하여",
  contact_us: "contact us",
  profile: "프로필",
  for_inquiries: "문의는 아래 링크를 클릭해주세요.",
  inquiry_form: "학생의 문의 양식",
  for_business_inquiries: "비즈니스에 관한 문의",
  business_inquiry_form: "비즈니스 문의 양식",
  submit: "제출",
  language: "언어",
  view_all: "전체 보기",
  our_services: "서비스 내용",
  course_search: "course search",
  school_search: "school search",
  our_other_services: "그 외 서비스",
  privacy_policy: "개인정보 보호원칙",
  terms_of_service: "이용약관",
  page_not_found: "요청하신 페이지가 없습니다. 불편을 드려 죄송합니다.",
  student_tag_line_heading: "일본 취업×일본 유학!",
  student_tag_line_subheading: "자신에게 맞는 유학 프로그램을 선택해 주세요.",
  find_best_matched_course: "나에게 맞는 일본어 코스 찾기",
  recommended_schools_msg: "일본어학교 추천",
  popular_schools: "추천 일본어학교 ",
  statistical_facts: "저희의 서비스에 대하여",
  courses_listed: "등록 코스수",
  schools_listed: "등록 학교수",
  no_results_found:
    "선택하신 조건에 맞는 정보가 없습니다. 불편을 드려 죄송합니다.",
  go_to_home: "홈으로 돌아가기",
  office_address: "회사 위치",
  read_more: "더 보기",
  phone_number: "연락처",
  something_went_wrong: "오류가 발생하였습니다.",
  area: {
    title: "지역",
    value: {
      hokkaido: "홋카이도",
      tohoku: "토호쿠",
      kanto: "칸토",
      chubu: "주부",
      kansai: "칸사이",
      chugoku: "주고쿠",
      shikoku: "시코쿠",
      kyushu: "규슈",
    },
  },
  course_type: {
    title: "코스 종류",
    value: {
      general_japanese: "일반 일본어",
      university_preparation: "대학 진학 준비",
      business_employment: "취업",
      others: "기타",
    },
  },
  course_duration: {
    title: "코스 기간",
    value: {
      short: "단기",
      long: "장기",
    },
  },
  enrollment_month: {
    title: "입학 시기",
    value: {
      april: "4월",
      july: "7월",
      october: "10월",
      january: "1월",
    },
  },
  japanese_level: {
    title: "일본어 실력",
    value: {
      beginner: "초급",
      intermediate: "중급",
      advance: "고급",
    },
  },
  course_info: {
    value: {
      course_name: "코스명",
      course_outline: "코스 개요",
      course_description: "코스 안내",
      applicable_student: "대상 학생",
      visa_type: "비자의 종류",
      beginning_period: "개강 시기",
      learning_content: "학습 내용",
      application_period: "모집 기간",
      curriculum: "교육과정",
      course_goal: "코스 목표",
      curriculum_description: "교육과정 상세",
      tuition: "학비(JPY)",
      screening_fee: "전형료",
      enrollment_fee: "등록금",
      class_fee: "수강료",
      facility_fee: "시설비",
      total: "총액",
      tuition_fee_notes: "수강료에 관한 주의사항",
    },
  },
  school_info: {
    value: {
      school_name: "학교명",
      school_logo: "학교 로고",
      available_course_type: "코스 종류",
      available_course_length: "코스 기간",
      school_introduction: "학교 소개",
      basic_information: "기본정보",
      location: "학교 위치",
      nearest_station: "오시는 길(전철)",
      phone_number: "연락처",
      fax_number: "팩스번호",
      school_website: "홈페이지",
      email: "이메일 주소",
      founder: "설립자",
      director: "교장",
      authorized_date: "학교 설립허가 연월일",
      establishmentDate: "설립 연월일",
      faculty_staff: "강사/스태프",
      num_of_faculty_full_time: "강사수(전임)",
      assisstant_for_housing: "방 구하기를 서포트하는 스태프",
      foreign_speaking_staff: "외국어 대응이 가능한 스태프",
      students: "학생",
      quota_of_school: "정원",
      total_num_students: "재적 학생수",
      major_countries_students: "주요 국적",
      grads_this_year: "2020년도3월  졸업 예정자수",
      grads_last_year: "작년 졸업자수",
      after_graduation: "주요 진학실적",
      jlpt_passer: "2019년도 12월 일본어능력시험 수험자수(합격자수)",
      admission_selection: "입학 전형",
      selection_method: "전형 방식",
      application_fee: "전형료",
      deadline: "마감 날짜",
      mailing_address: "우편물 주소",
      pr_school: "학교 홍보영상",
      message_from_school: "학교 메시지",
      images: "사진",
      videos: "동영상",
      school_year_calendar: "연간 활동 내용/이벤트등",
      scholarship_if_available: "장학금",
      facility: "시설/설비",
    },
  },
  confirm_account_modal_header: "계정인증을 해주세요.",
  confirm_account_modal_body:
    "로그인할 수 없습니다. 이메일을 통해 인증을 진행해 주세요.",
  confirm: "확정",
  cancel: "취소",
  email: "Email Address",
  password: "비밀번호",
  do_not_have_account: "Don’t have an account?",
  validation_empty_email: "이메일 주소는 필수로 입력해야 합니다.",
  validation_empty_password: "비밀번호는 필수로 입력해야 합니다.",
  user_agreement_prefix: "저는 Connect Study Abroad의 이용약관과 개인정보  ",
  user_agreement_conjunction: " 보호지침에 동의합니다. ",
  user_agreement_postfix: "",
  user_agreement_validation:
    "이용약관과 개인정보 보호 지침을 읽고 수락해 주세요.",
  already_have_account: "이미 계정이 있으신가요?",
  verification_code_sent:
    "이메일로 코드를 보냈습니다. 이 화면을 통해 계정 인증을 완료해 주세요.",
  confirm_account: "계정 확정",
  did_not_receive_code: "코드를 받지 못하셨습니까?",
  resend: "재송신",
  verification_title: "인증",
  passwords_do_not_match: "비밀번호가 일치하지 않습니다.",
  forgot_password_description:
    "아래에 이메일 주소를 입력해 주세요. 비밀번호를 재설정하기 위한 코드를 보내드립니다.",
  reset_password: "비밀번호를 재설정하기",
  password_reset_code_sent:
    "비밀번호를 재설정하기 위한 코드를 이메일로 보내드렸습니다. 비밀번호를 재설정하기 위한 코드를 아래에 입력해주세요.",
  code: "코드",
  onboarding: {
    first_name: "성",
    last_name: "이름",
    date_of_birth: "생일",
    year: "년",
    month: "월",
    day: "일",
    nationality: "국적",
    start_study_in_japan: "일본으로 유학을 언제 가실 예정이신가요?",
    currently_reside_in_japan: "현재 일본에서 거주하고 계시나요?",
    yes: "네",
    no: "아니요",
  },
  student_profile: {
    user_picture: "증명사진",
    first_name: "성",
    last_name: "이름",
    date_of_birth: "생일",
    sex: "성별",
    nationality: "국적",
    place_of_birth: "출생지",
    current_address: "현재 거주중인 주소",
    current_occupation: "현 직업",
    start_study_in_japan: "일본으로 유학을 언제 가실 예정이신가요?",
    currently_reside_in_japan: "현재 일본에서 거주하고 계시나요?",
  },
  profile_tabs: {
    profile: "프로필",
    application_for_admission: "지원서",
    documents: "자료",
  },
  admission_application_forms: {
    personal_info: "개인정보",
    passport_info_stay_history: "여권발급 현황",
    family_friend_japan: "일본에서 거주중인 친척/친구",
    marital_status: "배우자의 유무",
    education_background: "학력(초,중,고,대학, 전문대학)",
    professional_background: "경력사항",
    japanese_study_experience: "일본어 학습경력e",
    japanese_proficiency: "일본어 능력에 대하여(증명서 등)",
    plan_after_graduation: "졸업후 진로 계획",
    about_sponsor: "유학경비 부담자에 대하여",
  },
  admission_application_page1: {
    passport_status: "여권 발급 상황",
    passport_number: "여권번호",
    passport_expiration_date: "여권 유효기간",
    passport_issue_date: "여권 발급 연월일",
    previous_coe_application: "재류자격증명서 신청 이력",
    previous_stay_in_japan: "출입국 이력의 유무",
    previous_stay_in_japan_detail: "출입국 이력(상세)",
    date_of_entry: "입국일자",
    date_of_departure: "출국일자",
    status_of_residence: "재류자격",
    purpose_of_entry: "입국목적",
    family_or_friend_question: "일본에서 거주중인 친척/친구의 유무",
    family_or_friend_title: "일본에서 거주중인 친척/친구",
    relationship: "관계",
    name: "이름",
    intention_to_reside: "동거 예정",
    place_of_employment: "학교 또는 직장명",
    residence_card_number: "Residence card number",
    marital_status: "배우자의 유무",
    partner_name: "Partner's name",
  },
  admission_application_page2: {
    educational_background: "학력(초,중,고,대학,전문대)",
    type_of_school: "학교의 종류",
    name_of_school: "학교명(정식명칭)",
    location_in_detail: "상세 주소",
    period_of_attendance: "재적기간",
    start_date: "시작일자",
    expected_end_date: "Expected End Date",
    end_date: "종료일자",
    years: "년",
    professional_career_question: "경력의 유무",
    professional_career_title: "경력사항",
    company_name: "Company Name",
    type_of_job: "Type of Job",
    period_of_employment: "Period of Employment",
    japanese_study_experience_question: "일본어 학습 경험의 유무?",
    japanese_study_experience_title: "일본어 학습 기간",
    hours: "학습시간수",
    japanese_proficiency_certification_question: "일본어 자격증 소지의 유무?",
    japanese_proficiency_certification_title: "일본어 능력에 대하여(증명서 등)",
    name_of_test: "시험명",
    experience_of_test: "수험 경력",
    year_of_test: "수험 연도",
    level: "등급,레벨",
    score: "점수",
    plan_after_graduation: "졸업후 진로 계획",
    currently_enrolled: "재적중",
    do_you_have_work_experience: "근무 경력이 있습니까?-네/아니요?",
    expected_graduation_date: "졸업예정시기",
    enrollemnt_period: "재적기간",
    currently_working_company: "현재 이 직장에서 근무중",
    studied_japanese_institute:
      "일본어를 학교나 기관에서 공부하신 경험이 있습니까??",
    not_yet_completed: "재적중",
  },
  admission_application_page3: {
    sponsor_name: "유학경비 부담자의 성명",
    sponsor_address: "유학경비 부담자의 주소",
    sponsor_relationship: "유학경비 부담자와의 관계",
    sponsor_annual_income: "S유학경비 부담자의 수입",
    sponsor_occupation: "유학경비 부담자의 직업",
    sponsor_employer: "유학경비 부담자의 직장명",
  },
  student_document: {
    application_for_admission: "입학 지원서(학교 지정 양식)",
    statement_of_reason_title: "취학 이유서",
    statement_of_reason_about: "(최종학교 졸업후 5년이상 경과한 경우)",
    statement_of_advancement_title: "취학 이유서",
    statement_of_advancement_about: " (최종학교 졸업후 5년이상 경과한 경우)",
    identification_photograph: "증명사진(5장) 4cm×3cm",
    passport_or_identification: "여권 또는 신분증의 복사 ",
    diploma_of_last_school: "최종 학력의 졸업증명서",
    transcript_of_last_school: "최종학력의 성적증명서",
    japanese_language_proficiency: "일본어능력 입증 증명서",
    employment_certificate_title: "재직증명서 ",
    employment_certificate_about: " (경력이 있는 경우)",
    tuberculosis_screening_title: "결핵 미발병 증명서",
    tuberculosis_screening_about:
      " (필리핀,베트남,중국,인도네시아,네팔,미얀마 국적 신청자는 필수제출)",
    financial_support: "유학경비 부담계획서(학교 지정 양식)",
    supporter_family_members: "유학경비 부담자의 가족이름(학교 지정 양식)",
    supporter_family_structure: "유학경비 부담자의 가족구성을 증명하는 자료",
    supporter_relationship: "유학경비 부담자와 신청자의 관계를 증명하는 자료",
    certificate_of_deposit: "예금잔액증명서(원본)",
    supporter_employment: "유학경비 부담자의 직업을 증명하는 자료",
    three_years_asset_formation: "과거 3년간의 자산형성 과정을 증명하는 자료",
    supporter_three_years_income:
      "과거 3년간의 유학경비 부담자의 수입을 증명하는 자료",
  },
  save_profile_btn: "저장",
  date_of_birth: "생일",
  nationality: "국적",
  additional_fee_label: "입학후에 추가 비용이 발생합니까?",
  additional_fee_yes: "네",
  additional_fee_no: "아니요",
  early_application_deadline: "지원자수에 따라 일찍 마감이 끝날 수도 있습니다.",
  notes_for_the_tuition_fee: "수강료에 관한 주의사항",
  course_list: "코스 목록",
  course_detail: "코스의 상세 정보",
  school_list: "학교 리스트",
  school_detail: "학교 상세정보",
  go_to_list: "리스트로 돌아가기",
  apply: "지원하기",
  applied_date: "지원일",
  application_status: "지원 현황",
  student_top_page: {
    why_japans: {
      why_japan: "일본 유학의 매력",
      cost_of_living: "생활비와 학비가 미국,영국,호주보다 처럼하다!",
      various_scholarship: "장학금 제도가 다양함!",
      part_time_during_study:
        "유학 중에 아르바이트 가능, 졸업 후에는 일본 취업도 가능함",
    },
    what_is_csas: {
      what_is_csa: "CSA는 뭐하는 곳인가요?",
      platform_directly_match:
        "일본 유학을 희망하는 외국인과 일본 교육 기관이 직접 소통할 수 있는 플랫폼",
      sign_up_free: "무료 등록",
    },
    blogs: {
      blog: "Blog",
    },
    why_japans_stat: {
      why_japan: "일본 유학의 매력?",
      tuition_and_cost_living_japan: "일본에서의 생활비와 학비",
      annual_tuition: "학비",
      annual_living: "생활비",
    },
    carrers: {
      career_during_study_available: "유학중에 아르바이트 가능!",
      partime_job: "유학 비자 보유자는 1주일에",
      hrs_per_week: "28시간까지 아르바이",
      while_studying: "while studying트가 가능합니다.",
      work_upto: "*장기휴가시에는 1주일에 40시간까지 일할 수 있습니다!",
    },
    approval_rate_of_working_visas: {
      approval_rate_of_working_visa: "취업 비자 발급률이 높음! ",
      career_after_study: " 졸업 후 일본 취업이 가능!",
      number_of_visa_changes: "유학 비자에서 취업 비자로의 이행률 추이",
      by_how_much: "취업 비자 신청자 수 (2020)",
      eighty_Seven: ": 34,183명 발급률",
      approved: "약87%!",
    },
    grant_type_scholarships: {
      grant_type_scholarship: "장학금이 ",
      available: "다양함!",
      almost_foreign_students: "약 30%에 달하는 유학생이 장학금을 받고 일본 ",
      for_study_in_japan: "유학을 하고 있습니다.",
      there_142_scholarship: "일본 정부와 민간단체가 제공하는 장학금의 ",
      japanese_government: "종류는 무려 142가지!",
      got_scholarship: "2021년에는 1840명에 달하는 학생들이 일본 정부로부터 ",
      government_2021: "장학금을 받고 있습니다.",
    },
    connect_study_deliver_support: {
      connect_study_deliver_support_students: "입국 후 유학 생활 전폭 지원",
      sign_up_free: "무료 등록",
    },
    about_csas: {
      about_csa: "About CSA",
      get_support_fulfill_student_life:
        "CSA 서비스를 통해 보람찬 일본 유학 생활을 보내자!",
      information_not_only_schools:
        "CSA에서는 학교 정보뿐만 아니라 장학금과 주거, 아르바이트 등의 정보도 제공하고 있습니다!",
      get_support_for_education: "일본유학과 일본취업을 동시에 실현",
      our_strong_track_supporting:
        "일본 내 높은 글로벌 인재 취업 서포트 사업 실적을 가진 저희가",
    },
    our_factss: {
      our_facts: "Our Facts",
      support_for_7yrs: "과거 7년간 일본 유학",
      seven_yrs: " 준비생의 서",
      more_than: "포트 실적",
      thirteen_thousand: " 13,000명 이상의 학생들이",
      used_our_service: " 저희의 서비스를 이용해 왔습니다.",
      career_event: "20개국이 넘는 나라에서  ",
      twenty_countries: " 취업 이벤트 진행",
      pre_covid: " (코로나 이전). 10개국이 넘는 나라에서  ",
      ten_countries: "유학 이벤트 진행",
      covid: "(코로나 이전).",
      supported_around: "4,000명의 일본",
      four_thousand_Candidates: " 취업을 서포",
      job_hunting: " 트(2020년)",
      registered_users: "약 140여 개 국가로부터의 다양한 ",
      hundred_forty: "등록자 국적",
    },
    facebook_pages: {
      facebook_page: "Facebook Page",
    },
  },
  select_one: "아래 목록에서 선택해 주세요.",
  pre_apply: "미리 신청하기",
  tab_exit_heading: "Are you sure?",
  tab_exit_description: "저장되지 않는  내용은 삭제됩니다.",
  yes: "네",
  no: "아니요",
  duplicate_application_msg: "이미 지원이 완료된 코스입니다.",
  csa_free_minimun_balance:
    "CSA의 서비스는 무료로 이용하실 수 있습니다. 하지만, 유학비자를 신청하기 위해서는 본인 또는 가족의 수입(소득) 증명서와 200만엔(약 2000만원)이상의 예금잔액증명서가 필요합니다.",
  required_student_profile_fields: "필요한 정보가 아직 입력되어 있지 않습니다.",
  redirect_student_profile_page: "학생 프로필의 입력 페이지로 이동하겠습니다.",
  redirect: "페이지를 이동하겠습니다.",
  proceed_with_course_application: "코스 지원을 계속하기",
  faq: {
    faq1: {
      id: "1",
      title: "CSA는 뭐하는 곳인가요?",
      description:
        "CSA는 등록자가 완전히 무료로 사용할 수 있는 일본 유학 서비스입니다! 에이전트에 돈을 지불하지 않고 자신에게 맞는 학교를 찾아 일본으로 유학을 갈 수 있습니다. 프로필 입력이나 서류를 업로드하면 일본학교에서 캐스팅이 오는 경우도 있습니다! 궁금한 학교나 코스가 있다면 꼭 지원해보세요.",
    },
    faq2: {
      id: "2",
      title: "CSA에서는 어떤 서비스를 받을 수 있나요?",
      description:
        "CSA는 여러분의 일본 유학을 서포트하는 서비스입니다. 최신 일본 유학 정보를 당신의 모국어로 찾을 수 있습니다! 일본에 온 후의 생활 서포트도 충분합니다! 집 구하기, 휴대폰 계약까지 신청은 모두 무료로 서포트해드립니다! 그리고 CSA의 가장 큰 특징은 일본어 학교를 졸업한 후 일본에서의 취업 서포트도 받을 수 있다는 점! Study & Work in Japan을 반드시 실현하겠습니다!",
    },
    faq3: {
      id: "3",
      title: "학교에 어떻게 지원할 수 있나요?",
      description:
        "1. 우선 Connect Study ABROAD에 등록하세요! 2. 당신에게 맞는 학교 코스를 찾으세요. 3. 마음에 드는 코스를 찾으면, 즐겨찾기 등록하고 각 코스를 비교해봅시다.  4. 임시 지원(pre-apply) 버튼을 눌러 학교와 접촉 시작  5. 입학하고 싶은 학교를 하나를 정하고 apply 버튼을 누르세요.  ※ 서류나 프로필을 업로드하면 할수록 학교에서 캐스팅 올 확률이 업!  장학금 안내가 올 수있도록 많은 정보를 업로드하세요.",
    },
    faq4: {
      id: "4",
      title: "CSA 인턴쉽은 뭔가요?",
      description:
        "CSA를 운영하는 Fourth Valley Concierge Corporation에서 인턴을 할 수 있습니다. 일상 대화가 가능한 수준의 영어만 할 수 있다면 일본어를 할 수 없어도 괜찮습니다. 외국인의 일본 취업·일본 유학을 서포트하는 저희와  같이 일해보시지 않겠습니까?  * CSA를 통해 학교에 입학을 결정한 등록자 중 면접 등을 거쳐 결정합니다.  지원 방법은 아래와 같습니다.  1) 등록  2) 학교에 지원  3) 입학 결정  4) 상세한 인턴십 정보와 지원방법 정보 획득  5) 인턴십 지원 (전형을 통가한 사람만 참여 가능)  당신과 함께 일할 수 있으면 좋겠습니다!",
    },
    faq5: {
      id: "5",
      title: "일본에는 어떤학교가 있나요?",
      description:
        "일본에 유학하려면 크게 3가지 선택지가 있습니다. 1. 일본어학교 : 일본어가 N5레벨 이상이면 유학이 가능합니다. 전 세계에서 모인 유학생과 함께 일본어를 공부할 수 있습니다. 학교에 따라서 진학·취업 코스 등으로 나뉘어 있습니다. 2. 대학·대학원: 일본에서 학사·석사·박사 과정을 딸 수 있습니다. 입학하기 위해서는 EJU라는 시험에 합격해야 합니다. 영어로 입학할 수 있는 대학도 일부 있지만 기본적으로 N2 수준의 일본어 실력이 필요합니다. 많은 사람들이 일본어 학교를 다닌 후 대학과 대학원에 진학합니다.  3. 전문학교: 일본에서 전문적인 기술을 배울 수 있습니다. (애니메이션, 회계, IT, 조리 등) N2 이상 취득을 하거나  일본어 학교에 반년 이상 유학한 사람이 입학할 수 있습니다.  자세한 내용은 여기를 참고하세요.",
    },
    faq6: {
      id: "6",
      title: "When will the semistar start?",
      description:
        "일본의 학교는 기본적으로 3월에 졸업, 4월에 입학입니다. 대학이나 전문학교에서는 9월 입학도 있습니다.  일본어학교는 1년에 4회(1월, 4월, 7월, 10월) 입학시기가 있어 여러분의 졸업시기에 맞춰서 입학할 수 있습니다.  입학 반년~1년 전에는 서류 준비를 시작해야 하기 때문에 조심하세요!  자세한 내용은 이 기사를 참고하세요.",
    },
    faq7: {
      id: "7",
      title: "장학금을 받고 일본으로 유학가고 싶어요!",
      description:
        "일본에서는 장학금이 142종류나 있습니다! (Jasso 참고)  CSA를 통해 일본어 학교에 입학한 등록자만 받을 수 있는 장학금도 있습니다.  꼭 CSA에 있는 학교에 지원해 보세요!  자세한 내용은 이 기사를 참고하세요.",
    },
    faq8: {
      id: "8",
      title: "일본 유학을 하려면 비용이 얼마나 드나요?",
      description:
        "일본 유학은 서양권에서 유학하는 것에 비해 상당히 저렴합니다.  1년간의 비용은 수강료와 생활비 모두 포함하여 약 200만 엔입니다. (유학 지역, 유학 내용에 따라 달라질 수 있습니다.) 이것은 서양권 유학의 1/2~1/3 정도밖에 되지 않는 비용입니다!  아르바이트를 할 수도 있고 장학금의 기회도 다양해집니다.  자세한 내용은 이 기사를 참고하세요.",
    },
    faq9: {
      id: "9",
      title: "유학생도 아르바이트를 할 수 있나요?",
      description:
        "일본에서 유학생은 일주일에 28시간(장기 휴가 중에는 40시간)까지 아르바이트를 할 수 있습니다.  시급은 약 1000엔(=$9)으로 1년간 최대 약 160만 엔 벌 수 있습니다!  일본 유학을 오는 사람의 약 70%가 아르바이트를 해서 생활비와 학비를 벌고 있습니다. 일본어를 공부하면서 아르바이트를 해 충실한 일본 생활을 보내세요! 자세한 내용은 이 기사를 참고하세요.",
    },
    faq10: {
      id: "10",
      title: "일본에서 어떻게 일자리를 구할 수 있나요?",
      description:
        "일본에서 일하는 외국인 수는 지난 10년 사이에 약 3배로 늘어났습니다! 일본에서 일할 기회는 해마다 늘고 있습니다.  업무 내용과 체류 자격에 따라 다르지만, 일본에서 일자리를 찾으려면 최소 N4 수준의 일본어 실력이 필요합니다. 물론 일본어 수준이 높아지면 더 일자리를 구하기 쉬워집니다.  일본에서 일하기 위해 일본에 유학 가서 일본어를 할 수 있게 되세요!  저희는 일본에서 일하고 싶은 외국인을 위해 취업 서포트도 하고 있습니다! 반드시 이 사이트에도 등록을 해보세요.",
    },
    faq11: {
      id: "11",
      title: "일본에서 대학·대학원에 진학하고 싶어요.",
      description:
        "아직 CSA에는 학교 정보가 많지 않기 때문에 일본의 대학/대학원에 가고 싶으신 분은 이 사이트를 확인해보세요.  https://www.jpss.jp/ja/",
    },
  },
};
