import StaticCourseDurationType from "../../core/constants/course_duration_type";
import StaticCourseEnrollmentMonths from "../../core/constants/course_enrollment_months";
import StaticCourseLevels from "../../core/constants/course_levels";
import StaticCourseType from "../../core/constants/course_type";
import StaticSpecificCourseDuration from "../../core/constants/specific_course_duration";
import StaticVisaTypes from "../../core/constants/visa_type_in_course";
import status_type from "../constants/status_type";

import { API } from "aws-amplify";
import {
  getProfilePage, getSchoolProfile,
  getStudentProfile
} from "src/graphql/queries";
import scout_school_status from "../constants/scout_school_status";
// import documentStatusType from "../constants/documentStatusType";

const capitalize = (word) => {
  return word != null && typeof word === "string"
    ? word.charAt(0).toUpperCase() + word.slice(1)
    : word;
};

const isValidFileExtension = (file) => {
  console.log("from isValidFileExtension", file);

  // const fileExtenstion = file.split(",")[0]
};

//tab in indonesia, vietnamese, japanese
const getTabTitle = (
  activeIndex,
  setActiveIndex,
  show,
  setShow,
  previousActiveIndex
) => {
  const headerTabTitles = [
    "日本語",
    "英語",
    "ベトナム語",
    "Nepali",
    "Indonesian",
    "簡体字",
  ];

  const headerTabClickHandler = (index) => {
    setActiveIndex(index);
    setShow(true);
  };

  const goBackPreviousTab = () => {
    setActiveIndex(previousActiveIndex);
    setShow(false);
  };

  const headerTabClick = headerTabTitles.map((headerTabTitle, index) => (
    <button
      key={index}
      onClick={() => {
        headerTabClickHandler(index);
      }} // pass the index
      style={{
        width: "100%",
        // height: "80px",
        borderLeft: "2px solid #FFF",
        borderRight: "2px solid #FFF",
      }}
      className={`${activeIndex === index ? "active-tab" : "unactive-tab"} `}
    >
      {headerTabTitle}
    </button>
  ));

  const showPopUp = show;

  return { headerTabClick, showPopUp, goBackPreviousTab, headerTabTitles };
};

// const getValueOfMatchInCourseCreation = (masterData, japaneseItems) => {
//   if (masterData == null || japaneseItems == null) return japaneseItems;
//   const matchedArray =
//     Array.isArray(masterData) &&
//     masterData.map((a) => a.itemId).filter((x) => japaneseItems.includes(x));

//   const getMatchedData =
//     Array.isArray(masterData) &&
//     masterData.map((a) => {
//       for (let i = 0; i < 10; i++) {
//         for (let j = 0; j < 10; j++) {
//           if (a.itemId === matchedArray[j]) return a.value;
//         }
//       }
//     });
//   var matchedDataInArray = Array.prototype.slice.call(getMatchedData);
//   var commaSeparatedCourseData = matchedDataInArray
//     .filter((element) => element !== undefined)
//     .join(",");

//   return commaSeparatedCourseData;
// };

const getValueOfMatchedItemInMasterData = (
  searchItemId,
  masterData,
  isCommaSeparatedValue = false
) => {
  let matchedItemValue = "-";
  if (searchItemId != null && searchItemId.trim() != "") {
    matchedItemValue = searchItemId;
    if (Array.isArray(masterData) && masterData.length > 0) {
      if (isCommaSeparatedValue) {
        let itemList = searchItemId.split(",");
        if (itemList.length > 0) {
          /// Create new array by finding matched item for each value only if the value is non-empty
          let itemListWithValue = itemList.reduce(function (result, item) {
            if (item.trim() != "") {
              /// TODO: Try recursive calling of this function instead of code repitition
              let matchedItem = masterData.find(
                // TODO: Replace the key `id` with `itemId` in all hard-coded masterdata for uniformity
                (element) => element.itemId == item.trim()
              );
              if (matchedItem) {
                matchedItemValue = matchedItem.value;

                result.push(matchedItemValue);
              } else {
                // if no match found, return default value
                result.push(item);
              }
            }
            return result;
          }, []);
          return itemListWithValue.join(", ");
        }
      } else {
        let matchedItem = masterData.find(
          (element) => element.itemId == searchItemId.trim()
        );
        if (matchedItem) {
          matchedItemValue = matchedItem.value;
        }
      }
    }
  }
  return matchedItemValue;
};

// For Courses screens
// Not usable, as function not being called when page is rerendered on state update
const shouldShowApplyBtn = (isAuth, role) => {
  console.log("role: ", role);
  return isAuth && role == "student";
};

const getJpValueOfStatus = (searchValue) => {
  let matchedItemValue = "-";
  let matchedItem = status_type.find((element) => element.value == searchValue);
  let matchedPreviousStatus = scout_school_status.find(
    (element) => element.value == searchValue
  );
  if (matchedItem) {
    matchedItemValue = matchedItem.jp_value;
  } else if (matchedPreviousStatus) {
    matchedItemValue = matchedPreviousStatus.jp_value;
  }

  return matchedItemValue;
};

// const getJpValueOfDocumentStatus = (searchValue) => {
//   let matchedItemValue = "-";
//   let matchedItem = documentStatusType.find(
//     (element) => element.value == searchValue
//   );
//   if (matchedItem) {
//     matchedItemValue = matchedItem.jp_value;
//   }

//   return matchedItemValue;
// };

const getFormatEnValueOfStatus = (searchValue) => {
  let matchedItemValue = "-";
  let matchedItem = status_type.find((element) => element.value == searchValue);
  if (matchedItem) {
    matchedItemValue = matchedItem.format_en_value;
  }

  return matchedItemValue;
};

const getIdofStatus = (searchValue) => {

  const res = status_type.find((element) => element.value == searchValue)

  return res.id
}

export const getColorOfStatus = (searchValue) => {
  let matchedItemValue = "-";
let matchedItem = status_type.find((element) => element.value == searchValue);
if (matchedItem) {
  matchedItemValue = matchedItem.color;
}

return matchedItemValue;
}

// Implement for the case when `searchItem` is array e.g. enrollment months
// also, the language localization
const getValueOfMatchedItemInHardCodedMasterData = ({
  searchItem,
  masterData,
  langCode,
  isCommaSeparatedValue = false,
}) => {
  // console.log("isCommaSeparatedValue: ", isCommaSeparatedValue);
  let matchedItemValue = "-";
  if (searchItem != null && searchItem.trim() != "") {
    matchedItemValue = searchItem;
    if (Array.isArray(masterData) && masterData.length > 0) {
      if (isCommaSeparatedValue) {
        let itemList = searchItem.split(",");
        if (itemList.length > 0) {
          /// Create new array by finding matched item for each value only if the value is non-empty
          let itemListWithValue = itemList.reduce(function (result, item) {
            if (item.trim() != "") {
              /// TODO: Try recursive calling of this function instead of code repitition
              let matchedItem = masterData.find(
                // TODO: Replace the key `id` with `itemId` in all hard-coded masterdata for uniformity
                (element) => element.id == item.trim()
              );
              if (matchedItem) {
                matchedItemValue =
                  langCode == "ja" ? matchedItem.value : matchedItem.enValue;

                result.push(matchedItemValue);
              } else {
                // if no match found, return default value
                result.push(item);
              }
            }
            return result;
          }, []);
          return itemListWithValue.join(", ");
        }
      } else {
        let matchedItem = masterData.find(
          // TODO: Replace the key `id` with `itemId` in all hard-coded masterdata for uniformity
          (element) => element.id == searchItem.trim()
        );
        if (matchedItem) {
          matchedItemValue =
            langCode == "ja" ? matchedItem.value : matchedItem.enValue;
        }
      }
    }
  }
  return matchedItemValue;
};

// TODO: More robust implementation: check if the property exists in the first place
const updateCourseWithHardCodedMasterData = (course, langCode) => {
  // console.log("courseLengthType in original Course: ", course.courseLengthType);
  course.courseLengthType = getValueOfMatchedItemInHardCodedMasterData({
    searchItem: course.courseLengthType,
    masterData: StaticCourseDurationType,
    langCode: langCode,
  });

  // console.log("courseType in original Course: ", course.courseType);
  course.courseType = getValueOfMatchedItemInHardCodedMasterData({
    searchItem: course.courseType,
    masterData: StaticCourseType,
    langCode: langCode,
    isCommaSeparatedValue: true,
  });

  // console.log("enrollmentMonths in original Course: ", course.enrollmentMonths);
  course.enrollmentMonths = getValueOfMatchedItemInHardCodedMasterData({
    searchItem: course.enrollmentMonths,
    masterData: StaticCourseEnrollmentMonths,
    langCode: langCode,
    isCommaSeparatedValue: true,
  });

  // console.log("japaneseLevel in original Course: ", course.japaneseLevel);
  course.japaneseLevel = getValueOfMatchedItemInHardCodedMasterData({
    searchItem: course.japaneseLevel,
    masterData: StaticCourseLevels,
    langCode: langCode,
    isCommaSeparatedValue: true,
  });

  course.visaType = getValueOfMatchedItemInHardCodedMasterData({
    searchItem: course.visaType,
    masterData: StaticVisaTypes,
    langCode: langCode,
    isCommaSeparatedValue: true,
  });

  course.courseDuration = getValueOfMatchedItemInHardCodedMasterData({
    searchItem: course.courseDuration,
    masterData: StaticSpecificCourseDuration,
    langCode: langCode,
  });
};

const shouldNavigateToStudentOnboarding = async () => {
  let shouldNavigate = false;
  try {
    const getStudentProfileData = await API.graphql({
      query: getStudentProfile,
      variables: {},
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const data = getStudentProfileData.data.getStudentProfile;
    if (
      data.firstName == null ||
      data.lastName == null ||
      data.phoneNumber == null ||
      data.birthday == null ||
      data.isResidenceJapan == null ||
      data.nationality == null ||
      data.targetStudyStart == null
    ) {
      shouldNavigate = true;
    }
    // Check Page2 data after basic profile is confirmed above
    else {
      const getProfilePage2Data = await API.graphql({
        query: getProfilePage,
        variables: {
          pageTwo: true
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const data = getProfilePage2Data.data.getProfilePage.pageTwo;
      const educationData = data.education;
      const planAfterGraduationData = data.planAfterGraduation;
      if (
        !(
          educationData &&
          Array.isArray(educationData) &&
          educationData.length > 0
        )
      ) {
        shouldNavigate = true;
      } else {
        if (planAfterGraduationData == null) {
          shouldNavigate = true;
        }
      }
    }
  } catch (err) { }

  return shouldNavigate;
};

const shouldNavigateToSchoolOnboarding = async () => {
  let localSchoolID = localStorage.getItem("globalSchoolID");
  // console.log("localSchoolID", localSchoolID);
  let shouldNavigate = false;
  if (localSchoolID == null || localSchoolID == 0) {
    shouldNavigate = true;
  } else {
    try {
      const getSchoolProfileData = await API.graphql({
        query: getSchoolProfile,
        variables: { schoolId: localSchoolID ? localSchoolID : "" },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      // console.log(
      //   "getSchoolProfileData from School's shouldNavigateToOnboarding: ",
      //   getSchoolProfileData
      // );
    } catch (err) {
      // console.log("err from School's shouldNavigateToOnboarding: ", err);
      // shouldNavigate = true;

      if (err.errors && Array.isArray(err.errors) && err.errors.length > 0) {
        if (
          err.errors[0].message == "No schools found for the user or language!"
        ) {
          shouldNavigate = true;
        }
      }
    }
  }

  return shouldNavigate;
};

const getArrayFromCommaSeparatedString = (value) => {
  return value != null && typeof value === "string" ? value.split(",") : [];
};

const getLangCodeForApiCall = (langCode, isMasterData = true) => {
  // Currently, since we have masterData for only English and Japanese in DB
  // so, we fetch English content for other languages
  if (isMasterData) {
    return ["vi", "id", "np", "cn", "ko"].includes(langCode.toLowerCase())
      ? "en"
      : langCode;
  }

  // For the contents other than masterData, we fetch English content for following languages
  return ["vi", "id", "np", "cn", "ko"].includes(langCode.toLowerCase())
    ? "en"
    : langCode;
};

//Null Validation
const isNullEmptyBlank = (str) => {
  return (
    str === null || str === "" || str === "undefined"
    // str.match(/^ *$/) !== null
  );
};

export default capitalize;
export {
  isValidFileExtension,
  getValueOfMatchedItemInMasterData,
  shouldShowApplyBtn,
  getJpValueOfStatus,
  getFormatEnValueOfStatus,
  getIdofStatus,
  getValueOfMatchedItemInHardCodedMasterData,
  updateCourseWithHardCodedMasterData,
  shouldNavigateToStudentOnboarding,
  shouldNavigateToSchoolOnboarding,
  getArrayFromCommaSeparatedString,
  getLangCodeForApiCall,
  getTabTitle,
  isNullEmptyBlank,
  // getValueOfMatchInCourseCreation,
  // getJpValueOfDocumentStatus,
};

