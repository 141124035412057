import React from "react";
import { getValueOfMatchedItemInMasterData } from "src/core/utilities/utils";

const Index = ({
  japaneseProficiencies,
  masterJapaneseExam,
  masterExamExperience,
  masterJapaneseExamLevel,
  masterExamResult,
}) => {
  return (
    <div >
      <div className="row rowOutline--wrapper" id="englishProficiencySection">
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span className="section-heading">
                {/* Japanese Language Proficiency */}
                英語力（資格等）
              </span>
            </div>
          </div>
          <div className="row formInner">
            {Array.isArray(japaneseProficiencies) &&
              japaneseProficiencies.map((item, index) => {
                let proficiencyNameOfExamId = `proficiencyNameOfExam-${index}`;
                let proficiencyExperienceOfTestId = `proficiencyExperienceOfTest-${index}`;
                let proficiencyYearId = `proficiencyYear-${index}`;
                let proficiencyMonthId = `proficiencyMonth-${index}`;
                let proficiencyLevelId = `proficiencyLevel-${index}`;
                let proficiencyScoreId = `proficiencyScore-${index}`;

                return (
                  <div
                    key={index}
                    className="col-12"
                    style={{
                      backgroundColor: "#f5f5f5",
                      marginTop: "15px",
                      padding: "10px",
                      marginLeft: "15px",
                    }}
                  >
                    <div className="row">
                      <div className="col-12">
                        <label
                          for={proficiencyNameOfExamId}
                          class="form-label title-label"
                        >
                          {/* Name of the test */}
                          テスト名
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {getValueOfMatchedItemInMasterData(
                            item.nameOfExam,
                            masterJapaneseExam
                          )}
                        </label>
                      </div>
                      <div className="col-12">
                        <label for="inputYear" class="form-label title-label">
                          {/* Year of Test */}
                          受検した年
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.yearOfTest != "" ? item.yearOfTest : "-"}
                        </label>
                      </div>
                      <div class="col-12">
                        <label
                          for={proficiencyScoreId}
                          class="form-label title-label"
                        >
                          {/* Score */}
                          スコア
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.score != "" ? item.score : "-"}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
