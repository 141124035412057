import { DropdownMenu, NavItem } from "reactstrap";
import {
  faCog,
  faSignInAlt,
  faUserEdit,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { NavLink } from "react-router-dom";

const DropdownItem = ({
  toggle,
  profileTitle,
  profileLink,
  changePasswordTitle,
  changePasswordLink,
  logoutTitle,
  logoutAction,
}) => {
  return (
    <DropdownMenu className="p-0">
      <NavItem>
        <NavLink
          className="nav-link single-link position-relative "
          exact
          to={profileLink}
          onClick={() => {
            toggle();
          }}
        >
          <FontAwesomeIcon
            className="mr-2 position-relative text-primary"
            icon={faUserEdit}
          />
          {profileTitle}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link single-link position-relative "
          exact
          to={changePasswordLink}
          onClick={() => {
            toggle();
          }}
        >
          <FontAwesomeIcon
            className="mr-2 position-relative text-primary"
            icon={faCog}
          />
          {changePasswordTitle}
        </NavLink>
      </NavItem>
      <NavItem>
        <NavLink
          className="nav-link single-link position-relative "
          exact
          to=""
        >
          <FontAwesomeIcon
            className=" mr-2 position-relative  text-primary"
            icon={faSignInAlt}
          />
          <span onClick={logoutAction}>{logoutTitle}</span>
        </NavLink>
      </NavItem>
    </DropdownMenu>
  );
};
export default DropdownItem;
