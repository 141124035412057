import { API } from "aws-amplify";
import { manageSchoolM } from "src/graphql/mutations";
import { manageSchoolQ } from "src/graphql/queries";

// GET NOTIFICATIONS LIST
export const getNotificationList = async (langCode) => {

    let response = {
        status: null,
        data: ""
    }

    try {
        const res = await API.graphql({
            query: manageSchoolQ,
            variables: {
                listNotification: {
                    lang: langCode.toUpperCase()
                }

            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        response.status = true
        response.data = res.data.manageSchoolQ.listNotification

        return response

    } catch (err) {
        response.status = false
        response.data = err.errors
        return response

    }
}

// UPDATE NOTIFICATION
export const updateNotificationReadStatus = async (langCode, id) => {

    let response = {
        status: null,
        data: ""
    }

    try {
        const res = await API.graphql({
            query: manageSchoolM,
            variables: {
                updateNotificationReadStatus: {
                    id: id,
                    lang: langCode.toUpperCase()
                }

            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        response.status = true
        response.data = res.data.manageSchoolM.updateNotificationReadStatus

        return response

    } catch (err) {
        response.status = false
        response.data = err.errors
        return response

    }
}
