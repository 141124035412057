import React from "react";
// import close from "../../../../../assets/images/students/ButtonClose.png";
// import add from "../../../../../assets/images/students/Group 2416.png";

const Index = ({ studyExperiences }) => {
  return (
    <div>
      <div
        className="row rowOutline--wrapper"
        id="experienceStudyingJapaneseSection"
      >
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span className="section-heading">
                {/* Experience of studying Japanese */}
                日本語学習歴
              </span>
            </div>
          </div>
          <div className="row formInner">
            {Array.isArray(studyExperiences) &&
              studyExperiences.map((item, index) => {
                let experienceNameOfSchoolId = `experienceNameOfSchool-${index}`;
                let experienceLocationId = `experienceLocation-${index}`;
                let experienceStartYearId = `experienceStartYear-${index}`;
                let experienceStartMonthId = `experienceStartMonth-${index}`;
                let experienceEndYearId = `experienceEndYear-${index}`;
                let experienceEndMonthId = `experienceEndMonth-${index}`;
                let experienceHoursId = `experienceHours-${index}`;
                return (
                  <div
                    key={index}
                    className="col-12"
                    style={{
                      backgroundColor: "#f5f5f5",
                      marginTop: "15px",
                      padding: "10px",
                      marginLeft: "15px",
                    }}
                  >
                    <div className="row">
                      <div class="col-12">
                        <label
                          for={experienceNameOfSchoolId}
                          class="form-label title-label"
                        >
                          {/* Name of the school (official name) */}
                          学校名（正式名称）
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.nameOfSchool != "" ? item.nameOfSchool : "-"}
                        </label>
                      </div>
                      <div class="col-12">
                        <label
                          for={experienceLocationId}
                          class="form-label title-label"
                        >
                          {/* Location (in detail) */}
                          住所（詳細に）
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.location != "" ? item.location : "-"}
                        </label>
                      </div>

                      <div className="col-12">
                        <label class="form-label title-label">
                          {/* Period of Attendance */}
                          在籍期間
                        </label>
                        <br />
                        <label
                          for={experienceStartYearId}
                          class="form-label title-label"
                        >
                          {/* Start Date */}
                          開始日
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.startDate != "" ? item.startDate : "-"}
                        </label>
                      </div>

                      <div className="col-12">
                        <label
                          for={experienceEndYearId}
                          class="form-label title-label"
                        >
                          {/* End Date */}
                          終了日
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.endDate != "" ? item.endDate : "-"}
                        </label>
                      </div>

                      <div class="col-12">
                        <label
                          for={experienceHoursId}
                          class="form-label title-label"
                        >
                          {/* Hours */}
                          学習時間数
                        </label>
                        <br />
                        <label class="form-label info-label">
                          {item.hours != "" ? item.hours : "-"}
                        </label>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
