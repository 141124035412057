import React from "react";
import { getValueOfMatchedItemInMasterData } from "src/core/utilities/utils";

const MaritalStatus = ({ maritalStatuses, masterNationality }) => {
  return (
    <div>
      <div className=" row rowOutline--wrapper" id="maritalStatusSection">
        <div className="row px-0 rowOutline"   style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}>
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span className="section-heading">
                {/* Marital Status */}
                配偶者の有無
              </span>
              {/* <span>
                {" "}
                <FontAwesomeIcon icon={faChevronCircleUp} />{" "}
                <FontAwesomeIcon icon={faChevronCircleDown} />{" "}
              </span>{" "} */}
            </div>
          </div>
          <div className="col-12">
            <div className="row formInner">
              {/* ------------------------------Model----------------- */}
              {Array.isArray(maritalStatuses) &&
                maritalStatuses.map((item, index) => {
                  let partnerNameId = `marital-partnerName-${index}`;
                  let nationalityId = `marital-nationality-${index}`;
                  let birthYearId = `marital-birthYear-${index}`;
                  let birthMonthId = `marital-birthMonth-${index}`;
                  let birthDayId = `marital-birthDay-${index}`;

                  return (
                    <div
                      className="col-12"
                      style={{
                        backgroundColor: "#f5f5f5",
                        marginTop: "15px",
                        padding: "10px",
                      }}
                    >
                      <div className="row">
                        <div className="col-12">
                          <label
                            for={partnerNameId}
                            class="form-label title-label"
                          >
                            {/* Partner Name */}
                            配偶者氏名
                          </label>
                          <br />
                          <label class="form-label info-label">
                            {item.partnerName != "" ? item.partnerName : "-"}
                          </label>
                        </div>
                        {/* Nationality */}
                        <div class="col-12">
                          <div className="row">
                            <div className="col-12 col-sm-12">
                              <label
                                for={nationalityId}
                                class="form-label title-label"
                              >
                                {/* Nationality */}
                                国籍
                              </label>
                              <br />
                              <label class="form-label info-label">
                                {getValueOfMatchedItemInMasterData(
                                  item.nationality,
                                  masterNationality
                                )}
                                {/* {item.nationality != ""
                                  ? item.nationality
                                  : "-"} */}
                              </label>
                            </div>
                          </div>
                        </div>

                        {/* date of birth */}
                        <div className="col-12">
                          <label
                            for={birthYearId}
                            class="form-label title-label"
                          >
                            {/* Date of Birth */}
                            生年月日
                          </label>
                          <br />
                          <label class="form-label info-label">
                            {item.dateOfBirth != "" ? item.dateOfBirth : "-"}
                          </label>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaritalStatus;
