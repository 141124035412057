// CustomizedHeader.jsx
import { faSchool } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

export const CustomizedHeader = (props) => {
  const { channel } = props;
  const { isAuth, role } = useSelector((state) => state.user);
  const channelName = channel.name;
  // const channelSchoolIdentity =channel.members[1].nickname;

  const channelAvatar = useMemo(() => {
    if (channel.coverUrl) {
      return <img src={channel.coverUrl} style={{ width: "100px" }} />;
    }
    return <></>;
  }, [channel]);
  const channelTitle = useMemo(() => {
    if (channelName) {
      return channelName;
    }
  }, [channelName]);

  return (
    <>
      {isAuth && role == "student" && (
        <div
          className="customizedHeaderWrapper customize_school d-flex"
          style={{ margin: "20px 20px 0px 20px" }}
        >
          <div className="customize_school_icon">
            
            <FontAwesomeIcon
              style={{ height: "25px", width: "25px", margin: "3px" }}
              icon={faSchool}
            />
          </div>
          {Array.isArray(channel.members) &&
            channel.members.map((id) => (
              <>
                {id.nickname != channel.name ? (
                  <>
                    <Link
                      target="_blank"
                      to={`/list/schools/${id.metaData.schoolId}`}
                      className="d-flex customize_school_link"
                    >
                      <div className="customize_school_link_name">
                        
                        {id.nickname ? id.nickname : id.metaData.schoolId}
                      </div>

                      <div className="customize_school_link_id" style={{ marginLeft: "40px", color: "black" }}>
                        
                        ID:&nbsp;{id.metaData.schoolId}
                      </div>
                    </Link>
                  </>
                ) : (
                  ""
                )}
              </>
            ))}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      )}
      {isAuth && role == "school" && (
        <div
          className="customizedHeaderWrapper customize_student d-flex"
          style={{ margin: "20px 20px 0px 20px" }}
        >
          <div className="customize_student_icon">
            {console.log(channel)}
            <Link to={`/applicants/detail/${channel?.members[1]?.userId}`}>
              <img style={{ borderRadius: "50%" }} src={channel.coverUrl} alt="student-image" className="img-fluid" />
            </Link>
          </div>
          {Array.isArray(channel.members) &&
            channel.members.map((id) => (
              <>
                {id.nickname == channel.name ? (
                  <>
                    <div className="customize_student_link_name">
                      <Link to={`/applicants/detail/${id?.userId}`}>
                        {id.nickname}</Link>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ))}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </div>
      )}
    </>
  );
};
