import classnames from "classnames";
import React, { useEffect, useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import {
  Button,
  List,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavItem,
  UncontrolledButtonDropdown,
  UncontrolledCollapse,
  UncontrolledDropdown,
} from "reactstrap";
import { GetSchoolDataContext } from "src/contexts/GetSchoolDataContext";
import { NumStateContext } from "src/contexts/GlobalState";
import { getConsoleLog } from "src/core/utilities/useConsoleLog";
import { getFromLocalAndSet, setNewLanguage } from "src/state/ducks/ui";
import capitalize from "../../../core/utilities/utils";
import { HashLink } from 'react-router-hash-link';


const Navigation = ({ role, isSchoolPublicScreen }) => {
  const { t, i18n } = useTranslation();
  const [visable, setVisable] = useState(false);
  // const [activeIndex, setActiveIndex] = useState(-1);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dispatch = useDispatch();
  const languageCode = useSelector((store) => store.language);
  const languageToggle = () => setDropdownOpen((prevState) => !prevState);

  const changeLanguage = (langCode) => {
    getConsoleLog("langCode", langCode);
    dispatch(setNewLanguage(langCode));
  };

  useEffect(() => {
    dispatch(getFromLocalAndSet());
  }, []);

  useEffect(() => {
    console.log("languageCode from store: ", languageCode);
    i18n.changeLanguage(languageCode);
  }, [languageCode]);

  const resetNavBoc = () => {
    setVisable(false);
  };

  //school Id
  const useNumState = () => React.useContext(NumStateContext);
  const { num, setNum } = useNumState();

  const schoolDataValue = useContext(GetSchoolDataContext);

  const languageList = [
    { title: "日本語", code: "JA", flagCode: "jp" },
    { title: "English", code: "EN", flagCode: "gb" },
    { title: "Tiếng Viêt", code: "VI", flagCode: "vn" },
    { title: "Indonesia", code: "ID", flagCode: "id" },
    { title: "नेपाली", code: "NP", flagCode: "np" },
    { title: "簡体字", code: "CN", flagCode: "cn" },
    { title: "한국어", code: "KO", flagCode: "kr" },
  ]

  return (
    <div
      className={classnames("d-flex align-items-center justify-content-end justify-content-md-between navigation position-relative", {
        "menu-container-visable": visable,
      })}
      onMouseLeave={resetNavBoc}
    >
      <Navbar className="nav-position d-md-block d-none">
        <Nav className="align-items-center  flex-nowrap">
          {role == "student" && (<NavItem className="d-lg-block d-xl-block d-none">
            <NavLink
              exact
              onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
              className="nav-link position-relative main-nav text-dark"
              to="/student/dashboard"
            >
              Dashboard
            </NavLink>
          </NavItem>)}
          {!(isSchoolPublicScreen || role == "school") && (
            <>
              {/* COURSES */}
              <NavItem className="d-lg-block d-xl-block d-none">
                <NavLink
                  exact
                  onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                  className="nav-link position-relative main-nav text-dark"
                  to="/list/courses"
                >
                  {t("courses")}
                </NavLink>
              </NavItem>
              {/* SCHOOLS */}
              <NavItem className="d-lg-block d-xl-block d-none">
                <NavLink
                  exact
                  onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                  className="nav-link position-relative main-nav text-dark"
                  to="/list/schools"
                >
                  {t("schools")}
                </NavLink>
              </NavItem>

              {!(isSchoolPublicScreen || role == "school" || role == "student") && (
                <>
                  {/* EVENT LIST */}
                  <NavItem className="d-xl-block d-none">
                    <NavLink
                      exact
                      onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                      className="nav-link position-relative main-nav text-dark"
                      to="/list/event"
                    >
                      Events
                    </NavLink>
                  </NavItem>
                </>)
              }



            </>
          )}

          {role == "student" && (
            <>

              <NavItem className="d-lg-block d-xl-block d-none">
                <NavLink
                  exact
                  onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                  className="nav-link position-relative main-nav text-dark"
                  to="/applications"
                >
                  {t("applications")}
                </NavLink>
              </NavItem>
            </>
          )}

          {role == "school" && (
            <>
              <NavItem className="d-lg-block d-xl-block d-none">
                {num || schoolDataValue.schoolList.length >= 1 ? (
                  <NavLink
                    exact
                    onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                    className="nav-link position-relative main-nav text-dark"
                    to="/school/dashboard"
                  >
                    {/* Dashboard */}
                    ダッシュボード
                  </NavLink>
                ) : (
                  <NavLink
                    exact
                    onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                    className="nav-link position-relative main-nav text-dark"
                    to="/school/dashboard"
                    style={{
                      pointerEvents: "none",
                      // backgroundColor: "rgba(0,0,0,0.12)",
                      opacity: "0.5",
                    }}
                  >
                    {/* Dashboard */}
                    ダッシュボード
                  </NavLink>
                )}
              </NavItem>
              <NavItem className="d-lg-block d-xl-block d-none">
                {num || schoolDataValue.schoolList.length >= 1 ? (
                  <NavLink
                    exact
                    onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                    className="nav-link position-relative main-nav text-dark"
                    to="/school/courses"
                  >
                    {/* My Courses */}
                    コース管理
                  </NavLink>
                ) : (
                  <NavLink
                    exact
                    onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                    className="nav-link position-relative main-nav text-dark"
                    to="/school/courses"
                    style={{
                      pointerEvents: "none",
                      opacity: "0.5",
                    }}
                  >
                    {/* My Courses */}
                    コース管理
                  </NavLink>
                )}
              </NavItem>
              <NavItem className="d-lg-block d-xl-block d-none">
                {num || schoolDataValue.schoolList.length >= 1 ? (
                  <NavLink
                    exact
                    onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                    className="nav-link position-relative main-nav text-dark"
                    to="/school/applications"
                  >
                    {/* Applications */}
                    応募管理
                  </NavLink>
                ) : (
                  <NavLink
                    exact
                    onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                    className="nav-link position-relative main-nav text-dark"
                    to="/school/applications"
                    style={{
                      pointerEvents: "none",
                      opacity: "0.5",
                    }}
                  >
                    {/* Applications */}
                    応募管理
                  </NavLink>
                )}
              </NavItem>

              <NavItem className="d-lg-block d-xl-block d-none">
                <NavLink
                  exact
                  onMouseEnter={resetNavBoc} // NavLink and this attribute enables underline, when clicked
                  className="nav-link position-relative main-nav text-dark"
                  to="/school/search/student"
                >
                  学生検索
                </NavLink>
              </NavItem>
            </>
          )}

        </Nav>


      </Navbar>

      {!(isSchoolPublicScreen || role == "school") && (
        <>
          <UncontrolledButtonDropdown nav inNavbar>
            <DropdownToggle nav caret className="d-flex align-items-center justify-content-between text-white px-0">
              {languageCode.toLocaleUpperCase()}
            </DropdownToggle>
            <DropdownMenu right>
              {
                languageList.map(l => {
                  return (
                    <DropdownItem key={l.code} className="d-flex align-items-center justify-content-between" onClick={() => changeLanguage(l.code.toLocaleLowerCase())}>
                      <span className="pr-2 d-block"> {l.title}</span>
                      <figure className="m-0" style={{ width: "40px", height: "28px" }}>
                        <img
                          className="rounded border object-fit-contain"
                          src={`https://flagcdn.com/h80/${l.flagCode}.png`}
                          srcSet={`https://flagcdn.com/h160/${l.flagCode}.png 2x`}
                          alt={l.flagCode} />
                      </figure>
                    </DropdownItem>
                  )
                })
              }
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </>
      )}
    </div >
  );
};

export default Navigation;