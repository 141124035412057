import japanese from "./ja";
import english from "./en";
import vietnamese from "./vi";
import indonesian from "./id";
import nepali from "./np";
import chinese from "./cn";
import korean from "./ko";

const resources = {
  en: {
    translation: english,
  },
  ja: {
    translation: japanese,
  },
  vi: {
    translation: vietnamese,
  },
  id: {
    translation: indonesian,
  },
  np: {
    translation: nepali,
  },
  cn: {
    translation: chinese,
  },
  ko: {
    translation: korean,
  },
};

export default resources;
