import React from "react";
import { propTypes } from "react-bootstrap/esm/Image";
import { Helmet } from "react-helmet";

const MetaDecorator = ({ title, description }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      {/* TODO purpose of og:title and og:description */}
      <meta
        property="og:title"
        content="Connect Study Abroad |
    【日本に留学したい人へ】自分にあった日本語学校・コースを無料で検索して直接応募ができるサービスです。留学後の生活支援や就職もサポートします"
      />
      <meta
        property="og:description"
        content="【日本に留学したい人へ】自分にあった日本語学校・コースを無料で検索して直接応募ができるサービスです。留学後の生活支援や就職もサポートします"
      />
      <meta property="og:site_name" content="Connect Study Abroad" />
    </Helmet>
  );
};

MetaDecorator.propTypes = {
  tilte: propTypes.string,
  description: propTypes,
};

export default MetaDecorator;
