import React from "react";
import { useState } from "react";

const StudentDetailAnchorLink = () => {
  const [active, setActive] = useState("1");
  const handleClick = (event) => {
    setActive(event.target.id);
  };
  return (
    <>
      <ul className="li-data d-block">
        <li>
          <a
            key={1}
            className={active === "1" ? "anchor_active" : "anchor_unactive"}
            id={"1"}
            onClick={handleClick}
            href="#personalInfoSection"
          >
            {/* Personal info */}
            個人情報
          </a>
        </li>
        <li>
          <a
            key={2}
            className={active === "2" ? "anchor_active" : "anchor_unactive"}
            id={"2"}
            onClick={handleClick}
            href="#passportInfoSection"
          >
            {/* Passport information/ Stay history */}
            パスポート取得状況
          </a>
        </li>
        {/* <li>
          <a
            key={3}
            className={active === "3" ? "anchor_active" : "anchor_unactive"}
            id={"3"}
            onClick={handleClick}
            href="#friendsFamilySection"
          >
            {/* Family & friend in Japan */}
        {/* 在日親族や友人
          </a>
        </li>
        <li>
          <a
            key={4}
            className={active === "4" ? "anchor_active" : "anchor_unactive"}
            id={"4"}
            onClick={handleClick}
            href="#maritalStatusSection"
          >
            {/* Marital status */}
        {/* 配偶者の有無
          </a>
        </li>  */}
        <li>
          <a
            key={5}
            className={active === "5" ? "anchor_active" : "anchor_unactive"}
            id={"5"}
            onClick={handleClick}
            href="#educationBackgroundSection"
          >
            {/* Education background */}
            学歴
          </a>
        </li>
        <li>
          <a
            key={6}
            className={active === "6" ? "anchor_active" : "anchor_unactive"}
            id={"6"}
            onClick={handleClick}
            href="#professionalCareerSection"
          >
            {/* Professional background */}
            職歴
          </a>
        </li>
        <li>
          <a
            key={7}
            className={active === "7" ? "anchor_active" : "anchor_unactive"}
            id={"7"}
            onClick={handleClick}
            href="#experienceStudyingJapaneseSection"
          >
            {/* Experience of studying Japanese */}
            日本語学習歴
          </a>
        </li>
        <li>
          <a
            key={8}
            className={active === "8" ? "anchor_active" : "anchor_unactive"}
            id={"8"}
            onClick={handleClick}
            href="#japaneseProficiencySection"
          >
            {/* Japanese language proficiency */}
            日本語能力について（証明書等）
          </a>
        </li>
        <li>
          <a
            key={11}
            className={active === "11" ? "anchor_active" : "anchor_unactive"}
            id={"11"}
            onClick={handleClick}
            href="#englishProficiencySection"
          >
            {/* English language proficiency */}
            英語力（資格等）
          </a>
        </li>
        <li>
          <a
            key={12}
            className={active === "12" ? "anchor_active" : "anchor_unactive"}
            id={"12"}
            onClick={handleClick}
            href="#englishLevelSection"
          >
            {/* ENGLISH LEVEL */}
            英語のレベル
          </a>
        </li>

        <li>
          <a
            key={9}
            className={active === "9" ? "anchor_active" : "anchor_unactive"}
            id={"9"}
            onClick={handleClick}
            href="#planAfterGraduationSection"
          >
            {/* Plan after graduation */}
            卒業後の予定
          </a>
        </li>
        {/* <li>Previous application for certificate of eligibility</li> */}
        <li>
          <a
            key={10}
            className={active === "10" ? "anchor_active" : "anchor_unactive"}
            id={"10"}
            onClick={handleClick}
            href="#sponsorSection"
          >
            {/* About Sponsor */}
            経費支弁者について
          </a>
        </li>
      </ul>
    </>
  );
};

export default StudentDetailAnchorLink;
