/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getPublicData = /* GraphQL */ `
  query GetPublicData(
    $getSchoolList: getSchoolListInput
    $getSchoolDetail: getSchoolDetailInput
    $getSchoolCourseList: getSchoolCourseListInput
    $getCourseList: getCourseListInput
    $getCourseDetail: getCourseDetailInput
    $getSeminarList: getSeminarListInput
    $getSeminarDetail: getSeminarDetailInput
  ) {
    getPublicData(
      getSchoolList: $getSchoolList
      getSchoolDetail: $getSchoolDetail
      getSchoolCourseList: $getSchoolCourseList
      getCourseList: $getCourseList
      getCourseDetail: $getCourseDetail
      getSeminarList: $getSeminarList
      getSeminarDetail: $getSeminarDetail
    ) {
      getSchoolList {
        list {
          schoolId
          schoolName
          schoolUrl
          schoolLogo
          enrolledNumbers
          enrolledNumbersAsOf
          nationalityDemographics
          schoolPrefecture
          area
          schoolPostalCode
          schoolAddress
          schoolPhoneNumber
          schoolEmail
          enrollmentMonths
          isAccommodationAvailable
          accommodationUrl
          nearestStation
          founder
          director
          authorizedDate
          establishmentDate
          numberOfFacultyAll
          numberOfFacultyFullTime
          availableLanguageSupport
          intakeCapacity
          numberOfShifts
          screeningProcess
          commentsScreeningProcess
          availableCourseTypes
          availableCourseLength
          schoolTypes
          language
          createtime
          updatetime
          pinStatus
        }
        pagination {
          totalCount
          countPerPage
          activePage
        }
      }
      getSchoolDetail {
        schoolId
        schoolName
        schoolUrl
        schoolLogo
        enrolledNumbersAsOf
        schoolPrefecture
        area
        schoolPostalCode
        schoolAddress
        schoolPhoneNumber
        schoolEmail
        enrollmentMonths
        nearestStation
        founder
        director
        authorizedDate
        establishmentDate
        intakeCapacity
        numberOfShifts
        screeningProcess
        commentsScreeningProcess
        availableCourseTypes
        availableCourseLength
        schoolTypes
        personInChargeName
        personInChargeEmail
        personInChargePhone
        language
        numberOfFacultyAll
        numberOfFacultyFullTime
        assisstantForHousing
        isAccommodationAvailable
        accommodationUrl
        availableLanguageSupport
        quota
        enrolledNumbers
        nationalityDemographics
        expectedGradsThisYear
        numGraduatesLastYear
        afterGraduation
        numJlptPasser
        mailingAddress
        messageFromSchool
        images {
          id
          content
          contentType
          language
        }
        videos {
          id
          content
          contentType
          language
        }
        schoolYearCalendar
        scholarship
        facility
        createtime
        updatetime
        pinStatus
        guid
      }
      getSchoolCourseList {
        list {
          courseId
          courseStatus
          language
          schoolId
          schoolName
          courseName
          applicationId
          applicationStatus
          isOnline
          courseUrl
          courseType
          courseLengthType
          area
          prefecture
          enrollmentMonths
          japaneseLevel
          englishLevel
          courseOverview
          targetStudents
          visaType
          courseDuration
          contentOfStudy
          applicationPeriod
          courseGoal
          curriculumDescription
          selectionFee
          enrollmentFee
          tutionFee
          otherFee
          totalFee
          createtime
          updatetime
          pinStatus
          langList
        }
        pagination {
          totalCount
          countPerPage
          activePage
        }
      }
      getCourseList {
        list {
          courseId
          courseStatus
          language
          schoolId
          schoolName
          courseName
          applicationId
          applicationStatus
          isOnline
          courseUrl
          courseType
          courseLengthType
          area
          prefecture
          enrollmentMonths
          japaneseLevel
          englishLevel
          courseOverview
          targetStudents
          visaType
          courseDuration
          contentOfStudy
          applicationPeriod
          courseGoal
          curriculumDescription
          selectionFee
          enrollmentFee
          tutionFee
          otherFee
          totalFee
          createtime
          updatetime
          pinStatus
          langList
        }
        pagination {
          totalCount
          countPerPage
          activePage
        }
      }
      getCourseDetail {
        courseId
        courseStatus
        language
        schoolId
        schoolName
        courseName
        applicationId
        applicationStatus
        isOnline
        courseUrl
        courseType
        courseLengthType
        area
        prefecture
        enrollmentMonths
        japaneseLevel
        englishLevel
        courseOverview
        targetStudents
        visaType
        courseDuration
        contentOfStudy
        applicationPeriod
        courseGoal
        curriculumDescription
        selectionFee
        enrollmentFee
        tutionFee
        otherFee
        totalFee
        isAdditionalFee
        additionalFeeDetail
        tutionFeeNotes
        createtime
        updatetime
        pinStatus
        langList
      }
      getSeminarList {
        list {
          seminarId
          schoolId
          language
          seminarTitle
          seminarImage
          seminarDescription
          seminarTarget
          seminarLocation
          seminarOrganizer
          seminarType
          seminarInfoPageUrl
          seminarAttendUrl
          seminarCapacity
          seminarStartDate
          seminarEndDate
          startTime
          endTime
          howToApply
          seminarLanguage
          seminarTimeZone
          seminarRegistrationDeadline
          isOnline
          createtime
          updatetime
          appliedSeminarId
        }
        pagination {
          totalCount
          countPerPage
          activePage
        }
      }
      getSeminarDetail {
        seminarId
        schoolId
        language
        seminarTitle
        seminarImage
        seminarDescription
        seminarTarget
        seminarLocation
        seminarOrganizer
        seminarType
        seminarInfoPageUrl
        seminarAttendUrl
        seminarCapacity
        seminarStartDate
        seminarEndDate
        startTime
        endTime
        howToApply
        seminarLanguage
        seminarTimeZone
        seminarRegistrationDeadline
        isOnline
        createtime
        updatetime
        appliedSeminarId
      }
    }
  }
`;
export const getMasterData = /* GraphQL */ `
  query GetMasterData($masterdata: MasterDataInput) {
    getMasterData(masterdata: $masterdata) {
      type
      language
      items {
        itemId
        value
        parentId
      }
    }
  }
`;
export const getUserRole = /* GraphQL */ `
  query GetUserRole($userRole: String!) {
    getUserRole(userRole: $userRole) {
      id
      role
      isActive
      createdTimeStamp
      updatedTimeStamp
    }
  }
`;
export const getSchoolAccountInfo = /* GraphQL */ `
  query GetSchoolAccountInfo {
    getSchoolAccountInfo {
      isApproved
      updatetime
      createtime
      isMaster
    }
  }
`;
export const getStudentProfile = /* GraphQL */ `
  query GetStudentProfile {
    getStudentProfile {
      firstName
      lastName
      birthday
      email
      phoneNumber
      residenceCountry
      nationality
      placeOfbirth
      currentAddress
      currentOccupation
      isResidenceJapan
      targetStudyStart
      profileImage
      sns
      guid
      snsDetail
      isActive
      studentFindCsa
      englishLangProficiency
      isScoutable
      totalScout
      hasApplied
      scoutStatus
      scoutStatusId
      loginTime
      countryGroup
      profileProgress
      pinCourseCount
      pinSchoolCount
      totalApplication
      voyStudent
      voyEduInfo
      voyWorkInfo
      voyJapaneseStudyExperienceInfo
      voyJapaneseProficiencyInfo
      voyEnglishProficiencyInfo
      voyPlanAfterGraduationInfo
      voyVisaInfo
      voySponsorInfo
      photograph
      passport
      transcriptFromLastSchool
      japaneseLangCertificate
      englishLangCertificate
      employmentCertificate
      depositBalanceCert
    }
  }
`;
export const getStudentCourseApplicationList = /* GraphQL */ `
  query GetStudentCourseApplicationList(
    $pagination: PaginationInput
    $filters: LanguageInput
  ) {
    getStudentCourseApplicationList(
      pagination: $pagination
      filters: $filters
    ) {
      list {
        applicationId
        schoolId
        schoolName
        courseId
        courseName
        courseStatus
        applicationVia
        guidAgent
        firstName
        lastName
        birthday
        gender
        maritalStatus
        occupation
        address
        applicationStatus
        creationTimeStamp
        updateTimeStamp
      }
      pagination {
        totalCount
        countPerPage
        activePage
      }
    }
  }
`;
export const getStudentCourseApplicationDetail = /* GraphQL */ `
  query GetStudentCourseApplicationDetail(
    $applicationId: String!
    $filters: LanguageInput
  ) {
    getStudentCourseApplicationDetail(
      applicationId: $applicationId
      filters: $filters
    ) {
      applicationId
      courseId
      applicationVia
      guidAgent
      firstName
      lastName
      birthday
      gender
      maritalStatus
      occupation
      address
      status
      documents {
        documentATSId
        applicationId
        docVersion
        userDocumentId
        status
        timestamp
      }
      creationTimeStamp
      updateTimeStamp
    }
  }
`;
export const getProfilePage = /* GraphQL */ `
  query GetProfilePage(
    $pageOne: Boolean
    $pageTwo: Boolean
    $pageThree: Boolean
  ) {
    getProfilePage(
      pageOne: $pageOne
      pageTwo: $pageTwo
      pageThree: $pageThree
    ) {
      pageOne {
        visaInfo {
          id
          passportNumber
          passportIssueDate
          passportExpiryDate
          previousStay
          updatetime
          createtime
        }
        previousStayJapan {
          id
          dateOfEntry
          dateOfDeparture
          statusOfResidence
          purposeOfStay
          updatetime
          createtime
        }
        friendsFamilyJapan {
          id
          relationship
          name
          dateOfBirth
          nationality
          intendToReside
          placeOfEmploymentOrSchool
          idNumber
          updatetime
          createtime
        }
        maritalStatus {
          id
          partnerName
          dateOfBirth
          nationality
          updatetime
          createtime
        }
      }
      pageTwo {
        education {
          id
          type
          nameOfInstitution
          location
          startDate
          endDate
          years
          createtime
          updatetime
          isCurrentlyEnrolled
        }
        professionalCareer {
          id
          nameOfCompany
          typeOfJob
          location
          startDate
          endDate
          createtime
          updatetime
          isCurrentlyWorking
        }
        japaneseStudyExperience {
          id
          nameOfSchool
          location
          startDate
          endDate
          hours
          createtime
          updatetime
          isNotComplete
        }
        japaneseAbility {
          id
          nameOfExam
          experienceOfTest
          yearOfTest
          proficiencyLevel
          score
          createtime
          updatetime
        }
        englishAbility {
          id
          testName
          yearOfTest
          score
          createtime
          updatetime
        }
        planAfterGraduation {
          id
          type
          studyType
          createtime
          updatetime
        }
        englishLangProficiency
      }
      pageThree {
        sponsorInfo {
          id
          sponsorName
          sponsorAddress
          relationWithApplicant
          sponsorAnnualIncome
          sponsorOccupation
          sponsorEmployer
          updatetime
          createtime
        }
      }
    }
  }
`;
export const getStudentProfileDocs = /* GraphQL */ `
  query GetStudentProfileDocs {
    getStudentProfileDocs {
      statementOfReason
      statementOfAdvancement
      photograph
      passport
      diplomaOfLastSchool
      transcriptFromLastSchool
      japaneseLangCertificate
      englishLangCertificate
      employmentCertificate
      tuberculosisScreeningCert
      financialDoc
      sponsorFamilyMemberList
      sponsorFamilyStructure
      sponsorRelationWithApplicantCert
      depositBalanceCert
      sponsorEmploymentCert
      sponsorAssetFormationCert
      sponsorIncomeCert
      createtime
      updatetime
    }
  }
`;
export const getAgentProfile = /* GraphQL */ `
  query GetAgentProfile {
    getAgentProfile {
      name
      phoneNumber
      address
      country_id
      description
      logo
      isActive
      timeStamp
    }
  }
`;
export const getSchoolProfile = /* GraphQL */ `
  query GetSchoolProfile($schoolId: String, $language: String) {
    getSchoolProfile(schoolId: $schoolId, language: $language) {
      schoolId
      schoolName
      schoolUrl
      schoolLogo
      enrolledNumbersAsOf
      schoolPrefecture
      area
      schoolPostalCode
      schoolAddress
      schoolPhoneNumber
      schoolEmail
      enrollmentMonths
      nearestStation
      founder
      director
      authorizedDate
      establishmentDate
      intakeCapacity
      numberOfShifts
      screeningProcess
      commentsScreeningProcess
      availableCourseTypes
      availableCourseLength
      schoolTypes
      personInChargeName
      personInChargeEmail
      personInChargePhone
      language
      numberOfFacultyAll
      numberOfFacultyFullTime
      assisstantForHousing
      isAccommodationAvailable
      accommodationUrl
      availableLanguageSupport
      quota
      enrolledNumbers
      nationalityDemographics
      expectedGradsThisYear
      numGraduatesLastYear
      afterGraduation
      numJlptPasser
      mailingAddress
      messageFromSchool
      images {
        id
        content
        contentType
        language
      }
      videos {
        id
        content
        contentType
        language
      }
      schoolYearCalendar
      scholarship
      facility
      createtime
      updatetime
      pinStatus
      guid
    }
  }
`;
export const schoolGetCourseDetailsProfile = /* GraphQL */ `
  query SchoolGetCourseDetailsProfile($courseId: String, $language: String) {
    schoolGetCourseDetailsProfile(courseId: $courseId, language: $language) {
      courseId
      courseStatus
      language
      schoolId
      schoolName
      courseName
      applicationId
      applicationStatus
      isOnline
      courseUrl
      courseType
      courseLengthType
      area
      prefecture
      enrollmentMonths
      japaneseLevel
      englishLevel
      courseOverview
      targetStudents
      visaType
      courseDuration
      contentOfStudy
      applicationPeriod
      courseGoal
      curriculumDescription
      selectionFee
      enrollmentFee
      tutionFee
      otherFee
      totalFee
      isAdditionalFee
      additionalFeeDetail
      tutionFeeNotes
      createtime
      updatetime
      pinStatus
      langList
    }
  }
`;
export const schoolGetOwnCourseList = /* GraphQL */ `
  query SchoolGetOwnCourseList(
    $schoolId: String!
    $pagination: PaginationInput
    $filters: LanguageInput
  ) {
    schoolGetOwnCourseList(
      schoolId: $schoolId
      pagination: $pagination
      filters: $filters
    ) {
      list {
        courseId
        courseStatus
        language
        schoolId
        schoolName
        courseName
        applicationId
        applicationStatus
        isOnline
        courseUrl
        courseType
        courseLengthType
        area
        prefecture
        enrollmentMonths
        japaneseLevel
        englishLevel
        courseOverview
        targetStudents
        visaType
        courseDuration
        contentOfStudy
        applicationPeriod
        courseGoal
        curriculumDescription
        selectionFee
        enrollmentFee
        tutionFee
        otherFee
        totalFee
        createtime
        updatetime
        pinStatus
        langList
      }
      pagination {
        totalCount
        countPerPage
        activePage
      }
    }
  }
`;
export const schoolGetOwnSchoolList = /* GraphQL */ `
  query SchoolGetOwnSchoolList($pagination: PaginationInput) {
    schoolGetOwnSchoolList(pagination: $pagination) {
      list {
        schoolId
        schoolName
        schoolUrl
        schoolLogo
        enrolledNumbers
        enrolledNumbersAsOf
        nationalityDemographics
        schoolPrefecture
        area
        schoolPostalCode
        schoolAddress
        schoolPhoneNumber
        schoolEmail
        enrollmentMonths
        isAccommodationAvailable
        accommodationUrl
        nearestStation
        founder
        director
        authorizedDate
        establishmentDate
        numberOfFacultyAll
        numberOfFacultyFullTime
        availableLanguageSupport
        intakeCapacity
        numberOfShifts
        screeningProcess
        commentsScreeningProcess
        availableCourseTypes
        availableCourseLength
        schoolTypes
        language
        createtime
        updatetime
        pinStatus
      }
      pagination {
        totalCount
        countPerPage
        activePage
      }
    }
  }
`;
export const schoolGetApplicantProfile = /* GraphQL */ `
  query SchoolGetApplicantProfile($applicationId: String!) {
    schoolGetApplicantProfile(applicationId: $applicationId) {
      firstName
      lastName
      birthday
      email
      phoneNumber
      residenceCountry
      nationality
      placeOfbirth
      currentAddress
      currentOccupation
      isResidenceJapan
      targetStudyStart
      profileImage
      sns
      snsDetail
    }
  }
`;
export const schoolGetApplicantProfileDocs = /* GraphQL */ `
  query SchoolGetApplicantProfileDocs($applicationId: String!) {
    schoolGetApplicantProfileDocs(applicationId: $applicationId) {
      statementOfReason
      statementOfAdvancement
      photograph
      passport
      diplomaOfLastSchool
      transcriptFromLastSchool
      japaneseLangCertificate
      englishLangCertificate
      employmentCertificate
      tuberculosisScreeningCert
      financialDoc
      sponsorFamilyMemberList
      sponsorFamilyStructure
      sponsorRelationWithApplicantCert
      depositBalanceCert
      sponsorEmploymentCert
      sponsorAssetFormationCert
      sponsorIncomeCert
      createtime
      updatetime
    }
  }
`;
export const schoolGetApplicantProfilePage1 = /* GraphQL */ `
  query SchoolGetApplicantProfilePage1($applicationId: String!) {
    schoolGetApplicantProfilePage1(applicationId: $applicationId) {
      visaInfo {
        id
        passportNumber
        passportIssueDate
        passportExpiryDate
        previousStay
        updatetime
        createtime
      }
      previousStayJapan {
        id
        dateOfEntry
        dateOfDeparture
        statusOfResidence
        purposeOfStay
        updatetime
        createtime
      }
      friendsFamilyJapan {
        id
        relationship
        name
        dateOfBirth
        nationality
        intendToReside
        placeOfEmploymentOrSchool
        idNumber
        updatetime
        createtime
      }
      maritalStatus {
        id
        partnerName
        dateOfBirth
        nationality
        updatetime
        createtime
      }
    }
  }
`;
export const schoolGetApplicantProfilePage2 = /* GraphQL */ `
  query SchoolGetApplicantProfilePage2($applicationId: String!) {
    schoolGetApplicantProfilePage2(applicationId: $applicationId) {
      education {
        id
        type
        nameOfInstitution
        location
        startDate
        endDate
        years
        createtime
        updatetime
        isCurrentlyEnrolled
      }
      professionalCareer {
        id
        nameOfCompany
        typeOfJob
        location
        startDate
        endDate
        createtime
        updatetime
        isCurrentlyWorking
      }
      japaneseStudyExperience {
        id
        nameOfSchool
        location
        startDate
        endDate
        hours
        createtime
        updatetime
        isNotComplete
      }
      japaneseAbility {
        id
        nameOfExam
        experienceOfTest
        yearOfTest
        proficiencyLevel
        score
        createtime
        updatetime
      }
      englishAbility {
        id
        testName
        yearOfTest
        score
        createtime
        updatetime
      }
      planAfterGraduation {
        id
        type
        studyType
        createtime
        updatetime
      }
      englishLangProficiency
    }
  }
`;
export const schoolGetApplicantProfilePage3 = /* GraphQL */ `
  query SchoolGetApplicantProfilePage3($applicationId: String!) {
    schoolGetApplicantProfilePage3(applicationId: $applicationId) {
      sponsorInfo {
        id
        sponsorName
        sponsorAddress
        relationWithApplicant
        sponsorAnnualIncome
        sponsorOccupation
        sponsorEmployer
        updatetime
        createtime
      }
    }
  }
`;
export const schoolGetApplicantList = /* GraphQL */ `
  query SchoolGetApplicantList(
    $schoolId: String!
    $pagination: PaginationInput
    $filters: LanguageInput
  ) {
    schoolGetApplicantList(
      schoolId: $schoolId
      pagination: $pagination
      filters: $filters
    ) {
      list {
        applicationId
        email
        applicationStatus
        applicantGuid
        applicantFirstName
        applicantLastName
        applicantNationality
        applicantprofileImage
        courseId
        courseName
        courseStatus
        applicationVia
        appliedDateTime
        updateTimeStamp
      }
      pagination {
        totalCount
        countPerPage
        activePage
      }
    }
  }
`;
export const schoolGetPr = /* GraphQL */ `
  query SchoolGetPr($schoolId: String!, $filters: LanguageInput) {
    schoolGetPR(schoolId: $schoolId, filters: $filters) {
      language
      numberOfFacultyAll
      numberOfFacultyFullTime
      assisstantForHousing
      isAccommodationAvailable
      accommodationUrl
      availableLanguageSupport
      quota
      enrolledNumbers
      nationalityDemographics
      expectedGradsThisYear
      numGraduatesLastYear
      afterGraduation
      numJlptPasser
      mailingAddress
      messageFromSchool
      images {
        id
        content
        contentType
        language
      }
      videos {
        id
        content
        contentType
        language
      }
      schoolYearCalendar
      scholarship
      facility
    }
  }
`;
export const schoolGetApplicationDetail = /* GraphQL */ `
  query SchoolGetApplicationDetail($applicationId: String!) {
    schoolGetApplicationDetail(applicationId: $applicationId) {
      applicationId
      applicationStatus
      applicantGuid
      courseId
      courseName
    }
  }
`;
export const schoolGetStudentSearchList = /* GraphQL */ `
  query SchoolGetStudentSearchList(
    $pagination: PaginationInput
    $filters: StudentSearchFilters
  ) {
    schoolGetStudentSearchList(pagination: $pagination, filters: $filters) {
      list {
        id
        displayId
        profileImage
        nationality
        japaneseStudyExperience
        isSponsorConfirmed
        japaneseAbility
        numOfDocs
        planAfterGraduation
        numOfScouts
        reasonForApplication
        latestEducation
        scoutStatus
        scoutStatusId
        hasApplied
        pinStatus
        age
      }
      pagination {
        totalCount
        countPerPage
        activePage
      }
    }
  }
`;
export const schoolGetDashboardGraphData = /* GraphQL */ `
  query SchoolGetDashboardGraphData($schoolId: String!) {
    schoolGetDashboardGraphData(schoolId: $schoolId) {
      nationality {
        key
        value
      }
      age {
        key
        value
      }
      japaneseLevel {
        key
        value
      }
      planAfterGraduation {
        key
        value
      }
      applicationStatus {
        key
        value
      }
    }
  }
`;
export const studentGetPinList = /* GraphQL */ `
  query StudentGetPinList(
    $getSchoolList: getSchoolListInput
    $getCourseList: getCourseListInput
  ) {
    studentGetPinList(
      getSchoolList: $getSchoolList
      getCourseList: $getCourseList
    ) {
      getSchoolList {
        list {
          schoolId
          schoolName
          schoolUrl
          schoolLogo
          enrolledNumbers
          enrolledNumbersAsOf
          nationalityDemographics
          schoolPrefecture
          area
          schoolPostalCode
          schoolAddress
          schoolPhoneNumber
          schoolEmail
          enrollmentMonths
          isAccommodationAvailable
          accommodationUrl
          nearestStation
          founder
          director
          authorizedDate
          establishmentDate
          numberOfFacultyAll
          numberOfFacultyFullTime
          availableLanguageSupport
          intakeCapacity
          numberOfShifts
          screeningProcess
          commentsScreeningProcess
          availableCourseTypes
          availableCourseLength
          schoolTypes
          language
          createtime
          updatetime
          pinStatus
        }
        pagination {
          totalCount
          countPerPage
          activePage
        }
      }
      getCourseList {
        list {
          courseId
          courseStatus
          language
          schoolId
          schoolName
          courseName
          applicationId
          applicationStatus
          isOnline
          courseUrl
          courseType
          courseLengthType
          area
          prefecture
          enrollmentMonths
          japaneseLevel
          englishLevel
          courseOverview
          targetStudents
          visaType
          courseDuration
          contentOfStudy
          applicationPeriod
          courseGoal
          curriculumDescription
          selectionFee
          enrollmentFee
          tutionFee
          otherFee
          totalFee
          createtime
          updatetime
          pinStatus
          langList
        }
        pagination {
          totalCount
          countPerPage
          activePage
        }
      }
    }
  }
`;
export const manageSchoolQ = /* GraphQL */ `
  query ManageSchoolQ(
    $listSchoolStaff: schoolInput
    $listScoutStudent: schoolInput
    $scoutStudentDetail: scoutDetailInput
    $studentDetailInfo: getStudentInput
    $basicInfo: getStudentInput
    $pageOne: getStudentInput
    $pageTwo: getStudentInput
    $pageThree: getStudentInput
    $scoutStatusList: LanguageInput
    $schoolPinStudentList: getSchoolPinStudentInputForList
    $getNotification: getNotfInput
    $listNotification: getNotfInput
  ) {
    manageSchoolQ(
      listSchoolStaff: $listSchoolStaff
      listScoutStudent: $listScoutStudent
      scoutStudentDetail: $scoutStudentDetail
      studentDetailInfo: $studentDetailInfo
      basicInfo: $basicInfo
      pageOne: $pageOne
      pageTwo: $pageTwo
      pageThree: $pageThree
      scoutStatusList: $scoutStatusList
      schoolPinStudentList: $schoolPinStudentList
      getNotification: $getNotification
      listNotification: $listNotification
    ) {
      listSchoolStaff {
        list {
          guid
          firstName
          lastName
          email
          verified
        }
      }
      listScoutStudent {
        list {
          scoutId
          email
          firstName
          lastName
          scoutStatus
          scoutStatusId
          studentGuid
          nationality
          profileImage
          createtime
          isMovedToApplication
        }
        pagination {
          totalCount
          countPerPage
          activePage
        }
      }
      scoutStudentDetail {
        scoutId
        email
        firstName
        lastName
        scoutStatus
        scoutStatusId
        studentGuid
        nationality
        profileImage
        createtime
        isMovedToApplication
      }
      basicInfo {
        firstName
        lastName
        birthday
        email
        phoneNumber
        residenceCountry
        nationality
        placeOfbirth
        currentAddress
        currentOccupation
        isResidenceJapan
        targetStudyStart
        profileImage
        sns
        guid
        snsDetail
        isActive
        studentFindCsa
        englishLangProficiency
        isScoutable
        totalScout
        hasApplied
        scoutStatus
        scoutStatusId
        loginTime
        countryGroup
        profileProgress
        pinCourseCount
        pinSchoolCount
        totalApplication
        voyStudent
        voyEduInfo
        voyWorkInfo
        voyJapaneseStudyExperienceInfo
        voyJapaneseProficiencyInfo
        voyEnglishProficiencyInfo
        voyPlanAfterGraduationInfo
        voyVisaInfo
        voySponsorInfo
        photograph
        passport
        transcriptFromLastSchool
        japaneseLangCertificate
        englishLangCertificate
        employmentCertificate
        depositBalanceCert
      }
      pageOne {
        visaInfo {
          id
          passportNumber
          passportIssueDate
          passportExpiryDate
          previousStay
          updatetime
          createtime
        }
        previousStayJapan {
          id
          dateOfEntry
          dateOfDeparture
          statusOfResidence
          purposeOfStay
          updatetime
          createtime
        }
        friendsFamilyJapan {
          id
          relationship
          name
          dateOfBirth
          nationality
          intendToReside
          placeOfEmploymentOrSchool
          idNumber
          updatetime
          createtime
        }
        maritalStatus {
          id
          partnerName
          dateOfBirth
          nationality
          updatetime
          createtime
        }
      }
      pageTwo {
        education {
          id
          type
          nameOfInstitution
          location
          startDate
          endDate
          years
          createtime
          updatetime
          isCurrentlyEnrolled
        }
        professionalCareer {
          id
          nameOfCompany
          typeOfJob
          location
          startDate
          endDate
          createtime
          updatetime
          isCurrentlyWorking
        }
        japaneseStudyExperience {
          id
          nameOfSchool
          location
          startDate
          endDate
          hours
          createtime
          updatetime
          isNotComplete
        }
        japaneseAbility {
          id
          nameOfExam
          experienceOfTest
          yearOfTest
          proficiencyLevel
          score
          createtime
          updatetime
        }
        englishAbility {
          id
          testName
          yearOfTest
          score
          createtime
          updatetime
        }
        planAfterGraduation {
          id
          type
          studyType
          createtime
          updatetime
        }
        englishLangProficiency
      }
      pageThree {
        sponsorInfo {
          id
          sponsorName
          sponsorAddress
          relationWithApplicant
          sponsorAnnualIncome
          sponsorOccupation
          sponsorEmployer
          updatetime
          createtime
        }
      }
      scoutStatusList {
        statusId
        status
      }
      schoolPinStudentList {
        id
        displayId
        profileImage
        nationality
        japaneseStudyExperience
        isSponsorConfirmed
        japaneseAbility
        numOfDocs
        planAfterGraduation
        numOfScouts
        reasonForApplication
        latestEducation
        scoutStatus
        scoutStatusId
        hasApplied
        pinStatus
        age
      }
      getNotification {
        id
        lang
        guid
        is_read
        message
        redirect_url
        createtime
      }
      listNotification {
        id
        lang
        guid
        is_read
        message
        redirect_url
        createtime
      }
    }
  }
`;
