import { createSlice } from "@reduxjs/toolkit";
import { Spinner } from "reactstrap";

const schoolListSlice = createSlice({
  name: "schoolList",
  initialState: [
    // {
    //   id: "",
    //   name: <Spinner />,
    //   location: <Spinner />,
    //   description: <Spinner />,
    // },
  ],
  reducers: {
    changeSchoolList: (state, { payload }) => {
      console.log("from changeSchoolList reducer");
      state = [...payload];
      return payload;
    },
  },
});

const courseListSlice = createSlice({
  name: "courseList",
  initialState: [],
  reducers: {
    changeCourseList: (state, { payload }) => {
      state = [...payload];
      return payload;
    },
  },
});

export const { changeSchoolList } = schoolListSlice.actions;
export const { changeCourseList } = courseListSlice.actions;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  schoolList: schoolListSlice.reducer,
  courseList: courseListSlice.reducer,
};
