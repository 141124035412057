import { createSlice } from "@reduxjs/toolkit";

const defaultUser = {
  username: "", // username(API)
  email: "", // email(amplify API)
  role: "", // student(default), agent, school (during login screen)
  isOnboard: false, // flag to indicate if the user has filled onboarding form or not. (From API)
  isAuth: false, //flag set after a successful signin and logout(not used functionally)
  isAuthReady: false,
  isRoleReady: false,
};

const userSlice = createSlice({
  name: "user",
  initialState: defaultUser,
  reducers: {
    setAuthReady: (state, { payload }) => {
      state.isAuthReady = payload;
      return state;
    },
    setRoleReady: (state, { payload }) => {
      state.isRoleReady = payload;
      return state;
    },
    loginUser: (state, { payload }) => {
      state.username = payload.username;
      state.email = payload.email;
      state.role = payload.role;
      state.isAuth = true;
      state.isOnboard = payload.isOnboard;

      //TODO: Confirm setting `isAuthReady` and `isRoleReady` here good practice ? as we already have specific reducers
      state.isAuthReady = true;
      state.isRoleReady = true;

      return state;
    },
    logoutUser: (state, { payload }) => {
      localStorage.clear()
      state = { ...defaultUser, isAuthReady: true, isRoleReady: true };

      return state;
    },
    setUserType: (state, { payload }) => {
      state.type = payload;
      return state;
    },
  },
});

// for login info
export const {
  setAuthReady,
  setRoleReady,
  loginUser,
  logoutUser,
  setUserType,
} = userSlice.actions;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  user: userSlice.reducer,
};
