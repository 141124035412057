export default [
  {
    id: "1",
    value: "学生",
    enValue: "Student",
  },
  {
    id: "2",
    value: "短期滞在",
    enValue: "Short−term stay",
  },
  {
    id: "3",
    value: "その他",
    enValue: "Others",
  },
];
