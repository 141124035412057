// export default [
//   // {
//   //   id: "0",
//   //   value: "candidate_submission_pending",
//   //   jp_value: "ドキュメント準備中",
//   // },
//   {
//     id: "1",
//     value: "submitted_by_student",
//     jp_value:
//       // "ドキュメント提出済み"
//       "応募完了",
//   },
//   // {
//   //   id: "2",
//   //   value: "in_review_agent",
//   //   jp_value: "エージェントによるレビュー中",
//   // },
//   // {
//   //   id: "3",
//   //   value: "resubmit_request_to_candidate_by_agent",
//   //   jp_value: "ドキュメントの再提出が必要",
//   // },
//   // {
//   //   id: "4",
//   //   value: "resubmitted_by_student",
//   //   jp_value: "ドキュメント再提出済み",
//   // },
//   // {
//   //   id: "5",
//   //   value: "in_review_agent_",
//   //   jp_value: "エージェントによるレビュー中_",
//   // },
//   // {
//   //   id: "6",
//   //   value: "accepted_by_agent",
//   //   jp_value: "エージェントによるレビュー完了",
//   // },
//   { id: "7", value: "in_review_school", jp_value: "学校によるレビュー中" },
//   {
//     id: "8",
//     value: "resubmit_request_to_candidate_by_school",
//     jp_value: "ドキュメントの再提出が必要",
//   },
//   { id: "9", value: "accepted_by_school", jp_value: "学校によるレビュー完了" },
// ];

export default [
  {
    id: "0",
    value: "submitted_by_student",
    format_en_value: "Application submitted",
    jp_value:
      // "ドキュメント提出済み"
      "応募完了",
  },
  {
    id: "1",
    value: "in_review_school",
    format_en_value: "Under review by school",
    jp_value: "学校によるレビュー中",
  },
  {
    id: "2",
    value: "resubmit_request_to_candidate_by_school",
    format_en_value: "Document update required",
    jp_value: "ドキュメントの再提出が必要",
  },
  {
    id: "3",
    value: "accepted_by_school",
    format_en_value: "Document approved by school",
    jp_value: "学校によるレビュー完了",
  },
];