import {
  faBars, faCaretDown, faTimes, faUser
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { List } from "@mui/material";
import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MultilevelSidebar from "react-multilevel-sidebar";
import "react-multilevel-sidebar/src/Sidebar.css";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import {
  Button,
  Card,
  CardBody,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  NavItem,
  UncontrolledCollapse,
  UncontrolledDropdown
} from "reactstrap";
import logo from "src/assets/images/common/logo.png";
import { getFromLocalAndSet, setNewLanguage, toggleTheme } from "src/state/ducks/ui";
import { logoutUser } from "src/state/ducks/user";
import capitalize from "../../../core/utilities/utils";
import DropdownItem from "./DropdownMenu";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faBookOpen } from "@fortawesome/free-solid-svg-icons";
import { faSchool } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";

const Menubar = (props) => {
  const { email, username, isSchoolPublicScreen } = props;
  const { t, i18n } = useTranslation();
  const { isAuth, role } = useSelector((state) => state.user);
  const [open, setOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const dispatch = useDispatch();
  const languageCode = useSelector((store) => store.language);
  const history = useHistory();

  const handleClick = () => {
    setOpen(false);
  };

  const handleLogout = async () => {
    try {
      localStorage.removeItem("globalSchoolID");
      localStorage.removeItem("getSchoolProfileName");
      localStorage.removeItem("globalSchoolName");
      localStorage.removeItem("localSchoolID");
      localStorage.removeItem("getSchoolProfileGUID");
      localStorage.removeItem("getStudentProfile1");
      localStorage.removeItem("getSchoolProfileID");
      localStorage.removeItem("globalSchoolGuidID");
      localStorage.removeItem("getStudentProfile");
      localStorage.removeItem("addedUserEmail");
      await Auth.signOut();
      dispatch(logoutUser());
      dispatch(toggleTheme("default"));
      history.push(role == "school" ? "/auth/school/signin" : "/signin");

      // history.push("/signin");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };
  const languageToggle = () => setDropdownOpen((prevState) => !prevState);
  const changeLanguage = (langCode) => {
    dispatch(setNewLanguage(langCode));
  };

  useEffect(() => {
    dispatch(getFromLocalAndSet());
  }, []);

  useEffect(() => {
    i18n.changeLanguage(languageCode);
  }, [languageCode]);

  /// TODO: Cleaner code

  const options = [
    ...(!isAuth
      ? [
        ...(!isSchoolPublicScreen
          ? [
            {
              hideBorder: true,
              content: [
                {
                  id: 3,
                  name: (
                    <Link to="/list/courses" onClick={handleClick}>
                      {capitalize(t("courses"))}
                    </Link>
                  ),
                  icon: <i className="fa fa-chrome" />,
                },
              ],
            },
            {
              hideBorder: true,
              content: [
                {
                  id: 3,
                  name: (
                    <Link to="/list/schools" onClick={handleClick}>
                      {capitalize(t("schools"))}
                    </Link>
                  ),
                  icon: <i className="fa fa-chrome" />,
                },
              ],
            },
          ]
          : []),
      ]
      : [
        ...(role != "school"
          ? [
            {
              hideBorder: true,
              content: [
                {
                  id: 3,
                  name: (
                    <Link className="pl-4" to="/student/dashboard" onClick={handleClick}>
                      Dashboard
                    </Link>
                  ),
                  icon: <FontAwesomeIcon className="fs-5" icon={faHome} />,
                },
              ],
            },
            {
              hideBorder: true,
              content: [
                {
                  id: 3,
                  name: (
                    <Link className="pl-4" to="/list/courses" onClick={handleClick}>
                      {capitalize(t("courses"))}
                    </Link>
                  ),
                  icon: <FontAwesomeIcon className="fs-5" icon={faBookOpen} />,
                },
              ],
            },
            {
              hideBorder: true,
              content: [
                {
                  id: 3,
                  name: (
                    <Link className="pl-4" to="/list/schools" onClick={handleClick}>
                      {capitalize(t("schools"))}
                    </Link>
                  ),
                  icon: <FontAwesomeIcon className="fs-5" icon={faSchool} />,
                },
              ],
            },
            {
              hideBorder: true,
              content: [
                {
                  id: 4,
                  name: (
                    <Link className="pl-4" to="/applications" onClick={handleClick}>
                      {capitalize(t("applications"))}
                    </Link>
                  ),
                  icon: <FontAwesomeIcon className="fs-5" icon={faList} />,
                },
              ],
            },
          ]
          : [
            {
              hideBorder: true,
              content: [
                {
                  id: 3,
                  name: (
                    <Link to="/school/dashboard" onClick={handleClick}>
                      {/* My Courses */}
                      ダッシュボード
                    </Link>
                  ),
                  icon: <i className="fa fa-chrome" />,
                },
              ],
            },
            {
              hideBorder: true,
              content: [
                {
                  id: 3,
                  name: (
                    <Link to="/school/courses" onClick={handleClick}>
                      {/* My Courses */}
                      コース管理
                    </Link>
                  ),
                  icon: <i className="fa fa-chrome" />,
                },
              ],
            },
            {
              hideBorder: true,
              content: [
                {
                  id: 3,
                  name: (
                    <Link to="/school/applications" onClick={handleClick}>
                      {/* Applications */}
                      応募管理
                    </Link>
                  ),
                  icon: <i className="fa fa-chrome" />,
                },
              ],
            },
            {
              hideBorder: true,
              content: [
                {
                  id: 4,
                  name: (
                    <Link to="/school/search/student" onClick={handleClick}>
                      {/* Student Search */}
                      学生検索
                    </Link>
                  ),
                  icon: <i className="fa fa-chrome" />,
                },
              ],
            },
          ]),
      ]),
    ...(!isAuth
      ? [
        // EVENTS
        {
          hideBorder: true,
          content: [
            {
              id: 2,
              name: (
                <Link
                  to="/list/blog"
                  onClick={handleClick}
                >
                  Blogs
                </Link>
              ),
              icon: <i className="fa fa-chrome" />,
            },
          ],
        },
        // BLOGS
        {
          hideBorder: true,
          content: [
            {
              id: 2,
              name: (
                <Link
                  to="/list/event"
                  onClick={handleClick}
                >
                  Events
                </Link>
              ),
              icon: <i className="fa fa-chrome" />,
            },
          ],
        },

        // ABOUT US
        {
          hideBorder: true,
          content: [
            {
              id: 3,
              name: (
                <>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={languageToggle}
                    className="language-toggle"
                  >
                    <DropdownToggle
                      className="menubar-outline"
                      style={{ border: "none" }}
                    >
                      <div
                        style={{ marginLeft: "-40px" }}
                        body
                        className="text-primary"
                      >
                        <span
                          style={{
                            color: "#495057",
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          About Us
                        </span>
                      </div>
                      <div>
                        <FontAwesomeIcon
                          style={{ color: "black", marginLeft: "10px" }}
                          icon={faCaretDown}
                        />
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="p-0">
                      <NavItem>
                        <a href="https://workers.connectiu.com"
                          target="_blank"
                          rel="noreferrer">
                          CJ/CJW
                        </a>
                      </NavItem>
                      <NavItem>
                        <a href="https://www.4th-valley.com"
                          target="_blank"
                          rel="noreferrer">
                          About Us
                        </a>
                      </NavItem>
                      <NavItem>
                        <a href="https://nihongo.connectiu.com"
                          target="_blank"
                          rel="noreferrer">
                          Japanese Language
                        </a>
                      </NavItem>
                      <NavItem>
                        <HashLink
                          to="/#faq_section"
                          onClick={handleClick}
                        >FAQs</HashLink>

                      </NavItem>
                    </DropdownMenu>
                  </Dropdown>
                </>
              ),
              icon: <i className="fa fa-chrome" />,
            },
          ],
        },
        // INQUIRY STUDENTS
        {
          hideBorder: true,
          content: [
            {
              id: "contact",
              name: (
                <a
                  href="https://support.connectiu.com/hc/en-us/requests/new"
                  className="link-dark"
                  target="_blank"
                  rel="noreferrer">
                  Inquiry ( For Students )
                </a>
              ),
              icon: <i className="fa fa-chrome" />,
            },
          ],
        },
        // INQUIRY SCHOOL
        {
          hideBorder: true,
          content: [
            {
              id: "contact",
              name: (
                <Link
                  to="/business-inquiry-form"
                  className="link-dark"
                >
                  Inquiry ( For Schools )
                </Link>
              ),
              icon: <i className="fa fa-chrome" />,
            },
          ],
        },

        {
          hideBorder: true,
          content: [
            {
              id: 3,
              name: (
                <Link
                  to={
                    isSchoolPublicScreen ? "/auth/school/signup" : "/signup"
                  }
                  onClick={handleClick}
                >
                  {capitalize(t("sign_up"))}
                </Link>
              ),
              icon: <i className="fa fa-chrome" />,
            },
          ],
        },
        {
          hideBorder: true,
          content: [
            {
              id: 3,
              name: (
                <Link
                  to={
                    isSchoolPublicScreen ? "/auth/school/signin" : "/signin"
                  }
                  onClick={handleClick}
                >
                  {capitalize(t("Login"))}
                </Link>
              ),
              icon: <i className="fa fa-chrome" />,
            },
          ],
        },
      ]
      : [
        {
          hideBorder: true,
          content: [
            {
              id: 3,
              name: (
                <>
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={languageToggle}
                    className="language-toggle"

                  >
                    <DropdownToggle
                      className="menubar-outline pl-4 border-0"

                    >
                      <div
                        style={{ width: "300px" }}
                        body
                        className="text-primary text-left"
                      >{t("profile")}

                      </div>
                      <div>
                        <FontAwesomeIcon
                          style={{ color: "black", marginLeft: "10px" }}
                          icon={faCaretDown}
                        />
                      </div>
                    </DropdownToggle>

                    {role == "school" && (
                      <DropdownItem
                        toggle={toggle}
                        profileTitle=" プロフィール"
                        profileLink="/school/profile"
                        changePasswordTitle=" パスワードを変更"
                        changePasswordLink="/auth/school/changepassword"
                        logoutTitle=" ログアウト"
                        logoutAction={handleLogout}
                      />
                    )}

                    {role != "school" && (
                      <DropdownItem
                        toggle={toggle}
                        profileTitle={t("profile")}
                        profileLink="/student/profile"
                        changePasswordTitle={t("change_password")}
                        changePasswordLink="/changepassword"
                        logoutTitle={t("logout")}
                        logoutAction={handleLogout}
                      />
                    )}
                  </Dropdown>
                </>
              ),
              icon: <FontAwesomeIcon className="fs-5" icon={faUser} />,
            },
          ],
        },
      ]),
  ];

  return (
    <>
      <MultilevelSidebar
        open={open}
        options={options}
        wrapperClassName="header-menu-bar"
        onToggle={handleClick}
        header={
          <div className="text-left pl-4 pb-2 d-flex justify-content-between menu-header">
            <Link to="/">
              <img
                src={logo}
                alt="menu-logo"
                className="my-1"
                height="60"
              />
            </Link>
            <Button
              onClick={() => setOpen(false)}
              className="bg-white border-0 p-0 pr-3"
            >

              <FontAwesomeIcon
                className="text-white menu-bar-cancel"
                icon={faTimes}
              />
            </Button>
          </div>
        }
      />
      {/* using in our button to open the sidebar */}
      <Button
        onClick={() => setOpen(true)}
        className="bg-white border-0 d-xl-none d-lg-none d-inline-block text-primary pl-0 p-lg-5 pr-1"
      >
        <FontAwesomeIcon icon={faBars} />
      </Button>
    </>
  );
};

export default Menubar;
