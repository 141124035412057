import { API } from "aws-amplify";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { Spinner } from "reactstrap";
import StudentDetailAnchorLink from "src/components/StudentDetailAnchorLink";
import { getLangCodeForApiCall } from "src/core/utilities/utils";
import { getMasterData, manageSchoolQ } from "src/graphql/queries";
import withHeader from "src/services/with_header_footer";
import AboutSponsor from "../../ApplicationDetail/StudentsApplication/AboutSponsor";
import EducationBackground from "../../ApplicationDetail/StudentsApplication/EducationBackground";
import ExperienceJapanese from "../../ApplicationDetail/StudentsApplication/ExperienceJapanese";
import JapaneseLanguage from "../../ApplicationDetail/StudentsApplication/JapaneseLanguage";
import EnglishLanguage from "../../ApplicationDetail/StudentsApplication/EnglishLanguage";
import MaritalStatus from "../../ApplicationDetail/StudentsApplication/MaritalStatus";
import PassportInformation from "../../ApplicationDetail/StudentsApplication/PassportInformation";
import PlanGraduation from "../../ApplicationDetail/StudentsApplication/PlanGraduation";
import ProfessionalCareer from "../../ApplicationDetail/StudentsApplication/ProfessionalCareer";
import RelativeInJapan from "../../ApplicationDetail/StudentsApplication/RelativeInJapan";
import ScoutPersonalInfo from "../../ApplicationDetail/StudentsApplication/ScoutPersonalInfo";
import EnglishLevel from "../../ApplicationDetail/StudentsApplication/EnglishLevel";

const StudentSearchDetail = () => {
  const { t, i18n } = useTranslation();
  const { applicationId } = useParams();
  const [error, setError] = useState("");
  const languageCode = useSelector((store) => store.language);
  const [isLoading, setIsLoading] = useState(true);
  /// ===================== Master Data  =====================
  const [masterNationality, setMasterNationality] = useState([]);
  const [masterSchoolTypes, setMasterSchoolTypes] = useState([]);
  const [masterGender, setMasterGender] = useState([]);
  const [masterJapaneseExam, setMasterJapaneseExam] = useState([]);
  const [masterJapaneseExamLevel, setMasterJapaneseExamLevel] = useState([]);
  const [masterExamExperience, setMasterExamExperience] = useState([]);
  const [masterExamResult, setMasterExamResult] = useState([]);
  const [masterSNS, setMasterSNS] = useState([]);
  const [masterPlanAfterGraduation, setMasterPlanAfterGraduation] = useState(
    []
  );
  const [masterStudyType, setMasterStudyType] = useState([]);
  const [masterEnglishLevel, setMasterEnglishLevel] = useState([]);
  const { id } = useParams();
  // Get Search Parameters
  const location = useLocation();

  /// ===================== Student Profile  =====================
  const [personalInfo, setPersonalInfo] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    birthday: "",
    nationality: "",
    isResidenceJapan: "",
    sns: "",
    snsDetail: "",
    guid: "",
    hasApplied: false,
    scoutStatus: "",
    loginTime: "",
    profileImage: ""
  });
  /// ===================== Page 1  =====================
  const [passportInfo, setPassportInfo] = useState({
    id: null,
    passportNumber: "",
    passportExpiryDate: "",
    passportIssueDate: "",
    previousStay: "",
  });
  const [previousStayJapanese, setPreviousStayJapanese] = useState([
    {
      id: null,
      dateOfEntry: "",
      dateOfDeparture: "",
      statusOfResidence: "",
      purposeOfStay: "",
    },
  ]);
  const [friendsFamilyJapanese, setFriendsFamilyJapanese] = useState([
    {
      id: null,
      relationship: "",
      name: "",
      dateOfBirth: "",
      nationality: "",
      intendToReside: "",
      placeOfEmploymentOrSchool: "",
      idNumber: "",
    },
  ]);
  const [maritalStatuses, setMaritalStatuses] = useState([
    {
      id: null,
      partnerName: "",
      nationality: "",
      dateOfBirth: "",
    },
  ]);
  /// ===================== Page 2  =====================
  const [educations, setEducations] = useState([
    {
      id: null,
      type: "",
      nameOfInstitution: "",
      location: "",
      startDate: "",
      endDate: "",
      years: "",
    },
  ]);
  const [professionalCareers, setProfessionalCareers] = useState([
    {
      id: null,
      nameOfCompany: "",
      typeOfJob: "",
      location: "",
      startDate: "",
      endDate: "",
    },
  ]);
  const [studyExperiences, setStudyExperiences] = useState([
    {
      id: null,
      nameOfSchool: "",
      location: "",
      startDate: "",
      endDate: "",
      hours: "",
    },
  ]);
  const [japaneseProficiencies, setJapaneseProficiencies] = useState([
    {
      id: null,
      nameOfExam: "",
      experienceOfTest: "",
      yearOfTest: "",
      proficiencyLevel: "",
      // TODO:
      // result: "",
      score: "",
    },
  ]);
  const [englishProficiencies, setEnglishProficiencies] = useState([
    {
      id: null,
      nameOfExam: "",
      experienceOfTest: "",
      yearOfTest: "",
      proficiencyLevel: "",
      // TODO:
      // result: "",
      score: "",
    },
  ]);

  const [englishAbility, setEnglishAbility] = useState({
    id: null,
    type: "",
    studyType: "",
  });

  const [planAfterGraduation, setPlanAfterGraduation] = useState({
    id: null,
    type: "",
    studyType: "",
  });

  /// ===================== Page 3  =====================
  const [sponsorInfo, setSponsorInfo] = useState({
    id: null,
    sponsorName: "",
    sponsorAddress: "",
    // TODO: master data for relationWithApplicant
    relationWithApplicant: "",
    sponsorAnnualIncome: "",
    sponsorOccupation: "",
    sponsorEmployer: "",
  });

  /// ===================== GraphQL queries operations =====================
  const fetchMasterData = async () => {
    try {
      const masterDataFromAPI = await API.graphql({
        query: getMasterData,
        variables: {
          masterdata: {
            countryList: true,
            voySchoolTypesList: true,
            snsList: true,
            genderList: true,
            japaneseExamList: true,
            japaneseExamLevelList: true,
            examExperienceList: true,
            examResultList: true,
            voyPlanAfterGraduationList: true,
            voyStudyType: true,
            englishLevelList: true,
            language: getLangCodeForApiCall(languageCode),
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const masterData = masterDataFromAPI.data.getMasterData;
      if (masterData != null && Array.isArray(masterData)) {
        let countryList = masterData.filter(
          (item) => item.type == "countryList"
        )[0].items;
        setMasterNationality(countryList);

        let snsList = masterData.filter((item) => item.type == "snsList")[0]
          .items;
        setMasterSNS(snsList);

        let voySchoolTypesList = masterData.filter(
          (item) => item.type == "voySchoolTypesList"
        )[0].items;
        setMasterSchoolTypes(voySchoolTypesList);

        let genderList = masterData.filter(
          (item) => item.type == "genderList"
        )[0].items;
        setMasterGender(genderList);

        let japaneseExamList = masterData.filter(
          (item) => item.type == "japaneseExamList"
        )[0].items;
        setMasterJapaneseExam(japaneseExamList);

        let japaneseExamLevelList = masterData.filter(
          (item) => item.type == "japaneseExamLevelList"
        )[0].items;
        setMasterJapaneseExamLevel(japaneseExamLevelList);

        let examExperienceList = masterData.filter(
          (item) => item.type == "examExperienceList"
        )[0].items;
        setMasterExamExperience(examExperienceList);

        let examResultList = masterData.filter(
          (item) => item.type == "examResultList"
        )[0].items;
        setMasterExamResult(examResultList);

        let voyPlanAfterGraduationList = masterData.filter(
          (item) => item.type == "voyPlanAfterGraduationList"
        )[0].items;
        setMasterPlanAfterGraduation(voyPlanAfterGraduationList);

        let voyStudyTypeList = masterData.filter(
          (item) => item.type == "voyStudyType"
        )[0].items;
        setMasterStudyType(voyStudyTypeList);

        let voyEnglishLevelList = masterData.filter(
          (item) => item.type == "englishLevelList"
        )[0].items;
        setMasterEnglishLevel(voyEnglishLevelList);
      }
    } catch (err) {
      console.log(err);
    }
  };
  // fetch Basic Info
  const fetchBasicInfo = async () => {
    try {
      const getStudentDetail = await API.graphql({
        query: manageSchoolQ,
        variables: {
          basicInfo: {
            guid: id,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log(
        "getStudentDetail: ",
        getStudentDetail.data.manageSchoolQ.basicInfo
      );
      const data = getStudentDetail.data.manageSchoolQ.basicInfo;
      let personalInfoFromData = {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        birthday: "",
        nationality: "",
        isResidenceJapan: "",
        sns: "",
        snsDetail: "",
        hasApplied: false,
        scoutStatus: "",
        loginTime: "",
        profileImage: ""
      };
      if (data.firstName) {
        personalInfoFromData.firstName = data.firstName;
      }

      if (data.birthday) {
        personalInfoFromData.birthday = data.birthday;
      }

      if (data.lastName) {
        personalInfoFromData.lastName = data.lastName;
      }
      if (data.email) {
        personalInfoFromData.email = data.email;
      }
      if (data.phoneNumber) {
        personalInfoFromData.phoneNumber = data.phoneNumber;
      }
      if (data.guid) {
        personalInfoFromData.guid = data.guid;
      }
      if (data.sns) {
        personalInfoFromData.sns = data.sns;
      }
      if (data.hasApplied) {
        personalInfoFromData.hasApplied = data.hasApplied;
      }
      if (data.snsDetail) {
        personalInfoFromData.snsDetail = data.snsDetail;
      }
      if (data.isResidenceJapan) {
        personalInfoFromData.isResidenceJapan = data.isResidenceJapan;
      }

      if (data.nationality) {
        personalInfoFromData.nationality = data.nationality;
      }
      if (data.scoutStatus) {
        personalInfoFromData.scoutStatus = data.scoutStatus;
      }

      if (data.loginTime) {
        personalInfoFromData.loginTime = data.loginTime
      }
      if (data.profileImage) {
        personalInfoFromData.profileImage = data.profileImage
      }

      setPersonalInfo(personalInfoFromData);

      setEnglishAbility({
        ...englishAbility,
        type: data.englishLangProficiency,
      });


    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // fetch Page One
  const fetchPageOne = async () => {
    try {
      const getPageOne = await API.graphql({
        query: manageSchoolQ,
        variables: {
          pageOne: {
            guid: id,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("get Page One: ", getPageOne.data.manageSchoolQ.pageOne);
      const pageOneData = getPageOne.data.manageSchoolQ.pageOne;
      //   Page 1
      const visaInfoData = pageOneData.visaInfo;
      if (visaInfoData) {
        let newVisaInfo = {
          id: visaInfoData.id,
          passportNumber: visaInfoData.passportNumber,
          passportExpiryDate: visaInfoData.passportExpiryDate,
          passportIssueDate: visaInfoData.passportIssueDate,
          previousStay: visaInfoData.previousStay,
        };
        setPassportInfo(newVisaInfo);
      }

      const previousStayJapanData = pageOneData.previousStayJapan;
      if (
        previousStayJapanData != null &&
        Array.isArray(previousStayJapanData)
      ) {
        let newPreviousStayJapanese = [
          ...previousStayJapanData.map((previousStayJapan) => {
            return {
              id: previousStayJapan.id,
              dateOfEntry: previousStayJapan.dateOfEntry,
              dateOfDeparture: previousStayJapan.dateOfDeparture,
              statusOfResidence: previousStayJapan.statusOfResidence,
              purposeOfStay: previousStayJapan.purposeOfStay,
            };
          }),
        ];
        setPreviousStayJapanese(newPreviousStayJapanese);
      }

      const friendsFamilyJapanData = pageOneData.friendsFamilyJapan;
      if (
        friendsFamilyJapanData != null &&
        Array.isArray(friendsFamilyJapanData)
      ) {
        let newFriendsFamilyJapanese = [
          ...friendsFamilyJapanData.map((friendsFamilyJapan) => {
            return {
              id: friendsFamilyJapan.id,
              relationship: friendsFamilyJapan.relationship,
              name: friendsFamilyJapan.name,
              dateOfBirth: friendsFamilyJapan.dateOfBirth,
              nationality: friendsFamilyJapan.nationality,
              intendToReside: friendsFamilyJapan.intendToReside,
              placeOfEmploymentOrSchool:
                friendsFamilyJapan.placeOfEmploymentOrSchool,
              idNumber: friendsFamilyJapan.idNumber,
            };
          }),
        ];

        setFriendsFamilyJapanese(newFriendsFamilyJapanese);
      }

      const maritalStatusData = pageOneData.maritalStatus;
      if (maritalStatusData != null && Array.isArray(maritalStatusData)) {
        let newMaritalStatus = [
          ...maritalStatusData.map((maritalStatus) => {
            return {
              id: maritalStatus.id,
              partnerName: maritalStatus.partnerName,
              nationality: maritalStatus.nationality,
              dateOfBirth: maritalStatus.dateOfBirth,
            };
          }),
        ];

        setMaritalStatuses(newMaritalStatus);
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // fetch Page Two
  const fetchPageTwo = async () => {
    try {
      const getPageTwo = await API.graphql({
        query: manageSchoolQ,
        variables: {
          pageTwo: {
            guid: id,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const pageTwoData = getPageTwo.data.manageSchoolQ.pageTwo;
      //   Page 2
      const educationData = pageTwoData.education;
      if (educationData != null && Array.isArray(educationData)) {
        let newEducations = [
          ...educationData.map((education) => {
            return {
              id: education.id,
              type: education.type,
              nameOfInstitution: education.nameOfInstitution,
              location: education.location,
              startDate: education.startDate,
              endDate: education.endDate,
              years: education.years,
            };
          }),
        ];
        setEducations(newEducations);
      }

      const professionalCareerData = pageTwoData.professionalCareer;
      if (
        professionalCareerData != null &&
        Array.isArray(professionalCareerData)
      ) {
        let newProfessionalCareers = [
          ...professionalCareerData.map((professionalCareer) => {
            return {
              id: professionalCareer.id,
              nameOfCompany: professionalCareer.nameOfCompany,
              typeOfJob: professionalCareer.typeOfJob,
              location: professionalCareer.location,
              startDate: professionalCareer.startDate,
              endDate: professionalCareer.endDate,
            };
          }),
        ];

        setProfessionalCareers(newProfessionalCareers);
      }

      const japaneseStudyExperienceData = pageTwoData.japaneseStudyExperience;
      if (
        japaneseStudyExperienceData != null &&
        Array.isArray(japaneseStudyExperienceData)
      ) {
        let newjapaneseStudyExperiences = [
          ...japaneseStudyExperienceData.map((japaneseStudyExperience) => {
            return {
              id: japaneseStudyExperience.id,
              nameOfSchool: japaneseStudyExperience.nameOfSchool,
              location: japaneseStudyExperience.location,
              startDate: japaneseStudyExperience.startDate,
              endDate: japaneseStudyExperience.endDate,
              hours: japaneseStudyExperience.hours,
            };
          }),
        ];

        setStudyExperiences(newjapaneseStudyExperiences);
      }

      const japaneseAbilityData = pageTwoData.japaneseAbility;
      if (japaneseAbilityData != null && Array.isArray(japaneseAbilityData)) {
        let newJapaneseProficiencies = [
          ...japaneseAbilityData.map((japaneseAbility) => {
            return {
              id: japaneseAbility.id,
              nameOfExam: japaneseAbility.nameOfExam,
              experienceOfTest: japaneseAbility.experienceOfTest,
              yearOfTest: japaneseAbility.yearOfTest,
              proficiencyLevel: japaneseAbility.proficiencyLevel,
              score: japaneseAbility.score,
            };
          }),
        ];

        setJapaneseProficiencies(newJapaneseProficiencies);
      }
      const englishAbilityData = pageTwoData.englishAbility;
      if (englishAbilityData != null && Array.isArray(englishAbilityData)) {
        let newEnglishProficiencies = [
          ...englishAbilityData.map((japaneseAbility) => {
            return {
              id: japaneseAbility.id,
              nameOfExam: japaneseAbility.testName,
              experienceOfTest: japaneseAbility.experienceOfTest,
              yearOfTest: japaneseAbility.yearOfTest,
              proficiencyLevel: japaneseAbility.proficiencyLevel,
              score: japaneseAbility.score,
            };
          }),
        ];

        setEnglishProficiencies(newEnglishProficiencies);
      }
      const planAfterGraduationData = pageTwoData.planAfterGraduation;
      if (planAfterGraduationData != null) {
        setPlanAfterGraduation({
          id: planAfterGraduationData.id,
          type: planAfterGraduationData.type,
          studyType: planAfterGraduationData.studyType,
        });
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  // fetch Page Three
  const fetchPageThree = async () => {
    try {
      const getPageThree = await API.graphql({
        query: manageSchoolQ,
        variables: {
          pageThree: {
            guid: id,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("getPageThree: ", getPageThree.data.manageSchoolQ.pageThree);
      const pageThreeData = getPageThree.data.manageSchoolQ.pageThree;
      //   page 3
      const sponsorData = pageThreeData.sponsorInfo;
      if (sponsorData) {
        setSponsorInfo({
          id: sponsorData.id,
          sponsorName: sponsorData.sponsorName,
          sponsorAddress: sponsorData.sponsorAddress,
          relationWithApplicant: sponsorData.relationWithApplicant,
          sponsorAnnualIncome: sponsorData.sponsorAnnualIncome,
          sponsorOccupation: sponsorData.sponsorOccupation,
          sponsorEmployer: sponsorData.sponsorEmployer,
        });
      }
    } catch (err) {
      console.log(err);
      throw err;
    }
  };

  useEffect(async () => {
    setIsLoading(true);
    try {
      await fetchMasterData();
      // All following results are dependent upon master data so, await master data query
      await Promise.all([
        fetchBasicInfo(),
        fetchPageOne(),
        fetchPageTwo(),
        fetchPageThree(),
      ]);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      setError(err);
      setError(t("something_went_wrong"));
    }
    // }, [languageCode]);
  }, []);

  return (
    <div
      style={{
        paddingTop: "50px",
        paddingRight: "30px",
        paddingLeft: "30px",
        minHeight: "600px",
      }}
    >
      <div className="container ">
        <div className="row">
          <div className="col-3">
            <StudentDetailAnchorLink />
          </div>
          <div className="col-1"></div>
          <div className="col-7">
            {isLoading ? (
              <center>

                <Spinner />
              </center>
            ) : (
              <>
                <div>
                  <ScoutPersonalInfo
                    personalInfo={personalInfo}
                    masterNationality={masterNationality}
                    masterSNS={masterSNS}
                  />
                </div>
                <div className="app-admsn-form">
                  <PassportInformation
                    passportInfo={passportInfo}
                    previousStayJapanese={previousStayJapanese}
                  />
                </div>
                {/* <div className="app-admsn-form">
                  <RelativeInJapan
                    friendsFamilyJapanese={friendsFamilyJapanese}
                    masterNationality={masterNationality}
                  />
                </div>
                <div className="app-admsn-form">
                  <MaritalStatus
                    maritalStatuses={maritalStatuses}
                    masterNationality={masterNationality}
                  />
                </div> */}
                <div className="app-admsn-form">
                  <EducationBackground
                    educations={educations}
                    masterSchoolTypes={masterSchoolTypes}
                  />
                </div>
                <div className="app-admsn-form">
                  <ProfessionalCareer
                    professionalCareers={professionalCareers}
                  />
                </div>
                <div className="app-admsn-form">
                  <ExperienceJapanese studyExperiences={studyExperiences} />
                </div>
                <div className="app-admsn-form">
                  <JapaneseLanguage
                    japaneseProficiencies={japaneseProficiencies}
                    masterJapaneseExam={masterJapaneseExam}
                    masterExamExperience={masterExamExperience}
                    masterJapaneseExamLevel={masterJapaneseExamLevel}
                    masterExamResult={masterExamResult}
                  />
                </div>
                <div className="app-admsn-form">
                  <EnglishLanguage
                    japaneseProficiencies={englishProficiencies}
                    masterJapaneseExam={masterJapaneseExam}
                    masterExamExperience={masterExamExperience}
                    masterJapaneseExamLevel={masterJapaneseExamLevel}
                    masterExamResult={masterExamResult}
                  />
                </div>
                <div className="app-admsn-form">
                  <EnglishLevel
                    planAfterGraduation={englishAbility}
                    masterPlanAfterGraduation={masterEnglishLevel}
                    masterStudyType={masterStudyType}
                  />
                </div>
                <div className="app-admsn-form">
                  <PlanGraduation
                    planAfterGraduation={planAfterGraduation}
                    masterPlanAfterGraduation={masterPlanAfterGraduation}
                    masterStudyType={masterStudyType}
                  />
                </div>
                <div className="app-admsn-form">
                  <AboutSponsor sponsorInfo={sponsorInfo} />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withHeader(StudentSearchDetail);
