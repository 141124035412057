export default {
  terms_of_service: "#",
  privacy_policy: "https://www.4th-valley.com/privacypolicy/",
  connect_study_nihongo: "https://nihongo.connectiu.com/",
  connect_job_workers: "https://workers.connectiu.com/",
  connect_job: "https://job.connectiu.com/",
  zendesk_inquiry: "https://support.connectiu.com/hc/en-us/requests/new",
  be_good_japan:
    "https://bgj.co.jp/?utm_source=4v&utm_medium=banner&utm_campaign=csa",
};
