export default [
  {
    id: "",
    value: "以下から選択してください",
    enValue: "Select one",
  },
  {
    id: "1",
    value: "3ヶ月",
    enValue: "3 months",
  },
  {
    id: "2",
    value: "6ヶ月",
    enValue: "6 months",
  },
  {
    id: "3",
    value: "9ヶ月",
    enValue: "9 months",
  },
  {
    id: "4",
    value: "12ヶ月",
    enValue: "12 months",
  },
  {
    id: "5",
    value: "1年3ヶ月",
    enValue: "1 year and 3 months",
  },
  {
    id: "6",
    value: "1年6ヶ月",
    enValue: "1 year and 6 months",
  },
  {
    id: "7",
    value: "1年9ヶ月",
    enValue: "1 year and 9 months",
  },
  {
    id: "8",
    value: "2年",
    enValue: "2 years",
  },
  {
    id: "9",
    value: "他",
    enValue: "Others",
  },
];
