// eslint-disable-next-line import/no-anonymous-default-export
export default {
  language_code: "ja",
  language_title: "日本語",
  welcome_to_connect: "接続へようこそ",
  Login: "ログイン",
  school_login_title: "学校 ログイン",
  login_btn: "ログインする",
  sign_up: "アカウント登録",
  enter_your_email: "メール アドレス を 入力してください。",
  enter_your_password: "パスワード を 入力してください。",
  school_signup_title: "学校 サインアップ",
  signup_btn: "サインアップする",
  logout: "ログアウト",
  change_password: "パスワードを変更",
  current_password: "現在のパスワード",
  new_password: "新しいパスワード",
  confirm_password: "パスワード の 確認",
  incorrect_password: "現在のパスワードが正しくありません",
  forgot_password: "パスワードを忘れました?",
  valid_password:
    "パスワードは 大文字1文字以上、小文字1文字以上、数字1文字以上、8文字以上 で つくってください。",
  login_for_school_only_msg:
    "申し訳ありません。こちらのログインフォームは学校専用です。",
  login_for_student_only_msg:
    "申し訳ありません。こちらのログインフォームは学生専用です。",
  home: "ホーム",
  School: "学校",
  Dashboard: "ダッシュボード",
  blog: "ブログ",
  Contact: "連絡先",
  schools: "学校",
  courses: "コース",
  applications: "応募",
  about_us: "私たちについて",
  contact_us: "お問い合わせ",
  profile: "プロフィール",
  for_inquiries: "お問い合わせはこちら",
  inquiry_form: "学生向けお問い合せフォーム",
  for_business_inquiries: "サービス利用に関するお問い合わせはこちら",
  business_inquiry_form: "学校・エージェント向けお問い合せフォーム",
  submit: "送信する",
  language: "言語",
  view_all: "全部見る",
  our_services: "サービス内容",
  course_search: "コース検索",
  school_search: "学校検索",
  our_other_services: "その他のサービス",
  privacy_policy: "プライバシーポリシー",
  terms_of_service: "利用規約",
  page_not_found: "申し訳ありません。お探しのページは見つかりませんでした。",
  student_tag_line_heading: "日本留学×日本就職!",
  student_tag_line_subheading: "自分に合ったオンリーワンの日本留学をデザイン",
  find_best_matched_course: "自分にあった日本語コースを探す",
  recommended_schools_msg: "おすすめの日本語学校",
  popular_schools: "おすすめの日本語学校",
  statistical_facts: "私たちのサービス",
  courses_listed: "登録コース数",
  schools_listed: "登録学校数",
  no_results_found:
    "申し訳ありません。選択した条件にあった情報は見つかりませんでした。",
  go_to_home: "ホームにもどる",
  office_address: "会社所在地",
  read_more: "もっと読む",
  phone_number: "電話番号",
  something_went_wrong: "問題が発生しました",
  area: {
    title: "地域",
    value: {
      hokkaido: "北海道",
      tohoku: "東北",
      kanto: "関東",
      chubu: "中部",
      kansai: "関西",
      chugoku: "中国",
      shikoku: "四国",
      kyushu: "九州",
    },
  },
  course_type: {
    title: "コース種類",
    value: {
      general_japanese: "一般日本語",
      university_preparation: "大学進学準備",
      business_employment: "ビジネス就職",
      others: "その他",
    },
  },
  course_duration: {
    title: "コース期間",
    value: {
      short: "短期",
      long: "長期",
    },
  },
  enrollment_month: {
    title: "入学時期",
    value: {
      april: "4月",
      july: "7月",
      october: "10月",
      january: "1月",
    },
  },
  japanese_level: {
    title: "日本語レベル",
    value: {
      beginner: "初級",
      intermediate: "中級",
      advance: "上級",
    },
  },
  english_level: {
    title: "英語レベル",
    value: {
      beginner: "ベーシック",
      intermediate: "ネイティブ",
      advance: "ネイティブ",
    },
  },
  course_info: {
    value: {
      course_name: "コース名",
      course_outline: "コース概要",
      course_description: "コース説明",
      applicable_student: "対象学生",
      visa_type: "ビザ種類",
      beginning_period: "開始時期",
      learning_content: "学習内容",
      application_period: "募集期間",
      curriculum: "カリキュラム",
      course_goal: "コース目標",
      curriculum_description: "カリキュラム詳細",
      tuition: "学費(円)",
      screening_fee: "選考料",
      enrollment_fee: "入学金",
      class_fee: "授業料",
      facility_fee: "設備費",
      total: "総額",
      tuition_fee_notes: "コース費用について（注釈）",
    },
  },
  school_info: {
    value: {
      school_name: "学校名",
      school_logo: "school logo",
      school_type: "学校種別",
      available_course_type: "コース種類",
      available_course_length: "コース期間",
      school_introduction: "学校紹介",
      basic_information: "学校基本情報",
      location: "所在地",
      nearest_station: "最寄り駅",
      phone_number: "電話番号",
      fax_number: "FAX番号",
      school_website: "ホームページ",
      email: "email",
      founder: "創設者",
      director: "校長",
      authorized_date: "認可年月日",
      establishmentDate: "設立年月日",
      faculty_staff: "講師・スタッフ",
      num_of_faculty_full_time: "講師数（専任）",
      assisstant_for_housing: "家探しのアシスタント",
      foreign_speaking_staff: "外国語のできるスタッフ",
      students: "学生",
      quota_of_school: "定員",
      total_num_students: "学生総数",
      major_countries_students: "主な国籍",
      // grads_this_year: "2020年3月 卒業予定者数",
      grads_this_year: "今年度卒業予定者数",
      // grads_last_year: "昨年の卒業生数",
      grads_last_year: "昨年度卒業数",
      after_graduation: "主な進学先",
      // jlpt_passer: "2019年12月 日本語能力試験受験者数（合格者数）",
      jlpt_passer: "昨年度日本語能力試験受験者数",
      admission_selection: "入学選考",
      selection_method: "選考方法",
      application_fee: "選考料",
      deadline: "締め切り",
      mailing_address: "郵送先住所",
      pr_school: "学校より",
      message_from_school: "学校からのメッセージ",
      images: "写真",
      videos: "動画",
      school_year_calendar: "年間の活動内容・イベントなど",
      scholarship_if_available: "奨学金情報（もしあれば）",
      facility: "設備",
    },
  },
  confirm_account_modal_header: "アカウント認証を行ってください",
  confirm_account_modal_body:
    "ログインに失敗しました。メールによる認証を行ってください。",
  confirm: "認証する",
  cancel: "キャンセル",
  email: "メール アドレス",
  password: "パスワード",
  do_not_have_account: "アカウントをお持ちではありませんか",
  validation_empty_email: "メール アドレス を 入力してください。",
  validation_empty_password: "パスワード を 入力してください。",
  user_agreement_prefix: " 私はConnect Study Abroadの",
  user_agreement_conjunction: " と ",
  user_agreement_postfix: "に同意します。",
  user_agreement_validation:
    "利用規約およびプライバシー・ポリシーに同意してください。",
  already_have_account: "アカウントがある場合、こちらから",
  verification_code_sent:
    "コード を メール アドレス へ 送りました。下 に コード を 入れてアカウント を 確認して ください。",
  confirm_account: "アカウント を 確認する",
  did_not_receive_code: "コード が 届いて ない ですか",
  resend: "もう一度 送ります。",
  verification_title: "アカウント確認",
  passwords_do_not_match: "パスワード が いっち していません",
  forgot_password_description:
    "下にメールアドレスを入れてください。パスワードをリセットするためのコードを送ります。",
  reset_password: "パスワードを変更",
  password_reset_code_sent:
    "コード を メール アドレス へ 送りました。下 に コード を 入れてアカウント を 確認して ください。",
  code: "コード",
  onboarding: {
    first_name: "姓",
    last_name: "名",
    date_of_birth: "生年月日",
    year: "年",
    month: "月",
    day: "日",
    nationality: "国籍",
    start_study_in_japan: "日本へ留学はいつを予定していますか？",
    currently_reside_in_japan: "現在、日本に住んでいますか？",
    yes: "はい",
    no: "いいえ",
  },
  student_profile: {
    user_picture: "顔写真",
    first_name: "姓",
    last_name: "名",
    date_of_birth: "生年月日",
    sex: "性別",
    nationality: "国籍",
    place_of_birth: "出生地",
    current_address: "現住所",
    current_occupation: "現在の職業（学生・在職中・その他）",
    start_study_in_japan: "日本へ留学はいつを予定していますか？",
    currently_reside_in_japan: "現在、日本に住んでいますか？",
  },
  profile_tabs: {
    profile: "プロフィール",
    application_for_admission: "入学願書",
    documents: "資料",
  },
  admission_application_forms: {
    personal_info: "個人情報",
    passport_info_stay_history: "パスポート取得状況",
    family_friend_japan: "在日親族や友人",
    marital_status: "配偶者の有無",
    education_background: "学歴（小・中・高・大・専門・短大）",
    professional_background: "職歴",
    japanese_study_experience: "日本語学習歴",
    japanese_proficiency: "日本語能力について（証明書等）",
    plan_after_graduation: "卒業後の予定",
    about_sponsor: "経費支弁者について",
  },
  admission_application_page1: {
    passport_status: "パスポート取得状況",
    passport_number: "旅券番号",
    passport_expiration_date: "旅券の有効期限",
    passport_issue_date: "旅券の発行年月日",
    previous_coe_application: "在留資格申請歴",
    previous_stay_in_japan: "出入国履歴の有無",
    previous_stay_in_japan_detail: "出入国履歴 (詳細)",
    date_of_entry: "入国日",
    date_of_departure: "出国日",
    status_of_residence: "在留資格",
    purpose_of_entry: "入国目的",
    family_or_friend_question: "在日親族や友人の有無",
    family_or_friend_title: "在日親族や友人",
    relationship: "関係",
    name: "氏名",
    intention_to_reside: "同居予定",
    place_of_employment: "学校または勤務先",
    residence_card_number: "在留カード番号",
    marital_status: "配偶者の有無",
    partner_name: "配偶者氏名",
  },
  admission_application_page2: {
    educational_background: "学歴（小・中・高・大・専門・短大）",
    type_of_school: "学校種別",
    name_of_school: "学校名（正式名称）",
    location_in_detail: "住所（詳細に）",
    period_of_attendance: "在籍期間",
    start_date: "開始日",
    end_date: "終了日",
    years: "在籍年数",
    professional_career_question: "職歴の有無",
    professional_career_title: "職歴",
    company_name: "会社名",
    type_of_job: "職種",
    period_of_employment: "在籍期間",
    japanese_study_experience_question: "日本語学習歴の有無",
    japanese_study_experience_title: "日本語学習歴",
    hours: "学習時間数",
    japanese_proficiency_certification_question:
      "日本語能力を証明する資格の有無",
    japanese_proficiency_certification_title: "日本語能力について（証明書等）",
    name_of_test: "受検名",
    experience_of_test: "受検歴",
    year_of_test: "受検した年",
    level: "レベル",
    score: "スコア",
    plan_after_graduation: "卒業後の予定",
    currently_enrolled: "在籍中",
    do_you_have_work_experience: "職務経験はありますか？- はい/いいえ?",
    expected_graduation_date: "卒業見込み時期",
    enrollemnt_period: "在籍期間（年）",
    currently_working_company: "現在もこの会社で勤務中",
    studied_japanese_institute: "どこかの機関で日本語を勉強したことがありますか?",
    not_yet_completed: "在籍中"
  },
  admission_application_page3: {
    sponsor_name: "経費支弁者 氏名",
    sponsor_address: "経費支弁者 住所",
    sponsor_relationship: "経費支弁者 申請者との関係",
    sponsor_annual_income: "経費支弁者 年収",
    sponsor_occupation: "経費支弁者 職業",
    sponsor_employer: "経費支弁者 勤務先名",
  },
  student_document: {
    application_for_admission: "入学願書（学校指定用紙）",
    statement_of_reason_title: "就学理由書",
    statement_of_reason_about:
      "（最終学歴となっている学校卒業後5年以上経過している場合）",
    statement_of_advancement_title: "日本語教育機関卒業後の進路説明書",
    statement_of_advancement_about:
      "（最終学歴となっている学校卒業後5年以上経過している場合）",
    identification_photograph: "証明写真（5枚） 4×3センチ",
    passport_or_identification: "パスポートまたは身分証明書のコピー",
    diploma_of_last_school: "最終学校の卒業証明書",
    transcript_of_last_school: "最終学校の成績証明書",
    japanese_language_proficiency: "日本語能力立証資料",
    employment_certificate_title: "在職証明書",
    employment_certificate_about: "（職歴がある場合のみ）",
    tuberculosis_screening_title: "結核非発病証明書  ",
    tuberculosis_screening_about:
      "フィリピン、ベトナム、中国、 インドネシア、ネパール、 ミャンマーの国籍を有する申請者の方は必ず提出してください。",
    financial_support: "経費支弁書（学校指定用紙）",
    supporter_family_members: "経費支弁者家族一覧表（学校指定用紙）",
    supporter_family_structure: "経費支弁者家族構成を立証する資料",
    supporter_relationship: "経費支弁者と申請人との関係を立証する資料",
    certificate_of_deposit: "預金残高証明書（原本）",
    supporter_employment: "経費支弁者の職業を立証する資料",
    three_years_asset_formation: "過去3年間の資金形成過程立証資料",
    supporter_three_years_income: "過去3年間の経費支弁者の収入を立証する資料",
  },
  save_profile_btn: "保存する",
  date_of_birth: "生年月日",
  nationality: "国籍",
  additional_fee_label: "入学後の追加費用（教材費・保険料等）の有無",
  additional_fee_yes: "追加費用あり",
  additional_fee_no: "追加費用なし",
  early_application_deadline:
    "出願締切日は、応募状況により予定より早まる場合があります。",
  notes_for_the_tuition_fee: "コース費用について（注釈）",
  course_list: "コース一覧",
  course_detail: "コース 詳細",
  school_list: "学校一覧",
  school_detail: "学校 詳細",
  go_to_list: "一覧にもどる",
  apply: "おうぼ する",
  applied_date: "応募日",
  application_status: "ステータス",
  select_one: "以下から選択してください",
  pre_apply: "エントリーする",
  tab_exit_heading: "セーブ しましたか？",
  tab_exit_description: "セーブ してない内容は保存されません。",
  yes: "はい",
  no: "いいえ",
  duplicate_application_msg: "You have already applied to this course.",
  csa_free_minimun_balance:
    "CSAのサービスは無料です。ただし、留学ビザの申請には、本人またはご家族の年収証明書と200万円以上の預金残高証明書が必要です。",
  required_student_profile_fields: "必要な情報入力が完了していません",
  redirect_student_profile_page: "学生プロフィールの入力ページに移動します。",
  redirect: "ページを移動します",
  proceed_with_course_application: "コースへの応募を続ける",
  faq: {
    faq1: {
      id: "1",
      title: "CSAって何？",
      description:
        "CSAは完全無料の日本留学サービスです！エージェントにお金を払わず、自分にあう学校を探して日本に留学することができます。プロフィールの記入や書類をアップロードすれば、日本の学校からスカウトが来ることもあります！気になる学校やコースがあれば、ぜひ応募してみましょう。",
    },
    faq2: {
      id: "2",
      title: "CSAではどんなサービスが受けられるの？",
      description:
        "CSAは皆さんの日本留学を応援するサービスです。日本留学の最新情報を自分の言語で探すことができます！日本に来てからの生活サポートも充実！家探しや携帯の契約まで、申し込みは全部無料！そしてCSAの一番の特徴は、日本の学校を卒業した後、日本での就職もサポートしてくれること！ぜひStudy &work in Japanを実現しましょう！",
    },
    faq3: {
      id: "3",
      title: "どうやって学校に応募するの？",
      description:
        "1.まずはConnect Study ABROADに登録しましょう！2.次に自分に合った学校・コースを探します 3.気に入ったコースを見つけたらお気に入り登録してコースを比較しよう 4.仮応募（pre-apply）ボタンを押して、学校とやり取り開始 5.入学したい学校を一つに決めてapplyボタンを押しましょう ※書類やプロフィールをアップロードするほどに学校からスカウトが来る可能性がアップ！奨学金の案内が来るかも？たくさん情報を載せましょう",
    },
    faq4: {
      id: "4",
      title: "CSAインターンシップって何？",
      description:
        "CSAの運営会社であるフォースバレー・コンシェルジュ株式会社でインターンをすることができます。日常会話の英語が話せれば、日本語ができなくても大丈夫。外国人の日本就職・日本留学を支援する私たちと一緒に働いてみませんか？*CSA経由で学校に入学が決定した人の中から、面接等を経て決定します。応募の流れはこちら 1.登録 2.学校に応募 3.入学が決定 4.インターンシップの詳細と応募の流れに関して情報をゲット 5.インターンシップに応募（面接等を合格した人のみ参加可能 一緒に働けることを楽しみにしています！",
    },
    faq5: {
      id: "5",
      title: "日本にはどんな学校の種類があるの？",
      description:
        "日本に留学するには大まかに３つの選択肢があります。日本語学校：日本語がN5レベル〜留学可能です。世界中から集まった留学生と一緒に、日本語を勉強することができます。学校によって進学・就職コースなどに分かれています。大学・大学院：日本で学士・修士・博士課程を取ることができます。入学するためにはEJUという試験に合格する必要があります。一部英語で入学できる大学もありますが、基本的にはN2レベルの日本語力が必要です。多くの人が日本語学校に通ってから、大学・大学院に進学します。専門学校：日本で専門的な技能を学ぶことができます（アニメ、会計、IT、調理等）N2以上を持っている、または日本語学校に半年以上留学した人が入学できます。詳しくはこちらをご覧ください。",
    },
    faq6: {
      id: "6",
      title: "どうやったら奨学金がもらえるのか？",
      description:
        "日本では返済不要の奨学金が142種類もあります！（Jasso調べ）CSA経由で日本語学校に入学した人限定の奨学金もあります。ぜひCSA上の学校に応募してみてくださいね！詳しくはこちらの記事をご覧ください。",
    },
    faq7: {
      id: "7",
      title: "日本留学はどれくらい費用がかかるの？",
      description:
        "日本留学は欧米圏への留学に比べてかなり安くなっています。１年間でかかる金額は授業料と生活費、全て含めて約200万円です。（留学先、留学内容による）これは欧米留学の1/2~1/3の金額です！アルバイトをすることも可能、奨学金の機会も豊富です。詳しくはこちらの記事をご覧ください。",
    },
    faq8: {
      id: "8",
      title: "留学生でもアルバイトはできるの？",
      description:
        "日本では留学生のアルバイトが週に28時間（長期休暇中は40時間）まで認められています。時給は約1000円（=$9)で、１年間で最大約160万円稼ぐことができます！日本留学に来る人の約7割がアルバイトをして、生活費や学費を稼いでいます。日本語を勉強しながら、アルバイトをして、日本生活を充実させましょう！詳しくはこちらの記事をご覧ください。",
    },
    faq9: {
      id: "9",
      title: "どうやって日本で仕事を見つけることができるの？",
      description:
        "日本で働く外国人の数はこの10年で約３倍に！日本で働く機会は年々増えています。仕事内容や在留資格によって異なりますが、日本で仕事を見つけるには、最低N4レベルの日本語力が必要です。もちろん、日本語のレベルが上がれば、より仕事を見つけやすくなります。日本で働くために、日本に留学して日本語を話せるようになりましょう！ 私たちは日本で働きたい外国人向けの就職サポートもやっています！ぜひこちらのサイトにも登録してみてください",
    },
    faq10: {
      id: "10",
      title: "日本で大学・大学院に進学したい",
      description:
        "まだCSAには学校情報が少ないので、日本の大学/大学院に通いたい人はこちらのサイトを確認してください。 https://www.jpss.jp/ja/",
    },
  },
};
