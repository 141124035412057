import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useTranslation } from "react-i18next";
import { Container, Row } from "reactstrap";
import FooBottom from "./FooBottom";
import FooNav from "./FooNav";
import FooOverview from "./FooOverview";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const currentYear = new Date().getFullYear()

  return (
    <>
      <footer className="text-white pt-8 pb-4 pl-4 pr-4" id="footer-contact">
        <Container>
          <Row>
            <FooOverview />
            <FooNav />
          </Row>
          <Row>
            <div
              className="copyright text-capitalize m-auto col-md-8"
            >
              {/* <div className="test-white copyright">
              <Link
                className="mr-3"
                to={{ pathname: urls.privacy_policy }} // external link
                target="_blank"
                // to="/"
              >
                {capitalize(t("privacy_policy"))}
              </Link>
              <FontAwesomeIcon icon={faSquare} className="p-1" />
              <Link className="ml-3" to="/">
                {capitalize(t("terms_of_service"))}
              </Link>
            </div> */}
              <FontAwesomeIcon icon={faCopyright} /> {currentYear} Fourth Valley
              Concierge Corporation. All Rights Reserved.
            </div>

            <div className="col-md-4 text-center text-md-right d-flex align-items-center justify-content-end">

              <a className="fs-4 mx-2 link-light" href="https://www.facebook.com/Connect.StudyAbroad.Japan" rel="noreferrer" target={"_blank"}>
                <FontAwesomeIcon icon={faFacebook} />
              </a>

              <a className="fs-4 mx-2 link-light" href="https://www.instagram.com/connect.studyabroad_japan" rel="noreferrer" target={"_blank"}>
                <FontAwesomeIcon icon={faInstagram} />
              </a>

            </div>

          </Row>
        </Container>
        {/* <FooBottom /> */}
      </footer>
    </>
  );
};

export default Footer;
