import React from "react";
import MaintainanceImage from "src/assets/images/common/under_construction.png";
import CSA from "src/assets/images/common/old/csa.png";

const UnderMaintainance = () => {
  return (
    <div style={{ backgroundColor: "#10a9ac" }}>
      <div className="container">
        <div>
          <div className="row" style={{ paddingBottom: "50px" }}>
            <div
              className="col-12"
              style={{
                // textAlign: "center",
                marginTop: "30px",
                padding: "150px 0px 20px 0px",
              }}
            >
              <div className="row d-flex">
                {" "}
                <div className="col-md-2"></div>
                <div
                  className="col-12 col-sm-12 col-md-4"
                  style={{ textAlign: "center" }}
                >
                  <img
                    style={{
                      height: "300px",
                      width: "300px",
                      objectFit: "cover",
                      filter: "drop-shadow(30px 10px 4px #ffffff)",
                    }}
                    src={MaintainanceImage}
                    alt="maintainance-image"
                  />
                </div>
                <div className="col-12 col-sm-12 col-md-6">
                  <div style={{ textAlign: "center" }}>
                    <img style={{ height: "150px" }} src={CSA} alt="logo" />
                  </div>
                  <br />
                  <h3
                    style={{
                      textTransform: "uppercase",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    is currently
                  </h3>
                  <br />
                  <h1
                    style={{
                      textTransform: "uppercase",
                      fontWeight: "bold",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    Under Maintainance
                  </h1>
                </div>
              </div>

              {/* <div className="col-2"></div> */}
            </div>
            <div
              className="col-12"
              style={{
                marginTop: "100px",
                paddingBottom: "120px",
                textAlign: "center",
              }}
            >
              <div>
                <h3 style={{ color: "white" }}>We will be back soon!!!</h3>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default UnderMaintainance;
