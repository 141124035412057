export default [
  {
    id: "",
    value: "以下から選択してください",
    enValue: "Select one",
  },
  {
    id: "1",
    value: "長期",
    enValue: "long",
  },
  {
    id: "2",
    value: "短期",
    enValue: "short",
  },
];
