import React from "react";
import { Card, CardHeader, CardBody, Col } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";

// import nihongo from "src/assets/images/Footer/connect-nihongo.png";
import nihongo from "src/assets/images/Footer/connect-nihongo.svg";
// import workers from "src/assets/images/Footer/connect-workers.png";
import workers from "src/assets/images/Footer/connect-workers.svg";
import jobplus from "src/assets/images/Footer/connect-job.svg";

import urls from "../../../../src/core/constants/urls";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import capitalize from "../../../core/utilities/utils";

const FooNav = ({ to }) => {
  const { t, i18n } = useTranslation();
  const fooNav = [
    {
      title: capitalize(t("our_services")),
      item: [
        {
          icon: faSquare,
          text: capitalize(t("course_search")),
          type: null,
          url: "/list/courses",
        },
        {
          icon: faSquare,
          text: capitalize(t("school_search")),
          type: null,
          url: "/list/schools",
        },
        // {
        //   icon: faSquare,
        //   text: "School enrollment ",
        //   type: null,
        // },
        // {
        //   icon: faSquare,
        //   text: "Visa Processing",
        //   type: null,
        // },
      ],
    },
    // {
    //   title: "Countries we support",
    //   item: [
    //     {
    //       icon: faSquare,
    //       text: "China",
    //       type: null,
    //     },
    //     {
    //       icon: faSquare,
    //       text: "India ",
    //       type: null,
    //     },
    //     {
    //       icon: faSquare,
    //       text: "Nepal",
    //       type: null,
    //     },
    //     {
    //       icon: faSquare,
    //       text: "Vietnam",
    //       type: null,
    //     },
    //   ],
    // },
    {
      title: capitalize(t("our_other_services")),
      item: [
        {
          icon: nihongo,
          // text: " Connect Study NIHONHO",
          type: "image",
          url: urls.connect_study_nihongo,
        },
        {
          icon: workers,
          // text: "Connect Job Workers",
          type: "image",
          url: urls.connect_job_workers,
        },
        {
          icon: jobplus,
          // text: " Connect Job",
          type: "image",
          url: urls.connect_job,
        },
      ],
    },
  ];
  return (
    <>
      {fooNav &&
        fooNav.map((item, i) => (

          <Col lg={3} md={6} key={i}>
            <Card className="foo-nav bg-transparent border-0 text-capitalize">
              <CardHeader
                tag="h5"
                className="bg-transparent border-0 text-white m-0 pb-2 pl-0"
              >
                {item.title}
              </CardHeader>
              <CardBody tag="ul" className="pl-0 pr-0 ">
                {item.item.map((item, i) => (
                  <li key={i} className="d-flex">
                    {item.type === "image" ? (
                      // <Link to={to || "#"} className="text-muted">

                      <Link
                        className="text-muted"
                        to={{ pathname: item.url }} // external link
                        target="_blank"
                      // to="/"
                      >
                        <img
                          src={item.icon}
                          alt="feature"
                          className="mb-3"
                          style={{ height: "20px" }}
                        />
                      </Link>
                    ) : (
                      <Link to={to || item.url} className="text-muted">
                        <FontAwesomeIcon
                          className="mt-2 h6 position-relative fav-icon"
                          icon={item.icon}
                        />
                      </Link>
                    )}
                    <Link to={to || item.url} className="mb-2 text-muted ml-4">
                      {item.text}
                    </Link>
                  </li>
                ))}
              </CardBody>
            </Card>
          </Col>
        ))}
    </>
  );
};

export default FooNav;
