import { faThumbtack, faUser } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState, useEffect } from 'react'
import { useContext } from 'react'
import { Link } from 'react-router-dom'
import { Card, CardBody, Modal, ModalBody, Spinner } from 'reactstrap'
import { GetMasterDataContext } from 'src/contexts/GetMasterDataContext'
import { getValueOfMatchedItemInMasterData } from 'src/core/utilities/utils'
import { getStudentListPinnedBySchool, unpinStudentBySchool } from 'src/helpers/school'
import withHeaderFooter from 'src/services/with_header_footer'

function Index() {

    // MASTER DATA
    const masterData = useContext(GetMasterDataContext)

    const schoolId = localStorage.getItem('globalSchoolID')
    const [list, setList] = useState({
        status: null,
        data: null
    })

    // SET DATA
    const setData = async (id) => {
        setList(await getStudentListPinnedBySchool(id))
    }

    useEffect(() => {
        setData(schoolId)
    }, [])


    const [loader, setLoader] = useState(false)
    // UnPIN STUDENT ONCLICK
    const unpinStudent = async (id) => {
        setLoader(true)
        const schoolId = localStorage.globalSchoolID
        const postUnPin = await unpinStudentBySchool(schoolId, id)


        if (postUnPin?.status) {
            setData(schoolId)
            setLoader(false)
        } else {
            setLoader(false)
            return null
        }

    }



    return (
        <div>
            <div className="row">
                <div
                    className="col-12 d-flex app-list-header"
                    style={{ position: "fixed", zIndex: "25", right: "0px" }}
                >
                    <div>Your Favorite List</div>
                    <Link to="/">
                        <div>Home</div>
                    </Link>
                </div>
            </div>
            <div
                style={{
                    paddingTop: "90px",
                    paddingRight: "30px",
                    paddingLeft: "30px",
                    minHeight: "220px",
                    backgroundColor: "#f5f5f5",
                }}
            >
                <div className="student-form container m-auto">

                    {list.status == null && (<center><Spinner /></center>)}

                    {list?.status && (
                        <>
                            {list?.data?.map((d, i) => {
                                return (
                                    <Card key={i} className='mb-5'>

                                        <CardBody>

                                            <div className='row align-items-center'>
                                                <div className='col-md-2 text-center'>

                                                    <figure className='m-auto'>
                                                        {
                                                            d?.profileImage ?
                                                                <img
                                                                    className="school-std-search-img object-fit-cover m-auto"
                                                                    src={d?.profileImage}
                                                                    alt="student"
                                                                    style={{ width: "100px", height: "100px" }}
                                                                /> :
                                                                <FontAwesomeIcon
                                                                    className='fs-1'
                                                                    icon={faUser}
                                                                />
                                                        }
                                                    </figure>

                                                    <span className='d-block'>ID: {d?.displayId}</span>

                                                    <div>
                                                        {d.scoutStatus == null ? (
                                                            d.hasApplied ? (
                                                                <div
                                                                    className="school-std-search-img-highlight-applied"
                                                                    style={{
                                                                        textTransform: "capitalize",
                                                                        padding: "0px 5px",
                                                                    }}
                                                                >
                                                                    Pre-Applied
                                                                </div>
                                                            ) : (
                                                                <div className="school-std-search-img-danger">
                                                                    Accepting Scouts
                                                                </div>
                                                            )
                                                        ) : (
                                                            <div
                                                                className="school-std-search-img-highlight"
                                                                style={{ textTransform: "capitalize" }}
                                                            >
                                                                {d.scoutStatus}
                                                            </div>
                                                        )}
                                                    </div>

                                                </div>
                                                <div className='col-md-8'>

                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            {/* Nationality */}
                                                            {/* <ListCard title="国籍" value={d.nationality ? d.nationality : "-"} /> */}
                                                            <ListCard title="国籍"
                                                                value={d.nationality
                                                                    ? getValueOfMatchedItemInMasterData(
                                                                        d.nationality,
                                                                        masterData.masterNationality
                                                                    )
                                                                    : "-"} />

                                                        </div>
                                                        <div className='col-md-6'>
                                                            {/* Expense payment capacity confirmed */}
                                                            <ListCard title="経費支弁能力確認済" value={d.isSponsorConfirmed ? "はい" : "-"} />

                                                        </div>
                                                        <div className='col-md-6'>
                                                            {/* Number of Document Uploads */}
                                                            <ListCard
                                                                title="  書類アップロード数"
                                                                value={d.numOfDocs ? d.numOfDocs : "-"} />

                                                        </div>
                                                        <div className='col-md-6'>
                                                            {/* Japanese Learning Experience */}
                                                            <ListCard
                                                                title="日本語学習歴"
                                                                value={d.japaneseStudyExperience ? d.japaneseStudyExperience : "-"} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {/* Japanese Proficiency Qualifications */}
                                                            <ListCard
                                                                title="保有日本語資格"
                                                                value={d.japaneseAbility ? getValueOfMatchedItemInMasterData(
                                                                    d.japaneseAbility,
                                                                    masterData.masterJapaneseLevel) : "-"} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            {/* Plan after graduation */}
                                                            <ListCard
                                                                title="卒業語の希望進路"
                                                                value={d.planAfterGraduation ? getValueOfMatchedItemInMasterData(
                                                                    d.planAfterGraduation,
                                                                    masterData.masterPlanAfterGraduation
                                                                ) : "-"} />
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <ListCard
                                                                title="Number of Scouts"
                                                                value={d.numOfScouts ? d.numOfDocs : "-"} />
                                                        </div>

                                                    </div>

                                                </div>
                                                <div className='col-md-2'>

                                                    <div className="text-center">
                                                        <button className="btn shadow-none"
                                                            onClick={() => unpinStudent(d.id)}
                                                        >
                                                            <FontAwesomeIcon
                                                                className='fs-3 text-warning'
                                                                style={{
                                                                    width: "40px",
                                                                    height: "40px",
                                                                    float: "right",
                                                                    backgroundColor: "#f8f9fa",
                                                                    borderRadius: "50%",
                                                                    padding: "10px",
                                                                    transform: "rotate3d(0,1,1,45deg)",
                                                                }}
                                                                icon={faThumbtack}
                                                            />
                                                        </button>

                                                        <Link to={`/applicants/detail/${d.id}`}

                                                            className="btn btn-primary rounded-pill py-1 px-2"
                                                        >
                                                            Show More

                                                        </Link>
                                                    </div>

                                                </div>

                                            </div>
                                        </CardBody>
                                    </Card>)
                            })}
                        </>
                    )}

                    {!list.status && list.status !== null && (<center>Not Available!</center>)}

                    {
                        loader && (<Modal isOpen={true} centered contentClassName="bg-transparent border-0">

                            <ModalBody className='text-center text-light'>
                                <Spinner />
                            </ModalBody>

                        </Modal>)
                    }


                </div>
            </div>
        </div >
    )
}

export default withHeaderFooter(Index)


export const ListCard = (props) => {
    return (<div className='mb-2'>
        <span className="d-block school-std-search-color">

            {props.title}
        </span>
        <span className='d-block'>{props.value}</span>

    </div>)
}
