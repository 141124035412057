import React from "react";
import { getValueOfMatchedItemInMasterData } from "src/core/utilities/utils";

const EnglishLevel = ({
  planAfterGraduation,
  masterPlanAfterGraduation,
  masterStudyType,
}) => {

  return (
    <div>
      <div className="row rowOutline--wrapper" id="englishLevelSection">
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span className="section-heading">
                {/* ENGLISH LEVEL */}
                英語のレベル
              </span>
            </div>
          </div>
          <div className="row formInner">
            <div class="col-12 col-sm-6">
              <label class="form-label info-label">
                {getValueOfMatchedItemInMasterData(
                  planAfterGraduation.type,
                  masterPlanAfterGraduation
                )}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnglishLevel;
