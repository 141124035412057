export default {
  language_code: "cn",
  language_title: "簡体字",
  welcome_to_connect: "欢迎来到Connect！",
  Login: "Login",
  school_login_title: "学校登录 ",
  login_btn: "登录",
  sign_up: "注册",
  enter_your_email: "输入邮箱地址",
  enter_your_password: "输入密码",
  school_signup_title: "School Sign Up",
  signup_btn: "Sign up",
  logout: "登出",
  change_password: "变更密码",
  current_password: "现在的密码",
  new_password: "新密码",
  confirm_password: "确认密码",
  incorrect_password: "现在的密码不正确",
  forgot_password: "忘记密码？",
  valid_password: "密码必须包含大写字母、小写字母和数字，至少8个字符。",
  login_for_school_only_msg: "对不起！仅限学校登录。",
  login_for_student_only_msg: "对不起！仅限学生登录。",
  home: "主页",
  School: "School",
  Dashboard: "控制台",
  blog: "博客",
  Contact: "Contact",
  schools: "学校信息",
  courses: "课程",
  applications: "申请",
  about_us: "关于我们",
  contact_us: "联系我们",
  profile: "我的简历",
  for_inquiries: "如有疑问，请联系",
  inquiry_form: "学生咨询表格",
  for_business_inquiries: "For inquiries about the service, click here.",
  business_inquiry_form: "Business Inquiry Form",
  submit: "提交",
  language: "语言",
  view_all: "全部阅览",
  our_services: "服务内容",
  course_search: "课程检索",
  school_search: "学校检索",
  our_other_services: "其他服务",
  privacy_policy: "隐私条款",
  terms_of_service: "服务条款",
  page_not_found: "对不起！无法找到该页面。",
  student_tag_line_heading: "日本求职× 日本留学!",
  student_tag_line_subheading: "选择适合自己的留学项目",
  find_best_matched_course: "寻找适合自己的日语课程",
  recommended_schools_msg: "我们为您推荐的最佳学校",
  popular_schools: "人气学校",
  statistical_facts: "我们的服务",
  courses_listed: "注册课程数",
  schools_listed: "注册学校数",
  no_results_found: "对不起!我们无法找到符合搜索条件的信息。",
  go_to_home: "返回主页",
  office_address: "公司位置",
  read_more: "更多阅览",
  phone_number: "电话号码",
  something_went_wrong: "发生错误",
  area: {
    title: "地区",
    value: {
      hokkaido: "北海道",
      tohoku: "东北",
      kanto: "关东",
      chubu: "中部",
      kansai: "关西",
      chugoku: "中国",
      shikoku: "四国",
      kyushu: "九州",
    },
  },
  course_type: {
    title: "课程种类",
    value: {
      general_japanese: "一般日语",
      university_preparation: "大学升学准备",
      business_employment: "商务就职",
      others: "其他",
    },
  },
  course_duration: {
    title: "课程时间",
    value: {
      short: "短期",
      long: "长期",
    },
  },
  enrollment_month: {
    title: "入学时间",
    value: {
      april: "4月",
      july: "7月",
      october: "10月",
      january: "1月",
    },
  },
  japanese_level: {
    title: "日语水平",
    value: {
      beginner: "初级",
      intermediate: "中级",
      advance: "上级",
    },
  },
  course_info: {
    value: {
      course_name: "课程名",
      course_outline: "课程简介",
      course_description: "课程说明",
      applicable_student: "对象学生",
      visa_type: "签证种类",
      beginning_period: "开始时期",
      learning_content: "学习内容",
      application_period: "申请时间",
      curriculum: "课程设置",
      course_goal: "课程目标",
      curriculum_description: "课程介绍",
      tuition: "学费(JPY)",
      screening_fee: "选考费",
      enrollment_fee: "入学金",
      class_fee: "学费",
      facility_fee: "设备费",
      total: "总额",
      tuition_fee_notes: "Notes for the tuition fee",
    },
  },
  school_info: {
    value: {
      school_name: "学校名",
      school_logo: "校徽",
      available_course_type: "课程类型",
      available_course_length: "课程时间",
      school_introduction: "学校简介",
      basic_information: "学校基本信息",
      location: "位置",
      nearest_station: "最近车站",
      phone_number: "电话号码",
      fax_number: "FAX号码",
      school_website: "官网",
      email: "email",
      founder: "创建人",
      director: "校长",
      authorized_date: "认可年月日",
      establishmentDate: "设立年月日",
      faculty_staff: "讲师・职员",
      num_of_faculty_full_time: "讲师人数（全职）)",
      assisstant_for_housing: "租房助手",
      foreign_speaking_staff: "具备外语能力的职员",
      students: "学生",
      quota_of_school: "定员",
      total_num_students: "学生总数",
      major_countries_students: "学生主要出生国",
      grads_this_year: "2020年3月 毕业预定学生人数",
      grads_last_year: "去年毕业生人数",
      after_graduation: "毕业后去向",
      jlpt_passer: "2019年12月 日本语能力测试的考试人数（合格人数）",
      admission_selection: "入学选考",
      selection_method: "报名方法",
      application_fee: "选考费",
      deadline: "期限",
      mailing_address: "邮寄地址",
      pr_school: "学校PR",
      message_from_school: "来自学校的消息",
      images: "照片",
      videos: "视频",
      school_year_calendar: "学年日历（活动安排）",
      scholarship_if_available: "奖学金信息（如有）",
      facility: "设备",
    },
  },
  confirm_account_modal_header: "请认证帐号",
  confirm_account_modal_body: "登录失败。请进行邮件认证。",
  confirm: "确定",
  cancel: "取消",
  email: "Email Address",
  password: "密码",
  do_not_have_account: "Don’t have an account?",
  validation_empty_email: "必须输入邮箱地址",
  validation_empty_password: "必须输入密码",
  user_agreement_prefix: "同意Connect Study Abroad的～",
  user_agreement_conjunction: " 。下一步～ ",
  user_agreement_postfix: "",
  user_agreement_validation: "请同意服务条款和隐私条款。",
  already_have_account: "已有账号？",
  verification_code_sent: "该验证码已通过邮件发送。请在此输入验证码并进行验证",
  confirm_account: "确认账号",
  did_not_receive_code: "没有收到验证码？",
  resend: "再次发送",
  verification_title: "认证",
  passwords_do_not_match: "Passwords do not match",
  forgot_password_description:
    "Enter your Email below and we will send a message to reset your password",
  reset_password: "重置密码",
  password_reset_code_sent:
    "密码重置验证码已通过邮件发送发。请输入该验证码进行密码重置",
  code: "验证码",
  onboarding: {
    first_name: "姓",
    last_name: "名",
    date_of_birth: "出生年月日",
    year: "年",
    month: "月",
    day: "日",
    nationality: "国籍",
    start_study_in_japan: "打算何时去日本留学？",
    currently_reside_in_japan: "目前，是否居住在日本？",
    yes: "是",
    no: "不是",
  },
  student_profile: {
    user_picture: "证件照",
    first_name: "姓",
    last_name: "名",
    date_of_birth: "出生年月日",
    sex: "性别",
    nationality: "国籍",
    place_of_birth: "出生地",
    current_address: "现居地",
    current_occupation: "目前身份（学生・在职・其他）",
    start_study_in_japan: "打算何时去日本留学？",
    currently_reside_in_japan: "目前，是否居住在日本？",
  },
  profile_tabs: {
    profile: "简历",
    application_for_admission: "申请文件",
    documents: "资料",
  },
  admission_application_forms: {
    personal_info: "个人信息",
    passport_info_stay_history: "护照取得情况",
    family_friend_japan: "在日亲戚或者朋友",
    marital_status: "是否有配偶",
    education_background: "学历（小・中・高・大・专门学校・短期大学）",
    professional_background: "工作经历",
    japanese_study_experience: "日语学习经历",
    japanese_proficiency: "关于日语能力（证明书等）",
    plan_after_graduation: "毕业后计划n",
    about_sponsor: "关于经费支付者",
  },
  admission_application_page1: {
    passport_status: "护照取得情况",
    passport_number: "护照号码",
    passport_expiration_date: "护照有效期间",
    passport_issue_date: "护照发行年月日",
    previous_coe_application: "在留资格申请经历",
    previous_stay_in_japan: "是否有出入境经历",
    previous_stay_in_japan_detail: "出入境经历（详细）",
    date_of_entry: "入境日",
    date_of_departure: "出境日",
    status_of_residence: "在留资格",
    purpose_of_entry: "入境目的",
    family_or_friend_question: "是否有在日的亲戚或者友人",
    family_or_friend_title: "在日的亲戚或者友人",
    relationship: "关系",
    name: "姓名",
    intention_to_reside: "是否打算同住",
    place_of_employment: "学校或者工作单位",
    residence_card_number: "在留卡号码",
    marital_status: "是否有配偶",
    partner_name: "配偶名字",
  },
  admission_application_page2: {
    educational_background: "学历（小・中・高・大・专门学校・短期大学）",
    type_of_school: "学校类型",
    name_of_school: "学校名字（正式名称）",
    location_in_detail: "住所（详细填入）",
    period_of_attendance: "在籍期间",
    start_date: "开始日",
    end_date: "结束日",
    years: "在籍年数",
    professional_career_question: "是否有工作经历",
    professional_career_title: "工作经历",
    company_name: "公司名称",
    type_of_job: "职业种类",
    period_of_employment: "在籍期间",
    japanese_study_experience_question: "是否学习过日语",
    japanese_study_experience_title: "日语学习经历",
    hours: "学习时间数",
    japanese_proficiency_certification_question: "是否有证明日语能力的资格证书",
    japanese_proficiency_certification_title: "关于日语能力（证明书等）",
    name_of_test: "考试名称",
    experience_of_test: "考试历史",
    year_of_test: "考试时间",
    level: "日语水平",
    score: "分数",
    plan_after_graduation: "毕业后的计划",
    currently_enrolled: "Currently Enrolled",
    do_you_have_work_experience: "Do you have a work experience?",
    expected_graduation_date: "Expected Graduation Date",
    enrollemnt_period: "Enrollment Period",
    currently_working_company: "I am currently working for this company",
    studied_japanese_institute: "Have you studied Japanese at any institute?",
    not_yet_completed: "Not yet Completed",
  },
  admission_application_page3: {
    sponsor_name: "经费支付者 姓名",
    sponsor_address: "经费支付者 住所",
    sponsor_relationship: "经费支付者  和申请者关系",
    sponsor_annual_income: "经费支付者 年收入",
    sponsor_occupation: "经费支付者 职业",
    sponsor_employer: "经费支付者 工作单位",
  },
  student_document: {
    application_for_admission: "入学申请书（学校指定用纸）",
    statement_of_reason_title: "申请原因说明书",
    statement_of_reason_about: "(最终学历毕业后超过五年或五年以上的话)",
    statement_of_advancement_title: "日语教育机构毕业后的升学说明",
    statement_of_advancement_about: "(最终学历毕业后超过五年或五年以上的话)",
    identification_photograph: "证件照（5张） 4×3cm",
    passport_or_identification: "护照以及身份证明书的复印",
    diploma_of_last_school: "最终学校的毕业证明书",
    transcript_of_last_school: "最终学校的成绩证明书",
    japanese_language_proficiency: "日语能力证明材料",
    employment_certificate_title: "在职证明书",
    employment_certificate_about: "(只限有过工作经验者)",
    tuberculosis_screening_title: "结核病检查证明书",
    tuberculosis_screening_about:
      "※菲律宾、越南、中国、印度尼西亚、尼泊尔、缅甸国籍的留学生必须提交。",
    financial_support: "经费支付书（学校指定用纸）",
    supporter_family_members: "经费支付者家族一览表（学校指定用纸）",
    supporter_family_structure: "经费支付者家族结构证明资料",
    supporter_relationship: "经费支付者和申请人关系证明资料",
    certificate_of_deposit: "存款余额证明（原件）",
    supporter_employment: "经费支付者的职业证明资料",
    three_years_asset_formation: "过去3年的资金流水证明资料",
    supporter_three_years_income: "经费支付者过去三年的收入证明资料",
  },
  save_profile_btn: "保存",
  date_of_birth: "出生年月日",
  nationality: "国籍",
  additional_fee_label: "入学后是否有追加费用？",
  additional_fee_yes: "是",
  additional_fee_no: "不是",
  early_application_deadline: "根据申请人数，申请截止日期可能提前。",
  notes_for_the_tuition_fee: "Notes for the tuition fee",
  course_list: "课程一览",
  course_detail: "课程详细",
  school_list: "学校名单",
  school_detail: "School Detail",
  go_to_list: "返回一览",
  apply: "申请",
  applied_date: "申请日期",
  application_status: "Application Status",
  student_top_page: {
    why_japans: {
      why_japan: "留学日本的魅力?",
      cost_of_living: "生活费学费和美国、英国、澳大利亚相比更便宜！",
      various_scholarship: "丰富的奖学金制度",
      part_time_during_study: "留学期间可以打工，毕业后也可在日本工作!",
    },
    what_is_csas: {
      what_is_csa: "何为CSA？",
      platform_directly_match:
        "是提供有志于留学日本的学子一个能够直接联系日本教育机构的平台",
      sign_up_free: "免费注册",
    },
    blogs: {
      blog: "博客",
    },
    why_japans_stat: {
      why_japan: "留学日本的魅力?",
      tuition_and_cost_living_japan: "日本的生活费和学费",
      annual_tuition: "学费（每年／USD）",
      annual_living: "生活费（每年／USD）",
    },
    carrers: {
      career_during_study_available: "留学期间可以打工！",
      partime_job: "学生签证允许每周最多  ",
      hrs_per_week: "28小时的",
      while_studying: "兼职工作。",
      work_upto: "*长期休假期间允许每周最多40小时兼职工作。!",
    },
    approval_rate_of_working_visas: {
      approval_rate_of_working_visa: "工作签证的通过率较高！ ",
      career_after_study: "可以在毕业后留日就业！",
      number_of_visa_changes: "学生签证转工作签证的人数推移",
      by_how_much: "学生签证转工作签证的人数推移 ",
      eighty_Seven: "：34,183 ",
      approved: "通过率为87％！",
    },
    grant_type_scholarships: {
      grant_type_scholarship: "丰富的给付型奖学金",
      available: "制度！",
      almost_foreign_students: "约30%的留学生取得给付型奖学",
      for_study_in_japan: "金留学日本      ",
      there_142_scholarship: "日本政府和民间团体提供的",
      japanese_government: "给付型奖学金多达142种！",
      got_scholarship: "2021年，取得日本政府提供的奖学金留学生",
      government_2021: "人数有1840人",
    },
    connect_study_deliver_support: {
      connect_study_deliver_support_students: "全程支持入境后的留学生活",
      sign_up_free: "免费注册",
    },
    about_csas: {
      about_csa: "About CSA",
      get_support_fulfill_student_life: "通过CSA的服务来充实您的留学生活吧！",
      information_not_only_schools:
        "CSA不仅提供学校信息，还提供奖学金、住居、打工等各类信息。!",
      get_support_for_education: "同时实现日本留学和日本工作",
      our_strong_track_supporting:
        "我司在助力全球化人才就业日本方面具有丰富的实绩，能最大限度地挖掘您毕业后的潜力。",
    },
    our_factss: {
      our_facts: "Our Facts",
      support_for_7yrs: "在助力留学日本",
      seven_yrs: "方面拥有7年",
      more_than: " 的经验,",
      thirteen_thousand: " 超过13,000学子使用",
      used_our_service: " 了我们提供的服务。",
      career_event: "在20多个",
      twenty_countries: " 国家举行了求职活动",
      pre_covid: " （新冠疫情前）. 在10多个",
      ten_countries: "国家举行了留学活动",
      covid: "（新冠疫情前）.",
      supported_around: "帮助了4000",
      four_thousand_Candidates: " 人在日本就业",
      job_hunting: " （2020年）",
      registered_users: "约来自140个国家和",
      hundred_forty: " 地区的用户注册.",
    },
    facebook_pages: {
      facebook_page: "Facebook Page",
    },
  },
  select_one: "从以下选择",
  pre_apply: "事前申请",
  tab_exit_heading: "Are you sure?",
  tab_exit_description: "Any unsaved changes will be lost.",
  yes: "是",
  no: "不是",
  duplicate_application_msg: "You have already applied to this course.",
  csa_free_minimun_balance:
    "CSA service is FREE. However, to apply for visa to study in Japan, either you or a family member have to show a proof of annual income AND bank balance of minimum 2 million JPY.",
  required_student_profile_fields:
    "Required Student Profile fields are not filled up",
  redirect_student_profile_page:
    "You will be redirected to Student Profile Page.",
  redirect: "Redirect",
  proceed_with_course_application: "Proceed with Course Application",
  faq: {
    faq1: {
      id: "1",
      title: "何为CSA？",
      description:
        "CSA是一项完全免费的日本留学服务！无需支付中介费用，即可找到适合自己的学校并到日本留学。填写个人资料或上传文件后，也可能会收到来自日本学校的邀请哦！如果您有感兴趣的学校或课程，请一定来申请试试。",
    },
    faq2: {
      id: "2",
      title: "在CSA都能获得哪些服务？",
      description:
        "CSA是一项支持您在日本留学的服务。您可以用自己熟悉的语言找到最新的日本留学信息！我们还将为您来日本后的生活提供各种支持！找房子和手机签约等也在申请范围内，并且都是免费的！CSA最大的特点就是您从日本学校毕业后，还将获得在日就业帮助！让我们一起实现Study & work in Japan！",
    },
    faq3: {
      id: "3",
      title: "如何申请学校？",
      description:
        "1.首先，注册Connect Study ABROAD！2.接下来，找到适合自己的学校和课程 3.找到喜欢的课程后，加入收藏，并对课程进行比较。4.点击预申请（pre-apply）按钮，开始与校方交流。5.确定您想报考的一所学校，并按下申请（apply）按钮。※完善上传文件和个人资料，从学校发来邀请的几率将会提高！这也可能会收到奖学金通知哦。请完善您的信息吧。",
    },
    faq4: {
      id: "4",
      title: "何为CSA Internship？",
      description:
        "您可在CSA的运营公司Fourth Valley Concierge株式会社实习。如果您的英语能达到日常对话水平，那么即使您不会日语也没有关系。让我们一起帮助外国朋友在日就业和到日本留学吧？*我们将从通过CSA成功入学的学生之中，通过面试等确定人选。申请流程如下：1.注册 2.申请学校 3.确定录取 4.获取实习详情及申请流程相关信息 5.申请实习（只有通过面试等环节的人才可参与 期待能与您并肩作战！",
    },
    faq5: {
      id: "5",
      title: "日本有哪些种类的学校？",
      description:
        "赴日留学，大致有三种选择。日语学校：日语水平达到N5级别以上即可留学。您可以与来自世界各地的留学生一起学习日语。根据学校的不同，分为入学课程和就业课程等。大学和研究生院：您可以在日本攻读学士、硕士或博士课程。您需要通过名为EJU的考试才能被录取。虽然有些大学可以使用英语报考，但基本上需要您具备N2级别的日语能力。许多人在上完日语学校后，进入大学或研究生院。专门学校：可以在日本学习专业技能（动画、会计、IT、烹饪等）。具备N2以上级别日语水平、或在日语学校留学半年以上者可以入学。详情如下。",
    },
    faq6: {
      id: "6",
      title: "When will the semistar start?",
      description:
        "日本的学校基本上是3月份毕业，4月份入学。大学和专门学校也有9月份入学的。日语学校在一年中有1月、4月、7月、10月四个入学时段，大家可以根据自己的毕业时间选择入学。请注意，您必须在入学前六个月至一年开始准备文件！有关详细信息，请参考以下文章。",
    },
    faq7: {
      id: "7",
      title: "我想获得奖学金去日本留学！",
      description:
        "日本有142种无需返还的奖学金！（来源：Jasso）还有仅适用于通过CSA进入日语学校者的奖学金。请一定试试申请CSA上的学校！有关详细信息，请参考以下文章。 ",
    },
    faq8: {
      id: "8",
      title: "日本留学费用是多少？",
      description:
        "在日本留学要比在欧美国家留学便宜的多。包括学费和生活费全部在内，一年所需的金额约为200万日元。（视留学目的地和留学内容有所不同）可能为欧美留学金额的1/2到1/3！还可以打工，并且有很多获得奖学金的机会。有关详细信息，请参考以下文章。",
    },
    faq9: {
      id: "9",
      title: "留学生也可以打工吗？",
      description:
        "在日本，允许留学生每周打工28小时内（长期休假时为40小时）。每小时工资约1000日元（9美元），一年最多可以赚到160万日元！        来日留学的人中大约有七成通过打工，赚取生活费和学费。边学习日语边打工，您的在日生活将变得更加丰富！有关详细信息，请参考以下文章。",
    },
    faq10: {
      id: "10",
      title: "怎样能在日本找到工作？",
      description:
        "在日本工作的外国人数量在过去十年中约增加了两倍！在日本工作的机会正在逐年增加。虽说根据工作内容和在留资格有所不同，但在日本找工作至少需要N4级别日语能力。当然，日语水平越高，找工作就越容易。        如果您想在日本工作，那么就来日本留学并掌握日语吧！我们还为想在日本工作的外国朋友提供就业支持！也请一定在这个网站上注册。",
    },
    faq11: {
      id: "11",
      title: "我想在日本大学获取学士/硕士或博士学位",
      description:
        "目前，CSA上的学校信息还不太多，如果您想就读日本的大学或研究生院，那么请查看这个网站。https://www.jpss.jp/ja/",
    },
  },
};
