export default [
  {
    id: "1",
    value: "初級",
    enValue: "Beginner",
  },
  {
    id: "2",
    value: "中級",
    enValue: "Intermediate",
  },
  {
    id: "3",
    value: "上級",
    enValue: "Advanced",
  },
];
