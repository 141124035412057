import { API } from "aws-amplify";
import React, { createContext, useEffect, useState } from "react";
import { postProfilePage2 } from "src/graphql/mutations";
import { getProfilePage } from "src/graphql/queries";
import EducationEnums from "src/core/constants/education_enums";
import { useSelector } from "react-redux";
import { isNullEmptyBlank } from "src/core/utilities/utils";

export const GetPage2DataContext = createContext();

export const GetPage2DataContextProvider = ({ children }) => {
  const [openSuccessBar, setOpenSuccessBar] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuth, role } = useSelector((state) => state.user);

  const defaultEducations = [
    // {
    //   // SHOULD be null (not even empty string) otherwise, update statement will run
    //   id: null,
    //   type: EducationEnums.ELEMENTARY, // Elementary School
    //   nameOfInstitution: "",
    //   location: "",
    //   startYear: "",
    //   startMonth: "",
    //   endYear: "",
    //   endMonth: "",
    //   startDate: "",
    //   endDate: "",
    //   years: "",
    //   isUpdated: false,
    // },
    // {
    //   id: null,
    //   type: EducationEnums.JUNIOR_HIGH, // JUNIOR High School
    //   nameOfInstitution: "",
    //   location: "",
    //   startYear: "",
    //   startMonth: "",
    //   endYear: "",
    //   endMonth: "",
    //   startDate: "",
    //   endDate: "",
    //   years: "",
    //   isUpdated: false,
    // },
    {
      id: null,
      type: EducationEnums.SENIOR_HIGH, // SENIOR High School
      nameOfInstitution: "",
      location: "",
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      startDate: "",
      endDate: "",
      years: "",
      isCurrentlyEnrolled: "",
      isUpdated: false,
    },
  ];
  const [educations, setEducations] = useState([...defaultEducations]);
  const [educationError, setEducationError] = useState([
    {
      type: EducationEnums.SENIOR_HIGH, // SENIOR High School
      nameOfInstitutionErr: "",
      location: "",
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      startDate: "",
      endDate: "",
      years: "",
      isCurrentlyEnrolled: "",
    },
  ]);
  const [professionalCareers, setProfessionalCareers] = useState([]);
  const [professionalCareerError, setProfessionalCareerError] = useState([
    {
      nameOfCompany: "",
      typeOfJob: "",
      location: "",
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      startDate: "",
      endDate: "",
    },
  ]);

  const [studyExperiences, setStudyExperiences] = useState([]);
  const [studyExperiencesError, setStudyExperiencesError] = useState([
    {
      nameOfSchool: "",
      location: "",
      startYear: "",
      startMonth: "",
      endYear: "",
      endMonth: "",
      startDate: "",
      endDate: "",
      hours: "",
    },
  ]);

  const [japaneseProficiencies, setJapaneseProficiencies] = useState([]);
  const [japaneseProficienciesError, setJapaneseProficienciesError] = useState([
    {
      nameOfExam: "",
      // experienceOfTest: "",
      year: "",
      month: "",
      yearOfTest: "",
      proficiencyLevel: "",
      // TODO:
      // result: "",
      score: "",
    },
  ]);
  const [englishProficiencies, setEnglishProficiencies] = useState([]);
  const [englishProficienciesError, setEnglishProficienciesError] = useState([
    {
      nameOfExam: "",
      // experienceOfTest: "",
      year: "",
      month: "",
      yearOfTest: "",
      proficiencyLevel: "",
      // TODO:
      // result: "",
      score: "",
    },
  ]);

  const defaultEnglishLevel = {
    id: null,
    type: "",
    studyType: "",
    isUpdated: false,
  };
  const [englishLevel, setEnglishLevel] = useState({
    ...defaultEnglishLevel,
  });
  const [englishLevelError, setEnglishLevelError] = useState({
    type: "",
  });
  const defaultplanAfterGraduation = {
    id: null,
    type: "",
    studyType: "",
    isUpdated: false,
  };
  const [planAfterGraduation, setPlanAfterGraduation] = useState({
    ...defaultplanAfterGraduation,
  });
  const [planAfterGraduationError, setplanAfterGraduationError] = useState({
    type: "",
  });

  /// TODO: In order to submit only on change:
  /// Consider the case of when user opens the form for the first time,
  /// and no data is input but not submitted since there is no change in education
  const [changeInEducation, setChangeInEducation] = useState(false);

  const fetchProfilePage2 = async () => {
    try {
      const getProfilePage2Data = await API.graphql({
        query: getProfilePage,
        variables: {
          pageTwo: true
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const data = getProfilePage2Data.data.getProfilePage.pageTwo;
      const educationData = data.education;

      if (educationData && Array.isArray(educationData)) {
        let newEducations = [
          ...educationData.map((education) => {
            return {
              id: education.id,
              type: education.type,
              nameOfInstitution: education.nameOfInstitution,
              location: education.location,
              startYear: education.startDate.split("-")[0],
              startMonth: education.startDate.split("-")[1],
              endYear: education.endDate.split("-")[0],
              endMonth: education.endDate.split("-")[1],
              startDate: education.startDate,
              endDate: education.endDate,
              years: education.years,
              isCurrentlyEnrolled: education.isCurrentlyEnrolled,
              isUpdated: false,
            };
          }),
        ];
        setEducations(newEducations);
      }
      const professionalCareerData = data.professionalCareer;
      if (
        professionalCareerData != null &&
        Array.isArray(professionalCareerData)
      ) {
        let newProfessionalCareers = [
          ...professionalCareerData.map((professionalCareer) => {
            return {
              id: professionalCareer.id,
              nameOfCompany: professionalCareer.nameOfCompany,
              typeOfJob: professionalCareer.typeOfJob,
              isCurrentlyWorking: professionalCareer.isCurrentlyWorking,
              location: professionalCareer.location,
              startYear: professionalCareer.startDate.split("-")[0],
              startMonth: professionalCareer.startDate.split("-")[1],
              endYear: professionalCareer.endDate.split("-")[0],
              endMonth: professionalCareer.endDate.split("-")[1],
              startDate: professionalCareer.startDate,
              endDate: professionalCareer.endDate,
              isUpdated: false,
            };
          }),
        ];
        setProfessionalCareers(newProfessionalCareers);
      }

      const japaneseStudyExperienceData = data.japaneseStudyExperience;
      if (
        japaneseStudyExperienceData != null &&
        Array.isArray(japaneseStudyExperienceData)
      ) {
        let newjapaneseStudyExperiences = [
          ...japaneseStudyExperienceData.map((japaneseStudyExperience) => {
            return {
              id: japaneseStudyExperience.id,
              nameOfSchool: japaneseStudyExperience.nameOfSchool,
              location: japaneseStudyExperience.location,
              startYear: japaneseStudyExperience.startDate.split("-")[0],
              startMonth: japaneseStudyExperience.startDate.split("-")[1],
              endYear: japaneseStudyExperience.endDate.split("-")[0],
              endMonth: japaneseStudyExperience.endDate.split("-")[1],
              startDate: japaneseStudyExperience.startDate,
              endDate: japaneseStudyExperience.endDate,
              hours: japaneseStudyExperience.hours,
              isNotComplete: japaneseStudyExperience.isNotComplete,
              isUpdated: false,
            };
          }),
        ];
        setStudyExperiences(newjapaneseStudyExperiences);
      }

      const japaneseAbilityData = data.japaneseAbility;
      if (japaneseAbilityData != null && Array.isArray(japaneseAbilityData)) {
        let newJapaneseProficiencies = [
          ...japaneseAbilityData.map((japaneseAbility) => {
            return {
              id: japaneseAbility.id,
              nameOfExam: japaneseAbility.nameOfExam,
              // experienceOfTest: japaneseAbility.experienceOfTest,
              year: japaneseAbility.yearOfTest.split("-")[0],
              month: japaneseAbility.yearOfTest.split("-")[1],
              yearOfTest: japaneseAbility.yearOfTest,
              proficiencyLevel: japaneseAbility.proficiencyLevel,
              score: japaneseAbility.score,
              isUpdated: false,
            };
          }),
        ];
        setJapaneseProficiencies(newJapaneseProficiencies);
      }
      const englishAbilityData = data.englishAbility;
      if (englishAbilityData != null && Array.isArray(englishAbilityData)) {
        let newenglishProficiencies = [
          ...englishAbilityData.map((japaneseAbility) => {
            return {
              id: japaneseAbility.id,
              nameOfExam: japaneseAbility.testName,
              // experienceOfTest: japaneseAbility.experienceOfTest,
              year: japaneseAbility.yearOfTest.split("-")[0],
              month: japaneseAbility.yearOfTest.split("-")[1],
              yearOfTest: japaneseAbility.yearOfTest,
              proficiencyLevel: japaneseAbility.proficiencyLevel,
              score: japaneseAbility.score,
              isUpdated: false,
            };
          }),
        ];
        setEnglishProficiencies(newenglishProficiencies);
      }

      setEnglishLevel({ ...englishLevel, type: data.englishLangProficiency })

      const planAfterGraduationData = data.planAfterGraduation;
      if (planAfterGraduationData != null) {
        setPlanAfterGraduation({
          id: planAfterGraduationData.id,
          type: planAfterGraduationData.type,
          studyType: planAfterGraduationData.studyType,
          isUpdated: false,
        });
      }
      setIsLoading(false);
      // setisFormLoading(false);
    } catch (err) {
      // setError(t("something_went_wrong"));
      console.log(err);
      setIsLoading(false);
      // setisFormLoading(false);
    }
  };

  useEffect(async () => {
    await fetchProfilePage2();
  }, []);

  /// TODO: Implement validation of all sections
  const validateEducations = () => {
    // const educationError = {};
    const educationError = [
      {
        type: EducationEnums.SENIOR_HIGH, // SENIOR High School
        nameOfInstitutionErr: "",
        location: "",
        startYear: "",
        startMonth: "",
        endYear: "",
        endMonth: "",
        startDate: "",
        endDate: "",
        years: "",
      },
    ];
    let isValid = true;
    /// Check Elementary School
    // let elementary = educations.filter(
    //   (education) =>
    //     education.id != null &&
    //     education.isUpdated == false &&
    //     education.type == EducationEnums.ELEMENTARY
    // );
    // if (elementary.length < 1) {
    //   setEducationError("Elementary Education is required");
    //   document.getElementById("educationBackgroundSection").scrollIntoView();
    //   return false;
    //   /// `isValid = false;` is Required when each item needs to show error message
    //   // isValid = false;
    // }

    // /// Check Junior High School
    // let juniorHighSchool = educations.filter(
    //   (education) =>
    //     education.id != null &&
    //     education.isUpdated == false &&
    //     education.type == EducationEnums.JUNIOR_HIGH
    // );
    // if (juniorHighSchool.length < 1) {
    //   setEducationError("Junior High School Education is required");
    //   document.getElementById("educationBackgroundSection").scrollIntoView();
    //   return false;

    //   // isValid = false;
    // }
    /// Check Senior High School
    // let seniorHighSchool = educations.filter(
    //   (education) =>
    //     education.id != null &&
    //     education.isUpdated == false &&
    //     education.type == EducationEnums.SENIOR_HIGH
    // );
    // if (seniorHighSchool.length < 1) {
    //   setEducationError("Senior High School Education is required");
    //   document.getElementById("educationBackgroundSection").scrollIntoView();
    //   return false;

    //   // isValid = false;
    // }
    {
      Array.isArray(educations) &&
        educations.map((education) => {
          if (isNullEmptyBlank(education.nameOfInstitution)) {
            educationError[0].nameOfInstitutionErr =
              "Name of Institution is null, empty or has blank spaces";
            document
              .getElementById("educationBackgroundSection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(education.startYear)) {
            educationError[0].startYear = "Start year is mandatory";
            document
              .getElementById("educationBackgroundSection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(education.startMonth)) {
            educationError[0].startMonth = "Start month is mandatory";
            document
              .getElementById("educationBackgroundSection")
              .scrollIntoView();
            isValid = false;
          }
          if (education.endYear) {
            if (education.startYear > education.endYear) {
              educationError[0].endYear =
                "End year should be greater than start year";
              document
                .getElementById("educationBackgroundSection")
                .scrollIntoView();
              isValid = false;
            }
          }
          if (education.endMonth) {
            if (education.startYear == education.endYear) {
              if (education.startMonth >= education.endMonth) {
                educationError[0].endMonth =
                  "End month should be greater than start month";
                document
                  .getElementById("educationBackgroundSection")
                  .scrollIntoView();
                isValid = false;
              }
            }
          }
          if (isNullEmptyBlank(education.years)) {
            educationError[0].years = "Year is mandatory";
            document
              .getElementById("educationBackgroundSection")
              .scrollIntoView();
            isValid = false;
          }
          if (education.years < 1 && education.years > 20) {
            educationError[0].years =
              "Year must be greater than one and less than 20";
            document
              .getElementById("educationBackgroundSection")
              .scrollIntoView();
            isValid = false;
          }
        });
    }
    setEducationError(educationError);
    return isValid;
  };
  const validateProfessionalCareers = () => {
    let isValid = true;
    const professionalCareerError = [
      {
        nameOfCompany: "",
        typeOfJob: "",
        location: "",
        startYear: "",
        startMonth: "",
        endYear: "",
        endMonth: "",
        startDate: "",
        endDate: "",
      },
    ];
    {
      Array.isArray(professionalCareers) &&
        professionalCareers.map((professionalCarrer) => {
          if (isNullEmptyBlank(professionalCarrer.nameOfCompany)) {
            professionalCareerError[0].nameOfCompany =
              "Name of Company is needed";
            document
              .getElementById("professionalCareerSection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(professionalCarrer.typeOfJob)) {
            professionalCareerError[0].typeOfJob = "Type of Job is needed";
            document
              .getElementById("professionalCareerSection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(professionalCarrer.startYear)) {
            professionalCareerError[0].startYear = "Start year is mandatory";
            document
              .getElementById("professionalCareerSection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(professionalCarrer.startMonth)) {
            professionalCareerError[0].startMonth = "Start month is mandatory";
            document
              .getElementById("professionalCareerSection")
              .scrollIntoView();
            isValid = false;
          }
          if (professionalCarrer.endYear) {
            if (professionalCarrer.startYear > professionalCarrer.endYear) {
              professionalCareerError[0].endYear =
                "End year should be greater than start year";
              document
                .getElementById("professionalCareerSection")
                .scrollIntoView();
              isValid = false;
            }
          }
          if (professionalCarrer.endMonth) {
            if (professionalCarrer.startYear == professionalCarrer.endYear) {
              if (professionalCarrer.startMonth >= professionalCarrer.endMonth) {
                professionalCareerError[0].endMonth =
                  "End month should be greater than start month";
                document
                  .getElementById("professionalCareerSection")
                  .scrollIntoView();
                isValid = false;
              }
            }
          }
        });
    }
    setProfessionalCareerError(professionalCareerError);
    return isValid;
  };
  const validateStudyExperiences = () => {
    let isValid = true;
    const studyExperiencesError = [
      {
        nameOfSchool: "",
        location: "",
        startYear: "",
        startMonth: "",
        endYear: "",
        endMonth: "",
        startDate: "",
        endDate: "",
        hours: "",
      },
    ];
    {
      Array.isArray(studyExperiences) &&
        studyExperiences.map((studyExperience) => {
          if (isNullEmptyBlank(studyExperience.nameOfSchool)) {
            studyExperiencesError[0].nameOfSchool = "Name of School is needed";
            document
              .getElementById("experienceStudyingJapaneseSection")
              .scrollIntoView();
            isValid = false;
          }
          // if (isNullEmptyBlank(studyExperience.location)) {
          //   studyExperiencesError[0].location = "Location is mandatory";
          //   document
          //     .getElementById("experienceStudyingJapaneseSection")
          //     .scrollIntoView();
          //   isValid = false;
          // }
          if (isNullEmptyBlank(studyExperience.startYear)) {
            studyExperiencesError[0].startYear = "Start year is mandatory";
            document
              .getElementById("experienceStudyingJapaneseSection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(studyExperience.startMonth)) {
            studyExperiencesError[0].startMonth = "Start month is mandatory";
            document
              .getElementById("experienceStudyingJapaneseSection")
              .scrollIntoView();
            isValid = false;
          }

          if (studyExperience.endYear) {
            if (studyExperience.startYear > studyExperience.endYear) {
              studyExperiencesError[0].endYear =
                "End year should be greater than start year";
              document
                .getElementById("experienceStudyingJapaneseSection")
                .scrollIntoView();
              isValid = false;
            }
          }
          if (studyExperience.endMonth) {
            if (studyExperience.startYear == studyExperience.endYear) {
              if (studyExperience.startMonth >= studyExperience.endMonth) {
                studyExperiencesError[0].endMonth =
                  "End month should be greater than start month";
                document
                  .getElementById("experienceStudyingJapaneseSection")
                  .scrollIntoView();
                isValid = false;
              }
            }
          }
          // if (studyExperience.endYear === "") {
          //   studyExperiencesError[0].endYear = "End year is mandatory";
          //   document
          //     .getElementById("experienceStudyingJapaneseSection")
          //     .scrollIntoView();
          //   isValid = false;
          // }
          // if (isNullEmptyBlank(studyExperience.endMonth)) {
          //   studyExperiencesError[0].endMonth = "End month is mandatory";
          //   document
          //     .getElementById("experienceStudyingJapaneseSection")
          //     .scrollIntoView();
          //   isValid = false;
          // }
          if (isNullEmptyBlank(studyExperience.hours)) {
            studyExperiencesError[0].hours = "Hours is mandatory";
            document
              .getElementById("experienceStudyingJapaneseSection")
              .scrollIntoView();
            isValid = false;
          }
        });
    }
    setStudyExperiencesError(studyExperiencesError);
    return isValid;
  };
  const validateJapaneseProficiencies = () => {
    let isValid = true;
    const japaneseProficienciesError = [
      {
        nameOfExam: "",
        // experienceOfTest: "",
        year: "",
        month: "",
        yearOfTest: "",
        proficiencyLevel: "",
        // TODO:
        // result: "",
        score: "",
      },
    ];
    {
      Array.isArray(japaneseProficiencies) &&
        japaneseProficiencies.map((japaneseProficiencie) => {
          // if (isNullEmptyBlank(japaneseProficiencie.experienceOfTest)) {
          //   japaneseProficienciesError[0].experienceOfTest =
          //     "Experience of test is mandatory";
          //   document
          //     .getElementById("japaneseProficiencySection")
          //     .scrollIntoView();
          //   isValid = false;
          // }
          if (isNullEmptyBlank(japaneseProficiencie.year)) {
            japaneseProficienciesError[0].year = "Year is mandatory";
            document
              .getElementById("japaneseProficiencySection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(japaneseProficiencie.month)) {
            japaneseProficienciesError[0].month = "Month is mandatory";
            document
              .getElementById("japaneseProficiencySection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(japaneseProficiencie.proficiencyLevel)) {
            japaneseProficienciesError[0].proficiencyLevel =
              "Proficiency level is mandatory";
            document
              .getElementById("japaneseProficiencySection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(japaneseProficiencie.score)) {
            japaneseProficienciesError[0].score = "Score is mandatory";
            document
              .getElementById("japaneseProficiencySection")
              .scrollIntoView();
            isValid = false;
          }
        });
    }

    setJapaneseProficienciesError(japaneseProficienciesError);
    return isValid;
  };
  const validateEnglishProficiencies = () => {
    let isValid = true;
    const englishProficienciesError = [
      {
        nameOfExam: "",
        // experienceOfTest: "",
        year: "",
        month: "",
        yearOfTest: "",
        proficiencyLevel: "",
        // TODO:
        // result: "",
        score: "",
      },
    ];
    // eslint-disable-next-line no-lone-blocks
    {
      Array.isArray(englishProficiencies) &&
        englishProficiencies.map((japaneseProficiencie) => {
          if (isNullEmptyBlank(japaneseProficiencie.year)) {
            englishProficienciesError[0].year = "Year is mandatory";
            document
              .getElementById("japaneseProficiencySection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(japaneseProficiencie.month)) {
            englishProficienciesError[0].month = "Month is mandatory";
            document
              .getElementById("japaneseProficiencySection")
              .scrollIntoView();
            isValid = false;
          }
          if (isNullEmptyBlank(japaneseProficiencie.score)) {
            englishProficienciesError[0].score = "Score is mandatory";
            document
              .getElementById("japaneseProficiencySection")
              .scrollIntoView();
            isValid = false;
          }
        });
    }

    setEnglishProficienciesError(englishProficienciesError);
    return isValid;
  };
  const validateEnglishLevel = () => {
    let isValid = true;
    if (isNullEmptyBlank(englishLevel.type)) {
      setEnglishLevelError({ type: "English level is mandatory!" })
      isValid = false
    }
    return isValid;
  };
  const validatePlanAfterGraduation = () => {
    let isValid = true;
    const planAfterGraduationError = {
      type: "",
      studyType: "",
    };
    if (isNullEmptyBlank(planAfterGraduation.type)) {
      planAfterGraduationError.type = "Plan After Graduation is mandatory";
      isValid = false;
    }
    // if (isNullEmptyBlank(planAfterGraduation.studyType)) {
    //   planAfterGraduationError.type = "Type is mandatory";
    //   isValid = false;
    // }
    setplanAfterGraduationError(planAfterGraduationError);
    return isValid;
  };

  const validateForm = () => {
    if (!validateEducations()) {
      return false;
    }
    if (!validateProfessionalCareers()) {
      return false;
    }
    if (!validateStudyExperiences()) {
      return false;
    }
    if (!validateJapaneseProficiencies()) {
      return false;
    }
    if (!validateEnglishLevel) {
      return false;
    }
    if (!validatePlanAfterGraduation()) {
      return false;
    }

    return true;
  };

  const validateFormOnBoardEducationExperience = () => {
    if (!validateEducations()) {
      return false;
    }
    if (!validateProfessionalCareers()) {
      return false;
    }

    return true;
  };
  const validateFormOnBoardOthers = () => {

    if (!validateStudyExperiences()) {
      return false;
    }
    if (!validateJapaneseProficiencies()) {
      return false;
    }

    if (!validateEnglishProficiencies()) {
      return false;
    }
    if (!validateEnglishLevel()) {
      return false;
    }
    if (!validatePlanAfterGraduation()) {
      return false;
    }

    return true;
  };

  const onSubmitHandler = async () => {
    setIsLoading(true);

    if (isAuth) {
      let educationsToSubmit = educations.reduce(function (result, education) {
        if (education.isUpdated == true) {
          result.push({
            id: education.id,
            type: education.type,
            nameOfInstitution: education.nameOfInstitution,
            location: education.location,
            startDate: `${education.startYear}-${education.startMonth}`,
            endDate: `${education.endYear}-${education.endMonth}`,
            isCurrentlyEnrolled: education.isCurrentlyEnrolled,
            years: education.years,
          });
        }
        return result;
      }, []);

      let professionalCareersToSubmit = professionalCareers.reduce(function (
        result,
        professionalCareer
      ) {
        if (professionalCareer.isUpdated == true) {
          result.push({
            id: professionalCareer.id,
            nameOfCompany: professionalCareer.nameOfCompany,
            typeOfJob: professionalCareer.typeOfJob,
            isCurrentlyWorking: professionalCareer.isCurrentlyWorking,
            location: professionalCareer.location,
            startDate: `${professionalCareer.startYear}-${professionalCareer.startMonth}`,
            endDate: `${professionalCareer.endYear}-${professionalCareer.endMonth}`,
          });
        }
        return result;
      },
        []);

      let studyExperiencesToSubmit = studyExperiences.reduce(function (
        result,
        studyExperience
      ) {
        if (studyExperience.isUpdated == true) {
          result.push({
            id: studyExperience.id,
            nameOfSchool: studyExperience.nameOfSchool,
            location: studyExperience.location,
            startDate: `${studyExperience.startYear}-${studyExperience.startMonth}`,
            endDate: `${studyExperience.endYear}-${studyExperience.endMonth}`,
            hours: studyExperience.hours,
            isNotComplete: studyExperience.isNotComplete,
          });
        }
        return result;
      },
        []);

      let japaneseProficienciesToSubmit = japaneseProficiencies.reduce(function (
        result,
        japaneseProficiency
      ) {
        if (japaneseProficiency.isUpdated == true) {
          result.push({
            id: japaneseProficiency.id,
            nameOfExam: japaneseProficiency.nameOfExam,
            // experienceOfTest: japaneseProficiency.experienceOfTest,
            yearOfTest: `${japaneseProficiency.year}-${japaneseProficiency.month}`,
            proficiencyLevel: japaneseProficiency.proficiencyLevel,
            score: japaneseProficiency.score,
          });
        }
        return result;
      },
        []);

      let planAfterGraduationToSubmit =
        planAfterGraduation.isUpdated == true
          ? {
            id: planAfterGraduation.id,
            type: planAfterGraduation.type,
            studyType: planAfterGraduation.studyType,
          }
          : null;

      let englishProficienciesToSubmit = englishProficiencies.reduce(function (
        result,
        japaneseProficiency
      ) {
        if (japaneseProficiency.isUpdated == true) {
          result.push({
            id: japaneseProficiency.id,
            testName: japaneseProficiency.nameOfExam,
            // experienceOfTest: japaneseProficiency.experienceOfTest,
            yearOfTest: `${japaneseProficiency.year}-${japaneseProficiency.month}`,
            // proficiencyLevel: japaneseProficiency.proficiencyLevel,
            score: japaneseProficiency.score,
          });
        }
        return result;
      },
        []);

      try {
        const postProfilePage2Data = await API.graphql({
          query: postProfilePage2,
          variables: {
            profilePage2Input: {
              educationInputs: educationsToSubmit,
              professionalCareerInputs: professionalCareersToSubmit,
              japaneseStudyExperienceInputs: studyExperiencesToSubmit,
              japaneseAbilityInputs: japaneseProficienciesToSubmit,
              planAfterGraduationInputs: planAfterGraduationToSubmit,
              englishAbilityInputs: englishProficienciesToSubmit,
              englishLangProficiency: englishLevel?.type
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        await fetchProfilePage2();
        setOpenSuccessBar(true);
      } catch (err) {
        setIsLoading(false);
        console.log(err);

      }
    }
  };
  let page2DataValue = {
    educations,
    setEducations,
    educationError,
    professionalCareers,
    setProfessionalCareers,
    professionalCareerError,
    studyExperiences,
    setStudyExperiences,
    studyExperiencesError,
    japaneseProficiencies,
    setJapaneseProficiencies,
    japaneseProficienciesError,

    englishProficiencies,
    setEnglishProficiencies,
    englishProficienciesError,

    englishLevel,
    setEnglishLevel,
    englishLevelError,

    planAfterGraduation,
    setPlanAfterGraduation,
    planAfterGraduationError,

    openSuccessBar,
    setOpenSuccessBar,
    isLoading,
    onSubmitHandler,
    validateForm,
    validateFormOnBoardEducationExperience,
    validateFormOnBoardOthers,
    fetchProfilePage2,
  };

  return (
    <GetPage2DataContext.Provider value={page2DataValue}>
      {children}
    </GetPage2DataContext.Provider>
  );
};

export default GetPage2DataContextProvider;
