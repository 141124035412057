import { API } from "aws-amplify";
import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getLangCodeForApiCall } from "src/core/utilities/utils";
import { getMasterData } from "src/graphql/queries";
import { setNewLanguage } from "src/state/ducks/ui";

export const GetMasterDataContext = createContext();

export const GetMasterDataContextProvider = ({ children }) => {
  const dispatch = useDispatch();
  const { lang } = useParams();
  const languageCode = useSelector((store) => store.language);
  const [masterNationality, setMasterNationality] = useState([]);
  const [masterSchoolTypes, setMasterSchoolTypes] = useState([]);
  const [masterGender, setMasterGender] = useState([]);
  const [masterSNS, setMasterSNS] = useState([]);
  const [masterJapaneseExam, setMasterJapaneseExam] = useState([]);
  const [masterJapaneseExamLevel, setMasterJapaneseExamLevel] = useState([]);
  const [masterExamExperience, setMasterExamExperience] = useState([]);
  const [masterExamResult, setMasterExamResult] = useState([]);
  const [masterCourseTypes, setMasterCourseTypes] = useState([]);
  const [masterEnrollmentMonths, setMasterEnrollmentMonths] = useState([]);
  const [masterCourseLengthType, setMasterCourseLengthType] = useState([]);
  const [masterPrefecture, setMasterPrefecture] = useState([]);
  const [masterJapaneseLevel, setMasterJapaneseLevel] = useState([]);
  const [masterEnglishLevel, setMasterEnglishLevel] = useState([]);
  const [masterPlanAfterGraduation, setMasterPlanAfterGraduation] = useState(
    []
  );
  const [masterStatusOfResidence, setMasterStatusOfResidence] = useState([]);
  const [masterStudyType, setMasterStudyType] = useState([]);
  const [masterStudentFindCSA, setMasterStudentFindCSA] = useState([]);
  const [masterSchoolTypeListForSchool, setMasterSchoolTypeListForSchool] = useState([]);

  const fetchMasterData = async () => {
    try {
      const masterDataFromAPI = await API.graphql({
        query: getMasterData,
        variables: {
          masterdata: {
            prefectureList: true,
            voyJapaneseCourseTypesList: true,
            voyCourseLengthTypeList: true,
            voyEnrollmentMonthsList: true,
            voyJapaneseLevelList: true,
            countryList: true,
            snsList: true,
            voySchoolTypesList: true,
            genderList: true,
            japaneseExamList: true,
            japaneseExamLevelList: true,
            englishLevelList: true,
            examExperienceList: true,
            examResultList: true,
            voyPlanAfterGraduationList: true,
            studentFindCsaList: true,
            voyStudyType: true,
            voySchoolTypesList1: true,
            language: getLangCodeForApiCall(languageCode),
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const masterData = masterDataFromAPI.data.getMasterData;

      if (masterData != null && Array.isArray(masterData)) {

        let countryList = masterData.filter(
          (item) => item.type == "countryList"
        )[0].items;
        setMasterNationality(countryList);

        let prefectureList = masterData.filter(
          (item) => item.type == "prefectureList"
        )[0].items;
        setMasterPrefecture(prefectureList);

        let studentFindCsaList = masterData.filter(
          (item) => item.type == "studentFindCsaList"
        )[0].items;
        setMasterStudentFindCSA(studentFindCsaList);

        let voyJapaneseLevelList = masterData.filter(
          (item) => item.type == "voyJapaneseLevelList"
        )[0].items;
        setMasterJapaneseLevel(voyJapaneseLevelList);

        let voyJapaneseCourseTypesList = masterData.filter(
          (item) => item.type == "voyJapaneseCourseTypesList"
        )[0].items;
        setMasterCourseTypes(voyJapaneseCourseTypesList);

        let voyCourseLengthTypeList = masterData.filter(
          (item) => item.type == "voyCourseLengthTypeList"
        )[0].items;
        setMasterCourseLengthType(voyCourseLengthTypeList);

        let voyEnrollmentMonthsList = masterData.filter(
          (item) => item.type == "voyEnrollmentMonthsList"
        )[0].items;
        setMasterEnrollmentMonths(voyEnrollmentMonthsList);

        let snsList = masterData.filter((item) => item.type == "snsList")[0]
          .items;
        setMasterSNS(snsList);

        let voySchoolTypesList = masterData.filter(
          (item) => item.type == "voySchoolTypesList"
        )[0].items;
        setMasterSchoolTypes(voySchoolTypesList);

        let genderList = masterData.filter(
          (item) => item.type == "genderList"
        )[0].items;
        setMasterGender(genderList);

        let japaneseExamList = masterData.filter(
          (item) => item.type == "japaneseExamList"
        )[0].items;
        setMasterJapaneseExam(japaneseExamList);

        let japaneseExamLevelList = masterData.filter(
          (item) => item.type == "japaneseExamLevelList"
        )[0].items;
        setMasterJapaneseExamLevel(japaneseExamLevelList);

        let englishLevel = masterData.filter(
          (item) => item.type == "englishLevelList"
        )[0].items;
        setMasterEnglishLevel(englishLevel);

        let examExperienceList = masterData.filter(
          (item) => item.type == "examExperienceList"
        )[0].items;
        setMasterExamExperience(examExperienceList);

        let examResultList = masterData.filter(
          (item) => item.type == "examResultList"
        )[0].items;
        setMasterExamResult(examResultList);

        let voyPlanAfterGraduationList = masterData.filter(
          (item) => item.type == "voyPlanAfterGraduationList"
        )[0].items;
        setMasterPlanAfterGraduation(voyPlanAfterGraduationList);

        let voyStudyTypeList = masterData.filter(
          (item) => item.type == "voyStudyType"
        )[0].items;
        setMasterStudyType(voyStudyTypeList);
        let voySchoolTypeList1 = masterData.filter(
          (item) => item.type == "voySchoolTypesList1"
        )[0].items;
        setMasterSchoolTypeListForSchool(voySchoolTypeList1)
      }
    } catch (err) {
      console.log("masterDataFromAPI error in root view: ", err);
    }
  };

  useEffect(async () => {
    if (lang) dispatch(setNewLanguage(lang));
    fetchMasterData();
  }, [languageCode]);

  let masterDataValue = {
    masterNationality,
    masterCourseTypes,
    masterSchoolTypes,
    masterGender,
    masterJapaneseExam,
    masterJapaneseExamLevel,
    masterEnglishLevel,
    masterExamExperience,
    masterExamResult,
    masterPlanAfterGraduation,
    masterStatusOfResidence,
    masterSNS,
    masterStudyType,
    masterEnrollmentMonths,
    masterCourseLengthType,
    masterPrefecture,
    masterJapaneseLevel,
    masterStudentFindCSA,
    masterSchoolTypeListForSchool,
    fetchMasterData,
  };
  return (
    <GetMasterDataContext.Provider value={masterDataValue}>
      {children}
    </GetMasterDataContext.Provider>
  );
};
