// External components
import React from "react";
import { Link } from "react-router-dom";

import logo from "src/assets/images/common/logo.png";

const Logo = ({ role, isSchoolPublicScreen }) => {
  return (
    <Link
      to={
        role == "school" || isSchoolPublicScreen == true ? "/school_top" : "/"
      }
    >
      <img
        src={logo}
        alt="logo"
        className="image-fluid object-fit-contain"
        height="50px"
        width="100%"

      />
    </Link>
  );
};

export default Logo;
