export const reactHotToastError = {
    style: {
        borderRadius: '0px',
        padding: '16px',
        backgroundColor: "red",
        color: '#fff',
    },
    iconTheme: {
        primary: 'red',
    },
}
export const reactHotToastSuccess = {
    style: {
        borderRadius: '0px',
        padding: '16px',
        backgroundColor: "green",
        color: '#fff',
    },
    iconTheme: {
        primary: 'green',
    },
}