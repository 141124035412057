import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

const ChatHeader = ({ channel, user, onBack, role }) => (
  <div className="custom-channel-header">
    <button onClick={onBack}>
      <FontAwesomeIcon className="fs-4 text-primary" icon={faArrowLeft} />
    </button>
    <span>

      {role == "school" && Array.isArray(channel.members) &&
        channel.members.map((id) => (
          <>
            {id.nickname == channel.name && id.nickname}
          </>
        ))}
      {role == "student" && Array.isArray(channel.members) &&
        channel.members.map((id) => (
          <>
            {id.nickname !== channel.name && id.nickname}
          </>
        ))}
    </span>
  </div>
)

export default ChatHeader
