import { createSlice } from "@reduxjs/toolkit";

const modalSlice = createSlice({
  name: "modal",
  initialState: true,
  reducers: {},
});

const breadcrumbSlice = createSlice({
  name: "breadcrumb",
  initialState: null,
  reducers: {
    addBreadcrumb: (state, { payload }) => {
      return payload;
    },
  },
});

const themeSlice = createSlice({
  name: "theme",
  // initialState: "violet",
  initialState: "default",
  reducers: {
    toggleTheme: (state, { payload }) => {
      console.log("theme from payload", payload);
      document.body.setAttribute("data-theme", payload);
      return payload;
    },
  },
});

const languageKey = "languagePref";
const languageSlice = createSlice({
  name: "language",
  initialState: "en",
  reducers: {
    getFromLocalAndSet: (state, { payload }) => {
      let langFromLocal = localStorage.getItem(languageKey);
      if (langFromLocal) {
        state = langFromLocal;
      }
      return state;
    },
    setNewLanguage: (state, { payload }) => {
      // only change or update the state when the values are different
      if (state != payload) {
        if (["en", "ja", "vi", "id", "np", "cn", "ko"].includes(payload)) {
          // console.log("valid language codes");
          localStorage.setItem(languageKey, payload);
          state = payload;
        }
      }
      return payload;
    },
  },
});

export const { toggleTheme } = themeSlice.actions;

//for tacking breadcrums when 2 layer deep into pages
export const { addBreadcrumb } = breadcrumbSlice.actions;

export const { getFromLocalAndSet, setNewLanguage } = languageSlice.actions;

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  uiModal: modalSlice.reducer,
  breadcrumb: breadcrumbSlice.reducer,
  theme: themeSlice.reducer,
  language: languageSlice.reducer,
};
