import { faSchool } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

const getTimeFromTimestamp = (timestamp) => {
  const date = new Date(timestamp);
  const msgFromToCurrentDate = moment(date).fromNow();
  return msgFromToCurrentDate;
};

export const MyCustomChannelPreview = ({ channel }) => {
  return (
    <div className="d-flex channel_left_list">
      {Array.isArray(channel.members) &&
        channel.members.map((id) => (
          <>
            {id.metaData.userType === "school" ||
            id.metaData.userType === "School" ? (
              <>
                <div key={id.nickname} className="channel_left_list_image">
                  <FontAwesomeIcon
                    style={{ height: "40px", width: "40px" }}
                    icon={faSchool}
                  />
                </div>
                {/* <div className="d-block"> */}
                <div style={{ textAlign: "left" }}>
                  <div
                    className={`${
                      channel.unreadMessageCount < 1
                        ? "channel_left_list_name"
                        : "channel_left_list_name_unread_msg"
                    }`}
                  >
                    {" "}
                    {id.nickname ? id.nickname : id.metaData.schoolId}{" "}
                  </div>
                  <div className="d-flex">
                    <div
                      className={`${
                        channel.unreadMessageCount < 1
                          ? "channel_left_list_message"
                          : "channel_left_list_message_unread_msg"
                      }`}
                    >
                      {" "}
                      {channel.lastMessage.message}{" "}
                    </div>
                    <div className="channel_left_list_time">
                      {" "}
                      {getTimeFromTimestamp(channel.lastMessage.createdAt)}{" "}
                    </div>
                  </div>
                </div>
                {/* </div> */}
                {/* <div className="d-flex">
                  {showLeaveButton ? (
                    <div>
                      <button
                        className="channel_left_list_leave"
                        onMouseLeave={() => setIsShown(false)}
                        // onClick={() => {
                        //   const callback = () => {
                        //     console.warn("Leave channel success");
                        //   };
                        //   onLeaveChannel(channel, callback);
                        // }}
                        onClick={() => alert("hello")}
                      >
                        {" "}
                        <div>leave</div>
                      </button>
                    </div>
                  ) : (
                    ""
                  )}
                  <div
                    className="channel_left_list_dot-wrapper"
                    // onMouseEnter={() => setIsShown(true)}
                    onClick={leaveButtonClicked}
                  >
                    <div className="channel_left_list_dot"></div>
                    <div className="channel_left_list_dot"></div>
                    <div className="channel_left_list_dot"></div>
                  </div>
                </div> */}
                {channel.unreadMessageCount < 1 ? (
                  ""
                ) : (
                  <div className="channel_left_list_message_count">
                    {channel.unreadMessageCount}
                  </div>
                )}
              </>
            ) : (
              ""
            )}
          </>
        ))}

      {/* <img height="20px" width="20px" src={channel.coverUrl} /> */}
    </div>
  );
};
