import { combineReducers } from "redux";

import apiData from "./apiData";
import pricingPlan from "./pricingPlan";
import studentProfile from "./student/profile";
import ui from "./ui";
import user from "./user";
// import student from "../reducers/student";

const reducer = combineReducers({
  ...studentProfile,
  ...ui,
  ...pricingPlan,
  ...user,
  ...apiData,
});

export default reducer;
