export default [
  // {
  //   id: "",
  //   value: "以下から選択してください",
  //   enValue: "Select one",
  // },
  {
    id: "1",
    value: "一般日本語",
    enValue: "General Japanese",
  },
  {
    id: "2",
    value: "進学",
    enValue: "University Preparation",
  },
  {
    id: "3",
    value: "就職",
    enValue: "Business Employment",
  },
  {
    id: "4",
    value: "専門学校",
    enValue: "Vocational School",
  },
  {
    id: "5",
    value: "その他",
    enValue: "Others",
  },
];
