import React, { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const SnackbarIn = (props) => {
  const {
    openSuccessBar,
    setOpenSuccessBar,
    openErrorBar,
    setOpenErrorBar,
    error,
    snackbarErrorMsg,
    snackbarSuccessMsg,
  } = props;

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessBar(false);
  };
  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorBar(false);
  };
  return (
    <>
      <Snackbar
        open={openSuccessBar}
        autoHideDuration={3000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {snackbarSuccessMsg}
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorBar}
        autoHideDuration={3000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error != null && error != "" && error}
          {error == null || (error == "" && snackbarErrorMsg)}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarIn;
