import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";

import reducer from "./ducks";

const middleware = [...getDefaultMiddleware()];

export default function configureDucksStore(preloadedState = {}) {
  return configureStore({
    middleware,
    reducer,
    preloadedState,
    devTools: process.env.REACT_APP_ENVIRONMENT !== "production",
  });
}

/**
optimization for future
1--middileware with logger ---->
import logger from 'redux-logger'
middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger),  enhancers: [reduxBatch],
2---redux batch enancher ---->
import { reduxBatch } from '@manaflair/redux-batch'
enhancers: [reduxBatch],
*/
