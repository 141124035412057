// eslint-disable-next-line import/no-anonymous-default-export
export default {
  student_tag_line_heading: "Học tập & Làm việc tại Nhật Bản!",
  language_code: "vi",
  language_title: "Tiếng Viêt",
  welcome_to_connect: "Welcome to Connect.",
  Login: "Đăng nhập",
  school_login_title: "Đăng nhập Trường",
  login_btn: "Đăng nhập",
  sign_up: "Đăng ký",
  enter_your_email: "Điền email của bạn",
  enter_your_password: "Điền mật khẩu của bạn",
  school_signup_title: "Đăng ký Trường",
  signup_btn: "Đăng ký",
  logout: "Đăng xuất",
  change_password: "Đổi mật khẩu",
  current_password: "Điền mật khẩu hiện tại",
  new_password: "Điền mật khẩu mới",
  confirm_password: "Confirm password",
  incorrect_password: "Mật khẩu hiện tại không đúng",
  forgot_password: "Bạn đã quên mật khẩu?",
  valid_password: "Mật khẩu phải có 8 ký tự với chữ hoa, chữ thường và một số",
  login_for_school_only_msg:
    "Xin lỗi, đây là biểu mẫu đăng nhập chỉ dành cho trường học",
  login_for_student_only_msg:
    "Xin lỗi, đây là biểu mẫu đăng nhập chỉ dành cho học sinh",
  home: "Trang chủ",
  School: "Trường",
  Dashboard: "Dashboard",
  blog: "Blog",
  Contact: "Contact",
  schools: "Trường",
  courses: "Khoá học",
  applications: "Nộp đơn",
  about_us: "About Us",
  contact_us: "Liên hệ với chúng tôi",
  profile: "Hồ sơ",
  for_inquiries: "Đối với bất kỳ yêu cầu",
  inquiry_form: "Student Inquiry Form",
  for_business_inquiries: "For inquiries about the service, click here.",
  business_inquiry_form: "Business Inquiry Form",
  submit: "Submit",
  language: "Ngôn ngữ",
  view_all: "Xem tất cả",
  our_services: "Dịch vụ của chúng tôi",
  course_search: "Tìm khoá học",
  school_search: "Tìm trường",
  our_other_services: "Dịch vụ khác của chúng tôi",
  privacy_policy: "Chính sách bảo mật ",
  terms_of_service: "Điều khoản dịch vụ",
  page_not_found: "Xin lỗi, không thể tìm thấy trang",
  student_tag_line_subheading:
    "Choose a unique study abroad program that suits you",
  find_best_matched_course: "Tìm khóa học phù hợp nhất của bạn",
  recommended_schools_msg: "Trường học tốt nhất của chúng tôi dành cho bạn",
  popular_schools: "Các trường phổ biến",
  statistical_facts: "Một vài điều thống kê",
  courses_listed: "Danh sách các khoá học",
  schools_listed: "Danh sách các trường",
  no_results_found: "Xin lỗi, kết quả không tìm thấy",
  go_to_home: "Đi về nhà",
  office_address: "Địa chỉ văn phòng",
  read_more: "Xêm thêm",
  phone_number: "Số điện thoại",
  something_went_wrong: "Something went wrong",
  area: {
    title: "Khu vực",
    value: {
      hokkaido: "hokkaido",
      tohoku: "tohoku",
      kanto: "kanto",
      chubu: "chubu",
      kansai: "kansai",
      chugoku: "chugoku",
      shikoku: "shikoku",
      kyushu: "kyushu",
    },
  },
  course_type: {
    title: "Loại khoá học",
    value: {
      general_japanese: "Tiếng Nhật thông dụng",
      university_preparation: "Dự bị đại học",
      business_employment: "Việc làm kinh doanh",
      others: "Khác",
    },
  },
  course_duration: {
    title: "Thời gian khoá học",
    value: {
      short: "Ngắn",
      long: "Dài",
    },
  },
  enrollment_month: {
    title: "Tháng nhập học",
    value: {
      april: "Tháng tư",
      july: "Tháng tư",
      october: "Tháng mười",
      january: "Tháng một",
    },
  },
  japanese_level: {
    title: "Trình độ Nhật ngữ",
    value: {
      beginner: "Người mới bắt đầu",
      intermediate: "trình độ trung cấp",
      advance: "Cao cấp",
    },
  },
  course_info: {
    value: {
      course_name: "Tên khoá học",
      course_outline: "Tóm tắt nội dung chương trình",
      course_description: "Diễn tả khoá học",
      applicable_student: "Sinh viên ứng tuyển",
      visa_type: "Loại visa",
      beginning_period: "Thời kỳ bắt đầu",
      learning_content: "Nội dung học tập",
      application_period: "Thời gian nộp đơn",
      curriculum: "Chương trình giảng dạy",
      course_goal: "Mục tiêu khóa học",
      curriculum_description: "Mô tả chương trình học",
      tuition: "Học phí(JPY)",
      screening_fee: "Phí xét hồ sơ",
      enrollment_fee: "Phí nhập học",
      class_fee: "Phí lớp học",
      facility_fee: "Phí cơ sơ vật chất",
      total: "Tổng cộng",
      tuition_fee_notes: "Notes for the tuition fee",
    },
  },
  school_info: {
    value: {
      school_name: "Tên trường",
      school_logo: "Logo trường",
      available_course_type: "Loại khóa học hiện có",
      available_course_length: "Độ dài khoá học",
      school_introduction: "Giới thiệu về trường",
      basic_information: "Thông tin cơ bản",
      location: "Địa điểm",
      nearest_station: "Ga tàu gần nhất",
      phone_number: "Số điện thoại",
      fax_number: "Số Fax",
      school_website: "Website trường",
      email: "email",
      founder: "Người sáng lập",
      director: "Hiệu trưởng",
      authorized_date: "Ngày có hiệu lực",
      establishmentDate: "Ngày thành lập",
      faculty_staff: "Đội ngũ giảng viên",
      num_of_faculty_full_time: "Số lượng giảng viên (Toàn thời gian)",
      assisstant_for_housing: "Người hỗ trợ cho nhà ở",
      foreign_speaking_staff:
        "Nhân viên có thể sử dụng tiếng Anh, tiếng Trung, tiếng Hàn etc..",
      students: "Hoc sinh",
      quota_of_school: "Hạn ngạch của trường",
      total_num_students: "Tổng số học sinh",
      major_countries_students: "Đất nước có nhiều học sinh",
      grads_this_year: "Dự định tốt nghiệp (cuối năm nay)",
      grads_last_year: "Số lượng tốt nghiệp (năm ngoái)",
      after_graduation: "Sau khi tốt nghiệp",
      jlpt_passer: "Số lượng học sinh đậu kỳ thi JLPT",
      admission_selection: "Nhận vào",
      selection_method: "selection method",
      application_fee: "Phí nộp đơn",
      deadline: "Hạn cuối",
      mailing_address: "Địa chỉ gửi thư",
      pr_school: "PR từ trường",
      message_from_school: "Thông điệp từ trường",
      images: "Hình ảnh",
      videos: "Video",
      school_year_calendar: "Lịch năm học (lịch sự kiện)",
      scholarship_if_available: "Học bổng (nếu có)",
      facility: "Cơ sở vật chấtÏ",
    },
  },
  confirm_account_modal_header: "Xác nhận tài khoản của bạn",
  confirm_account_modal_body:
    "Xin lỗi, đăng nhập không thành công! Vui lòng xác nhận tài khoản của bạn trước bằng xác minh email.",
  confirm: "Confirm",
  cancel: "Cancel",
  email: "Email Address",
  password: "mật khẩu",
  do_not_have_account: "Bạn không có tài khoản?",
  validation_empty_email: "Email Address cannot be empty",
  validation_empty_password: "Password cannot be empty",
  user_agreement_prefix: "I agree to the Connect Study Abroad ",
  user_agreement_conjunction: " and ",
  user_agreement_postfix: "",
  user_agreement_validation:
    "Vui lòng đồng ý với Điều khoản dịch vụ và Chính sách quyền riêng tư",
  already_have_account: "Already have an account?",
  verification_code_sent:
    "We have sent a code by email. Enter it below to confirm your account",
  confirm_account: "Confirm Account",
  did_not_receive_code: "Didn't receive a code?",
  resend: "Resend it",
  verification_title: "Verification",
  passwords_do_not_match: "Mật khẩu không trùng khớp",
  forgot_password_description:
    "Nhập Email của bạn vào bên dưới và chúng tôi sẽ gửi tin nhắn để đặt lại mật khẩu của bạn",
  reset_password: "Reset Password",
  password_reset_code_sent:
    "We have sent a password reset code by email. Enter it below to reset your password.",
  code: "Code",
  onboarding: {
    first_name: "Họ",
    last_name: "Tên",
    date_of_birth: "Ngày tháng năm sinh",
    year: "Year",
    month: "Month",
    day: "Day",
    nationality: "Quốc tịch",
    start_study_in_japan: "Khi nào thì bạn muốn bắt đầu học tại Nhật Bản?",
    currently_reside_in_japan: "Bạn hiện đang cư trú tại Nhật Bản?",
    yes: "Yes",
    no: "No",
  },
  student_profile: {
    user_picture: "Hình ảnh của người sử dụng",
    first_name: "Họ",
    last_name: "Tên",
    date_of_birth: "Ngày tháng năm sinh",
    sex: "Giới tính",
    nationality: "Quốc tịch",
    place_of_birth: "Nơi sinh",
    current_address: "Địa chỉ hiện tại",
    current_occupation: "Nghề nghiệp hiện tại",
    start_study_in_japan: "Khi nào thì bạn muốn bắt đầu học tại Nhật Bản?",
    currently_reside_in_japan: "Bạn hiện đang cư trú tại Nhật Bản?",
  },
  profile_tabs: {
    profile: "Hồ sơ",
    application_for_admission: "Đơn xin nhập học",
    documents: "Tài liệu",
  },
  admission_application_forms: {
    personal_info: "Thông tin cá nhân",
    passport_info_stay_history: "Thông tin hộ chiếu / Lịch sử lưu trú",
    family_friend_japan: "Gia đình và bạn bè ở Nhật Bản",
    marital_status: "Tình trạng hôn nhân",
    education_background: "Quá trình học vấn",
    professional_background: "Professional background",
    japanese_study_experience: "Kinh nghiệm học tiếng Nhật",
    japanese_proficiency: "Trình độ tiếng Nhật",
    plan_after_graduation: "Kế hoach sau khi tốt nghiệp",
    about_sponsor: "Về người bảo lãnh",
  },
  admission_application_page1: {
    passport_status: "Tình trang hộ chiếu",
    passport_number: "Số hộ chiếu",
    passport_expiration_date: "Ngày hết hạn hộ chiếu",
    passport_issue_date: "Ngày phát hành hộ chiếu",
    previous_coe_application:
      "Đơn xin cấp giấy chứng nhận đủ điều kiện trước đây",
    previous_stay_in_japan: "Trước đây đã ở Nhật Bản",
    previous_stay_in_japan_detail: "Trước đây đã ở Nhật Bản (chi tiết)",
    date_of_entry: "Ngày nhập cảnh",
    date_of_departure: "Date of Departure",
    status_of_residence: "Tình trạng cư trú",
    purpose_of_entry: "Mục đích nhập cảnh",
    family_or_friend_question: "Bạn có gia đình hoặc bạn bè ở Nhật Bản không?",
    family_or_friend_title: "Gia đình và bạn bè tại Nhật Bản",
    relationship: "Mối quan hệ",
    name: "Họ và Tên",
    intention_to_reside: "Có ý định cư trú với người nộp đơn hay không",
    place_of_employment: "Nơi làm việc hoặc trường học",
    residence_card_number: "Số thẻ cư trú",
    marital_status: "Tình trạng hôn nhân",
    partner_name: "Tên đối tác",
  },
  admission_application_page2: {
    educational_background: "Quá trình học vấn",
    type_of_school: "Loại trường học",
    name_of_school: "Tên trường (tên chính thức)",
    location_in_detail: "Vị trí (chi tiết)",
    period_of_attendance: "Thời gian tham gia",
    start_date: "Ngày bắt đầu",
    end_date: "Ngày kết thúc",
    years: "Năm",
    professional_career_question: "Bạn có bất kỳ nghề nghiệp chuyên môn?",
    professional_career_title: "Nghề nghiệp chuyên môn",
    company_name: "Tên công ty",
    type_of_job: "Loại nghề nghiệp",
    period_of_employment: "Thời gian làm việc",
    japanese_study_experience_question:
      "Bạn đã có kinh nghiệm học tiếng Nhật chưa?",
    japanese_study_experience_title: "Kinh nghiệm học tiếng Nhật",
    hours: "Giờ",
    japanese_proficiency_certification_question:
      "Bạn có chứng chỉ hoặc năng lực tiếng Nhật nào không?",
    japanese_proficiency_certification_title: "Khả năng tiếng Nhật",
    name_of_test: "Tên kỳ thi",
    experience_of_test: "Kinh nghiệm của bài thi",
    year_of_test: "Năm thi",
    level: "Cấp độ",
    score: "Số điểm",
    plan_after_graduation: "Kế hoạch sau khi tốt nghiệp",
    currently_enrolled: "Hiện đang theo học",
    do_you_have_work_experience:
      "Bạn có kinh nghiệm làm việc không? -- Có/Không",
    expected_graduation_date: "ngày tốt nghiệp dự kiến",
    enrollemnt_period: "Thời gian nhập học (năm）      ",
    currently_working_company: "Tôi hiện tại đang làm việc cho công ty này",
    studied_japanese_institute:
      "Bạn đã từng học tiếng Nhật ở cơ sở nào chưa? --- Không/Có",
    not_yet_completed: "Chưa hoàn thành xong",
  },
  admission_application_page3: {
    sponsor_name: "Tên người bảo lãnh",
    sponsor_address: "Địa chỉ người bảo lãnh",
    sponsor_relationship: "Mối quan hệ với người bảo lãnh",
    sponsor_annual_income: "Số thu nhập hằng năm của người bảo lãnh",
    sponsor_occupation: "Nghề nghiệp của người bảo lãnh",
    sponsor_employer: "Nơi làm việc của người bảo lãnh",
  },
  student_document: {
    application_for_admission: "Đơn xin nhập học",
    statement_of_reason_title: "Lý do đi học",
    statement_of_reason_about:
      "(nếu đã hơn 5 năm kể từ khi tốt nghiệp tại trường với kết quả học tập cuối cùng)",
    statement_of_advancement_title:
      "Hướng dẫn nghề nghiệp sau khi tốt nghiệp tại một cơ sở dạy tiếng Nhật ",
    statement_of_advancement_about:
      "(nếu đã hơn 5 năm kể từ khi tốt nghiệp tại trường có học lực cuối cùng)",
    identification_photograph:
      "Ảnh nhận dạng (5 bản)  *Kích thước (H x W): 4cm x 3cm ",
    passport_or_identification: "Bản sao hộ chiếu hoặc giấy tờ tùy thân",
    diploma_of_last_school: "Văn bằng của trường đã theo học lần cuối",
    transcript_of_last_school: "Bảng điểm từ trường đã học gần đây nhất",
    japanese_language_proficiency: "Tài liệu chứng nhận năng lực tiếng Nhật",
    employment_certificate_title: "Giấy chứng nhận việc làm",
    employment_certificate_about: "(chỉ khi bạn có hồ sơ việc làm)",
    tuberculosis_screening_title:
      "Khám sàng lọc bệnh lao trước khi nhập cảnh Nhật Bản",
    tuberculosis_screening_about:
      "Người nộp đơn có hộ chiếu từ Philippines, Việt Nam, Trung Quốc, Indonesia, Nepal và Myanmar phải nộp hồ sơ này. ",
    financial_support:
      "Tài liệu hỗ trợ tài chính (Văn bản do Trường quy định) ",
    supporter_family_members:
      "Danh sách các thành viên trong gia đình của người hỗ trợ tài chính (Văn bản do Trường quy định)",
    supporter_family_structure:
      "Tài liệu xác nhận cấu trúc gia đình của người hỗ trợ tài chính",
    supporter_relationship:
      "Tài liệu xác nhận mối quan hệ giữa người hỗ trợ tài chính và người nộp đơn",
    certificate_of_deposit: "Giấy xác nhận số dư tiền gửi (bản gốc)",
    supporter_employment:
      "Tài liệu xác nhận việc làm của người hỗ trợ tài chính",
    three_years_asset_formation:
      "Văn bản xác nhận quá trình hình thành tài sản trong 3 năm qua",
    supporter_three_years_income:
      "Văn bản xác nhận thu nhập của người hỗ trợ tài chính trong 3 năm qua",
  },
  save_profile_btn: "Lưu hồ sơ",
  date_of_birth: "Ngày tháng năm sinh",
  nationality: "Quốc tịch",
  additional_fee_label: "Is there an additional fee after enrollment?",
  additional_fee_yes: "Yes",
  additional_fee_no: "No",
  early_application_deadline:
    "The application deadline may become earlier than planned depending on the number of applications received.",
  course_list: "Danh sách khoá học",
  course_detail: "Chi tiết khoá học",
  school_list: "Danh sách Trường",
  school_detail: "Chi tiết Trường",
  go_to_list: "Đi về Danh sách",
  apply: "Nôp đơn ứng tuyển",
  applied_date: "Ngày áp dụng",
  application_status: "Trạng thái",
  student_top_page: {
    why_japans: {
      why_japan: "Tại Sao Lại Chọn Nhật Bản?",
      cost_of_living: "Chi phí sinh hoạt và học phí thấp hơn Mỹ, Anh và Úc!",
      various_scholarship: "Có nhiều học bổng khác nhau!",
      part_time_during_study:
        "Bạn có thể đi làm thêm trong thời gian học, và toàn thời gian sau khi tốt nghiệp!",
    },
    what_is_csas: {
      what_is_csa: "CSA là gì?",
      platform_directly_match:
        "Một nền tảng kết hợp trực tiếp trường học và sinh viên",
      sign_up_free: "Đăng ký miễn phí",
    },
    blogs: {
      blog: "Blog",
    },
    why_japans_stat: {
      why_japan: "Tại Sao Lại Chọn Nhật Bản?",
      tuition_and_cost_living_japan:
        "Học phí và chi phí sinh hoạt tại Nhật Bản",
      annual_tuition: "Học phí hàng năm (USD)",
      annual_living: "Chi phí sinh hoạt hàng năm (USD)",
    },
    carrers: {
      career_during_study_available: "Có nghề nghiệp trong quá trình học! ",
      partime_job: "Công việc bán thời gian có sẵn lên đến  ",

      hrs_per_week: "28 giờ mỗi tuần",
      while_studying: "trong khi học.",
      work_upto:
        "Trong kỳ nghỉ của trường học, bạn có thể làm việc tới 40 giờ mỗi tuần",
    },
    approval_rate_of_working_visas: {
      approval_rate_of_working_visa: "Tỷ lệ chấp thuận visa lao động cao!  ",
      career_after_study: "Việc làm sau khi ra trường cũng có!",
      number_of_visa_changes: "Số lượng visa thay đổi từ du học sang lao động",
      by_how_much: "In 2020, Khoảng ",
      eighty_Seven: "87%",
      approved: "được chấp thuận!",
    },
    grant_type_scholarships: {
      grant_type_scholarship: "Học bổng dạng trợ cấp được cung cấp",
      available: " rộng rãi!",
      almost_foreign_students:
        "Japan Gần 30% sinh viên nước ngoài được cấp học bổng",
      for_study_in_japan: "du học Nhật Bản      ",
      there_142_scholarship:
        "Có 142 loại học bổng trợ cấp do Chính phủ Nhật Bản ",
      japanese_government: "và các tổ chức tư nhân cung cấp",
      got_scholarship:
        "1.840 sinh viên nhận được học bổng của chính phủ Nhật Bản",
      government_2021: "vào năm 2021",
    },
    connect_study_deliver_support: {
      connect_study_deliver_support_students:
        "Connect Study ABROAD hỗ trợ cho sinh viên",
      sign_up_free: "Đăng ký miễn phí",
    },
    about_csas: {
      about_csa: "Về CSA",
      get_support_fulfill_student_life:
        "Nhận hỗ trợ để hoàn thành cuộc sống sinh viên của bạn với việc làm và hơn thế nữa!",
      information_not_only_schools:
        "CSA cung cấp thông tin không chỉ về trường học mà còn về học bổng, chỗ ở, việc làm bán thời gian, v.v.!",
      get_support_for_education:
        "Nhận hỗ trợ cho giáo dục đại học và nghề nghiệp",
      our_strong_track_supporting:
        "Thành tích mạnh mẽ của chúng tôi trong việc hỗ trợ các công ty Nhật Bản tuyển dụng tài năng toàn cầu sẽ mở rộng khả năng của bạn sau khi học tập.",
    },
    our_factss: {
      our_facts: "Sự kiện của chúng tôi",

      support_for_7yrs:
        "Ước mơ của sinh viên được hỗ trợ về du học Nhật Bản trong",
      seven_yrs: " 7 năm qua.",
      more_than: " Hơn ",
      thirteen_thousand: "  13.000 sinh",
      used_our_service: " viên đã sử dụng dịch vụ của chúng tôi.",
      career_event: "Sự kiện nghề nghiệp trong hơn",
      twenty_countries: " 20 quốc gia",
      pre_covid: " (Pre-COVID). Sự kiện du học ở",
      ten_countries: " 10 quốc gia ",
      covid: " (Pre-COVID).",
      supported_around: "Được hỗ trợ khoảng",
      four_thousand_Candidates: " 4,000 ứng",
      job_hunting:
        "  viên với việc tìm kiếm việc làm của họ ở Nhật Bản vào năm ngoái.",
      registered_users: "Người dùng đã đăng ký của chúng tôi là từ khoảng",
      hundred_forty: " 140 quốc gia.",
    },
    facebook_pages: {
      facebook_page: "Facebook Page",
    },
  },
  select_one: "Select one",
  pre_apply: "Pre-apply",
  tab_exit_heading: "Are you sure?",
  tab_exit_description: "Any unsaved changes will be lost.",
  yes: "Có",
  no: "Không",
  duplicate_application_msg: "You have already applied to this course.",
  csa_free_minimun_balance:
    "Dịch vụ CSA MIỄN PHÍ.  Tuy nhiên, để xin visa du học Nhật Bản, bạn hoặc thành viên trong gia đình phải xuất trình giấy tờ chứng minh thu nhập hàng năm VÀ số dư ngân hàng tối thiểu là 2 triệu JPY",
  required_student_profile_fields:
    "Required Student Profile fields are not filled up",
  redirect_student_profile_page:
    "You will be redirected to Student Profile Page.",
  redirect: "Redirect",
  proceed_with_course_application: "Proceed with Course Application",
  faq: {
    faq1: {
      id: "1",
      title: "CSA là gì？",
      description:
        "CSA là cơ sở kết nối trực tiếp giữa bạn và các trường học tại Nhật Bản. Ứng viên KHÔNG phải trả phí. Bạn không cần phải trả tiền cho các đại lý nữa để học tập tại Nhật Bản! Bạn thậm chí có thể nhận được thông tin từ các trường học sau khi bạn nhập hồ sơ và tải lên tài liệu của mình!      Bắt đầu từ việc điền vào hồ sơ của bạn và trước khi nhập học vào các trường bạn quan tâm. ",
    },
    faq2: {
      id: "2",
      title: "Dịch vụ mà CSA cung cấp là gì?",
      description:
        "CSA là cơ sở kết nối trực tiếp giữa bạn và các trường học tại Nhật Bản. Ứng viên KHÔNG phải trả phí. Một số điều bạn có thể làm trong CSA là .....    - Tìm kiếm trường học      - Nhận tuyển trạch viên từ các trường học      - Tìm các hỗ trợ cần thiết cho cuộc sống ở Nhật Bản (nhà ở, nhận điện thoại di động, v.v.)      Một trong những điều tuyệt vời nhất của CSA là chúng tôi có thể hỗ trợ bạn kiếm được công việc bán thời gian trong khi học và cả việc làm toàn thời gian sau khi học! ",
    },
    faq3: {
      id: "3",
      title: "Làm thế nào để nộp đơn ứng tuyển vào trường?",
      description:
        "1. Đăng ký Kết nối Học ABROAD 2. Tìm trường học / khóa học bạn quan tâm 3. Nhấp vào nút '' Đăng ký trước ''      * Bạn điền càng nhiều hồ sơ, càng tải lên nhiều tài liệu, thì bạn càng có nhiều cơ hội được tuyển trạch viên từ các trường học. Vì vậy, hãy đảm bảo rằng bạn làm được điều này ngay khi đăng ký Connect Study ABROAD! ",
    },
    faq4: {
      id: "4",
      title: "CSA thực tập là gì?",
      description:
        "Đây là một chương trình trọn gói do Công ty Four Valley Concierge Corporation (một công ty điều hành Tổ chức Du học Kết nối) cung cấp cho sinh viên du học Nhật Bản và thực tập nhằm hỗ trợ sinh viên trải nghiệm làm việc tại Nhật Bản và hỗ trợ tài chính cho họ. Không yêu cầu tiếng Nhật (yêu cầu tiếng Anh)",
    },
    faq5: {
      id: "5",
      title: "Những loại hệ thống trường học ở Nhật Bản?",
      description:
        "Có khoảng ba lựa chọn. 1. Trường Nhật ngữ Bạn có thể du học Nhật Bản từ trình độ JLPT N5. Bạn có thể học tiếng Nhật với mọi người từ khắp nơi trên thế giới. Các khóa học khác nhau tùy theo trường học. 2. Các trường đại học (Cử nhân, Thạc sĩ, Tiến sĩ)Về cơ bản, bạn cần phải vượt qua EJU. Có những trường có các khóa học tiếng Anh cũng cho phép thực hiện bài kiểm tra bằng tiếng Anh, nhưng không nhiều. Vì vậy, bạn cần có ít nhất trình độ tiếng Nhật JLPT N2. Đây là lý do tại sao hầu hết các sinh viên quốc tế lần đầu tiên đến trường Nhật ngữ và học lên cao hơn. 3. Trường dạy nghề / đào tạo        Bạn có thể học các lĩnh vực cụ thể (ví dụ: Anime, kế toán, CNTT, nấu ăn, v.v.) ở Nhật Bản. Về cơ bản bạn cần có JLPT N2 trở lên và / hoặc có kinh nghiệm học tập tại Nhật Bản trên 6 tháng. Chi tiết vui lòng xem tại đây ",
    },
    faq6: {
      id: "6",
      title: "Làm thế nào để đạt được học bổng?",
      description:
        "Ở Nhật Bản, có 142 suất học bổng không cần hoàn trả (thông tin: Jasso) Ngoài ra còn có các học bổng dành riêng cho CSA! Hãy xem nó trên CSA và đảm bảo rằng bạn không bỏ lỡ cơ hội! Chi tiết vui lòng xem tại đây ",
    },
    faq7: {
      id: "7",
      title: "Du học Nhật Bản tốn bao nhiêu tiền?",
      description:
        " “So với Mỹ, Châu Âu, du học Nhật Bản rất hợp lý! Tất nhiên, nó phụ thuộc vào cá nhân / loại trường học, khóa học, v.v., nhưng thường là khoảng 2 triệu JPY mỗi năm bao gồm học phí và kinh nghiệm sống. Nó bằng 1/2 ~ 1/3 so với học ở Mỹ hoặc Châu Âu! Bạn cũng có thể làm việc bán thời gian hợp pháp tại Nhật Bản trong khi bạn đang học. Chi tiết vui lòng xem tại đây ",
    },
    faq8: {
      id: "8",
      title:
        "Tôi có thể làm công việc bán thời gian với tư cách là một sinh viên quốc tế không?",
      description:
        "Ở Nhật Bản, bạn được phép làm việc tối đa 28 giờ mỗi tuần (trong thời gian nghỉ học, 40 giờ mỗi tuần) Mức lương tối thiểu theo giờ (ở Tokyo) là khoảng 1.000JPY (= 9USD), vì vậy bạn có thể kiếm được 1,6 triệu mỗi năm! Khoảng 70% du học sinh tại Nhật Bản đang đi làm thêm để trang trải cho việc học của mình. Chi tiết vui lòng xem tại đây",
    },
    faq9: {
      id: "9",
      title: "Làm thế nào tôi có thể kiếm được một công việc ở Nhật Bản?",
      description:
        "Số lượng người nước ngoài làm việc tại Nhật Bản đã tăng gấp 3 lần trong 10 năm (và số lượng các công ty tuyển dụng người nước ngoài cũng đang tăng lên) nên điều đó có nghĩa là cơ hội để bạn tìm được việc làm tại Nhật Bản ngày càng tăng. Tất nhiên chúng ta cần đưa ra sự khác biệt Lời khuyên tùy thuộc vào từng cá nhân và tình huống, nhưng điểm chung là bạn sẽ cần một trình độ tiếng Nhật nhất định trong bất kỳ lĩnh vực nào, tối thiểu là N4, nhưng bạn càng thông thạo tiếng Nhật, bạn càng có nhiều cơ hội. Chúng tôi cũng đang hỗ trợ những người mong muốn có được việc làm tại Nhật Bản, vì vậy hãy xem các nền tảng này cũng như để tìm kiếm cơ hội việc làm. ",
    },
    faq10: {
      id: "10",
      title: "Tôi muốn theo học bằng Cử nhân / Thạc sĩ ở Nhật Bản",
      description:
        "Thông tin về bằng cử nhân và bằng thạc sĩ chưa được thêm vào CSA. Nếu bạn muốn tiếp tục với bằng cử nhân / thạc sĩ, vui lòng xem https://www.jpss.jp/ja/",
    },
  },
};
