import { React } from "react";

import { useSelector } from "react-redux";
import { Route, Redirect, useHistory } from "react-router-dom";
import UnderMaintainance from "src/views/Common/UnderMaintainance";

import RouteGuards from "../core/constants/auth/route_guards";

// protects Role-based routing too,
// e.g. student not being able to go to url of agent and vice-versa
const GuardedRoute = ({
  component: Component,
  guard = RouteGuards.PUBLIC,
  isUnderConstruction = false,
  ...rest
}) => {
  const history = useHistory();
  const { isAuth, role } = useSelector((state) => state.user);

  const shouldAllowNavigation = (isAuth, guard) => {
    let shouldAllow = false;

    try {
      if (guard == RouteGuards.PUBLIC) {
        shouldAllow = true;
      } else if (guard == RouteGuards.GUEST) {
        if (isAuth != true) {
          shouldAllow = true;
        }
      } else {
        if (isAuth == true && guard == role) {
          shouldAllow = true;
        }
      }
    } catch (err) {
      console.log(err);
    }

    return shouldAllow;
  };

  return isUnderConstruction ? (
    <Route {...rest} render={(props) => <UnderMaintainance {...props} />} />
  ) : shouldAllowNavigation(isAuth, guard) ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    /// TODO: Navigate to previous page, if exists
    // history.goBack()
    // OR, Redirect to relevant sign in screens
    <Redirect to="/" />
  );
};

export default GuardedRoute;
