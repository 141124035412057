import { Auth } from "aws-amplify";

export const getAuthMode = async () => {
  let authMode = "AWS_IAM";
  try {
    const user = await Auth.currentAuthenticatedUser();
    if (user) authMode = "AMAZON_COGNITO_USER_POOLS";
  } catch (err) {
    // console.log("currentAuthenticatedUser has error:", err);
  }

  return authMode;
};
