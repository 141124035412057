import { API } from "aws-amplify";
import { toast } from "react-hot-toast";
import { manageSchoolM } from "src/graphql/mutations";
import { manageSchoolQ } from "src/graphql/queries";

import { reactHotToastError, reactHotToastSuccess } from './react-hot-toast'

export const pinStudentBySchool = async (schoolId, studentId) => {

    let response = {
        status: null,
        data: ""
    }

    try {
        const res = await API.graphql({
            query: manageSchoolM,
            variables: {
                schoolPinStudent: {
                    schoolId: schoolId,
                    studentGuid: studentId
                }

            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        response.status = true
        response.data = res.data

        toast.success("Pinned Successfully!", reactHotToastSuccess);



        return response

    } catch (err) {
        response.status = false
        response.data = err.errors
        toast.error(err?.errors[0]?.message, reactHotToastError);
        return response

    }
}
export const unpinStudentBySchool = async (schoolId, studentId) => {

    let response = {
        status: null,
        data: ""
    }

    try {
        const res = await API.graphql({
            query: manageSchoolM,
            variables: {
                schoolUnPinStudent: {
                    schoolId: schoolId,
                    studentGuid: studentId
                }

            },
            authMode: "AMAZON_COGNITO_USER_POOLS",
        });


        response.status = true
        response.data = res.data
        toast.success("Unpinned Successfully!", reactHotToastSuccess);

        return response

    } catch (err) {
        response.status = false
        response.data = err.errors

        toast.error(err?.errors[0]?.message, reactHotToastError);
        return response

    }
}

export const getStudentListPinnedBySchool = async (id) => {

    let response = {
        status: null,
        data: ""
    }
    try {
        const res = await API.graphql({
            query: manageSchoolQ,
            variables: {
                schoolPinStudentList: {
                    schoolId: id
                },

            },

            authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        response.status = true
        response.data = res.data.manageSchoolQ.schoolPinStudentList

        return response

    } catch (err) {
        toast.error(err?.errors[0]?.message, reactHotToastError);

        response.status = false

        return response

    }
}