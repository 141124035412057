import { faChevronCircleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "src/components/Footer";
import Header from "src/components/Header";

const withHeaderFooter = (Component, isSchoolPublicScreen = false) => {
  return (props) => {
    const { username, email, isAuth, role } = useSelector(
      (state) => state.user
    );

    const [scrollDirection, setScrollDirection] = useState(null);

    useEffect(() => {

      const updateScrollDirection = () => {
        const scrollY = window.pageYOffset;
        // const direction = scrollY > lastScrollY ? "down" : "up";
        // if (direction !== scrollDirection && (scrollY - lastScrollY > 10 || scrollY - lastScrollY < -10)) {
        //   setScrollDirection(direction);
        // }
        // lastScrollY = scrollY > 0 ? scrollY : 0;

        if (scrollY > 300) {
          setScrollDirection(true)
        } else {
          setScrollDirection(false)
        }

      };

      window.addEventListener("scroll", updateScrollDirection); // add event listener
      console.log("SCROLL", scrollDirection)

      return () => {
        window.removeEventListener("scroll", updateScrollDirection); // clean up
      }



    }, [scrollDirection]);

    return (
      <><div id="top" />
        <Header
          loggedIn={isAuth}
          username={username}
          email={email}
          role={role}
          isSchoolPublicScreen={isSchoolPublicScreen}
        />

        <Component />
        {(scrollDirection) &&
          <a href="#top" className="bg-primary shadow-lg p-2 position-fixed text-light text-center d-flex align-items-center justify-content-between rounded-left"
            style={{ right: "0", gap: "12px", top: '80%',zIndex:"10001" }}>
            <FontAwesomeIcon className="d-block" icon={faChevronCircleUp} />

            <span className="d-block"> Top</span>
          </a>}

        <div id="bottom" />
        <Footer />
      </>
    );
  };
};

export default withHeaderFooter;
