// External components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Sticky } from "react-sticky";
import { Col, Container, Row } from "reactstrap";
import { SendBirdProvider } from "sendbird-uikit";
import MessageCount from "src/views/Common/ChatBox/MessageCount";
// Own Components
import { faBell, faCommentDots, faHome, faUser } from "@fortawesome/free-solid-svg-icons";
import ButtonGroups from "./ButtonGroups";
import Logo from "./Logo";
import Navigation from "./Navigation";
import { getConsoleLog } from "src/core/utilities/useConsoleLog";
import { Dropdown, ListGroup } from "react-bootstrap";
import Badge from 'react-bootstrap/Badge';
import { getNotificationList, updateNotificationReadStatus } from "src/helpers/common";
import { SettingsOutlined, Store } from "@mui/icons-material";
import Skeleton from "react-loading-skeleton";

const Header = (props) => {
  const {
    loggedIn,
    username,
    email,
    role,
    isSchoolPublicScreen,
    schoolList,
  } = props;
  const history = useHistory();
  const [appState, setAppState] = useState(null);
  const [schoolAppState, setSchoolAppState] = useState(null);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { isAuth } = useSelector((state) => state.user);
  const studentProfileDataValue = localStorage.getItem("getStudentProfile1");
  const schoolProfileDataGUID = localStorage.getItem("getSchoolProfileGUID");
  const schoolProfileDataID = localStorage.getItem("getSchoolProfileID");

  const languageCode = useSelector((store) => store.language);

  //get student access token
  const fetchToken = async () => {
    try {
      const getToken = await axios
        .get(
          `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/users/${studentProfileDataValue}`,
          {
            headers: {
              "Api-Token": process.env.REACT_APP_API_TOKEN,
            },
          }
        )
        .then((repos) => {
          const allRepos = repos.data;
          setAppState(allRepos);
          setLoading(true);
        });
    } catch (err) {
      setError(t("something_went_wrong"));
      console.log(err);
    }
  };

  //get school access token
  const schoolFetchToken = async () => {
    try {
      const getToken = await axios
        .get(
          `https://api-${process.env.REACT_APP_APP_ID}.sendbird.com/v3/users/${schoolProfileDataGUID}-${schoolProfileDataID}`,
          {
            headers: {
              "Api-Token": process.env.REACT_APP_API_TOKEN,
            },
          }
        )
        .then((repos) => {
          const allRepos1 = repos.data;
          console.log("All repos", allRepos1);
          setSchoolAppState(allRepos1);
          setLoading(true);
        });
    } catch (err) {
      setError(t("something_went_wrong"));
      console.log(err);
    }
  };


  // NOTIFCATION
  const [notification, setnotification] = useState()
  const [notificationUnread, setnotificationUnread] = useState(0)

  const getNotification = async (code) => {
    let res = await getNotificationList(code)
    if (res.status) {
      setnotification(res.data)
      setnotificationUnread(res.data.filter(d => !d.is_read).length)
    } else {
      setnotification([])
    }
  }
  const notificationClick = async (id, url) => {

    const res = await updateNotificationReadStatus(languageCode, id)

    if (res?.status) {
      history.push(url)
    } else {
      history.push(url)
      console.log(res?.data)
    }


  }


  useEffect(() => {

    if (role == "school") {
      getNotification("JA")
      schoolFetchToken();
    }
    if (role == "student") {
      getNotification("EN")

      fetchToken();
    }
  }, []);



  return (
    <>
      <Sticky topOffset={0} >
        {({ style, isSticky }) => (
          <header
            style={style}
            className={` ${isSticky ? "sticky-header" : "position-relative border bg-white"
              }`}
          >
            <Container>
              <Row
                className="align-items-center"
              >
                <Col xl={2} lg={2} md={2} xs={4} className="d-flex px-0">
                  <div className="jb-logo">
                    <Logo
                      role={role}
                      isSchoolPublicScreen={isSchoolPublicScreen}
                    />
                  </div>
                </Col>

                {loggedIn && (role === "school" || role === "student") ? (
                  <>
                    <Col className="nav-col" xl={7} lg={6} md={7} xs={6}>
                      <Navigation
                        role={role}
                        isSchoolPublicScreen={isSchoolPublicScreen}
                      />
                    </Col>
                    <Col xl={3} lg={4} md={3} xs={2}>
                      <div className="user-info d-flex justify-content-md-end justify-content-evenly align-items-center align-self-center text-center">
                        {/* NOTIFICATION */}
                        <Dropdown className="show d-none d-md-block" id="notification">
                          <Dropdown.Toggle size="sm" as="div" className="bg-transparent  fs-4 after-none chat mx-0" style={{ backgroundImage: "none" }}>
                            <div className="chat_icon">
                              <FontAwesomeIcon
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  color: "#fff",
                                  cursor: "pointer",
                                }}
                                icon={faBell}
                              />

                              {(notification && notificationUnread > 0) && <div className="chat_text">
                                <div class="chat_circle"> <div class="chat_circle_notification" style={{ fontSize: "1rem" }}>
                                  {notification?.length > 8 ? "9+" : notificationUnread}
                                </div>
                                </div>
                              </div>}
                            </div>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="py-0 shadow-lg rounded-4" align="right" style={{ maxHeight: "50vh", whiteSpace: "normal", overflowY: "auto" }}>
                            {!notification && <Skeleton count={5} height={20} />}

                            {
                              notification && <>
                                {notification.length != 0 ? notification?.map(n => {
                                  return (<Dropdown.Item key={n.id} onClick={() => notificationClick(n.id, n.redirect_url)} className={`rounded-4 border-bottom border-2 py-md-3 py-4 ${!n.is_read && "bg-gray"}`}>{n.message}</Dropdown.Item>
                                  )
                                }) : <Dropdown.Item className={`rounded-4 border-bottom border-2 py-md-3 py-4 `}>No Notifications!</Dropdown.Item>}
                              </>}

                          </Dropdown.Menu>
                        </Dropdown>
                        {/* CHAT BOX */}
                        <div
                          className="chat mx-0 mx-md-5 d-none d-md-block"
                          onClick={() => history.push("/chat")}
                        >
                          <div className="chat_icon">
                            <FontAwesomeIcon
                              style={{
                                width: "25px",
                                height: "25px",
                                color: "#fff",
                                cursor: "pointer",
                              }}
                              icon={faCommentDots}
                            />
                            <div className="chat_text">
                              {appState && (
                                <SendBirdProvider
                                  appId={process.env.REACT_APP_APP_ID}
                                  userId={studentProfileDataValue}
                                  accessToken={
                                    appState.access_token
                                      ? appState.access_token
                                      : ""
                                  }
                                >

                                  <MessageCount />
                                </SendBirdProvider>
                              )}
                              {schoolAppState && (
                                <SendBirdProvider
                                  appId={process.env.REACT_APP_APP_ID}
                                  userId={
                                    schoolProfileDataGUID +
                                    "-" +
                                    schoolProfileDataID
                                  }
                                  accessToken={
                                    schoolAppState.access_token
                                      ? schoolAppState.access_token
                                      : ""
                                  }
                                >

                                  <MessageCount />
                                </SendBirdProvider>
                              )}
                            </div>
                          </div>
                        </div>
                        <ButtonGroups
                          loggedIn={loggedIn}
                          username={username}
                          email={email}
                          role={role}
                          isSchoolPublicScreen={isSchoolPublicScreen}
                        />
                        {/* <Menubar /> */}
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col className="nav-col px-0" xl={6} lg={5} md={7} xs={6}>
                      <Navigation
                        role={role}
                        isSchoolPublicScreen={isSchoolPublicScreen}
                      />
                    </Col>
                    <Col xl={4} lg={5} md={3} xs={2}>
                      <div className="user-info d-flex justify-content-end align-items-center align-self-center text-center">
                        <ButtonGroups
                          loggedIn={loggedIn}
                          username={username}
                          email={email}
                          role={role}
                          isSchoolPublicScreen={isSchoolPublicScreen}
                        />
                        {/* <Menubar /> */}
                      </div>
                    </Col>
                  </>
                )}
              </Row>
            </Container>
          </header>
        )
        }
      </Sticky >
      {(loggedIn && role === "student") && <nav className="bg-light border-top shadow-lg text-dark position-fixed d-md-none" style={{ bottom: "0", right: "0", left: "0", zIndex: "1001" }}>
        <div className="d-flex align-items-center justify-content-between">
          <Link className="d-block px-4" to="/">
            <FontAwesomeIcon className="fs-4" icon={faHome} />
          </Link>
          {/* NOTIFICATION */}
          <Dropdown className="show px-4" id="notification">
            <Dropdown.Toggle size="sm" as="div" className="bg-transparent  fs-4 after-none chat mx-0" style={{ backgroundImage: "none" }}>
              <div className="chat_icon">
                <FontAwesomeIcon className="fs-4"
                  icon={faBell}
                />

                {(notification && notificationUnread > 0) && <div className="chat_text">
                  <div class="chat_circle"> <div class="chat_circle_notification" style={{ fontSize: "1rem" }}>
                    {notification?.length > 8 ? "9+" : notificationUnread}
                  </div>
                  </div>
                </div>}
              </div>
            </Dropdown.Toggle>

            <Dropdown.Menu className="py-0 shadow-lg rounded-4" align="right" style={{ maxHeight: "75vh", whiteSpace: "normal", overflowY: "auto" }}>
              {!notification && <Skeleton count={5} height={20} />}

              {
                notification && <>
                  {notification.length != 0 ? notification?.map(n => {
                    return (<Dropdown.Item key={n.id} onClick={() => notificationClick(n.id, n.redirect_url)} className={`rounded-4 border-bottom border-2 py-md-3 py-4 ${!n.is_read && "bg-gray"}`}>{n.message}</Dropdown.Item>
                    )
                  }) : <Dropdown.Item className={`rounded-4 border-bottom border-2 py-md-3 py-4 `}>No Notifications!</Dropdown.Item>}
                </>}

            </Dropdown.Menu>
          </Dropdown>
          {/* CHAT BOX */}
          <div className="chat  px-4 mx-0"
            onClick={() => history.push("/chat")}
          >
            <div className="chat_icon fs-4">
              <FontAwesomeIcon icon={faCommentDots}
              />
              <div className="chat_text">
                {appState && (
                  <SendBirdProvider
                    appId={process.env.REACT_APP_APP_ID}
                    userId={studentProfileDataValue}
                    accessToken={
                      appState.access_token
                        ? appState.access_token
                        : ""
                    }
                  >

                    <MessageCount />
                  </SendBirdProvider>
                )}
                {schoolAppState && (
                  <SendBirdProvider
                    appId={process.env.REACT_APP_APP_ID}
                    userId={
                      schoolProfileDataGUID +
                      "-" +
                      schoolProfileDataID
                    }
                    accessToken={
                      schoolAppState.access_token
                        ? schoolAppState.access_token
                        : ""
                    }
                  >

                    <MessageCount />
                  </SendBirdProvider>
                )}
              </div>
            </div>
          </div>
          <Link className="d-block px-4" to="/student/profile/information">
            <FontAwesomeIcon className="fs-4" icon={faUser} />
          </Link>
        </div>
      </nav>}
    </>
  );
};

export default Header;
