import API from "@aws-amplify/api";
import {
  faAngleRight,
  faCaretDown,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { forwardRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { getAuthMode } from "src/core/utilities/authMode";
import { getValueOfMatchedItemInMasterData } from "src/core/utilities/utils";
import { manageSchoolM } from "src/graphql/mutations";
import { manageSchoolQ } from "src/graphql/queries";
import AvailableDocsIcon from "../../../../assets/images/common/available_docs.png";
import UnavailableDocsIcon from "../../../../assets/images/common/unavailable_docs.png";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={10} ref={ref} variant="filled" {...props} />;
});
const ScoutTab1 = ({
  courseInfo,
  masterNationality,
  personalInfo,
  documents,
  passUrl,
  studentId,
}) => {
  const { isAuth } = useSelector((state) => state.user);
  const history = useHistory();
  const { id } = useParams();
  const [activeIndex, setActiveIndex] = useState(0);
  const [error, setError] = useState("");
  const [openSuccessBar, setOpenSuccessBar] = useState(false);
  const [openErrorBar, setOpenErrorBar] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [scoutList, setScoutList] = useState([]);
  const [scoutDetail, setScoutDetail] = useState({});
  const languageCode = useSelector((store) => store.language);
  const [isScoutDetail, setIsScoutDetail] = useState(false);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccessBar(false);
  };

  const handleCloseError = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenErrorBar(false);
  };
  const defaultSchoolStatus = {
    status: "",
    isUpdated: false,
  };
  const [schoolStatus, setSchoolStatus] = useState({
    ...defaultSchoolStatus,
  });
  const [loading, setLoading] = useState(courseInfo.applicationStatus);

  const statusOfType = scoutList.map((e, index) => {
    return schoolStatus.status === e.statusId && e.status;
  });

  const a = statusOfType[schoolStatus.status];
  console.log("a", a);
  //comparing with value and getting id
  const statusOfValue = scoutList.map((e, index) => {
    return courseInfo.applicationStatus === e.status && e.statusId;
  });
  const statusArray = statusOfValue.filter(Boolean);
  const statusIndex = statusArray.toString();

  const onInputChangeHandler = async (e) => {
    let newItems = { ...schoolStatus };
    newItems[e.target.dataset.name] = e.target.value;
    newItems["isUpdated"] = true;

    setSchoolStatus(newItems);
  };
  const getScoutedList = async () => {
    try {
      const getScoutedListData = await API.graphql({
        query: manageSchoolQ,
        variables: {
          scoutStatusList: { language: languageCode },
        },
        authMode: await getAuthMode(),
      });
      setScoutList(getScoutedListData.data.manageSchoolQ.scoutStatusList);
      console.log("getScoutedListData", getScoutedListData);
    } catch (err) {
      console.log(err);
      setError("No users is found");
    }
  };
  // get Scout Detail
  const getScoutedStudentDetail = async () => {
    setIsScoutDetail(true);
    try {
      const getScoutedDetailData = await API.graphql({
        query: manageSchoolQ,
        variables: {
          scoutStudentDetail: { language: languageCode, scoutId: id },
        },
        authMode: await getAuthMode(),
      });
      setScoutDetail(
        getScoutedDetailData.data.manageSchoolQ.scoutStudentDetail
      );
      console.log("getScoutedDetailData", getScoutedDetailData);
      setIsScoutDetail(false);
    } catch (err) {
      console.log(err);
      setIsScoutDetail(false);
      setError("No users is found");
    }
  };
  console.log("getScoutedDetailData status Id", scoutDetail.scoutStatusId);
  const scoutedStatusId = parseInt(schoolStatus.status) + 1;
  console.log("scoutedStatusIdscoutedStatusIdscoutedStatusId", scoutedStatusId);

  const submitStatus = async () => {
    setIsLoading(true);
    setActiveIndex(schoolStatus.status);
    setLoading(a);
    console.log("submit status", schoolStatus.status);
    if (isAuth) {
      try {
        const schoolApplicationStatus = await API.graphql({
          query: manageSchoolM,
          variables: {
            updateScoutStatus: {
              scoutId: id,
              statusId: scoutedStatusId,
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });
        setOpenSuccessBar(true);
        setIsLoading(false);
        console.log(
          "Response from school application status",
          schoolApplicationStatus
        );
      } catch (err) {
        setOpenErrorBar(true);
        setIsLoading(false);
        console.log(err);
      }
    }
  };

  console.log("getScoutedList", scoutList);
  useEffect(() => {
    getScoutedList();
    getScoutedStudentDetail();
  }, []);

  const statusOption = scoutList.map((e, index) => {
    return (
      <>
        <option
          value={index}
          // selected={e.id == schoolStatus.status ? true : false}
          selected={e.status == loading ? true : false}
          // onClick={() => handleOnClick(index)} // pass the index
        >
          {/* {e.value} */}
          {e.status}
        </option>
      </>
    );
  });

  return (
    <>
      <div className="row application-detail">
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div>
            {personalInfo.profileImage == "" && (
              <FontAwesomeIcon
                icon={faUser}
                className="application-detail-image"
                style={{
                  color: "#C7C7C7",
                  width: "100%",
                }}
              />
            )}

            {personalInfo.profileImage != "" && (
              <img
                className="application-detail-image img-fluid"
                src={personalInfo.profileImage}
              />
            )}
          </div>
          <div>
            <label
              for="inputTargetStudyStart"
              style={{
                marginTop: "20px",
                marginBottom: "20px",
                color: "#7634a8",
              }}
            >
              {/* Status */}
              ステータス
            </label>
            {/* -------------------------------------progress bar-------------------------------------- */}
            {isScoutDetail ? (
              <center>
                {" "}
                <Spinner />{" "}
              </center>
            ) : (
              <>
                <div class="timeline">
                  <div class="box">
                    {scoutList.map((progressStat, index) => (
                      <div className="container d-flex">
                        {activeIndex == "" ? (
                          <div class="lines">
                            {/* {index == 9 ? ( */}
                            {index == 10 ? (
                              <div>
                                {console.log("progressStat.statusId", index)}
                                <div
                                  class={`${
                                    index > scoutDetail.scoutStatusId
                                      ? "blur"
                                      : "unblur"
                                  }`}
                                >
                                  {" "}
                                  <div class="dot"></div>
                                </div>{" "}
                              </div>
                            ) : (
                              <div>
                                <div
                                  class={`${
                                    index > scoutDetail.scoutStatusId
                                      ? "blur"
                                      : "unblur"
                                  }`}
                                >
                                  {" "}
                                  <div className="dot"></div>
                                  <div class="line"></div>{" "}
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div class="lines">
                            {/* {index == 9 ? ( */}
                            {index == 10 ? (
                              <div>
                                <div
                                  class={`${
                                    index > activeIndex ? "blur" : "unblur"
                                  }`}
                                >
                                  {" "}
                                  <div class="dot"></div>
                                </div>{" "}
                              </div>
                            ) : (
                              <div>
                                <div
                                  class={`${
                                    index > activeIndex ? "blur" : "unblur"
                                  }`}
                                >
                                  {" "}
                                  <div className="dot"></div>
                                  <div class="line"></div>{" "}
                                </div>
                              </div>
                            )}
                          </div>
                        )}

                        <div className="cards">
                          <div className="card">
                            {activeIndex == "" ? (
                              <p
                                key={index}
                                // onClick={() => handleOnClick(5)} // pass the index
                                style={{ textTransform: "capitalize" }}
                                className={`${
                                  scoutDetail.scoutStatusId === index
                                    ? "highlight"
                                    : ""
                                } ${
                                  index > scoutDetail.scoutStatusId
                                    ? "blur"
                                    : ""
                                } `}
                              >
                                {progressStat.status}
                              </p>
                            ) : (
                              <p
                                key={index}
                                // onClick={() => handleOnClick(5)} // pass the index
                                style={{ textTransform: "capitalize" }}
                                className={`${
                                  activeIndex === index ? "highlight" : ""
                                } ${index > activeIndex ? "blur" : ""} `}
                              >
                                {progressStat.status}
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                {/* -------------------select------------ */}
                <select
                  class="form-select form-select-sm"
                  style={{ textTransform: "capitalize" }}
                  aria-label=".form-select-sm example"
                  id="inputTargetStudyStart"
                  data-name="status"
                  onChange={onInputChangeHandler}
                >
                  {statusOption}
                </select>
                {/* -----------------------button---------------------------- */}
                <Button
                  className="application-detail-button"
                  style={{
                    borderRadius: "30px",
                    fontSize: "14px",
                    marginTop: "20px",
                    padding: "8px 24px",
                    textTransform: "capitalize",
                  }}
                  disabled={!schoolStatus.isUpdated || isLoading}
                  onClick={submitStatus}
                >
                  {isLoading && <Spinner />}
                  {!isLoading &&
                    // loading
                    "保存する"}
                  <FontAwesomeIcon
                    style={{ marginLeft: "10px" }}
                    icon={faAngleRight}
                  />
                </Button>
              </>
            )}
          </div>
        </div>

        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
          <div className="row info-section">
            <div className="col-12 application-detail-heading">
              <span>
                {personalInfo.firstName} {personalInfo.lastName}
              </span>
              {/* <span className="application-detail-heading-name" >Review Documents</span> */}
            </div>
            {/*----------------Course Info==---------------------  */}
            <div className="col-12">
              <div className="row" style={{ marginBottom: "50px" }}>
                <div className="col-12 school-headerclr">
                  <div className="d-flex justify-content-between">
                    <span>
                      コース情報
                      {/* Course Info */}
                    </span>{" "}
                  </div>
                </div>
                {/* ----------------------------Border Line------------- */}
                <div
                  className="col-12"
                  style={{
                    border: "3px solid #f5f5f5",
                    borderTopStyle: "none",
                  }}
                >
                  <div>
                    <div className="row" style={{ padding: "20px" }}>
                      {/* --------------Screening Fee------- */}
                      <div className="col-12">
                        <div className="row">
                          <div className="col-12 col-sm-6 d-block application-detail-course">
                            <div>{courseInfo.name}</div>
                            {/* <div>Enrollment month</div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ---------------------------------Student Profile---------- */}
            <div className="col-12">
              <div className="row" style={{ marginBottom: "50px" }}>
                <div className="col-12 school-headerclr">
                  <div className="d-flex justify-content-between">
                    <span>
                      {/* Student Profile */}
                      学生プロフィール
                    </span>{" "}
                  </div>
                </div>
                {/* ----------------------------Border Line------------- */}
                <div
                  className="col-12"
                  style={{
                    border: "3px solid #f5f5f5",
                    borderTopStyle: "none",
                  }}
                >
                  <div>
                    <div className="row" style={{ padding: "20px" }}>
                      {/* --------------student profile------------------- */}
                      <div className="col-12">
                        <div className="row application-detail-profile">
                          {/* ------------1st column--------------- */}
                          <div className="col-12 d-block application-detail-profile-first">
                            <div className="d-flex">
                              <div>メール アドレス</div>
                              <div>{personalInfo.email}</div>
                            </div>
                            <div className="d-flex">
                              <div>
                                {/* Nationality */}
                                国籍
                              </div>
                              <div>
                                {getValueOfMatchedItemInMasterData(
                                  personalInfo.nationality,
                                  masterNationality
                                )}
                              </div>
                            </div>
                            <div className="d-flex">
                              <div>
                                {/* Date of Birth  */}
                                生年月日
                              </div>
                              <div>{personalInfo.birthday}</div>
                            </div>
                          </div>
                          {/* --------------------2nd column----- */}
                          {/* <div className="col-6 d-block application-detail-profile-second">
                            <div className="d-flex">
                              <div>Currently Residing in Japan</div>
                              <div>{personalInfo.isResidenceJapan}</div>
                            </div>
                            <div className="d-flex">
                              <div>Japanese Level</div>
                              <div>18</div>
                            </div>
                          </div> */}
                        </div>
                      </div>
                      {/* --------------------------button--- */}
                      <div
                        style={{ textAlign: "center" }}
                        className="col-12 d-block"
                      >
                        <Button
                          className="application-detail-button p-2"
                          onClick={() =>
                            history.push(
                              studentId ? passUrl + studentId : passUrl + id
                            )
                          }
                        >
                          {/* View Complete Profile */}
                          プロフィール詳細を確認する
                          <br /> <FontAwesomeIcon icon={faCaretDown} />
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* --------------------------Document List----------- */}
            <div className="col-12">
              <div className="row" style={{ marginBottom: "50px" }}>
                <div className="col-12 school-headerclr">
                  <div className="d-flex justify-content-between">
                    <span>
                      {/* Document List */}
                      資料一覧
                    </span>{" "}
                  </div>
                </div>
                {/* ----------------------------Border Line------------- */}
                <div
                  className="col-12"
                  style={{
                    border: "3px solid #f5f5f5",
                    borderTopStyle: "none",
                  }}
                >
                  <div style={{ padding: "20px" }}>
                    {/* <div className="row" style={{ padding: "20px" }}> */}
                    {/* --------------Application for admission------- */}
                    {Object.keys(documents).map((key) => {
                      return (
                        <div style={{ marginTop: "8px" }}>
                          {/* <div className="row"> */}
                          {/* <div className="col-12 col-sm-6 d-flex"> */}
                          {/* <div style={{ marginRight: "20px" }}> */}
                          {/* <FontAwesomeIcon icon={faCheck} /> */}
                          <img
                            // style={{
                            //   height: "70px",
                            //   width: "auto",
                            // }}
                            src={
                              documents[key].srcFileLink != ""
                                ? AvailableDocsIcon
                                : UnavailableDocsIcon
                            }
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                          {/* </div>
                                <div> */}
                          <span
                            style={{
                              fontSize: "16px!important",
                              color: "#707070!important",
                            }}
                          >
                            {" "}
                            {documents[key].title}
                          </span>
                          {/* </div> */}
                          {/* </div> */}
                          {/* </div> */}
                        </div>
                      );
                    })}
                    {/* </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Snackbar
        open={openSuccessBar}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {/* Completed successfully! */}
          更新が完了しました!
        </Alert>
      </Snackbar>
      <Snackbar
        open={openErrorBar}
        autoHideDuration={2000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%" }}
        >
          {error != null && error != "" && error}
          {error == null ||
            (error == "" &&
              // "Update failed. Something went wrong"
              "更新に失敗しました")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ScoutTab1;
