import {
  faCaretDown,
  faChalkboardTeacher,
  faCog, faSchool, faSearch,
  faSignInAlt,
  faUser,
  faUserEdit,
  faUsers
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { API, Auth } from "aws-amplify";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useLocation } from "react-router-dom";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Media,
  NavItem
} from "reactstrap";
import { GetSchoolDataContext } from "src/contexts/GetSchoolDataContext";
import { NumStateContext, TextStateContext } from "src/contexts/GlobalState";
import { getSchoolAccountInfo } from "src/graphql/queries";
import { toggleTheme } from "src/state/ducks/ui";
import { logoutUser } from "src/state/ducks/user";
import Menubar from "../MenuBar";

const ButtonGroups = (props) => {
  const { loggedIn, username, email, role, isSchoolPublicScreen } = props;
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const [hasMaster, setHasMaster] = useState(null);
  const { isAuth } = useSelector((state) => state.user);

  // school Name
  const useTextState = () => React.useContext(TextStateContext);
  const { text, setText } = useTextState();
  console.log("school name from header", text);

  //school Id
  const useNumState = () => React.useContext(NumStateContext);
  const { num, setNum } = useNumState();

  const schoolDataValue = useContext(GetSchoolDataContext);

  const getSchoolAccountInfoData = async () => {
    try {
      /// IMPORTANT for checking approved status
      const schoolAccountInfoData = await API.graphql({
        query: getSchoolAccountInfo,
        variables: {},
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const isMaster = schoolAccountInfoData.data.getSchoolAccountInfo.isMaster;
      setHasMaster(isMaster);
      // console.log("isApproved: ", isApproved);
    } catch (err) {
      console.log("error from schoolAccountInfoData", err);
    }
  };

  useEffect(() => {
    if (role == "school") {
      getSchoolAccountInfoData();
    }
  }, []);

  console.log("hasMaster", hasMaster);

  const handleSwitch = () => {
    setNum(0);
    setText("");
    if (location.pathname == "/school_top?school=switch") {
      return window.location.reload();
    }
  };

  const handleLogout = async (role) => {
    try {
      localStorage.clear();
      sessionStorage.clear();
      await Auth.signOut();
      dispatch(logoutUser());
      dispatch(toggleTheme("default"));
      history.push(role == "school" ? "/auth/school/signin" : "/signin");
      window.location.reload()
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  if (isAuth && role === "school") {

    if (!localStorage.getItem("globalSchoolName") && !localStorage.getItem("globalSchoolID") && !localStorage.getItem("globalSchoolGuidID")) {
      Array.isArray(schoolDataValue.schoolList) &&
        schoolDataValue.schoolList.length > 0 &&
        localStorage.setItem(
          "globalSchoolName",
          schoolDataValue.schoolList[0].schoolName
        );

      Array.isArray(schoolDataValue.schoolList) &&
        schoolDataValue.schoolList.length > 0 &&
        localStorage.setItem(
          "globalSchoolID",
          schoolDataValue.schoolList[0].schoolId
        );

      Array.isArray(schoolDataValue.schoolList) &&
        schoolDataValue.schoolList.length > 0 &&
        localStorage.setItem(
          "globalSchoolGuidID",
          schoolDataValue.schoolList[0].guid
        );
    }
  }

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  return (
    <>
      {!loggedIn ? (
        <>
          <NavLink
            to={isSchoolPublicScreen ? "/auth/school/signup" : "/signup"}
          >
            <div
              className="d-none d-lg-block"
              style={{
                color: "white",
                textDecoration: "underline",
                marginRight: "3rem",
              }}
            >
              {/* Sign Up */}
              {isSchoolPublicScreen ? "アカウント登録" : t("sign_up")}
            </div>
          </NavLink>
          <NavLink
            to={isSchoolPublicScreen ? "/auth/school/signin" : "/signin"}
          >
            <button
              className="d-none d-lg-block "
              style={{
                backgroundColor: "white",
                color: "black",
                borderRadius: "40px",
                padding: "8px 35px",
              }}
            >
              {/* Login */}
              {isSchoolPublicScreen ? "ログイン" : t("Login")}
            </button>
          </NavLink>
        </>
      ) : (
        <Dropdown
          className="user-profile d-none d-lg-block "
          isOpen={dropdownOpen}
          toggle={toggle}
        >
          <DropdownToggle className="bg-transparent text-dark border-0 p-0">
            <Media body className="text-primary d-none d-lg-block">
              <Media heading tag="h5" className="text-dark">
                {role === "student" ? (
                  <>
                    <FontAwesomeIcon
                      style={{ width: "20px", height: "20px" }}
                      icon={faUser}
                    />
                    <FontAwesomeIcon
                      style={{ width: "20px", height: "20px" }}
                      icon={faCaretDown}
                    />
                  </>
                ) : role === "school" && text === "" ? (
                  <div className="school_header_truncate d-flex">
                    {/* <div
                      className="school_header_truncate_name"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title={
                        text
                          ? text
                          : localStorage.getItem("getSchoolProfileName")
                            ? localStorage.getItem("getSchoolProfileName")
                            : email
                      }
                    >
                      <span style={{ fontSize: "20px" }}>

                        {localStorage.getItem("getSchoolProfileName") == null
                          ? email
                          : localStorage.getItem("getSchoolProfileName")}
                      </span>
                      <span style={{ fontSize: "20px" }}>

                        {text == null
                          ? localStorage.getItem("getSchoolProfileName")
                          : text}
                      </span>
                    </div> */}
                    <div title={
                      text
                        ? text
                        : localStorage.getItem("getSchoolProfileName")
                          ? localStorage.getItem("getSchoolProfileName")
                          : email
                    }>
                      <FontAwesomeIcon
                        style={{ width: "30px", height: "30px" }}
                        icon={faSchool}
                      />
                      <FontAwesomeIcon
                        style={{ width: "20px", height: "20px" }}
                        icon={faCaretDown}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="d-flex">
                    {/* <div
                      className=" school_header_truncate_name"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title={text}
                    >
                      {text}
                    </div> */}
                    <div>
                      <FontAwesomeIcon
                        style={{ width: "30px", height: "30px" }}
                        icon={faSchool}
                      />
                      <FontAwesomeIcon
                        style={{ width: "20px", height: "20px" }}
                        icon={faCaretDown}
                      />
                    </div>
                  </div>
                )}
              </Media>
            </Media>
          </DropdownToggle>
          {role == "school" && (
            <DropdownMenu className="p-0">
              <NavItem>
                {num || schoolDataValue.schoolList.length >= 1 ? (
                  <NavLink
                    className="nav-link single-link position-relative "
                    exact
                    to="/school/dashboard"
                    onClick={() => {
                      toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-2 position-relative text-primary"
                      icon={faChalkboardTeacher}
                    />
                    {/* 学校Dashboard */}
                    ダッシュボード
                  </NavLink>
                ) : (
                  <NavLink
                    className="nav-link single-link position-relative "
                    exact
                    to="/school/dashboard"
                    onClick={() => {
                      toggle();
                    }}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "rgba(0,0,0,0.12)",
                      opacity: "0.5",
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-2 position-relative text-primary"
                      icon={faChalkboardTeacher}
                    />
                    {/* 学校Dashboard */}
                    ダッシュボード
                  </NavLink>
                )}
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link single-link position-relative "
                  exact
                  to="/school/profile"
                  onClick={() => {
                    toggle();
                  }}
                >
                  <FontAwesomeIcon
                    className="mr-2 position-relative text-primary"
                    icon={faUserEdit}
                  />
                  {/* My Profile */}
                  {/* プロフィール */}
                  学校プロフィール
                </NavLink>
              </NavItem>

              <NavItem>
                {num || schoolDataValue.schoolList.length >= 1 ? (
                  <NavLink
                    className="nav-link single-link position-relative "
                    exact
                    to="/school/pr/edit"
                    onClick={() => {
                      toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-2 position-relative text-primary"
                      icon={faUserEdit}
                    />
                    {/* School PR */}
                    学校PR
                    {/* 学校より */}
                  </NavLink>
                ) : (
                  <NavLink
                    className="nav-link single-link position-relative "
                    exact
                    to="/school/pr/edit"
                    onClick={() => {
                      toggle();
                    }}
                    style={{
                      pointerEvents: "none",
                      opacity: "0.5",
                      backgroundColor: "rgba(0,0,0,0.12)",
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-2 position-relative text-primary"
                      icon={faUserEdit}
                    />
                    {/* School PR */}
                    学校PR
                    {/* 学校より */}
                  </NavLink>
                )}
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link single-link position-relative "
                  exact
                  to="/school/search/student"
                  onClick={() => {
                    toggle();
                  }}
                >
                  <FontAwesomeIcon
                    className="mr-2 position-relative text-primary"
                    icon={faSearch}
                  />
                  {/* School PR */}
                  学生検索
                  {/* 学校より */}
                </NavLink>
              </NavItem>
              {/* ---------------------------------------Staff Added in School---------------------------------------- */}
              {console.log("num of school list", num)}
              {console.log(
                "num of school list from context",
                schoolDataValue.schoolList.length
              )}
              <NavItem>
                {hasMaster == "true" &&
                  (num || schoolDataValue.schoolList.length >= 1) ? (
                  <NavLink
                    className="nav-link single-link position-relative "
                    exact
                    to="/staff_account"
                    onClick={() => {
                      toggle();
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-2 position-relative text-primary"
                      icon={faUsers}
                    />
                    {/* Add multiple staff */}
                    スタッフ追加
                  </NavLink>
                ) : (
                  <NavLink
                    className="nav-link single-link position-relative "
                    exact
                    to="/staff_account"
                    onClick={() => {
                      toggle();
                    }}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "rgba(0,0,0,0.12)",
                      opacity: "0.5",
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-2 position-relative text-primary"
                      icon={faUsers}
                    />
                    {/* Add multiple staff */}
                    スタッフ追加
                  </NavLink>
                )}
              </NavItem>
              {hasMaster == "true" && schoolDataValue.schoolList.length > 1 ? (
                <NavItem>
                  <NavLink
                    className="nav-link single-link position-relative "
                    exact
                    to="/school_top?school=switch"
                    onClick={() => {
                      toggle();
                      handleSwitch();
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-2 position-relative text-primary"
                      icon={faChalkboardTeacher}
                    />
                    スクール切り替え
                  </NavLink>
                </NavItem>
              ) : (
                <NavItem>
                  <NavLink
                    className="nav-link single-link position-relative "
                    exact
                    to="/school_top"
                    onClick={() => {
                      toggle();
                      handleSwitch();
                    }}
                    style={{
                      pointerEvents: "none",
                      backgroundColor: "rgba(0,0,0,0.12)",
                      opacity: "0.5",
                    }}
                  >
                    <FontAwesomeIcon
                      className="mr-2 position-relative text-primary"
                      icon={faChalkboardTeacher}
                    />
                    スクール切り替え
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className="nav-link single-link position-relative "
                  exact
                  to="/auth/school/changepassword"
                  onClick={() => {
                    toggle();
                  }}
                >
                  <FontAwesomeIcon
                    className="mr-2 position-relative text-primary"
                    icon={faCog}
                  />
                  {/* Change Password */}
                  パスワードを変更
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link single-link position-relative "
                  exact
                  to=""
                >
                  <FontAwesomeIcon
                    className=" mr-2 position-relative  text-primary"
                    icon={faSignInAlt}
                  />
                  <span onClick={(e) => handleLogout(role)}>
                    {/* Logout */}
                    ログアウト
                  </span>
                </NavLink>
              </NavItem>
            </DropdownMenu>
          )}
          {role != "school" && (
            <DropdownMenu className="p-0">
              <NavItem>
                <NavLink
                  className="nav-link single-link position-relative "
                  exact
                  to="/student/profile"
                  onClick={() => {
                    toggle();
                  }}
                >
                  <FontAwesomeIcon
                    className="mr-2 position-relative text-primary"
                    icon={faUserEdit}
                  />
                  {/* My Profile */}
                  {t("profile")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link single-link position-relative "
                  exact
                  to="/changepassword"
                  onClick={() => {
                    toggle();
                  }}
                >
                  <FontAwesomeIcon
                    className="mr-2 position-relative text-primary"
                    icon={faCog}
                  />
                  {/* Change Password */}
                  {t("change_password")}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className="nav-link single-link position-relative "
                  exact
                  to=""
                >
                  <FontAwesomeIcon
                    className=" mr-2 position-relative  text-primary"
                    icon={faSignInAlt}
                  />
                  <span onClick={handleLogout}>
                    {/* Logout */}
                    {t("logout")}
                  </span>
                </NavLink>
              </NavItem>
            </DropdownMenu>
          )}
        </Dropdown>
      )}
      <Menubar
        loggedIn={loggedIn}
        username={username}
        email={email}
        isSchoolPublicScreen={isSchoolPublicScreen}
      />
    </>
  );
};

export default ButtonGroups;
