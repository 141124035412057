import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleUp,
  faChevronCircleDown,
} from "@fortawesome/free-solid-svg-icons";

const AboutSponsor = ({ sponsorInfo }) => {
  return (
    <div>
      <div className=" row rowOutline--wrapper" id="sponsorSection">
        <div
          className="row px-0 rowOutline"
          style={{
            border: "1px solid #C7C7C7",
            marginBottom: "30px",
            borderRadius: "7px",
          }}
        >
          <div className="col-12 px-4 school-headerclr">
            <div className="d-flex justify-content-between">
              <span>
                {/* About Sponsor */}
                経費支弁者について
              </span>
              {/* <span>
                {" "}
                <FontAwesomeIcon icon={faChevronCircleUp} />{" "}
                <FontAwesomeIcon icon={faChevronCircleDown} />{" "}
              </span>{" "} */}
            </div>
          </div>
          <div className="row formInner">
            {/* Sponsor's name' */}
            <div className="col-12">
              <label for="sponsorName" class="form-label title-label">
                {/* Sponsor's Name */}
                経費支弁者 氏名
              </label>
              <br />
              <label class="form-label info-label">
                {sponsorInfo.sponsorName != "" ? sponsorInfo.sponsorName : "-"}
              </label>
            </div>
            {/* Sponsor's name' */}
            <div className="col-12">
              <label for="sponsorAddress" class="form-label title-label">
                {/* Sponsor's Address */}
                経費支弁者 住所
              </label>
              <br />
              <label class="form-label info-label">
                {sponsorInfo.sponsorAddress != ""
                  ? sponsorInfo.sponsorAddress
                  : "-"}
              </label>
            </div>
            {/* Sponsor's name' */}
            <div className="col-12">
              <label for="relationWithApplicant" class="form-label title-label">
                {/* Sponsor's Relationship with the Applicant */}
                経費支弁者 申請者との関係
              </label>
              <br />
              <label class="form-label info-label">
                {sponsorInfo.relationWithApplicant != ""
                  ? sponsorInfo.relationWithApplicant
                  : "-"}
              </label>
            </div>
            {/* Sponsor's name' */}
            <div className="col-12">
              <label for="sponsorAnnualIncome" class="form-label title-label">
                {/* Sponsor's Annual Income */}
                経費支弁者 年収
              </label>
              <br />
              <label class="form-label info-label">
                {sponsorInfo.sponsorAnnualIncome != ""
                  ? sponsorInfo.sponsorAnnualIncome
                  : "-"}
              </label>
            </div>
            {/* Sponsor's name' */}
            <div className="col-12">
              <label for="sponsorOccupation" class="form-label title-label">
                {/* Sponsor's Occupation */}
                経費支弁者 職業
              </label>
              <br />
              <label class="form-label info-label">
                {sponsorInfo.sponsorOccupation != ""
                  ? sponsorInfo.sponsorOccupation
                  : "-"}
              </label>
            </div>
            {/* Sponsor's name' */}
            <div className="col-12">
              <label for="sponsorEmployer" class="form-label title-label">
                {/* Sponsor's Employer */}
                経費支弁者 勤務先名
              </label>
              <br />
              <label class="form-label info-label">
                {sponsorInfo.sponsorEmployer != ""
                  ? sponsorInfo.sponsorEmployer
                  : "-"}
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutSponsor;
